import React, { ReactElement } from 'react';
import { TimeStampOptions } from '@sprinklr/stories/widget/types';
import { getEndianTime, getPrettyTimeAgo } from 'utils/Number/NumberUtils';

// Meta
export interface MetaProps {
    category: string;
    timestamp: {
        value: number;
        format?: TimeStampOptions;
        endian?: string;
    };
}
export function Meta({ category, timestamp }: MetaProps): ReactElement {
    const timeStampValue = React.useMemo(() => {
        const { value, format = 'timePosted', endian = 'MMM DD, h:mm A' } = timestamp;
        switch (format) {
            case 'timePosted':
                return getEndianTime(value, endian);
            case 'timeNoAgo':
                return getPrettyTimeAgo(value, false);
            default:
            case 'timeAgo':
                return getPrettyTimeAgo(value);
        }
    }, [timestamp.value, timestamp.format]);

    return (
        <span key='meta' className='flex middle story_meta secondary_font_family'>
            {timestamp.value && <span className='story_timestamp'>{timeStampValue}</span>}
            {timestamp.value && category && <span>|</span>}
            {category && <span className='flex story_category'>{category}</span>}
        </span>
    );
}
