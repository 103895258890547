import { ReviewOptions } from '@sprinklr/stories/widget/types';

export const portraitReviewOpts: ReviewOptions = {
    enabled: false,
    title: {
        enabled: true,
        color: '',
        size: 21,
        format: {
            type: 'clamp',
            clamp: {
                enabled: true,
                lines: 3,
            },
            fit: {
                min: 20,
                max: 400,
            },
        },
    },
    rating: {
        enabled: true,
        filledColor: '',
        emptyColor: '',
        size: 17,
    },
    product: {
        enabled: true,
        color: '',
        size: 20,
    },
    message: {
        enabled: true,
        size: 14,
        color: '',
        format: {
            type: 'clamp',
            clamp: {
                enabled: true,
                lines: 5,
            },
            fit: {
                min: 20,
                max: 400,
            },
        },
    },
    domain: {
        enabled: true,
        color: '',
        size: 20,
    },
};

export const retweetIndicatorConfig = {};

export const defaultSmartSize = {
    baseline: {
        postImageTextSize: 30,
        postTextOnlySize: 30,
        userGroupNameSize: 17,
        postLineClampValue: 8,
        userGroupScreenNameSize: 11,
        userGroupAvatarSize: 25,
        userGroupIconSize: 14,
        engagementMetricsSize: 27,
        imagePostLineClampValue: 8,
        nativeStyling: {
            fontScale: 45,
        },
    },
    optionDefaults: {
        userGroupLayoutType: 'c',
        imagePostLineClampValue: 8,
        postLineClampValue: 8,
    },
    breakpoints: {
        xs: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 3,
                postLineClampValue: 3,
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-05 pl-0 pr-0 pb-0',
                    },
                    postInner: {
                        default: 'p-05',
                    },
                },
            },
        },
        s: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 3,
                postLineClampValue: 3,
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-05 pl-1 pr-1 pb-0',
                    },
                    postInner: {
                        default: 'p-05',
                    },
                },
            },
        },
        m: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 4,
                postLineClampValue: 4,
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-1 pl-15 pr-15 pb-0',
                    },
                    postInner: {
                        default: 'p-1',
                    },
                },
            },
        },
        l: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 5,
                postLineClampValue: 5,
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-1 pl-15 pr-15 pb-0',
                    },
                    postInner: {
                        default: 'p-15',
                    },
                },
            },
        },
    },
};
