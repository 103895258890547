import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet';
import styled from 'components/_UI/designSystem';
import { Flexbox, Spinner } from 'components/spaceKit';
import { FALLBACK_SPINNER } from 'utils/constants';
import config from '../../config';
import { useMemo } from 'react';
import ModuleNotEnabled from '@sprinklr/display-builder/containers/App/ModuleNotEnabled';
import Auth from '@sprinklr/display-builder/containers/Auth/Auth';
import useIsStandalone from '@sprinklr/display-builder/hooks/useIsStandalone';
import { RouteProps } from 'react-router';
import useStores from '@sprinklr/display-builder/hooks/useStores';
import { displayService } from '@sprinklr/display-builder/serviceContext/displayService';

const Menu = loadable(
    () => import(/* webpackChunkName: "Menu" */ 'components/Menu/Menu'),
    FALLBACK_SPINNER
);

type PrivateRouteProps = RouteProps & {
    component: any;
    suppressNavigation?: boolean;
};

export const StyledBuilder = styled(Flexbox)`
    height: 100vh;
    width: 100vw;
`;

let inIframe: boolean;
try {
    inIframe = window.self !== window.top;
} catch (e) {
    inIframe = true;
}

const helmet = (
    <Helmet>
        <meta charSet='utf-8' />
        <title>{`Sprinklr ${config.siteTitle}`}</title>
        <meta name='apple-mobile-web-app-title' content={`Sprinklr ${config.siteTitle}`} />
    </Helmet>
);

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    suppressNavigation,
    ...outerProps
}) => {
    const WrappedComponent = useMemo(() => {
        const NewComponent: React.FC<RouteComponentProps> = props => {
            const { location, match } = props;
            const { authService } = useStores();

            const isStandalone = useIsStandalone();
            const { applicationMode } = config;
            const { isLoggedIn } = authService;
            const moduleEnabled = authService.hasModule(applicationMode);

            if (isLoggedIn && !moduleEnabled) {
                return <ModuleNotEnabled authService={authService} />;
            }

            const renderMenu: boolean =
                !inIframe && (applicationMode !== 'EMBED' || isStandalone) && !suppressNavigation;

            const params: any = match.params ?? {};

            return (
                <div className={config.classRoot}>
                    {helmet}
                    <Auth clientId={params.clientId}>
                        <StyledBuilder className='builder' vertical>
                            {renderMenu && <Menu location={location} />}

                            <Component {...props} suppressNavigation />
                        </StyledBuilder>
                    </Auth>
                </div>
            );
        };
        return observer(NewComponent);
    }, [suppressNavigation, Component]);

    return (
        <Provider displayService={displayService}>
            <React.Suspense fallback={<Spinner />}>
                <Route {...outerProps} component={WrappedComponent} />
            </React.Suspense>
        </Provider>
    );
};

export default observer(PrivateRoute);
