import * as React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import PartsBuilder from '../../PartsBuilder/PartsBuilder';
import StackedBar from './StackedBar';
import Icon from '../../Icon/Icon';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { Threshold } from '../../Threshold/Thresholds';
import RenderSettingsService from '../../../services/RenderSettingsService/RenderSettingsService';
import NumberFormat from 'react-number-format';
import CountUp from 'react-countup';
import {
    DecimalFormat,
    getDecimalsByFormat,
    getPrettyMetric,
    getPrettyNumber,
    getPrettyTimeInterval,
} from 'utils/Number/NumberUtils';
import { HAlign } from '@sprinklr/stories/widget/types';
import i18next from 'i18next';
import { VizSegmentClick } from 'components/Widget/WidgetComponent/types';
import { FontFamilyPreset } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

import './Metric.scss';
import { FontCase } from 'models/Theme/Theme';
import { StatusTagDot } from '../../statusTagDot/StatusTagDot';
import { StatusTag } from '../../../widgets/utils/getStatusTag';

export interface MetricProps {
    value?: number;
    valueColor?: string;
    type?: FieldType;
    ratio?: number;
    previousValue?: number;
    name?: string;
    icon?: string;
    timePeriod?: string;
    renderSettingsService?: RenderSettingsService;
    statusTag?: StatusTag;

    layoutType?: 'default' | 'reversed' | 'stacked' | 'valueOnly' | null;
    alignment?: HAlign;
    metricClasses?: string;
    metricValueClasses?: string;
    metricRatioClasses?: string;
    metricRatioGroupClasses?: string;
    metricNameClasses?: string;
    metricIconClasses?: string;
    metricGroupClasses?: string;

    // options
    isPrettyNumber?: boolean;
    countUp?: boolean;
    countUpDuration?: number;
    countUpEasing?: boolean;
    showIcon?: boolean;
    wrapIcon?: boolean;

    decimalPlaces?: number;
    valueFormat?: DecimalFormat;
    valueCase?: FontCase;
    valuePrefix?: string;

    primaryClasses?: string;
    highlightPercentChange?: boolean;
    percentChangeFormat?: DecimalFormat;
    showBackgroundCircle?: boolean;

    showMetricValue?: boolean;
    showMetricName?: boolean;
    showMetricChange?: boolean;
    showTimeRemainder?: boolean;
    metricTimeRoundSeconds?: boolean;

    showMetricPreviousValue?: boolean;
    metricPreviousValueSize?: number;
    previousValueFormat?: DecimalFormat;
    previousValuePretty?: boolean;

    metricValueNameStacked?: boolean;

    animationDelay?: number;
    animationEnabled?: boolean;
    animationLength?: number;

    metricUsePrimaryFontFamily?: boolean;
    isPercentage?: boolean;

    thresholds?: Threshold[];
    thresholdsEnabled?: boolean;
    thresholdBarHeight?: number;
    thresholdBarLength?: number;
    thresholdsPercent?: boolean;
    fontFamily?: FontFamilyPreset;
    onSegmentClick?: VizSegmentClick;

    showTrailingZeros?: boolean;
}

class Metric extends React.Component<MetricProps, any> {
    public static defaultProps: MetricProps = {
        value: 12342.1,
        type: null,
        // ratio: 0, // percent change
        name: null, // metric name
        icon: null,
        showIcon: false,
        wrapIcon: false,
        layoutType: 'default',
        alignment: 'right',

        // classes
        metricClasses: null,
        metricValueClasses: null,
        metricRatioClasses: null,
        metricRatioGroupClasses: null,
        metricNameClasses: null,
        metricIconClasses: null,
        metricGroupClasses: null,

        // options
        isPrettyNumber: true, // do we make the number pretty
        showTimeRemainder: true, // if data is TIME_INTERVAL, do we show non-integer part as decimal or remainder?

        countUp: true, // do we count up the number
        countUpDuration: 30,
        countUpEasing: true,

        decimalPlaces: 1,

        metricValueNameStacked: true,
        metricUsePrimaryFontFamily: true,

        isPercentage: false,
        thresholdBarLength: 10,

        showTrailingZeros: false,
    };

    constructor(props: any) {
        super(props);
    }

    render() {
        const {
            name,
            showMetricName,
            metricClasses,
            metricValueClasses,
            metricRatioGroupClasses,
            metricNameClasses,
            metricIconClasses,
            metricGroupClasses,
            isPrettyNumber,
            decimalPlaces,
            valueFormat,
            valueCase,
            valuePrefix,
            countUp,
            countUpEasing,
            layoutType,
            type,
            alignment,
            showIcon,
            wrapIcon,
            highlightPercentChange,
            metricValueNameStacked,
            metricUsePrimaryFontFamily,
            isPercentage,
            showTimeRemainder,
            metricTimeRoundSeconds,
            thresholds,
            thresholdsEnabled,
            thresholdBarHeight,
            thresholdBarLength,
            thresholdsPercent,
            percentChangeFormat,
            previousValue,
            previousValueFormat,
            previousValuePretty,
            countUpDuration,
            fontFamily = 'primary_font_family',
            valueColor,
            statusTag,
            showTrailingZeros,
        } = this.props;

        const supportsRemainder = type === 'TIME_INTERVAL';
        const showRemainder = showTimeRemainder && supportsRemainder;
        const percentChangePretty = percentChangeFormat !== '1,234';
        const adjustedCountupDuration = countUpDuration * 0.1;
        const icon = showIcon ? this.props.icon : null;

        const horAlign =
            layoutType === 'stacked' || layoutType === 'reversed' ? 'center' : alignment;

        const isCurrency: boolean = valuePrefix === '$';
        const decimals: number = valueFormat
            ? getDecimalsByFormat(valueFormat, isCurrency, this.props.value)
            : decimalPlaces;
        const percentChangeDecimals: number = percentChangeFormat
            ? getDecimalsByFormat(percentChangeFormat, false, this.props.ratio)
            : decimalPlaces;
        const previousValueDecimals: number = previousValueFormat
            ? getDecimalsByFormat(previousValueFormat, isCurrency, previousValue)
            : decimalPlaces;

        if (isPrettyNumber) {
            switch (type) {
                case 'NUMBER':
                default:
                    var { suffix: valueSuffix, value } = getPrettyNumber(
                        this.props.value,
                        decimals,
                        isPercentage,
                        valueCase
                    );
                    break;
                case 'TIME_INTERVAL':
                    var {
                        suffix: valueSuffix,
                        value,
                        remainder,
                        remainderSuffix,
                    } = getPrettyTimeInterval(
                        this.props.value,
                        showRemainder,
                        metricTimeRoundSeconds
                    );
                    break;
            }
        } else {
            var { value } = this.props;
        }

        if (percentChangePretty) {
            var { suffix: ratioSuffix, value: ratio } = getPrettyNumber(
                this.props.ratio,
                percentChangeDecimals,
                isPercentage
            );
        } else {
            var { ratio } = this.props;
        }

        // Disabling countUp the more obvious ways doesn't work. We used to set the count time to a very small value but
        // this proved troublesome for snapshot tests, sometimes catching zeros or intermediate values.
        const reallyCountUp = this.props.renderSettingsService.countUpEnabled && countUp;
        const startValue = reallyCountUp ? 0 : value;
        const startRatio = reallyCountUp ? 0 : ratio;

        const {
            suffix: previousSuffix,
            value: previousVal = previousValue,
            remainder: previousRemainder,
            remainderSuffix: previousRemainderSuffix,
        } =
            (previousValuePretty &&
                getPrettyMetric({
                    value: previousValue,
                    showTimeRemainder: showRemainder,
                    roundSeconds: metricTimeRoundSeconds,
                    decimals: previousValueDecimals,
                    type,
                    isPercentage,
                })) ||
            {};

        const validPreviousValue = previousValue !== null && previousValue !== undefined;

        const metricClassNames = classNames(`metric flex no-grow layout_type_${layoutType}`, {
            [`${metricClasses}`]: metricClasses !== null,
            vertical: layoutType === 'stacked',
        });

        const metricNameClassNames = classNames('metric_name', {
            [`${metricNameClasses}`]: metricNameClasses !== null,
        });
        const metricValueClassNames = classNames('metric_value', {
            [`${metricValueClasses}`]: metricValueClasses !== null,
        });

        const metricRatioClassNames = classNames('metric_ratio flex', {
            [`${metricRatioGroupClasses}`]: metricRatioGroupClasses !== null,
            'change_positive top': ratio > 0,
            'secondary_color change_negative bottom': ratio < 0,
        });

        const metricPreviousValueClassNames = classNames('metric_previous_value', {});

        const metricIconClassNames = classNames('metric_icon', {
            [`${metricIconClasses}`]: metricIconClasses !== null,
        });
        const metricGroupClassNames = classNames('group fb-a', {
            [`${metricGroupClasses}`]: metricGroupClasses !== null,
        });

        let threshold = null;
        let thresholdGoal = null;
        let asPercent = null;

        if (
            thresholdsEnabled &&
            thresholds !== undefined &&
            thresholds !== null &&
            thresholds.length > 0
        ) {
            threshold = {
                name,
                label: thresholds[0].name,
                value: this.props.value,
                type: this.props.type,
                threshold: thresholds[0].value,
                color: thresholds[0].color,
                timeInterval: thresholds[0].timeInterval,
            };

            threshold.threshold !== null &&
            threshold.threshold !== undefined &&
            !Number.isNaN(threshold.threshold)
                ? (thresholdGoal = threshold.threshold)
                : (thresholdGoal = 0);
            threshold.threshold = thresholdGoal;

            thresholdGoal === 0
                ? // Prevents 'infinity' value or error due to division by 0
                  (asPercent = (this.props.value / 1) * 100)
                : (asPercent = (this.props.value / thresholdGoal) * 100);
        }

        const metricValue =
            value !== null && !countUp && !isPrettyNumber ? (
                <NumberFormat
                    className={classNames(`metric_value_inner ${fontFamily}`, {})}
                    value={value.toFixed(decimals)}
                    thousandSeparator={true}
                    suffix={isPercentage ? '%' : ''}
                    displayType='text'
                    color={valueColor}
                />
            ) : value !== null ? (
                <div className='flex' style={{ color: valueColor }}>
                    <CountUp
                        className='metric_value_inner flex'
                        key={'' + value + decimals + valuePrefix + valueSuffix} // component wasn't observing decimal changes
                        start={
                            countUp
                                ? startValue
                                : thresholdsPercent && thresholdsEnabled
                                ? asPercent
                                : value
                        }
                        end={thresholdsPercent && thresholdsEnabled ? asPercent : value}
                        useEasing={countUpEasing}
                        duration={adjustedCountupDuration}
                        decimals={value === Math.round(value) && !showTrailingZeros ? 0 : decimals}
                        separator=','
                        prefix={valuePrefix}
                        suffix={
                            ((thresholdsPercent && thresholdsEnabled) || isPercentage) &&
                            !valueSuffix?.includes('%')
                                ? '%'
                                : valueSuffix
                        }
                    />

                    {showRemainder &&
                        remainder !== 0 &&
                        remainder !== null &&
                        remainder !== undefined && (
                            <CountUp
                                className='metric_value_inner flex ml-05'
                                key={'' + remainder + decimals + valuePrefix + remainderSuffix} // component wasn't observing decimal changes
                                start={countUp ? startValue : remainder}
                                end={remainder}
                                useEasing={countUpEasing}
                                duration={adjustedCountupDuration}
                                decimals={remainder === Math.round(remainder) ? 0 : decimals}
                                separator=','
                                prefix={valuePrefix}
                                suffix={remainderSuffix}
                            />
                        )}
                </div>
            ) : (
                <div key='empty'></div>
            );

        let metricRatio;
        if (ratio !== null) {
            metricRatio =
                !countUp && !isPrettyNumber
                    ? [
                          <div key='1' className='metric_change_arrow no-grow'>
                              {ratio > 0 && <span>▲</span>}
                              {ratio < 0 && <span>▼</span>}
                          </div>,
                          <div key='2' className={`metric_ratio_inner ${fontFamily}`}>
                              {Math.abs(ratio).toFixed(
                                  ratio === Math.round(ratio) ? 0 : percentChangeDecimals
                              )}{' '}
                              %
                          </div>,
                      ]
                    : [
                          <div key='1' className='metric_change_arrow no-grow'>
                              {ratio > 0 && <span>▲</span>}
                              {ratio < 0 && <span>▼</span>}
                          </div>,
                          <CountUp
                              className={`metric_ratio_inner ${fontFamily}`}
                              key={ratio + percentChangeDecimals + ratioSuffix + 2}
                              start={Math.abs(countUp ? startRatio : ratio)}
                              end={Math.abs(ratio)}
                              useEasing={countUpEasing}
                              duration={adjustedCountupDuration}
                              decimals={ratio === Math.round(ratio) ? 0 : percentChangeDecimals}
                              separator=','
                              suffix={`${
                                  ratioSuffix && ratioSuffix.indexOf('%') === -1
                                      ? ratioSuffix + '%'
                                      : '%'
                              }`}
                          />,
                      ];
        }

        let metricPreviousValue = null;

        const renderPrevious = (
            <CountUp
                className={`metric_ratio_previous ${fontFamily}`}
                key={'' + previousVal + previousValueDecimals + valuePrefix + previousSuffix} // component wasn't observing decimal changes
                start={countUp ? startValue : previousVal}
                end={previousVal}
                useEasing={countUpEasing}
                duration={adjustedCountupDuration}
                decimals={previousValueDecimals}
                separator=','
                prefix={i18next.t('Previous Period') + ': ' + valuePrefix}
                suffix={thresholdsPercent && thresholdsEnabled ? '%' : previousSuffix}
            />
        );

        const renderPreviousRemainder =
            showRemainder && previousRemainder ? (
                <CountUp
                    className='metric_ratio_previous metric_ratio_previous_remainder flex ml-05'
                    key={'' + previousRemainder + decimals + valuePrefix + previousRemainderSuffix} // component wasn't observing decimal changes
                    start={countUp ? startValue : previousRemainder}
                    end={previousRemainder}
                    useEasing={countUpEasing}
                    duration={adjustedCountupDuration}
                    decimals={remainder === Math.round(previousRemainder) ? 0 : decimals}
                    separator=','
                    suffix={previousRemainderSuffix}
                />
            ) : null;

        const resolvedPrevious =
            showRemainder && previousRemainder ? (
                <div className='flex metric_ratio_previous_group' style={{ color: valueColor }}>
                    {renderPrevious} {renderPreviousRemainder}
                </div>
            ) : (
                renderPrevious
            );

        if (validPreviousValue && previousVal !== null) {
            metricPreviousValue = countUp ? (
                resolvedPrevious
            ) : (
                <div
                    key='metricPreviousValue_prefix'
                    className={`metric_ratio_previous ${fontFamily}`}>
                    {`${i18next.t('Previous Period')}: `}
                    <NumberFormat
                        key={'metricPreviousValue' + previousValueDecimals} // component wasn't observing decimal changes
                        className={classNames(`metric_ratio_previous ${fontFamily}`, {})}
                        value={
                            previousVal
                                ? previousVal.toFixed(previousValueDecimals)
                                : previousValue.toFixed(previousValueDecimals)
                        }
                        thousandSeparator={true}
                        displayType='text'
                        suffix={previousSuffix}
                    />
                </div>
            );
        }

        const metricIcon = (
            <Icon
                wrap={wrapIcon}
                value={
                    icon
                        ? (icon + '')
                              .replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '')
                              .replace(/\s/g, '_')
                              .toLowerCase()
                        : ''
                }
            />
        );

        const metricName = (
            <span key='metricName' className='metric_name_inner secondary_font_family'>
                {showMetricName ? name : null}
            </span>
        );

        const statusTagRender = statusTag ? (
            <StatusTagDot
                color={statusTag.color}
                size={statusTag.size}
                animation={statusTag.animation}
            />
        ) : null;

        let parts: any = [];

        const previousFigure: boolean = ratio !== null || validPreviousValue;

        switch (layoutType) {
            case 'default':
                // has icon
                if (icon) {
                    // has ratio and icon
                    if (previousFigure) {
                        parts = [
                            {
                                classes: `${metricIconClassNames} flex no-grow`,
                                content: metricIcon,
                            },

                            {
                                classes: `metric_group flex middle right`,
                                parts: [
                                    {
                                        classes: `metric_inner_group top flex no-grow`,
                                        parts: [
                                            {
                                                classes: `${metricGroupClassNames} metric_value_name_group group_index_1 flex vertical top ${horAlign}`,
                                                parts: [
                                                    {
                                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                                        content: metricValue,
                                                    },
                                                    {
                                                        classes: `${metricGroupClassNames} metric_previous_value group_index_2 flex top`,
                                                        parts: [
                                                            {
                                                                classes: `${metricPreviousValueClassNames}`,
                                                                content: metricPreviousValue,
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        classes: `${metricNameClassNames}`,
                                                        content: metricName,
                                                    },
                                                ],
                                            },
                                            {
                                                classes: `${metricGroupClassNames} metric_ratio group_index_2 flex top`,
                                                parts: [
                                                    {
                                                        classes: `${metricRatioClassNames}`,
                                                        content: metricRatio,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ];
                        // has icon but NO ratio
                    } else {
                        parts = [
                            {
                                classes: `${metricIconClassNames} flex top no-grow`,
                                content: metricIcon,
                            },
                            {
                                classes: `${metricGroupClassNames} flex vertical middle ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                    // NO icon
                } else {
                    if (previousFigure) {
                        // NO icon but HAS ratio
                        parts = [
                            {
                                classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                parts: [
                                    {
                                        classes: `primary_size`,
                                        content: statusTagRender,
                                    },
                                ],
                            },
                            {
                                classes: `${metricGroupClassNames} group_index_1 flex vertical ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricPreviousValueClassNames}`,
                                        content: metricPreviousValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                            {
                                classes: `${metricGroupClassNames} group_index_2 flex top no-grow ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricRatioClassNames}`,
                                        content: metricRatio,
                                    },
                                ],
                            },
                        ];
                    } else {
                        // NO icon and NO ratio
                        parts = [
                            {
                                classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                parts: [
                                    {
                                        classes: `primary_size`,
                                        content: statusTagRender,
                                    },
                                ],
                            },
                            {
                                classes: `${metricGroupClassNames} flex ${horAlign} ${
                                    metricValueNameStacked ? 'vertical' : 'horizontal'
                                }`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${
                                            metricUsePrimaryFontFamily ? fontFamily : ''
                                        } `,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                }

                break;
            case 'reversed':
                // HAS icon
                if (icon) {
                    // HAS ratio and HAS icon
                    if (previousFigure) {
                        parts = [
                            {
                                classes: `${metricIconClassNames} top flex no-grow`,
                                content: metricIcon,
                            },
                            {
                                classes: `${metricGroupClassNames} flex vertical ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricRatioClassNames} primary_size ${fontFamily} no-grow ${alignment}`,
                                        content: metricRatio,
                                    },
                                    {
                                        classes: `${metricGroupClassNames} metric_value_name_group metric_group_inline ${horAlign}`,
                                        parts: [
                                            {
                                                classes: `${metricValueClassNames}`,
                                                content: metricValue,
                                            },
                                            {
                                                classes: `${metricPreviousValueClassNames} primary_size ${fontFamily} no-grow ${alignment}`,
                                                content: metricPreviousValue,
                                            },
                                            {
                                                classes: `${metricNameClassNames}`,
                                                content: metricName,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ];
                        // HAS icon but NO ratio
                    } else {
                        parts = [
                            {
                                classes: `${metricIconClassNames} flex top no-grow`,
                                content: metricIcon,
                            },
                            {
                                classes: `${metricGroupClassNames} flex vertical ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                    // NO icon
                } else {
                    if (previousFigure) {
                        // NO icon but HAS ratio
                        parts = [
                            {
                                classes: `${metricGroupClassNames}`,
                                parts: [
                                    {
                                        classes: `${metricRatioClassNames} primary_size ${fontFamily} ${horAlign}`,
                                        content: metricRatio,
                                    },
                                    {
                                        classes: `${metricGroupClassNames} flex vertical w-100 metric_value_name_group metric_group_inline ${horAlign}`,
                                        parts: [
                                            {
                                                classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                                parts: [
                                                    {
                                                        classes: `primary_size`,
                                                        content: statusTagRender,
                                                    },
                                                    {
                                                        classes: `${metricValueClassNames} primary_size ${fontFamily} ${
                                                            thresholdsEnabled ? 'top' : ''
                                                        }`,
                                                        content: metricValue,
                                                    },
                                                ],
                                            },
                                            {
                                                classes: `${metricPreviousValueClassNames} primary_size ${fontFamily} ${horAlign}`,
                                                content: metricPreviousValue,
                                            },
                                            {
                                                classes: `${metricNameClassNames}`,
                                                content: metricName,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ];
                    } else {
                        // NO icon and NO ratio
                        parts = [
                            {
                                classes: `${metricGroupClassNames} flex vertical ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                }

                break;
            case 'stacked':
                // HAS icon
                if (icon) {
                    // HAS ratio and HAS icon
                    if (previousFigure) {
                        if (highlightPercentChange) {
                            parts = [
                                {
                                    classes: `${metricIconClassNames} flex center no-grow`,
                                    content: metricIcon,
                                },
                                {
                                    classes: `${metricRatioClassNames} ${horAlign} primary_size ${fontFamily}`,
                                    content: metricRatio,
                                },
                                {
                                    classes: `${metricGroupClassNames} metric_value_name_group metric_group_inline ${horAlign}`,
                                    parts: [
                                        {
                                            classes: `${metricValueClassNames}`,
                                            content: metricValue,
                                        },
                                        {
                                            classes: `${metricPreviousValueClassNames}`,
                                            content: metricPreviousValue,
                                        },
                                        {
                                            classes: `${metricNameClassNames}`,
                                            content: metricName,
                                        },
                                    ],
                                },
                            ];
                        } else {
                            // don't highlight percent change
                            parts = [
                                {
                                    classes: `${metricIconClassNames} flex center no-grow`,
                                    content: metricIcon,
                                },
                                {
                                    classes: `${metricGroupClassNames} metric_value_ratio_group flex top ${horAlign}`,
                                    parts: [
                                        {
                                            classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                            content: metricValue,
                                        },
                                        {
                                            classes: `${metricRatioClassNames} center`,
                                            content: metricRatio,
                                        },
                                    ],
                                },
                                {
                                    classes: `${metricPreviousValueClassNames} center`,
                                    content: metricPreviousValue,
                                },
                                {
                                    classes: `${metricNameClassNames} flex center`,
                                    content: metricName,
                                },
                            ];
                        }

                        // HAS icon but NO ratio
                    } else {
                        parts = [
                            {
                                classes: `${metricIconClassNames} flex center no-grow`,
                                content: metricIcon,
                            },
                            {
                                classes: `${metricGroupClassNames} flex vertical middle ${horAlign}`,
                                parts: [
                                    {
                                        classes: `${metricValueClassNames} primary_size ${fontFamily}`,
                                        content: metricValue,
                                    },
                                    {
                                        classes: `${metricNameClassNames}`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                    // NO icon
                } else {
                    if (previousFigure) {
                        // NO icon but HAS ratio
                        if (highlightPercentChange) {
                            parts = [
                                {
                                    classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                    parts: [
                                        {
                                            classes: `primary_size`,
                                            content: statusTagRender,
                                        },
                                    ],
                                },
                                {
                                    classes: `${metricRatioClassNames} primary_size ${fontFamily} top`,
                                    content: metricRatio,
                                },
                                {
                                    classes: `${metricGroupClassNames} metric_value_name_group metric_group_inline pt-05 flex center vertical ${horAlign}`,
                                    parts: [
                                        {
                                            classes: `${metricValueClassNames}`,
                                            content: metricValue,
                                        },
                                        {
                                            classes: `${metricPreviousValueClassNames} primary_size ${fontFamily} top`,
                                            content: metricPreviousValue,
                                        },
                                        {
                                            classes: `${metricNameClassNames}`,
                                            content: metricName,
                                        },
                                    ],
                                },
                            ];
                        } else {
                            // // NO icon but HAS ratio and don't highlight percent change
                            parts = [
                                {
                                    classes: `${metricGroupClassNames} metric_value_ratio_group flex ${
                                        thresholdsEnabled ? 'vertical right' : horAlign + ' top'
                                    }`,
                                    parts: [
                                        {
                                            classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                            parts: [
                                                {
                                                    classes: `primary_size`,
                                                    content: statusTagRender,
                                                },
                                                {
                                                    classes: `${metricValueClassNames} primary_size ${fontFamily} ${
                                                        thresholdsEnabled ? 'top' : ''
                                                    }`,
                                                    content: metricValue,
                                                },
                                            ],
                                        },
                                        {
                                            classes: `${metricRatioClassNames} ${
                                                thresholdsEnabled ? 'bottom' : 'center'
                                            }`,
                                            content: metricRatio,
                                        },
                                    ],
                                },
                                {
                                    classes: `${metricPreviousValueClassNames} ${
                                        thresholdsEnabled ? 'bottom' : 'center'
                                    }`,
                                    content: metricPreviousValue,
                                },
                                {
                                    classes: `${metricNameClassNames} flex center`,
                                    content: metricName,
                                },
                            ];
                        }
                    } else {
                        // NO icon and NO ratio
                        parts = [
                            {
                                classes: `${metricGroupClassNames} vertical metric_value_ratio_group flex ${
                                    alignment ? alignment : 'right'
                                }`,
                                parts: [
                                    {
                                        classes: `group_index_1 flex middle no-grow ${horAlign}`,
                                        parts: [
                                            {
                                                classes: `primary_size`,
                                                content: statusTagRender,
                                            },
                                            {
                                                classes: `${metricValueClassNames} primary_size ${fontFamily} top`,
                                                content: metricValue,
                                            },
                                        ],
                                    },
                                    {
                                        classes: `${metricNameClassNames} flex center`,
                                        content: metricName,
                                    },
                                ],
                            },
                        ];
                    }
                }
                break;
            case 'valueOnly':
                parts = [
                    {
                        classes: `${metricValueClassNames} primary_size ${
                            metricUsePrimaryFontFamily ? fontFamily : ''
                        }`,
                        content: metricValue,
                    },
                ];
                break;
            default:
                parts = [];
        }

        return (
            <div
                className='metric-wrapper'
                style={
                    thresholdsEnabled
                        ? { display: 'flex', flexDirection: 'column', height: '100%' }
                        : {}
                }
                onClick={this.handleSegmentClick}
                onTouchEnd={this.handleSegmentClick}>
                <PartsBuilder parts={parts} classes={metricClassNames} />
                {thresholdsEnabled && threshold !== undefined && threshold !== null && (
                    <StackedBar
                        metricGoal={threshold}
                        barWidth={thresholdBarHeight}
                        barLength={thresholdBarLength}
                        showPercent={thresholdsPercent}
                    />
                )}
            </div>
        );
    }

    handleSegmentClick = this.props.onSegmentClick
        ? (event: React.MouseEvent | React.TouchEvent) => {
              const { name, value, onSegmentClick } = this.props;

              if (!!onSegmentClick) {
                  onSegmentClick(
                      {
                          dataType: 'analytics',
                          segmentIndex: 0,
                          segments: [
                              {
                                  name,
                                  id: null,
                                  value,
                              },
                          ],
                          iteratorPrefix: `metric`,
                      },
                      event
                  );
              }
          }
        : undefined;
}

// CountUp props
// start
// end
// duration
// useEasing
// useGrouping
// separator
// decimals
// decimal
// prefix
// suffix
// callback

export default inject('renderSettingsService')(observer(Metric));
