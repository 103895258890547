export const FONT_WEIGHT_MAP = {
    light: 100,
    regular: 400,
    bold: 700,
};

export const GOOGLE_FONTS_BUILDER =
    'family=Abril+Fatface&family=Alice&family=Arvo:wght@400;700&family=Noto+Sans+JP:wght@400;700;900&family=M+PLUS+Rounded+1c:wght@400;700&family=Barlow+Condensed:wght@400;700&family=Roboto+Condensed:wght@300;400;700&family=M+PLUS+1p:wght@400;700&family=Rubik:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Crimson+Text:wght@400;700&family=Fredericka+the+Great&family=IBM+Plex+Sans:wght@400;700&family=Josefin+Slab:wght@400;700&family=Jost:wght@400;700&family=Kalam:wght@400;700&family=Libre+Baskerville:wght@400;700&family=Lora:wght@400;700&family=Marcellus&family=Montserrat:wght@100;400;700&family=Open+Sans:wght@400;700&family=Ovo&family=Poppins:wght@400;700&family=Prata&family=Questrial&family=Raleway:wght@400;700&family=Sawarabi+Gothic&family=Secular+One&family=Urbanist:wght@400;700&family=Work+Sans:wght@400;700&family=Zilla+Slab:wght@400;700&display=swap';

export const NON_GOOGLE_FONTS = [
    ' ハンナリレギュラー',
    'Bernier Regular',
    'Bernier Shade',
    'Bourbon Grotesque',
    'Dense',
    'FuturaMedium',
    'Hannari Regular',
    'Kokoro Regular',
    'LovinSans-Bold',
    'LovinSans-Light',
    'LovinSans-Medium',
    'LovinSans-Regular',
    'Museo Sans Rounded',
    'Orkney Regular',
    'SawarabiGothic Regular',
    'SawarabiMincho Regular',
    'Simplifica',
    'Wavehaus Bold',
    'ココロレギュラー',
    'サワラビゴシックレギュラー',
    'プラス太字',
    'ラウンドドレギュラー',
    '丸みを帯びた太字',
    '定期的にプラス',
    '澤原民朝レギュラー',
];
export const FONT_FAMILIES_VS_GOOGLE_FONT = {
    OpenSansBold: 'Open Sans',
    OpenSansRegular: 'Open Sans',
    'Open Sans Bold': 'Open Sans',
    'Open Sans Regular': 'Open Sans',
    'Arvo Bold': 'Arvo',
    'Arvo Regular': 'Arvo',
    'Josefin Slab Bold': 'Josefin Slab',
    'Josefin Slab Semibold': 'Josefin Slab',
    'Libre Baskerville Bold': 'Libre Baskerville',
    'Libre Baskerville Regular': 'Libre Baskerville',
    'Lora Bold': 'Lora',
    'Lora Regular': 'Lora',
    'Ovo Regular': 'Ovo',
    'Questrial Regular': 'Questrial',
    'RobotoCondensed Bold': 'Roboto Condensed',
    'RobotoCondensed Regular': 'Roboto Condensed',
    'RobotoCondensed Light': 'Roboto Condensed',
    'Rubik Regular': 'Rubik',
    'Rubik Bold': 'Rubik',
    'SourceSansPro Bold': 'Source Sans Pro',
    'SourceSansPro Regular': 'Source Sans Pro',
    'Mplus1p Bold': 'M PLUS 1p',
    'Mplus1p-Bold': 'M PLUS 1p',
    'Mplus1p Regular': 'M PLUS 1p',
    'RoundedMplus1c Regular': 'M PLUS Rounded 1c',
    'RoundedMplus1c Bold': 'M PLUS Rounded 1c',
    'AbrilFatface Regular': 'Abril Fatface',
    'Barlow Condensed Regular': 'Barlow Condensed',
    'Barlow Condensed Bold': 'Barlow Condensed',
    'Crimson Text Bold': 'Crimson Text',
    'Crimson Text Regular': 'Crimson Text',
    'IBM Plex Sans Bold': 'IBM Plex Sans',
    'IBM Plex Sans Regular': 'IBM Plex Sans',
    'Jost Bold': 'Jost',
    'Jost Regular': 'Jost',
    'Kalam Bold': 'Kalam',
    'Kalam Regular': 'Kalam',
    'Montserrat Bold': 'Montserrat',
    'Montserrat Light': 'Montserrat',
    'Montserrat Regular': 'Montserrat',
    'Poppins Bold': 'Poppins',
    'Poppins Regular': 'Poppins',
    'Raleway Bold': 'Raleway',
    'Raleway Regular': 'Raleway',
    'Urbanist Bold': 'Urbanist',
    'Urbanist Regular': 'Urbanist',
    'Work Sans Bold': 'Work Sans',
    'Work Sans Regular': 'Work Sans',
    'Zilla Slab Bold': 'Zilla Slab',
    'Zilla Slab Regular': 'Zilla Slab',
    'Noto Sans JP Regular': 'Noto Sans JP',
    'Noto Sans JP Bold': 'Noto Sans JP',
    'NotoSansJP Regular': 'Noto Sans JP',
    'NotoSansJP Bold': 'Noto Sans JP',
};

export const FONT_FAMILIES_TO_SPLIT = Object.keys(FONT_FAMILIES_VS_GOOGLE_FONT);
