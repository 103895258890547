import * as React from 'react';
import { StyledPulsingDot, StyledStatusTagDot } from './styledComponents';
// types
import { StatusTagDotProps } from './types';

function StatusTagDot({ color, size, animation }: StatusTagDotProps) {
    return (
        <>
            {!animation?.enabled ? (
                <StyledStatusTagDot size={size} color={color} />
            ) : (
                <StyledPulsingDot color={color} size={size} duration={animation?.duration} />
            )}
        </>
    );
}

export { StatusTagDot };
