import { toJS } from 'mobx';
import '../../../../i18n';
import i18n from 'i18next';
import { positionDefaults, WidgetFont, WidgetPosition } from '@sprinklr/stories/widget/Widget';
import { stringToNum } from 'utils/Number/NumberUtils';
import { DefaultWidgetPosition } from '../../../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import { gridConfig } from './CompositionsConfig/GridConfig';
import { filmStripConfig } from './CompositionsConfig/FilmStripConfig';
import { cinematicConfig } from './CompositionsConfig/CinematicConfig';
import { collageConfig } from './CompositionsConfig/CollageConfig';
import { singlePostConfig } from './CompositionsConfig/SinglePostConfig';
import { waterfallConfig } from './CompositionsConfig/WaterfallConfig';
import { tickerConfig } from './CompositionsConfig/TickerConfig';
import { postCardConfig } from './CompositionsConfig/PostCardConfig';
import { storyCardConfig } from './CompositionsConfig/StoryCardConfig';
import { runOfShowConfig } from './CompositionsConfig/RunOfShowConfig';
import { FullscreenHudPosition } from 'components/storyboard/StoryboardEditor/FullscreenControls/types';

// Light 300
// Regular 400
// Medium 500
// Semibold 600
// Bold 700
// black 800

export const fontFamilyOptions: WidgetFont[] = [
    { family: 'Alice', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Barlow Condensed Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Barlow Condensed Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Crimson Text Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Crimson Text Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'IBM Plex Sans Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'IBM Plex Sans Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Jost Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Jost Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Kalam Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Kalam Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Marcellus', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Montserrat Bold', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Montserrat Light', style: 'normal', weight: 100, useFamily: true },
    { family: 'Montserrat Regular', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Poppins Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Poppins Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Prata', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Raleway Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Raleway Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Secular One', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Urbanist Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Urbanist Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Work Sans Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Work Sans Regular', style: 'normal', weight: 'normal', useFamily: true },
    { family: 'Zilla Slab Bold', style: 'normal', weight: 'bold', useFamily: true },
    { family: 'Zilla Slab Regular', style: 'normal', weight: 'normal', useFamily: true },
    {
        family: 'RobotoCondensed Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: true,
    },
    {
        family: 'RobotoCondensed Light',
        style: 'normal',
        weight: 'lighter',
        useFamily: true,
    },
    {
        family: 'RobotoCondensed Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'Rubik Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: true,
    },
    {
        family: 'Rubik Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'SourceSansPro Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: true,
    },
    {
        family: 'SourceSansPro Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'Wavehaus Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: true,
    },
    // kanji supported fonts
    {
        family: 'Mplus1p-Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: false,
    },
    {
        family: 'Mplus1p Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'NotoSansJP Black',
        style: 'normal',
        weight: 'bold',
        useFamily: false,
    },
    {
        family: 'NotoSansJP Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: false,
    },
    {
        family: 'NotoSansJP Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: false,
    },
    {
        family: 'RoundedMplus1c Bold',
        style: 'normal',
        weight: 'bold',
        useFamily: true,
    },
    {
        family: 'RoundedMplus1c Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'Hannari Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: false,
    },
    {
        family: 'Kokoro Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: false,
    },
    {
        family: 'SawarabiGothic Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
    {
        family: 'SawarabiMincho Regular',
        style: 'normal',
        weight: 'normal',
        useFamily: true,
    },
];

export const imageBackgroundSizeOptions = [
    { value: 'cover', label: i18n.t('Cover'), icon: 'cover' },
    { value: 'contain', label: i18n.t('Contain'), icon: 'contain' },
    { value: '100%', label: i18n.t('Auto'), icon: 'none' },
];

export const fontCaseOptions = [
    { value: 'inherit', label: i18n.t('Default'), icon: 'engagement_bottom_right' },
    { value: 'uppercase', label: i18n.t('All Caps'), icon: 'case-all-caps' },
    { value: 'lowercase', label: i18n.t('All Lowercase'), icon: 'case-all-lowercase' },
    { value: 'capitalize', label: i18n.t('Capitalize'), icon: 'case-capitalize' },
];

export type FontFamilyPreset = 'primary_font_family' | 'secondary_font_family';

export const fontFamilyPresets: Array<{ value: FontFamilyPreset; label: string }> = [
    { value: 'primary_font_family', label: i18n.t('Primary') },
    { value: 'secondary_font_family', label: i18n.t('Secondary') },
];

export const interpolationOptions = [
    {
        value: 'linear',
        label: i18n.t('Linear'),
    },
    // basis does some weird things with my point math
    // {
    //     value: 'basis',
    //     label: 'Basis',
    // },
    {
        value: 'step-before',
        label: i18n.t('Step Before'),
    },
    {
        value: 'step-after',
        label: i18n.t('Step After'),
    },
    {
        value: 'cardinal',
        label: i18n.t('Cardinal'),
    },
    {
        value: 'monotone',
        label: i18n.t('Monotone'),
    },
];

export const interpolationTypeOptions = [
    {
        value: 'linear',
        label: i18n.t('Linear'),
        icon: 'line-chart-linear',
    },
    {
        value: 'step-after',
        label: i18n.t('Step After'),
        icon: 'line-chart-step-after',
    },
    {
        value: 'cardinal',
        label: i18n.t('Cardinal'),
        icon: 'line-chart-cardinal',
    },
];

export const scaleTypeOptions = [
    {
        value: 'linear',
        label: i18n.t('Linear'),
    },
    {
        value: 'log',
        label: i18n.t('Log'),
    },
];
export const scaleByOptions = [
    {
        value: 'row',
        label: i18n.t('Row'),
    },
    {
        value: 'set',
        label: i18n.t('Set'),
    },
];

export const stackedTypeOptions = [
    {
        value: 'a',
        label: i18n.t('100% Stacked'),
    },
    {
        value: 'b',
        label: i18n.t('Stacked'),
    },
    {
        value: 'c',
        label: i18n.t('Series'),
    },
];

export const stackedBarTypeOptions = [
    {
        value: 'a',
        label: i18n.t('100% Stacked'),
        icon: 'stacked-bar-chart-stacked-100',
    },
    {
        value: 'b',
        label: i18n.t('Stacked'),
        icon: 'stacked-bar-chart-stacked',
    },
    {
        value: 'c',
        label: i18n.t('Series'),
        icon: 'stacked-bar-chart-series',
    },
];

export const stackedColumnTypeOptions = [
    {
        value: 'a',
        label: i18n.t('100% Stacked'),
        icon: 'stacked-column-chart-stacked-100',
    },
    {
        value: 'b',
        label: i18n.t('Stacked'),
        icon: 'stacked-column-chart-stacked',
    },
    {
        value: 'c',
        label: i18n.t('Series'),
        icon: 'stacked-column-chart-series',
    },
];

export const stackedTypeConfig: any = {
    a: {
        stacked100Percent: true,
        stackedInline: true,
    },
    b: {
        stacked100Percent: false,
        stackedInline: true,
    },
    c: {
        stacked100Percent: false,
        stackedInline: false,
    },
};

export const userGroupLayoutTypeOptions: any = [
    {
        value: 'a',
        label: i18n.t('type 1'),
        icon: 'user_type_a',
    },
    {
        value: 'd',
        label: i18n.t('type d'),
        icon: 'user_type_d',
    },
    {
        value: 'c',
        label: i18n.t('type c'),
        icon: 'user_type_c',
    },
];

/*
private setStackedType(event: any) {
        // const { widget, updateOptions } = this.props;
        // updateOptions(widget, 'stackedType', event.value);
        this.props.widget.options.stackedType = event.value;
        this.props.setWidgetOption('stackedType', event.value);

        // const { options } = this.props.widget;
        // options.stackedType = event.value;

        switch (event.value) {
            case "a":
                this.props.setWidgetOption('stacked100Percent', true);
                this.props.setWidgetOption('stackedInline', true);

                break;
            case "b":
                this.props.widget.options.stacked100Percent = false;
                this.props.widget.options.stackedInline = true;
                break;
            case "c":
                this.props.widget.options.stacked100Percent = false;
                this.props.widget.options.stackedInline = false;
                break;
            default:
        }
    }
*/

export const presentationsHudPositionOptions: Array<{
    label: string;
    value: FullscreenHudPosition | 'custom';
}> = [
    {
        label: 'Bottom',
        value: 'bottom',
    },
    {
        label: i18n.t('Upper Left'),
        value: 'upperLeft',
    },
    {
        label: i18n.t('Upper Right'),
        value: 'upperRight',
    },
    {
        label: i18n.t('Custom'),
        value: 'custom',
    },
];

export const gridLayoutOptions: Array<{ label: string; value: number }> = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
];

export const textFormatOptions = [
    {
        value: 'fit',
        label: i18n.t('Fit'),
        icon: 'text_fit',
    },
    {
        value: 'clamp',
        label: i18n.t('Clamp'),
        icon: 'text_clamp',
    },
    {
        value: 'teleprompter',
        label: i18n.t('Teleprompter'),
        icon: 'text_teleprompter',
    },
];

export const labelFormatOptions = [
    {
        value: 'fit',
        label: i18n.t('Fit'),
        icon: 'text_fit',
    },
    {
        value: 'clamp',
        label: i18n.t('Clamp'),
        icon: 'text_clamp',
    },
];

export const timeFormatOptions = [
    {
        value: 'a',
        label: i18n.t('Mon April 4th'),
    },
    {
        value: 'b',
        label: i18n.t('April 4th'),
    },
    {
        value: 'c',
        label: i18n.t('Mon'),
    },
    {
        value: 'd',
        label: i18n.t('Monday'),
    },
    {
        value: 'e',
        label: i18n.t('4/4'),
    },
    {
        value: 'f',
        label: i18n.t('4/4/17'),
    },
    {
        value: 'g',
        label: i18n.t("April 4, '17"),
    },
];

export const timeIntervals = [
    { value: 'seconds', label: i18n.t('Seconds') },
    { value: 'minutes', label: i18n.t('Minutes') },
    { value: 'hours', label: i18n.t('Hours') },
    { value: 'days', label: i18n.t('Days') },
    { value: 'weeks', label: i18n.t('Weeks') },
    { value: 'months', label: i18n.t('Months') },
    { value: 'years', label: i18n.t('Years') },
];

export const metricValueOptions = [
    { value: '1k', label: i18n.t('1k'), icon: '' },
    { value: '1.2k', label: i18n.t('1.2k'), icon: '' },
    { value: '1.23k', label: i18n.t('1.23k'), icon: '' },
    { value: '1,234', label: i18n.t('1,234'), icon: '' },
];

export const metricValueCaseOptions = [
    { value: 'lowercase', label: i18n.t('Lowercase'), icon: '' },
    { value: 'uppercase', label: i18n.t('Uppercase'), icon: '' },
];

export const metricRatioOptions = [
    { value: '1k', label: i18n.t('1k'), icon: '' },
    { value: '1.2k', label: i18n.t('1.2k'), icon: '' },
    { value: '1.23k', label: i18n.t('1.23k'), icon: '' },
];

export const legendOrientationOptionsLandscape = [
    { value: 'bottom', label: i18n.t('Bottom') },
    { value: 'right', label: i18n.t('Right') },
];

export const legendOrientationOptionsPortrait = [
    { value: 'top', label: i18n.t('Top') },
    { value: 'bottom', label: i18n.t('Bottom') },
];

export const lineChartLegendOrientationOptionsLandscape = [
    { value: 'top', label: i18n.t('Top') },
    { value: 'bottom', label: i18n.t('Bottom') },
    { value: 'right', label: i18n.t('Right') },
];

export const topRightBottomLeft = [
    { value: 'top', label: i18n.t('Top'), icon: 'vertical-align-top' },
    { value: 'bottom', label: i18n.t('Bottom'), icon: 'vertical-align-bottom' },
    { value: 'left', label: i18n.t('Left'), icon: 'horizontal-align-left' },
    { value: 'right', label: i18n.t('Right'), icon: 'horizontal-align-right' },
];

// used in widget label and posts
// moment.format() https://momentjs.com/docs/#/displaying/format/
export const DATE_FORMAT_OPTIONS = [
    {
        value: 'YYYY/M/D',
        label: i18n.t('2049/12/31'),
    },
    {
        value: 'YYYY/M/D, h:mma',
        label: i18n.t('2049/12/31, 12:00am'),
    },
    {
        value: 'Do MMM, YYYY h:mma',
        label: i18n.t('31st Dec, 2049 12:00am'),
    },
    {
        value: 'MMMM Do, YYYY h:mma',
        label: i18n.t('December 31st, 2049 12:00am'),
    },
    {
        value: 'MMMM Do, YYYY h:mma Z',
        label: i18n.t('December 31st, 2049 12:00am -07:00'),
    },
    {
        value: 'Do MMM',
        label: i18n.t('31st Dec'),
    },
    {
        value: 'Do MMM, YYYY',
        label: i18n.t('31st Dec, 2049'),
    },
    {
        value: 'Do, MMMM, YYYY',
        label: i18n.t('31st, December, 2049'),
    },
    {
        value: 'MMM Do',
        label: i18n.t('Dec 31st'),
    },
    {
        value: 'MMMM Do, YYYY',
        label: i18n.t('December 31st, 2049'),
    },
    {
        value: 'dddd, MMMM Do YYYY',
        label: i18n.t('Friday, December 31st 2049'),
    },
    {
        value: 'MMMM, YYYY',
        label: i18n.t('December, 2049'),
    },
    {
        value: '[Q]Q',
        label: i18n.t('Q4'),
    },
    {
        value: '[Q]Q, YY',
        label: i18n.t('Q4, 49'),
    },
    {
        value: '[Q]Q, YYYY',
        label: i18n.t('Q4, 2049'),
    },
];

export const postCompositionTypes: any = [
    {
        value: 'a',
        label: i18n.t('Default'),
        icon: 'image',
    },
    {
        value: 'b',
        label: i18n.t('Alternate'),
        icon: 'image',
    },
    {
        value: 'c',
        label: i18n.t('Special'),
        icon: 'image',
    },
    {
        value: 'd',
        label: i18n.t('Other'),
        icon: 'image',
    },
];

export const tickLabelAngleOptions = [
    {
        value: 0,
        label: i18n.t('Default'),
    },
    {
        value: -15,
        label: i18n.t('15°'),
    },
    {
        value: -30,
        label: i18n.t('30°'),
    },
    {
        value: -45,
        label: i18n.t('45°'),
    },
    {
        value: -60,
        label: i18n.t('60°'),
    },
];

// value used in hyperspace analytics xAxis https://date-fns.org/v2.29.3/docs/format
// valueMoment used in legacy analytics xAxis https://momentjs.com/docs/#/displaying/format/
export const X_AXIS_OPTIONS = [
    {
        valueMoment: 'auto',
        label: i18n.t('Auto'),
    },
    {
        valueMoment: 'ha',
        value: 'haaa',
        label: i18n.t('3pm'),
    },
    {
        valueMoment: 'ddd ha',
        value: 'eee haaa',
        label: i18n.t('Fri 3pm'),
    },
    {
        valueMoment: 'dddd',
        value: 'eeee',
        label: i18n.t('Friday'),
    },
    {
        valueMoment: 'D',
        value: 'd',
        label: i18n.t('31'),
    },
    {
        valueMoment: 'k',
        value: 'H',
        label: i18n.t('24'),
    },
    {
        valueMoment: 'MMM',
        value: 'MMM',
        label: i18n.t('Dec'),
    },
    {
        valueMoment: 'MMM Do',
        value: 'MMM do',
        label: i18n.t('Dec 31st'),
    },
    {
        valueMoment: 'D MMM',
        value: 'dd MMM',
        label: i18n.t('31 Dec'),
    },
    {
        valueMoment: 'MMMM',
        value: 'MMMM',
        label: i18n.t('December'),
    },
    {
        valueMoment: 'YYYY',
        value: 'yyyy',
        label: i18n.t('2049'),
    },
    {
        valueMoment: 'MMM, YYYY',
        value: 'MMM, yyyy',
        label: i18n.t('Dec, 2049'),
    },
    {
        valueMoment: 'MMM Do, YYYY',
        value: 'MMM do, yyyy',
        label: i18n.t('Dec 31st, 2049'),
    },
    {
        valueMoment: 'Do, MMM, YYYY',
        value: 'do, MMM, yyyy',
        label: i18n.t('31st, Dec, 2049'),
    },
    {
        valueMoment: '[Q]Q',
        value: 'QQQ',
        label: i18n.t('Q1'),
    },
    {
        valueMoment: '[Q]Q, YY',
        value: 'QQQ, yy',
        label: i18n.t('Q1, 20'),
    },
    {
        valueMoment: '[Q]Q, YYYY',
        value: 'QQQ, yyyy',
        label: i18n.t('Q1, 2049'),
    },
];

export const spacewebTimeFormatOptions = X_AXIS_OPTIONS.filter(item => item.valueMoment !== 'auto');

export const spacewebTickLabelAngleOptions = [
    {
        value: 0,
        label: i18n.t('0°'),
    },
    {
        value: 15,
        label: i18n.t('15°'),
    },
    {
        value: 30,
        label: i18n.t('30°'),
    },
    {
        value: 45,
        label: i18n.t('45°'),
    },
    {
        value: 60,
        label: i18n.t('60°'),
    },
];

export const [first, ...xAxisOptionsRest] = X_AXIS_OPTIONS;
export const labelDateOptions = [
    {
        valueMoment: 'none',
        label: i18n.t('No Applied Format'),
    },
    ...xAxisOptionsRest,
];

export const philippinesRegionTypeOptions = [
    {
        value: 'region',
        label: i18n.t('Region'),
    },
    {
        value: 'province',
        label: i18n.t('Province'),
    },
];

export const regionOptions = [
    {
        value: 'africa',
        label: i18n.t('Africa'),
    },
    {
        value: 'asia',
        label: i18n.t('Asia'),
    },
    {
        value: 'centralAmerica',
        label: i18n.t('Central America'),
    },
    {
        value: 'europeWithRussia',
        label: i18n.t('Europe'),
    },
    {
        value: 'westernEurope',
        label: i18n.t('Europe (Western)'),
    },
    {
        value: 'india',
        label: i18n.t('India'),
    },
    {
        value: 'japan',
        label: i18n.t('Japan'),
    },
    {
        value: 'philippines',
        label: i18n.t('Philippines'),
    },
    {
        value: 'mexico',
        label: i18n.t('Mexico'),
    },
    {
        value: 'northAmerica',
        label: i18n.t('North America'),
    },
    {
        value: 'southAmerica',
        label: i18n.t('South America'),
    },
    {
        value: 'unitedStates',
        label: i18n.t('United States'),
    },
    {
        value: 'world',
        label: i18n.t('World'),
    },
];

export const videoBackgroundSizeOptions: SegmentedControlOption[] = [
    { value: 'cover', label: i18n.t('Cover'), icon: 'cover' },
    { value: 'contain', label: i18n.t('Contain'), icon: 'contain' },
];

export type InlineStyles = 'ITALIC' | 'BOLD' | 'UNDERLINE';

export interface SegmentedControlOption {
    value: string;
    label: string;
    icon: string;
}

export const horizontalAlignOptions: SegmentedControlOption[] = [
    {
        value: 'left',
        label: i18n.t('Left'),
        icon: 'horizontal-align-left',
    },
    {
        value: 'center',
        label: i18n.t('Center'),
        icon: 'horizontal-align-center',
    },
    {
        value: 'right',
        label: i18n.t('Right'),
        icon: 'horizontal-align-right',
    },
];

export const textAlignOptions: SegmentedControlOption[] = [
    {
        value: 'left',
        label: i18n.t('Left'),
        icon: 'text-align-left',
    },
    {
        value: 'center',
        label: i18n.t('Center'),
        icon: 'text-align-center',
    },
    {
        value: 'right',
        label: i18n.t('Right'),
        icon: 'text-align-right',
    },
];

export const yAxisPositionOptions = [
    {
        value: 'left',
        label: i18n.t('Left'),
    },
    {
        value: 'right',
        label: i18n.t('Right'),
    },
];

export const verticalAlignOptions: SegmentedControlOption[] = [
    {
        value: 'top',
        label: i18n.t('Top'),
        icon: 'vert-align-top',
    },
    {
        value: 'middle',
        label: i18n.t('Middle'),
        icon: 'vert-align-middle',
    },
    {
        value: 'bottom',
        label: i18n.t('Bottom'),
        icon: 'vert-align-bottom',
    },
];

// for direct styles
export const vAlignOptions: SegmentedControlOption[] = [
    {
        value: 'top',
        label: 'Top',
        icon: 'vert-align-top',
    },
    {
        value: 'center',
        label: 'Center',
        icon: 'vert-align-middle',
    },
    {
        value: 'bottom',
        label: 'Bottom',
        icon: 'vert-align-bottom',
    },
];

export type ArrangeOptions = 'front' | 'back' | 'foreward' | 'backward';

// type widgetSizeOption = {}
// thirds and sixths maybe temporary and eliminated after we implement a multiple-metric widget
export type WidgetPositionOptions =
    | 'full'
    | 'half_left'
    | 'half_right'
    | 'half_top'
    | 'half_bottom'
    | 'quarter_centered'
    | 'quarter_top_left'
    | 'quarter_top_right'
    | 'quarter_bottom_left'
    | 'quarter_bottom_right'
    | 'eighth_centered'
    | 'eighth_top_left'
    | 'eighth_top_right'
    | 'eighth_bottom_left'
    | 'eighth_bottom_right'
    | 'third_left'
    | 'third_centered'
    | 'third_right'
    | 'sixth_top_left'
    | 'sixth_top_right'
    | 'sixth_bottom_left'
    | 'sixth_bottom_right'
    | 'sixth_top_centered'
    | 'sixth_bottom_centered';

export const remoteLayoutSettings = [
    {
        value: 'list',
        label: i18n.t('List'),
    },
    {
        value: 'grid',
        label: i18n.t('Custom'),
    },
];

function addGutter(gutter: number, size: number, percent: number, subtract: boolean) {
    const gutterSizePercent = (gutter / size) * 100;
    if (subtract) {
        return `${(percent - gutterSizePercent).toFixed(2)}%`;
    } else {
        return `${(percent + gutterSizePercent).toFixed(2)}%`;
    }
}

/**
 *
 * @param width string from rootwidget position.width
 * @param height string from rootwidget position.height
 * @param padding rootWidget padding
 * @param gutter spacing between layout cells
 * @param {WidgetPositionOptions} position the option to apply
 */

export const placeWidget = (position: WidgetPositionOptions): WidgetPosition => {
    switch (position) {
        case 'full':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 12,
                    },
                    rows: {
                        start: 0,
                        end: 6,
                    },
                },
            };
        case 'half_left':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 6,
                    },
                    rows: {
                        start: 0,
                        end: 6,
                    },
                },
            };
        case 'half_right':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 6,
                        end: 12,
                    },
                    rows: {
                        start: 0,
                        end: 6,
                    },
                },
            };
        case 'half_top':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 12,
                    },
                    rows: {
                        start: 0,
                        end: 3,
                    },
                },
            };
        case 'half_bottom':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 12,
                    },
                    rows: {
                        start: 3,
                        end: 6,
                    },
                },
            };
        case 'quarter_centered':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 2,
                        end: 10,
                    },
                    rows: {
                        start: 1,
                        end: 5,
                    },
                },
            };
        case 'quarter_top_left':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 6,
                    },
                    rows: {
                        start: 0,
                        end: 3,
                    },
                },
            };
        case 'quarter_top_right':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 6,
                        end: 12,
                    },
                    rows: {
                        start: 0,
                        end: 3,
                    },
                },
            };
        case 'quarter_bottom_left':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 6,
                    },
                    rows: {
                        start: 3,
                        end: 6,
                    },
                },
            };
        case 'quarter_bottom_right':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 6,
                        end: 12,
                    },
                    rows: {
                        start: 3,
                        end: 6,
                    },
                },
            };
        case 'eighth_centered':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 4,
                        end: 8,
                    },
                    rows: {
                        start: 2,
                        end: 4,
                    },
                },
            };
        case 'eighth_top_left':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 4,
                    },
                    rows: {
                        start: 0,
                        end: 2,
                    },
                },
            };
        case 'eighth_top_right':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 12,
                        end: 8,
                    },
                    rows: {
                        start: 0,
                        end: 2,
                    },
                },
            };
        case 'eighth_bottom_left':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 0,
                        end: 4,
                    },
                    rows: {
                        start: 4,
                        end: 6,
                    },
                },
            };
        case 'eighth_bottom_right':
            return {
                ...positionDefaults,
                grid: {
                    enabled: true,
                    columns: {
                        start: 8,
                        end: 12,
                    },
                    rows: {
                        start: 4,
                        end: 6,
                    },
                },
            };
        default:
            break;
    }
};

export const zoomOptions: any = {
    default: {
        zoom: {
            min: 10,
            max: 100,
        },
        offsetX: {
            min: -100,
            max: 100,
        },
        offsetY: {
            min: -100,
            max: 100,
        },
    },

    tallHeatMap: {
        zoom: {
            min: 10,
            max: 200,
        },
        offsetX: {
            min: -150,
            max: 150,
        },
        offsetY: {
            min: -150,
            max: 150,
        },
    },
};

export const supportsMaxItemsDefaults: any = {
    name: i18n.t('Max Items'),
    min: 1,
    max: 15,
};

export const easingOptions = {
    fade: [
        {
            label: i18n.t('Default'),
            value: 'easeQuadOut',
        },
        {
            label: i18n.t('Cubic'),
            value: 'easeCubicIn',
        },
        {
            label: i18n.t('Linear'),
            value: 'easeLinear',
        },
    ],
    slide_down: [
        {
            label: i18n.t('Default'),
            value: 'easeCubicOut',
        },
        {
            label: i18n.t('Soft Bounce'),
            value: 'easeBackInOut',
        },
        {
            label: i18n.t('Big Bounce'),
            value: 'easeBounceOut',
        },
        {
            label: i18n.t('Hard Bounce'),
            value: 'easeElasticInOut',
        },
    ],
    slide_down_fade: [
        {
            label: i18n.t('Default'),
            value: 'easePolyOut',
        },
        {
            label: i18n.t('Quad'),
            value: 'easeQuad',
        },
        {
            label: i18n.t('Soft Bounce'),
            value: 'easeBackOut',
        },
    ],
    slide_over: [
        {
            label: i18n.t('Default'),
            value: 'easeCubicOut',
        },
        {
            label: i18n.t('Soft Bounce'),
            value: 'easeBackInOut',
        },
        {
            label: i18n.t('Big Bounce'),
            value: 'easeBounceOut',
        },
        {
            label: i18n.t('Hard Bounce'),
            value: 'easeElasticInOut',
        },
    ],
    slide_over_fade: [
        {
            label: i18n.t('Default'),
            value: 'easePolyOut',
        },
        {
            label: i18n.t('Quad'),
            value: 'easeQuad',
        },
        {
            label: i18n.t('Soft Bounce'),
            value: 'easeBackOut',
        },
    ],
    scale_fade: [
        {
            label: i18n.t('Default'),
            value: 'easePolyOut',
        },
        {
            label: i18n.t('Quad'),
            value: 'easeQuad',
        },
        {
            label: i18n.t('Linear'),
            value: 'easeLinear',
        },
        {
            label: i18n.t('Soft Bounce'),
            value: 'easeBackOut',
        },
    ],
};

export const transitionOptions = [
    {
        label: i18n.t('Fade'),
        value: 'fade',
    },
    {
        label: i18n.t('Slide Down and Fade In'),
        value: 'slide_down_fade',
    },
    {
        label: i18n.t('Slide Down'),
        value: 'slide_down',
    },
    {
        label: i18n.t('Slide Over and Fade In'),
        value: 'slide_over_fade',
    },
    {
        label: i18n.t('Slide Over'),
        value: 'slide_over',
    },
    {
        label: i18n.t('Zoom and Fade In'),
        value: 'scale_fade',
    },
];

export const blendModeOptions = [
    { value: 'overlay', label: i18n.t('Overlay') },
    { value: 'normal', label: i18n.t('Normal') },
    { value: 'multiply', label: i18n.t('Multiply') },
    { value: 'screen', label: i18n.t('Screen') },
    { value: 'darken', label: i18n.t('Darken') },
    { value: 'lighten', label: i18n.t('Lighten') },
    { value: 'color-dodge', label: i18n.t('Color-dodge') },
    { value: 'color-burn', label: i18n.t('Color-burn') },
    { value: 'hard-light', label: i18n.t('Hard-light') },
    { value: 'soft-light', label: i18n.t('Soft-light') },
    { value: 'difference', label: i18n.t('Difference') },
    { value: 'exclusion', label: i18n.t('Exclusion') },
    { value: 'hue', label: i18n.t('Hue') },
    { value: 'saturation', label: i18n.t('Saturation') },
    { value: 'color', label: i18n.t('Color') },
    { value: 'luminosity', label: i18n.t('Luminosity') },
    { value: 'initial', label: i18n.t('Initial') },
    { value: 'inherit', label: i18n.t('Inherit') },
    { value: 'unset', label: i18n.t('Unset') },
];

export const presetImageFilterOptions = [
    { value: 'none', label: i18n.t('None') },
    { value: 'x1977', label: i18n.t('1977') },
    { value: 'aden', label: i18n.t('Aden') },
    { value: 'brooklyn', label: i18n.t('Brooklyn') },
    { value: 'earlybird', label: i18n.t('Earlybird') },
    { value: 'gingham', label: i18n.t('Gingham') },
    { value: 'hudson', label: i18n.t('Hudson') },
    { value: 'inkwell', label: i18n.t('Inkwell') },
    { value: 'lofi', label: i18n.t('Lo-Fi') },
    { value: 'perpetua', label: i18n.t('Perpetua') },
    { value: 'reyes', label: i18n.t('Reyes') },
    { value: 'toaster', label: i18n.t('Toaster') },
    { value: 'walden', label: i18n.t('Walden') },
    { value: 'xpro2', label: i18n.t('X-Pro II') },
];

export const presetValues = {
    none: [
        { optionName: 'imageFilterBrightnessValue', value: 100 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 100 },
        { optionName: 'imageFilterContrast', value: false },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        { optionName: 'imageBlendColor', value: '' },
        { optionName: 'imageBlendMode', value: '' },
    ],
    x1977: [
        { optionName: 'imageFilterBrightnessValue', value: 110 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 110 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 130 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: null },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: null },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: null },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: null },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: null },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: null },
        { optionName: 'imageFilterSepia', value: false },
        { optionName: 'imageBlendColor', value: 'rgba(243, 106, 188, 0.3)' },
        { optionName: 'imageBlendMode', value: 'screen' },
    ],
    aden: [
        { optionName: 'imageFilterBrightnessValue', value: 120 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 90 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 85 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: null },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: null },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 20 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: true },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: null },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: null },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value: 'linear-gradient(to right, rgba(66, 10, 14, 0.2) 1%, rgba(66, 10, 14, 0))',
        },
        { optionName: 'imageBlendMode', value: 'darken' },
    ],
    brooklyn: [
        { optionName: 'imageFilterBrightnessValue', value: 110 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 90 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(circle closest-corner at 50% 50%, rgba(168, 223, 193, 0.4) 1%, rgba(183, 196, 200, 0.2))',
        },
        { optionName: 'imageBlendMode', value: 'overlay' },
    ],
    earlybird: [
        { optionName: 'imageFilterBrightnessValue', value: 110 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 90 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 20 },
        { optionName: 'imageFilterSepia', value: true },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(circle closest-corner at 50% 50%, rgba(208, 186, 142, 1) 20%, rgba(29, 2, 16, 0.2))',
        },
        { optionName: 'imageBlendMode', value: 'overlay' },
    ],
    gingham: [
        { optionName: 'imageFilterBrightnessValue', value: 105 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 100 },
        { optionName: 'imageFilterContrast', value: false },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 350 },
        { optionName: 'imageFilterHueRotate', value: true },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value: 'linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(0, 0, 0, 0))',
        },
        { optionName: 'imageBlendMode', value: 'darken' },
    ],
    hudson: [
        { optionName: 'imageFilterBrightnessValue', value: 120 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 90 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 110 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 50 },
        { optionName: 'imageFilterOpacity', value: true },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(50% 50%, circle closest-corner, rgba(255, 177, 166, 1) 50, rgba(52, 33, 52, 1))',
        },
        { optionName: 'imageBlendMode', value: 'multiply' },
    ],
    inkwell: [
        { optionName: 'imageFilterBrightnessValue', value: 100 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 110 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 110 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 100 },
        { optionName: 'imageFilterGrayscale', value: true },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 30 },
        { optionName: 'imageFilterSepia', value: true },
        { optionName: 'imageBlendColor', value: 'rgba(0, 0, 0, 0)' },
        { optionName: 'imageBlendMode', value: '' },
    ],
    lofi: [
        { optionName: 'imageFilterBrightnessValue', value: 100 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 150 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 110 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(50% 50%, circle closest-corner, rgba(0, 0, 0, 0) 70, rgba(34, 34, 34, 1))',
        },
        { optionName: 'imageBlendMode', value: 'multiply' },
    ],
    perpetua: [
        { optionName: 'imageFilterBrightnessValue', value: 100 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 100 },
        { optionName: 'imageFilterContrast', value: false },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 50 },
        { optionName: 'imageFilterOpacity', value: true },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value: 'linear-gradient(to bottom, rgba(0, 91, 154, 1) 1, rgba(61, 193, 230, 0))',
        },
        { optionName: 'imageBlendMode', value: 'soft-light' },
    ],
    reyes: [
        { optionName: 'imageFilterBrightnessValue', value: 110 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 85 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 75 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 50 },
        { optionName: 'imageFilterOpacity', value: true },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 22 },
        { optionName: 'imageFilterSepia', value: false },
        { optionName: 'imageBlendColor', value: 'rgba(173, 205, 239, 1)' },
        { optionName: 'imageBlendMode', value: 'soft-light' },
    ],
    toaster: [
        { optionName: 'imageFilterBrightnessValue', value: 90 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 150 },
        { optionName: 'imageFilterContrast', value: true },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 50 },
        { optionName: 'imageFilterOpacity', value: true },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 0 },
        { optionName: 'imageFilterSepia', value: false },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(50% 50%, circle closest-corner, rgba(15, 78, 128, 1) 1, rgba(59, 0, 59, 1))',
        },
        { optionName: 'imageBlendMode', value: 'screen' },
    ],
    walden: [
        { optionName: 'imageFilterBrightnessValue', value: 110 },
        { optionName: 'imageFilterBrightness', value: true },
        { optionName: 'imageFilterContrastValue', value: 100 },
        { optionName: 'imageFilterContrast', value: false },
        { optionName: 'imageFilterSaturateValue', value: 160 },
        { optionName: 'imageFilterSaturate', value: true },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 350 },
        { optionName: 'imageFilterHueRotate', value: true },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 30 },
        { optionName: 'imageFilterSepia', value: true },
        { optionName: 'imageBlendColor', value: 'background: rgba(204, 68, 0, 1)' },
        { optionName: 'imageBlendMode', value: 'screen' },
    ],
    xpro2: [
        { optionName: 'imageFilterBrightnessValue', value: 100 },
        { optionName: 'imageFilterBrightness', value: false },
        { optionName: 'imageFilterContrastValue', value: 100 },
        { optionName: 'imageFilterContrast', value: false },
        { optionName: 'imageFilterSaturateValue', value: 100 },
        { optionName: 'imageFilterSaturate', value: false },
        { optionName: 'imageFilterBlurValue', value: 0 },
        { optionName: 'imageFilterBlur', value: false },
        { optionName: 'imageFilterGrayscaleValue', value: 0 },
        { optionName: 'imageFilterGrayscale', value: false },
        { optionName: 'imageFilterHueRotateValue', value: 0 },
        { optionName: 'imageFilterHueRotate', value: false },
        { optionName: 'imageFilterOpacityValue', value: 100 },
        { optionName: 'imageFilterOpacity', value: false },
        { optionName: 'imageFilterInvertValue', value: 0 },
        { optionName: 'imageFilterInvert', value: false },
        { optionName: 'imageFilterSepiaValue', value: 30 },
        { optionName: 'imageFilterSepia', value: true },
        {
            optionName: 'imageBlendColor',
            value:
                'radial-gradient(50% 50%, circle closest-corner, rgba(224, 231, 230, 1) 40, rgba(43, 42, 161, 0.6))',
        },
        { optionName: 'imageBlendMode', value: 'color-burn' },
    ],
};

export const colorModeOptions = [
    {
        value: 'light',
        label: i18n.t('Light'),
    },
    {
        value: 'dark',
        label: i18n.t('Dark'),
    },
];
export const emojiPlatformOptions = [
    {
        value: 'default',
        label: i18n.t('Default'),
    },
    {
        value: 'twitter',
        label: i18n.t('X'),
    },
];

export const postCompositionConfigGrid = gridConfig;
export const postCompositionConfigFilmStrip = filmStripConfig;
export const postCompositionConfigCollage = collageConfig;
export const postCompositionConfigCinematic = cinematicConfig;
export const postCompositionConfigSinglePost = singlePostConfig;
export const postCompositionConfigTicker = tickerConfig;
export const postCompositionConfigWaterfall = waterfallConfig;
export const postCompositionConfigPostCard = postCardConfig;
export const postCompositionConfigStoryCard = storyCardConfig;
export const postCompositionConfigRunOfShow = runOfShowConfig;
