import { action, observable } from 'mobx';

// Types
import { HeaderBackgroundProps, HeaderProps } from './types';
import { HeaderImageProps } from './HeaderImage/types';
import { HeaderTitleProps } from './HeaderTitle/types';
import { HeaderDateProps } from './HeaderDate/types';

// Options
import { HeaderSubTitleImpl, HeaderTitleImpl } from './HeaderTitle/options';
import { HeaderImageImpl } from './HeaderImage/options';
import { HeaderDateImpl } from './HeaderDate/options';
import { BackgroundPosition, BackgroundRepeat } from 'models/Theme/Theme';

// for graphQl
export const HeaderSnippet = `
    header {
        enabled
        horizontalPadding
        verticalPadding
        background {
            enabled
            src
            color
            size
            repeat
            position
            opacity
        }
        title {
            enabled
            content
            inheritContent
            inheritFrom
            sourceName
            size
            color
        }
        subTitle {
            enabled
            content
            inheritContent
            inheritFrom
            sourceName
            size
            color
        }
        date {
            enabled
            size
            color
            format
        }
        image {
            src
            backgroundColor
            objectFit
            radius
            dimensions {
                height
                width
            }
            imageMask
        }
        isEmpty
    }
`;

export class HeaderBackgroundImpl implements HeaderBackgroundProps {
    @observable enabled = true;
    @observable src = '';
    @observable color = '';
    @observable size = 'cover';
    @observable repeat: BackgroundRepeat = 'no-repeat';
    @observable position: BackgroundPosition = 'center center';
    @observable opacity = 100;
}
export class HeaderPropsImpl implements HeaderProps {
    @observable enabled = false;
    @observable verticalPadding = 40;
    @observable horizontalPadding = 40;
    @observable title: HeaderTitleProps = new HeaderTitleImpl();
    @observable background: HeaderBackgroundProps = new HeaderBackgroundImpl();
    @observable subTitle: HeaderTitleProps = new HeaderSubTitleImpl();
    @observable date: HeaderDateProps = new HeaderDateImpl();
    @observable image: HeaderImageProps = new HeaderImageImpl();
    @observable isEmpty = false;

    @action
    setValues(opts?: any) {
        if (opts) {
            Object.assign(this, opts);
        }
        return this;
    }
}

export class SceneHeaderPropsImpl implements HeaderProps {
    @observable enabled = true;
    @observable verticalPadding = 40;
    @observable horizontalPadding = 40;
    @observable title: HeaderTitleProps = new HeaderTitleImpl();
    @observable background: HeaderBackgroundProps = new HeaderBackgroundImpl();
    @observable subTitle: HeaderTitleProps = new HeaderSubTitleImpl();
    @observable date: HeaderDateProps = new HeaderDateImpl();
    @observable image: HeaderImageProps = new HeaderImageImpl();
    @observable isEmpty = true;

    @action
    setValues(opts?: any) {
        if (opts) {
            Object.assign(this, opts);
        }
        return this;
    }
}

export const getEmptyHeader = () => {
    return { ...new HeaderPropsImpl(), isEmpty: true };
};
