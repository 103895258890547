import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import AuthService from 'services/Auth/AuthService';
import WidgetService from 'services/WidgetService/WidgetService';
import UiStateService from 'services/UiStateService/UiStateService';
import { LayoutService } from 'services/LayoutService/LayoutService';
import { LayoutCustomService } from 'services/LayoutCustomService/LayoutCustomService';
import SprinklrAPIService from 'services/SprinklrAPIService/SprinklrAPIService';
import SocketService from 'services/SocketService/SocketService';
import MetaInfoService from 'services/MetaInfoService/MetaInfoService';
import { StoryboardService } from 'services/StoryboardService/StoryboardService';
import { StoryboardVersionService } from 'services/StoryboardVersionService/StoryboardVersionService';
import { SceneVersionService } from 'services/SceneVersionService/SceneVersionService';
import EditorStateService from 'services/EditorStateService/EditorStateService';
import WidgetTypeService from 'services/WidgetTypes/WidgetTypeService';
import ClockService from 'services/ClockService/ClockService';
import { DisplayService } from 'services/DisplayService/DisplayService';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import JSContextService from 'services/JSContextService/JSContextService';
import { EnvironmentConfig } from '../config';
import TouchScreenService from 'services/TouchScreenService/TouchScreenService';
import { AxiosInstance } from 'axios';
import { DisplayTokenService } from 'services/DisplayTokenService/DisplayTokenService';

type Stores = {
    authService: AuthService;
    widgetService: WidgetService;
    uiStateService: UiStateService;
    layoutService: LayoutService;
    layoutCustomService: LayoutCustomService;
    sprinklrAPIService: SprinklrAPIService;
    socketService: SocketService;
    metaInfoService: MetaInfoService;
    storyboardService: StoryboardService;
    storyboardVersionService: StoryboardVersionService;
    sceneVersionService: SceneVersionService;
    editorStateService: EditorStateService;
    widgetTypeService: WidgetTypeService;
    clockService: ClockService;
    displayService: DisplayService;
    graphQLService: GraphQLService;
    jsContextService: JSContextService;
    touchScreenService: TouchScreenService;
    axios: AxiosInstance;
    displayTokenService: DisplayTokenService;
    config: EnvironmentConfig;
};

function useStores(): Stores {
    return React.useContext(MobXProviderContext) as Stores;
}

export default useStores;
