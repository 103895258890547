import { InjectGlobalStylesProps } from 'utils/Storybook/GlobalStyle';
import { css } from 'components/_UI/designSystem';

export const styledAsyncSelect = css`
    ${({ theme }: InjectGlobalStylesProps) => css`
        [data-spaceweb='tag'] {
            border-radius: 8px;
            padding-left: 1.2rem;
            height: 33px;
        }
        [data-baseweb='select'][data-test-id='spacekit'] {
            > div {
                padding: 0 9px;
                border-radius: 2px;

                &,
                &:hover {
                    border-color: ${theme && theme.select.control.default.border};
                }
            }

            * {
                font-size: 13px;
                font-family: BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif;
            }
        }
        [data-test-id='spacekit'] {
            [data-baseweb='popover'] {
                margin-top: 8px;

                > div {
                    box-shadow: none;
                    overflow: hidden;
                    border-radius: 2px;
                }
            }

            [data-spaceweb='list'] {
                padding: 0;
                background-color: ${theme && theme.select.menu.default.background};

                li[aria-selected='true'] {
                    background-color: ${theme && theme.select.menu.default.background};
                }

                li {
                    transition: background-color 300ms;
                    padding: 6px 12px;

                    &:hover[aria-disabled='false'] {
                        background-color: ${theme && theme.select.option.hover.background};
                    }

                    &:before {
                        background-color: ${theme && theme.select.option.selected.border};
                    }

                    * {
                        font-size: 12px;
                    }
                }
            }
        }
    `}
`;
