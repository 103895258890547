import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import './PostImage.scss';
import { BoundingBox } from 'models/Intuition/Intuition';
import { meanPlacement } from 'utils/MathUtils/LinearMeasurement';
import { computed, observable, runInAction } from 'mobx';
import { thumbnailUrl, thumbnailUrlWithFallback } from 'utils/Thumbnailer/Thumbnailer';
import Icon from 'src/components/Icon/Icon';

interface PostImagePropsInterface {
    src?: string;
    maxImageWidth?: number;
    maxImageHeight?: number;
    color?: string;
    // overlay
    hasOverlay?: boolean;
    showSecondaryImageOverlay?: boolean;
    overlayColor?: string;
    overlaySrc?: string;
    backgroundColor?: string;
    imageBackgroundSize?: string;
    secondaryOverlayColor?: string;
    postVersion2?: boolean;
    noCropRegion?: BoundingBox;
    featureDebug?: boolean;
    width?: number;
    height?: number;
}

class PostImage extends React.Component<PostImagePropsInterface, any> {
    public static defaultProps: PostImagePropsInterface = {
        color: '#222',
        src: '',
        maxImageWidth: 1920,
        maxImageHeight: 1080,
        overlayColor: 'black',
        backgroundColor: 'black',
        featureDebug: false,
        hasOverlay: false,
    };

    @observable imgSrc = '';

    componentDidMount() {
        runInAction(async () => {
            this.imgSrc = await thumbnailUrlWithFallback(
                this.props.src,
                this.props.maxImageWidth,
                this.props.maxImageHeight
            );
        });
    }

    @computed
    private get backgroundPosition() {
        const { width, height, noCropRegion } = this.props;

        if (!noCropRegion) {
            return undefined;
        }

        const imageOffsetX: number = meanPlacement(width, [noCropRegion.x0, noCropRegion.x1]) * 100;
        const imageOffsetY: number =
            meanPlacement(height, [noCropRegion.y0, noCropRegion.y1]) * 100;

        return `${imageOffsetX}% ${imageOffsetY}%`;
    }

    /**
     * Used in "debug" mode for drawing a rectangle over the noCropRegion of the image. Useful for understanding when
     * things don't work out as expected - where do we think the faces are vs. what's actually in view? Also used to
     * help recognize when NO face is detected in an image where a human can see a face. The Intuition service face
     * detector isn't perfect!
     */
    @computed
    private get debugSvgRectDataUrl(): string {
        const { noCropRegion, width, height } = this.props;
        const el = (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}>
                {noCropRegion && (
                    <rect
                        x={noCropRegion.x0}
                        y={noCropRegion.y0}
                        width={noCropRegion.x1 - noCropRegion.x0}
                        height={noCropRegion.y1 - noCropRegion.y0}
                        style={{
                            fill: 'rgb(54,54,200)',
                            fillOpacity: 0.3,
                            strokeOpacity: 0.5,
                            strokeWidth: '3px',
                            stroke: 'rgb(200,54,54)',
                        }}
                    />
                )}
            </svg>
        );

        return `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(el)}')`;
    }

    render() {
        const { src } = this.props;

        const overlayClasses = classNames({
            post_image_overlay: true,
        });

        const secondaryOverlayClasses = classNames({
            post_image_secondary_overlay: true,
        });

        const imageClasses = classNames(
            `post__image post_image image_${this.props.imageBackgroundSize}`,
            {}
        );

        const imageStyles = {
            backgroundImage: `url("${this.imgSrc}")`,
            backgroundPosition: this.backgroundPosition,
        };

        const { noCropRegion } = this.props;
        const debugMode = !!(
            noCropRegion &&
            (this.props.featureDebug || (window as any).faceDetectDebug)
        );
        const debugStyles: any = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: this.debugSvgRectDataUrl,
            backgroundPosition: this.backgroundPosition,
        };

        const imageContainerClasses = classNames('post_image_container', {
            fullbleed: this.props.postVersion2,
        });

        return (
            <div className={imageContainerClasses}>
                {/* imageBlendColor */}
                <div className='post_image_background' />

                {/* Image */}
                {src !== 'image-fallback' && <div className={imageClasses} style={imageStyles} />}

                {debugMode && <div className={imageClasses} style={debugStyles} />}

                {/* Overlay */
                this.props.hasOverlay && <div className={overlayClasses}> </div>}

                {/* Secondary Overlay */
                this.props.showSecondaryImageOverlay && (
                    <div className={secondaryOverlayClasses}> </div>
                )}

                {src === 'image-fallback' && (
                    <div className='image_fallback_container flex center middle h100'>
                        <Icon value='image-fallback' classes='image_fallback' reset />
                    </div>
                )}
            </div>
        );
    }
}

export default observer(PostImage);
