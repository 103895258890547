import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { styler } from 'utils/GenerateStyles/GenerateStyles';
import UserGroup from 'components/UserGroup/UserGroup';
import { formatTextEntities } from 'utils/EntityUtils/EntityUtils';

export type QuotedTweetProps = {
    quotedTweet: any;
    quotedTweetSize: number;
    postLayoutOrientation?: string;
};

type StyledProps = {
    size?: number;
    orientation?: string;
    hasMedia?: boolean;
    url?: string;
};

const StyledQuotedTweet = styled.div`
    display: flex;
    flex-direction: row;
    border: 0.5px solid;
    border-radius: 2px;
    ${({ size, hasMedia }: StyledProps) =>
        hasMedia
            ? css`
                  margin-top: ${styler(size, 0.15, 'em', '')};
              `
            : css`
                  margin-top: ${styler(size, 0.15, 'em', '')};
              `}
    ${({ size }: StyledProps) =>
        css`
            width: ${size * 7 + 30 + '%'};
        `}
`;

const StyledQuotedTweetText = styled.div`
    text-align: left;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    width: 100%;
    ${({ size, orientation }: StyledProps) =>
        orientation === 'horizontal'
            ? css`
                  font-size: ${styler(size, 0.18, 'rem', '')};
                  margin: ${styler(size, 0.1, 'em', '')};
              `
            : css`
                  font-size: ${styler(size, 0.12, 'rem', '')};
                  margin: ${styler(size, 0.1, 'em', '')};
              `}
    ${({ hasMedia }: StyledProps) =>
        hasMedia
            ? css`
                  width: 50%;
              `
            : css`
                  width: 100%;
              `}
`;

export const StyledImage = styled.div`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
    ${({ url }: StyledProps) =>
        url &&
        css`
        background-image: url('${url}');
    `};
`;

const QuotedTweet: React.FC<QuotedTweetProps> = ({
    quotedTweet,
    quotedTweetSize,
    postLayoutOrientation,
}) => {
    const { message, urlEntities, mediaList, senderProfile } = quotedTweet;
    const image = mediaList?.length && mediaList[0].picture;

    const classOverrides = `fs-75 p-reset pb-1 pr-0 pl-0 pt-0`;
    const formattedMessage = formatTextEntities(message, urlEntities);

    const quotedTweetUserGroup = quotedTweet?.message && (
        <UserGroup
            key='userGroup'
            classes='no-grow'
            icon={senderProfile.profileImgUrl}
            full_name={senderProfile.name}
            screen_name={senderProfile.screenName}
            time={quotedTweet.snCreatedTime}
            channel={'twitter'}
            layoutType={'a'}
            postLayoutType={'a'}
            showAvatar={true}
            showIcon={true}
            showTimeStampInUserGroup={false}
            showScreenName={true}
            showFullName={true}
            socialIconBgColor={false}
            userGroupPosition={'top'}
            showPostTextOverlay={false}
            showTimeStamp={false}
            userGroupGradientEnabled={false}
            classOverrides={classOverrides}
        />
    );

    return (
        <StyledQuotedTweet size={quotedTweetSize} hasMedia={image}>
            {image && <StyledImage url={image} size={quotedTweetSize}></StyledImage>}
            <StyledQuotedTweetText
                size={quotedTweetSize}
                hasMedia={image}
                orientation={postLayoutOrientation}>
                {quotedTweetUserGroup}
                {formattedMessage}
            </StyledQuotedTweetText>
        </StyledQuotedTweet>
    );
};

QuotedTweet.displayName = 'QuotedTweet';
export default observer(QuotedTweet);
