import React, { useCallback, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { observer } from 'mobx-react';
import moment from 'moment';
import { scaleLinear, scaleTime } from '@vx/scale';
import { Group } from '@vx/group';

// types
import { FieldType } from '@sprinklr/stories/reporting/types';
import { Theme } from 'models/Theme/Theme';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { AxisBubbleChartWidgetOptionsImpl } from '../../../widgets/AxisBubbleChartWidget/options';
import {
    BubblePlotChartWidgetOptions,
    BubblePlotChartWidgetOptionsImpl,
} from '../../../widgets/BubblePlotChartWidget/options';
import Dimension from '@sprinklr/stories/analytics/Dimension';

// components
import XAxis, { IAxis } from '../Primitives/XAxis';
import YAxis from '../Primitives/YAxis';
import Grid from '../Primitives/Grid';
import Legend from '../Primitives/Legend';
import SizeLegend, { ZAxis } from '../Primitives/SizeLegend';

// hooks
import useYAxisDimensions from 'components/_charts/Primitives/UseYAxisDimensions';
import { useNumTicks } from '../../../hooks/useNumTicks';

// helpers
import { GenerateLabelClasses } from 'utils/StringUtils/StringUtils';
import { ComputedStyle, styler, computedStyleToString } from 'utils/GenerateStyles/GenerateStyles';
import { transformData as transformDataBubblePlot } from '../../../widgets/BubblePlotChartWidget/helpers';
import { transformData as transformDataAxisBubble } from '../../../widgets/AxisBubbleChartWidget/helpers';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { getFieldTypeValue } from '@sprinklr/stories/analytics/Field';
import { additionalSort } from 'utils/Widget/WidgetUtils';

import './AxisBubbleChart.scss';

export interface AxisBubbleDataPoints {
    x: number | string;
    y: number;
    z: number;
}

export type AxisBubbleSeries = {
    name: string;
    data: AxisBubbleDataPoints[];
    type: FieldType;
    color: string;
    image?: string;
    snType?: string;
};

export type AxisProps = {
    type?: FieldType;
    label?: string;
    isPercentage?: boolean;
    options?: IAxis;
};

export type ZAxisProps = {
    type?: FieldType;
    label?: string;
    options?: ZAxis;
    isPercentage?: boolean;
};

export type AxisBubbleChartProps = {
    data?: AxisBubbleSeries[];
    options?: BubblePlotChartWidgetOptionsImpl & BubblePlotChartWidgetOptions;
    xAxis?: AxisProps;
    yAxis?: AxisProps;
    zAxis?: ZAxisProps;
    dimensionSort?: Dimension;
    language?: string;
};
const fontSizeScale = 0.75;

function AxisBubbleChart({
    data,
    options,
    xAxis,
    yAxis,
    zAxis,
    dimensionSort,
    language,
}: AxisBubbleChartProps) {
    const zRadius = zAxis && zAxis.options && zAxis.options.radius;
    const zRangeMin = zRadius && zAxis.options.radius.min;
    const zRangeMax = zRadius && zAxis.options.radius.max;
    const zSize = zRadius && zAxis.options.radius.size;
    const { legend, showAccountIcon } = options;

    const [chartRef, { height: chartRefHeight = 0, width: chartRefWidth = 0 }] = useMeasure();
    const [xAxisRef, { height: xAxisHeight = 0 }] = useMeasure();
    const [yAxisRef, yAxisWidth] = useYAxisDimensions({
        watchObject: JSON.stringify([options, yAxis]),
    });

    const showYAxisLabel = yAxis?.options?.label?.enabled;
    const labelWidth = showYAxisLabel ? yAxis?.options.label.size * fontSizeScale : 0;
    const labelPadding = yAxis ? labelWidth * yAxis.options.label.padding * 0.01 : 0;
    const paddedLeftYAxisWidth = yAxisWidth + labelWidth + labelPadding;
    const zAxisValueOptions = (options as AxisBubbleChartWidgetOptionsImpl).zAxisValues;

    const { numTicksForHeight } = useNumTicks({
        height: chartRefHeight,
        yAxisNumTicks: yAxis?.options.ticks.numTicks,
    });

    const xTicks = useMemo(() => {
        const output = [];
        if (typeof data?.[0]?.data?.[0]?.x === 'string') {
            data.forEach(datum =>
                datum.data.forEach(d => {
                    if (output.indexOf(d.x) === -1) {
                        output.push(d.x);
                    }
                })
            );
        }
        // sort the xTicks if the widget has a time-based sort (applies to BubblePlotChart only)
        dimensionSort && additionalSort(dimensionSort, output, options.sortDirection);

        return output;
    }, [data, dimensionSort, options.sortDirection]);

    // extract min/max/mid
    const maxY = useMemo(
        () =>
            Math.max(
                ...data?.map(datum =>
                    datum.data.reduce((acc, current) => Math.max(acc, current.y), 0)
                )
            ),
        [data]
    );

    const minY: number = useMemo(
        () =>
            typeof data?.[0]?.data?.[0].y === 'number'
                ? Math.min(
                      ...data?.map(datum =>
                          datum.data.reduce((min, d) => Math.min(d.y as number, min), maxY)
                      )
                  )
                : 0,
        [data, maxY]
    );

    const maxX: number = useMemo(
        () =>
            typeof data?.[0]?.data?.[0].x === 'number'
                ? Math.max(
                      ...data.map(datum =>
                          datum.data.reduce((acc, current) => Math.max(acc, current.x as number), 0)
                      )
                  )
                : xTicks.length,
        [data, xTicks.length]
    );

    const minX: number = useMemo(
        () =>
            typeof data?.[0]?.data?.[0].x === 'number'
                ? Math.min(
                      ...data.map(datum =>
                          datum.data.reduce((min, d) => Math.min(d.x as number, min), maxX)
                      )
                  )
                : 0,
        [data, maxX]
    );

    const maxZ = useMemo(
        () =>
            Math.max(
                ...data?.map(datum =>
                    datum.data.reduce(
                        (max, d) =>
                            Number.isNaN(Math.max(d.z, max)) ? zRangeMax : Math.max(d.z, max),
                        0
                    )
                )
            ),
        [data, zRangeMax]
    );

    const minZ = useMemo(
        () =>
            Math.min(
                ...data?.map(datum =>
                    datum.data.reduce(
                        (min, d) =>
                            Number.isNaN(Math.min(d.z, min)) ? zRangeMin : Math.min(d.z, min),
                        maxZ
                    )
                )
            ),
        [data, maxZ, zRangeMin]
    );

    const midZ = (minZ + maxZ) / 2;

    const getLeftMargin = useMemo(() => {
        let result = 0;
        if (!yAxis?.options.enabled) {
            return result;
        }
        const labelSize = yAxis?.options.label.enabled
            ? yAxis?.options.label.size * fontSizeScale
            : 0;
        const labelPad = labelSize * yAxis?.options.label.padding * 0.01;
        result = yAxisWidth + labelSize + labelPad;

        return result;
    }, [
        yAxis?.options.enabled,
        yAxis?.options.label.enabled,
        yAxis?.options.label.padding,
        yAxis?.options.label.size,
        yAxisWidth,
    ]);

    // space outside of chart
    const margin = useMemo(
        () => ({
            top: 0,
            bottom: xAxisHeight || 0,
            right: chartRefWidth * 0.01,
            left: getLeftMargin || 0,
        }),
        [chartRefWidth, getLeftMargin, xAxisHeight]
    );

    // space inside chart
    const padding = useMemo(
        () => ({
            top: maxY * (yAxis?.options.padding.start * 0.01),
            bottom: maxY * (yAxis?.options.padding.end * 0.01),
            left: maxX * (xAxis?.options.padding.start * 0.01),
            right: maxX * (xAxis?.options.padding.end * 0.01),
        }),
        [
            maxX,
            maxY,
            xAxis?.options.padding.end,
            xAxis?.options.padding.start,
            yAxis?.options.padding.end,
            yAxis?.options.padding.start,
        ]
    );

    const zScale = useMemo(
        () =>
            scaleLinear({
                domain: [minZ, maxZ],
                range: [zRangeMin, zRangeMax],
                nice: true,
            }),
        [maxZ, minZ, zRangeMax, zRangeMin]
    );

    const radiusSpace = isNaN(zScale(maxZ)) ? zSize : zScale(maxZ);
    const heightMax: number = chartRefHeight - margin.top - margin.bottom;
    const widthMax: number = chartRefWidth - margin.left - margin.right;
    const xRadius = maxX * (radiusSpace / widthMax);
    const yRadius = maxY * (radiusSpace / heightMax);

    // create left/right padding for time
    if (xAxis?.type === 'TIMESTAMP') {
        const diff: any = moment.duration(moment(minX).diff(maxX)).get('days');
        padding.left = Math.ceil(Math.abs(diff) * (xAxis?.options.padding.start * 0.01));
        padding.right = Math.ceil(Math.abs(diff) * (xAxis?.options.padding.end * 0.01));
    }

    const yAxisMin = options.yAxisZeroStart ? Math.min(0, minY) : minY;
    const yScale = useMemo(
        () =>
            scaleLinear({
                rangeRound: [heightMax, 0],
                domain: [yAxisMin - padding.bottom, maxY + padding.top + yRadius],
                nice: true,
            }),
        [heightMax, maxY, padding.bottom, padding.top, yAxisMin, yRadius]
    );

    const xScale = useMemo(
        () =>
            xAxis?.type === 'TIMESTAMP'
                ? scaleTime({
                      rangeRound: [0, widthMax],
                      domain: [
                          moment(minX)
                              .subtract(padding.left, 'days')
                              .valueOf(),
                          moment(maxX)
                              .add(padding.right, 'days')
                              .valueOf(),
                      ],
                      nice: true,
                  })
                : scaleLinear({
                      rangeRound: [0, widthMax],
                      domain: [minX - padding.left, maxX + padding.right + xRadius],
                      nice: true,
                  }),
        [maxX, minX, padding.left, padding.right, widthMax, xAxis?.type, xRadius]
    );

    const numTicksForWidth = useCallback((width: number): number => {
        if (width <= 300) {
            return 4;
        } else if (300 < width && width <= 600) {
            return 7;
        } else {
            return 10;
        }
    }, []);

    const getDotPosition = useCallback(
        dot => {
            const x = (d: any) => xScale(d.x);
            const y = (d: any) => yScale(d.y);
            const resolvedX = Number.isNaN(x(dot)) ? xScale(xTicks.indexOf(dot.x)) : x(dot);
            const radius = dot.z === undefined ? zSize / 2 : zScale(dot.z);
            const cx = margin.left + resolvedX;
            const cy = margin.top + y(dot);

            return { cx, cy, radius };
        },
        [margin.left, margin.top, xScale, xTicks, yScale, zScale, zSize]
    );

    const items = useMemo(
        () =>
            data?.slice()?.[0]?.data[0].z >= 0
                ? [
                      {
                          value: minZ,
                          radius: isNaN(zScale(maxZ)) ? zSize / 2 : zScale(minZ),
                      },
                      {
                          value: midZ,
                          radius: isNaN(zScale(maxZ)) ? zSize / 2 : zScale(midZ),
                      },
                      {
                          value: maxZ,
                          radius: isNaN(zScale(maxZ)) ? zSize / 2 : zScale(maxZ),
                      },
                  ]
                : [
                      {
                          value: midZ,
                          radius: isNaN(zScale(maxZ)) ? zSize / 2 : zScale(midZ),
                      },
                  ],
        [data, maxZ, midZ, minZ, zScale, zSize]
    );

    let bubbleIndex = 0;

    const ticksForWidth = useMemo(
        () =>
            xTicks?.length > 0 && xTicks?.length < numTicksForWidth(widthMax)
                ? xTicks?.length
                : numTicksForWidth(widthMax),
        [numTicksForWidth, widthMax, xTicks?.length]
    );

    const showXAxisLegend = legend?.enabled && data?.length > 1;
    const showZAxisLegend = zAxis.options.enabled && items.length > 1;

    // if all the datapoints are less than one, change number format to 1,234
    let yNumberFormat = yAxis?.options.ticks.label.numberFormat;
    const valuesGreaterThanOne = useMemo(
        () =>
            data?.filter(point => {
                return point.data[0].y >= 1;
            }),
        [data]
    );
    if (valuesGreaterThanOne?.length === 0) {
        yNumberFormat = '1,234';
    }

    if (!data?.length || !xAxis || !yAxis) {
        return <div className='axis_bubble_chart flex rel' />;
    }

    return (
        <div className='axis_bubble_chart flex rel h-100 w-100 vertical'>
            <div className={`flex no-grow top ${!showXAxisLegend ? 'right' : ''}`}>
                {(showXAxisLegend || showZAxisLegend) && (
                    <>
                        {showXAxisLegend && (
                            <Legend
                                items={data?.map(item => ({
                                    name: item.name + '',
                                    snType: item.snType,
                                }))}
                                options={legend}
                                showAccountIcon={showAccountIcon}
                            />
                        )}
                        {showZAxisLegend && (
                            <SizeLegend
                                items={items}
                                label={zAxis.label}
                                decimalFormat={zAxis.options.numberFormat}
                                isPercentage={zAxis.isPercentage}
                            />
                        )}
                    </>
                )}
            </div>
            <div className='flex w-100 h-100 rel mt-1' ref={chartRef}>
                {chartRefHeight > 0 && chartRefWidth > 0 && (
                    <>
                        <svg width={chartRefWidth} height={chartRefHeight} className='ov-v'>
                            <Group>
                                {options.grid?.enabled && (
                                    <Grid
                                        height={heightMax}
                                        margin={margin}
                                        numTicksColumns={ticksForWidth}
                                        numTicksRows={numTicksForHeight}
                                        width={widthMax}
                                        xScale={xScale}
                                        yScale={yScale}
                                    />
                                )}
                                {yAxis?.options?.enabled && (
                                    <YAxis
                                        ref={yAxisRef}
                                        decimalFormat={yNumberFormat}
                                        hideAxisLine={!options.grid.enabled}
                                        hideTicks={
                                            (yAxis && !yAxis.options.ticks.line.enabled) ||
                                            !options.grid.enabled
                                        }
                                        hideZero={false}
                                        labelOffset={
                                            paddedLeftYAxisWidth -
                                            labelWidth -
                                            (!yAxis.options.ticks.label.enabled ? 0 : 10)
                                        }
                                        label={
                                            yAxis.label && yAxis.options.label.enabled
                                                ? yAxis.label
                                                : undefined
                                        }
                                        labelType={yAxis.type}
                                        top={margin.top}
                                        left={margin.left}
                                        numTicks={numTicksForHeight}
                                        orientation='left'
                                        scale={yScale}
                                        options={yAxis.options}
                                        isPercentage={yAxis.isPercentage}
                                    />
                                )}
                                {xAxis?.options?.enabled && (
                                    <XAxis
                                        ref={xAxisRef as React.Ref<SVGGElement>}
                                        left={margin.left}
                                        top={heightMax + margin.top}
                                        bandwidth={10}
                                        dateFormat={xAxis.options.ticks.label.timeFormat}
                                        hideAxisLine={!options.grid.enabled}
                                        label={xAxis.label}
                                        labelType={xAxis.type}
                                        numTicksForWidth={ticksForWidth}
                                        options={xAxis.options}
                                        xScale={xScale}
                                        xValues={xTicks}
                                        language={language}
                                    />
                                )}
                            </Group>
                        </svg>
                        <div className='fullbleed'>
                            {data?.map((datum, datumIndex) => {
                                return datum.data.map(dot => {
                                    const { cx, cy, radius } = getDotPosition(dot);
                                    let collision;
                                    if (zAxisValueOptions?.enabled) {
                                        const collidingDot = data.find((otherDatum, index) => {
                                            if (index !== datumIndex) {
                                                const comparisonDotPosition = getDotPosition(
                                                    otherDatum.data[0]
                                                );
                                                const buffer =
                                                    (radius + comparisonDotPosition.radius) * 0.6;

                                                return (
                                                    datumIndex < index &&
                                                    checkCollision(
                                                        comparisonDotPosition.cx,
                                                        cx,
                                                        buffer
                                                    ) &&
                                                    checkCollision(
                                                        comparisonDotPosition.cy,
                                                        cy,
                                                        buffer
                                                    )
                                                );
                                            }
                                        });
                                        if (collidingDot) {
                                            collision = true;
                                        }
                                    }

                                    const style: React.CSSProperties = {
                                        transform: `translate3d(${cx - radius}px, ${cy -
                                            radius}px, 0)`,
                                        width: radius * 2,
                                        height: radius * 2,
                                        fontSize: zAxisValueOptions?.autoSize
                                            ? `${radius * 0.035}em`
                                            : zAxisValueOptions?.size &&
                                              styler(zAxisValueOptions.size, 0.1, 'em', ''),
                                        color: zAxisValueOptions?.color,
                                    };

                                    bubbleIndex++;

                                    const renderBubbleValue =
                                        !collision && zAxisValueOptions?.enabled;
                                    const bubbleValue =
                                        renderBubbleValue &&
                                        zAxisValueOptions &&
                                        getFieldTypeValue(dot.z, 'NUMBER', {
                                            decimalFormat: zAxisValueOptions.format,
                                        });
                                    const bubbleClassName = GenerateLabelClasses(
                                        datum.name + '',
                                        'bubble'
                                    );

                                    return (
                                        <div
                                            className={`bubble bubble_index_${bubbleIndex} ${bubbleClassName} series_index_${datumIndex +
                                                1} primary_background circle primary_font_family flex vertical center middle`}
                                            title={`x: ${dot.x}, y: ${dot.y}, z: ${dot.z}`}
                                            style={style}
                                            key={`${bubbleIndex}_dot.x_dot.y`}>
                                            {renderBubbleValue && (
                                                <div className={'bubble_text'}>{bubbleValue}</div>
                                            )}
                                        </div>
                                    );
                                });
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export const axisBubbleChartOptionStyles = (
    options: BubblePlotChartWidgetOptionsImpl & BubblePlotChartWidgetOptions
) => {
    const { legend } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.legend_item ',
            styles: {
                fontSize: styler(legend.size, 0.1, 'em', ''),
            },
        },
    ];

    return computedStyles;
};

const checkCollision = (a: number, b: number, c: number): boolean => {
    return Math.abs(a - b) <= c;
};

AxisBubbleChart.displayName = 'AxisBubbleChart';
export default observer(AxisBubbleChart);
