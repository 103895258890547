import { defaultSmartSize } from './SharedConfig';

const defaultClassOverrides = {
    postInner: {
        default: 'between',
    },
    userGroup: {
        hasMedia: 'no-grow middle p-reset pt-3 pb-1 pr-3 pl-3',
        noMedia: 'no-grow middle p-reset pt-3 pb-1 pr-3 pl-3',
        review: 'no-grow f-reset-center p-0 p-reset vertical',
    },
    postText: {
        hasMedia: 'p-reset pb-3 pr-3 pl-3 pt-1',
        noMedia: 'p-reset pb-3 pr-3 pl-3 pt-1 ta-c',
        review: 'p-reset pr-3 pl-3 pt-2 ta-c h-100',
    },
    lineClampVerticalAlign: {
        hasMedia: 'bottom',
        noMedia: 'middle',
    },
    metaGroup: {
        hasMedia: 'pr-3 pl-3 pt-3 pb-0',
        noMedia: 'pr-3 pl-3 pt-1 pb-2',
    },
    reviewTitle: {
        default: 'flex center ta-c f-ng-reset mt-2 bottom h-33',
    },
    reviewRating: {
        default: 'flex center no-grow',
    },
    reviewDomain: {
        default: 'flex left no-grow',
    },
    reviewProductTitle: {
        default: 'center',
    },
    postTitle: {
        default: 'flex middle no-grow',
    },
};

const retweetOptions = {
    enabled: true,
    opacity: 50,
    size: 15,
};

export const cinematicConfig: any = {
    a: {
        postTextClasses: 'bottom',
        userGroupLayoutType: 'a',
        userGroupPosition: 'top',
        showSecondaryImageOverlay: false,
        imageBlendMode: null,
        showPostTextOverlay: true,
        showPostTextOverlayAsGradient: true,
        showIconInUserGroup: true,

        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postTextOnlySize: 35,
        postLineClampValue: 6,

        postImageTextFormat: 'clamp',
        imagePostLineClamp: true,
        postImageTextSize: 20,
        imagePostLineClampValue: 5,
        imagePostHideText: false,

        userGroupNameSize: 25,
        userGroupScreenNameSize: 15,
        retweetIndicator: retweetOptions,

        userGroupAvatarSize: 30,
        userGroupIconSize: 20,
        engagementMetricsSize: 30,

        imageBlendColor: 'rgba(0,0,0,.0)',

        imageFilterSaturate: false,
        imageFilterSaturateValue: 100,

        imageFilterBlur: false,
        imageFilterBlurValue: 0,

        imageFilterOpacity: false,
        imageFilterOpacityValue: 100,

        imageFiltersOpen: true,

        classOverrides: {
            ...defaultClassOverrides,
            postTitle: {
                default: 'p-reset pl-2 pr-2 flex no-grow middle center ta-c',
            },
        },
        smartSizeCompositions: {
            baseline: {
                postTextOnlySize: 35,
                postImageTextSize: 20,
                userGroupNameSize: 25,
                userGroupScreenNameSize: 15,
                userGroupAvatarSize: 30,
                userGroupIconSize: 20,
                engagementMetricsSize: 30,
                retweetIndicator: {
                    size: 15,
                },
            },
            optionDefaults: {
                showUserGroup: true,
                showEngagementMetrics: true,
                imagePostHideText: false,
                classOverrides: {
                    ...defaultClassOverrides,
                },
            },
            breakpoints: {
                xs: {
                    options: {
                        showUserGroup: false,
                        imagePostHideText: true,
                        showEngagementMetrics: false,
                        classOverrides: {
                            postText: {
                                default: 'p-reset p-1 ta-c',
                            },
                        },
                    },
                },
                s: {
                    options: {
                        classOverrides: {
                            userGroup: {
                                hasMedia: 'no-grow middle p-reset pt-15 pb-1 pr-15 pl-15',
                                noMedia: 'no-grow middle p-reset pt-15 pb-1 pr-15 pl-15',
                                review: 'no-grow f-reset-center p-0 p-reset vertical',
                            },
                            postText: {
                                hasMedia: 'p-reset pb-15 pr-15 pl-15 pt-1',
                                noMedia: 'p-reset pb-15 pr-15 pl-15 pt-1 ta-c',
                                review: 'p-reset pr-15 pl-15 pt-15 ta-c h-100',
                            },
                            lineClampVerticalAlign: {
                                hasMedia: 'bottom',
                                noMedia: 'middle',
                            },
                            metaGroup: {
                                hasMedia: 'pr-15 pl-15 pt-15 pb-0',
                                noMedia: 'pr-15 pl-15 pt-1 pb-15',
                            },
                            postTitle: {
                                default: 'p-reset pr-15 pl-15 h-30 bottom no-grow ',
                            },
                        },
                    },
                },
                m: {
                    options: {
                        classOverrides: {
                            userGroup: {
                                hasMedia: 'no-grow middle p-reset pt-2 pb-1 pr-2 pl-2',
                                noMedia: 'no-grow middle p-reset pt-2 pb-1 pr-2 pl-2',
                                review: 'no-grow f-reset-center p-0 p-reset vertical',
                            },
                            postText: {
                                hasMedia: 'p-reset pb-2 pr-2 pl-2 pt-1',
                                noMedia: 'p-reset pb-2 pr-2 pl-2 pt-1 ta-c',
                                review: 'p-reset pr-2 pl-2 pt-2 ta-c h-100',
                            },
                            lineClampVerticalAlign: {
                                hasMedia: 'bottom',
                                noMedia: 'middle',
                            },
                            metaGroup: {
                                hasMedia: 'pr-2 pl-2 pt-2 pb-0',
                                noMedia: 'pr-2 pl-2 pt-1 pb-2',
                            },
                            postTitle: {
                                default: 'p-reset pl-2 pr-2 pb-2 no-grow middle ta-c',
                            },
                        },
                    },
                },
            },
        },
    },
    b: {
        userGroupLayoutType: 'c',
        userGroupPosition: 'bottom',
        showSecondaryImageOverlay: false,
        showPostTextOverlay: false,
        showIconInUserGroup: false,
        textOnlyTextFormat: 'fit',
        postTextOnlySize: 35,

        imagePostHideText: false,
        imagePostLineClamp: true,
        postImageTextFormat: 'clamp',
        postImageTextSize: 25,
        imagePostLineClampValue: 6,

        userGroupNameSize: 25,
        userGroupScreenNameSize: 15,
        retweetIndicator: retweetOptions,

        userGroupAvatarSize: 30,
        userGroupIconSize: 20,
        engagementMetricsSize: 30,
        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: 'multiply',
        imageFilterSaturateValue: 0,
        imageFilterSaturate: true,
        imageFilterBlurValue: 2,
        imageFilterBlur: true,

        imageFilterOpacity: true,
        imageFilterOpacityValue: 40,
        imageFiltersOpen: false,

        classOverrides: {
            userGroup: {
                default: 'p-reset pt-1 pb-2 pr-3 pl-3',
                review: 'p-0 p-reset left horizontal',
            },
            metaGroup: {
                default: 'p-reset pb-0 pt-3 pr-3 pl-3',
            },
            postText: {
                hasMedia: 'p-reset p-3',
                noMedia: 'p-reset pb-2 pr-3 pl-3 pt-1',
                review: 'p-reset pb-2 pr-3 pl-3 pt-1 h-50',
            },
            lineClampVerticalAlign: {
                default: 'middle',
            },
            reviewTitle: {
                default: 'h-25 mt-1 mb-1 pl-3 pr-3 w-100',
            },
            reviewRating: {
                default: 'flex right no-grow p-1',
            },
            reviewDomain: {
                default: 'flex left no-grow',
            },
            reviewProductTitle: {
                default: 'p-reset p-0 pl-2',
            },
            postTitle: {
                default: 'p-reset pr-3 pl-3 h-30 bottom no-grow ta-c',
            },
        },
        smartSizeCompositions: {
            ...defaultSmartSize,
        },
    },

    c: {
        userGroupLayoutType: 'd',
        userGroupPosition: 'bottom',
        showSecondaryImageOverlay: false,
        showPostTextOverlay: false,
        imageBlendMode: null,
        showIconInUserGroup: false,

        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 6,
        postTextOnlySize: 35,

        postImageTextFormat: 'clamp',
        imagePostLineClamp: true,
        imagePostLineClampValue: 3,
        imagePostHideText: true,
        postImageTextSize: 30,

        userGroupNameSize: 25,
        userGroupScreenNameSize: 15,
        retweetIndicator: retweetOptions,

        userGroupAvatarSize: 30,
        userGroupIconSize: 20,
        engagementMetricsSize: 30,

        imageBlendColor: 'rgba(0,0,0,.0)',

        imageFilterSaturate: false,
        imageFilterSaturateValue: 100,

        imageFilterBlur: false,
        imageFilterBlurValue: 0,

        imageFilterOpacity: false,
        imageFilterOpacityValue: 100,

        imageFiltersOpen: true,

        classOverrides: {
            postInner: {
                default: 'between',
            },
            userGroup: {
                default: 'p-reset pt-1 pb-2 pr-3 pl-3',
            },
            metaGroup: {
                default: 'pt-3 pr-3 pl-3 pb-0',
            },
            postText: {
                default: 'p-reset pr-3 pl-3 pb-0 pt-0',
                review: 'p-reset pr-3 pl-3 pb-0 pt-0 h-33 mt-2',
                showTitle: 'p-reset pr-3 pl-3 pb-0 pt-0 h-70',
            },
            lineClampVerticalAlign: {
                default: 'middle',
            },
            reviewDomain: {
                default: 'flex center',
            },
            reviewTitle: {
                default: 'p-reset pl-3 pr-3 f-ng-reset',
            },
            reviewRating: {
                default: 'mt-4 pl-3 pr-3',
            },
            postTitle: {
                default: 'p-reset pr-3 pl-3 pb-3 h-30 ta-c',
            },
        },
        smartSizeCompositions: {
            ...defaultSmartSize,
        },
    },
};
