import { useState, useCallback, useLayoutEffect, useRef } from 'react';

type UseDimensionsHook = [
    (node: HTMLElement | SVGGraphicsElement) => void,
    number,
    HTMLElement | SVGGraphicsElement
];
interface UseDimensionsArgs {
    labelOffset?: number;
    watchObject?: any;
}
function getMaxWidth(node: HTMLElement | SVGGraphicsElement): number {
    const values = Array.from(node.children[0].children).map(
        item => (item as SVGGraphicsElement).getBBox().width
    );
    return Math.max(...values);
}
function useYAxisDimensions({
    watchObject,
    labelOffset = 0,
}: UseDimensionsArgs = {}): UseDimensionsHook {
    const disposeFrame = useRef(null);
    const [width, setWidth] = useState<number>(0);
    const [node, setNode] = useState<SVGGraphicsElement | HTMLElement>(null);
    const ref = useCallback(node => {
        setNode(node);
    }, []);
    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                (disposeFrame.current = window.requestAnimationFrame(() =>
                    setWidth(getMaxWidth(node) + labelOffset)
                ));
            measure();
        } else {
            disposeFrame.current = window.requestAnimationFrame(() => setWidth(0));
        }

        // cancel the animation frame when the component is unmounted
        return () => {
            window.cancelAnimationFrame(disposeFrame.current);
        };
    }, [node, watchObject]);
    return [ref, width, node];
}
export default useYAxisDimensions;
