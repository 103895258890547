import { socialColors } from 'utils/GenerateColors/GenerateColors';
import { ColorScheme } from '.';

export function getChannelColor({
    channel,
    theme,
}: {
    channel: string;
    theme?: ColorScheme;
}): string {
    const match = Object.keys(socialColors).find(color => channel.includes(color));
    if (match) {
        return socialColors[match];
    } else {
        return theme?.channel.background ?? '';
    }
}
