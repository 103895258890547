import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Avatar from '../Avatar/Avatar';
import TimeStamp from '../TimeStamp/TimeStamp';
import UserName from '../UserName/UserName';
import Icon from '../Icon/Icon';
import { TimeStampOptions } from '@sprinklr/stories/widget/types';
import { thumbnailUrl } from 'utils/Thumbnailer/Thumbnailer';
import { PostLocation } from '@sprinklr/stories/post/Post';
import { PostOptionLocation } from 'src/widgets/PostsWindowWidget/types';
import { PostsLocation, getTimeStampLocationLockup } from 'utils/PostUtils/PostUtils';
import { SYMBOL_SCREEN_NAMES } from './constants';

import './UserGroup.scss';

type UserGroupProps = {
    postLayoutType?: 'a' | 'b' | 'c' | 'd' | 'e';
    maxAvatarImageSize?: number;
    icon?: string;
    full_name?: string;
    screen_name?: string;
    isRss?: boolean;
    channel?: string;
    time?: number;
    effect?: string;
    showAvatar?: boolean;
    showScreenName?: boolean;
    showFullName?: boolean;
    showTimeStamp?: boolean;
    socialIconBgColor?: boolean;
    showIcon?: boolean;
    userGroupPosition?: string;
    layoutType?: string;
    showPostTextOverlay?: boolean;
    showTimeStampInUserGroup?: boolean;
    timeStampFormat?: TimeStampOptions;
    endianTime?: string;
    classOverrides?: string;
    userGroupGradientEnabled?: boolean;
    userGroupGradientDirection?: string;
    classes?: string;
    customProperty?: string | string[];
    location?: PostLocation;
    locationOptions?: PostOptionLocation;
    retweetIndicator?: React.ReactElement;
    missingUserInfo?: boolean;
    removeGenericPII?: boolean;
};

const FlexContainer = ({ children, retweetIndicator }) => (
    <>
        {retweetIndicator}
        <div className='flex w-100'>{children}</div>
    </>
);

const UserGroup: React.FC<UserGroupProps> = ({
    icon = '',
    maxAvatarImageSize = 300,
    full_name,
    screen_name,
    isRss,
    channel,
    time,
    effect,
    showAvatar,
    showScreenName,
    showFullName,
    userGroupPosition,
    showTimeStamp = false,
    layoutType,
    showIcon,
    socialIconBgColor,
    postLayoutType,
    showPostTextOverlay,
    timeStampFormat,
    endianTime,
    showTimeStampInUserGroup,
    classOverrides = '',
    userGroupGradientEnabled,
    userGroupGradientDirection,
    customProperty,
    location,
    locationOptions,
    retweetIndicator,
    missingUserInfo,
    removeGenericPII,
}) => {
    const hideUserInfo = removeGenericPII && missingUserInfo;

    const effectClasses = classNames(`position_${userGroupPosition}`, {
        effect: true,
        [`effect--${effect}`]: true,
    });

    const avatarClasses = classNames({
        post_avatar: true,
        'rel z-top no-grow': true,
        primary_background: true,
        'mr-05 ml-05': layoutType === 'd',
    });

    const postChannelClasses = classNames({
        post_channel_icon: true,
        'abs z-top ': layoutType === 'c',
        'right right top origin-middle': layoutType === 'c',
    });

    const userContainerClasses = classNames(`${classOverrides}`, {
        'post_user_container flex w100': true,
        top: showPostTextOverlay && userGroupPosition === 'top',
        bottom: showPostTextOverlay && userGroupPosition === 'bottom',
        middle: !showPostTextOverlay,
        'no-grow': postLayoutType !== 'c' && !showPostTextOverlay,
        'vertical center': layoutType === 'c',
        center: layoutType === 'd',
        right: layoutType === 'a' || layoutType === 'b',
        'p-reset p-2': (layoutType === 'a' || layoutType === 'b') && hideUserInfo,
        vertical: !!retweetIndicator,
    });

    const fullNameClasses = layoutType === 'b' ? 'fb-3 rel' : 'rel';
    const userInitial = full_name ? full_name[0] : screen_name && screen_name[0];
    const avatar =
        showAvatar && (icon || userInitial) ? (
            <Avatar
                url={thumbnailUrl(icon, maxAvatarImageSize, maxAvatarImageSize)}
                classes={avatarClasses}
                userInitial={userInitial}
            />
        ) : null;
    const fullName =
        showFullName && full_name ? <UserName name={full_name} classes={fullNameClasses} /> : null;
    const screenName =
        showScreenName && screen_name ? (
            <UserName
                name={screen_name}
                classes='rel'
                type={isRss ? 'rssSource' : 'screenName'}
                showAtSymbol={SYMBOL_SCREEN_NAMES.includes(channel)}
            />
        ) : null;
    const property = customProperty ? (
        <div className='user_property secondary_font_family ml-05'>{customProperty}</div>
    ) : null;
    const hasFullName = full_name && full_name.length > 0 ? true : false;

    const gradient = userGroupGradientEnabled && (
        <div
            className={classNames(`user_group_gradient`, {
                position_bottom: userGroupGradientDirection === 'bottomToTop',
                position_top: userGroupGradientDirection === 'topToBottom',
            })}></div>
    );

    const nameGroup =
        screen_name || full_name ? (
            <div className='user_names_container flex vertical left middle ellipsis rel'>
                {full_name && fullName}
                {screen_name && !customProperty && screenName}
                {!screen_name && customProperty && property}
                {screen_name && customProperty && (
                    <div className='user_property_container flex wrap middle ellipsis'>
                        {screenName}
                        {property}
                    </div>
                )}
            </div>
        ) : null;

    const locationInfo = locationOptions?.enabled && (
        <PostsLocation key='post location' location={location} />
    );

    const timestamp = showTimeStamp && showTimeStampInUserGroup && (
        <TimeStamp time={time} timeStampFormat={timeStampFormat} endianTime={endianTime} />
    );

    const metaContainer = (
        <div
            className={classNames(`post_meta_container flex middle rel`, {
                'no-grow': layoutType === 'a',
                'fb-3 right': layoutType === 'b',
            })}>
            {getTimeStampLocationLockup(timestamp, locationInfo)}
            {showIcon && (
                <Icon value={channel} wrap={socialIconBgColor} classes={postChannelClasses} />
            )}
        </div>
    );

    switch (layoutType) {
        case 'a':
            const layoutA = (
                <>
                    {gradient}
                    {avatar}
                    {nameGroup}
                    {metaContainer}
                </>
            );
            const layoutAMissingUser = <>{metaContainer}</>;
            return (
                <div className={`${userContainerClasses}`}>
                    {retweetIndicator && (
                        <FlexContainer retweetIndicator={retweetIndicator}>{layoutA}</FlexContainer>
                    )}
                    {hideUserInfo && layoutAMissingUser}
                    {!hideUserInfo && !retweetIndicator && layoutA}
                </div>
            );
        case 'b':
            const layoutB = (
                <>
                    {gradient}
                    {fullName}
                    {screen_name && (
                        <div
                            className={classNames(`flex fb-3  middle rel`, {
                                'center vertical': showFullName && hasFullName,
                                left: !showFullName || !hasFullName,
                            })}>
                            {icon && showAvatar && avatar}
                            {showScreenName && (
                                <div className='user_names_container'>
                                    <UserName
                                        name={screen_name}
                                        type={isRss ? 'rssSource' : 'screenName'}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {customProperty && property}
                    {metaContainer}
                </>
            );
            const layoutBMissingUser = <>{metaContainer}</>;
            return (
                <div className={userContainerClasses}>
                    {retweetIndicator && (
                        <FlexContainer retweetIndicator={retweetIndicator}>{layoutB}</FlexContainer>
                    )}
                    {hideUserInfo && layoutBMissingUser}
                    {!hideUserInfo && !retweetIndicator && layoutB}
                </div>
            );
        case 'c':
            return (
                !hideUserInfo && (
                    <div className={userContainerClasses}>
                        {retweetIndicator}
                        {gradient}
                        <div className={effectClasses}></div>

                        {showAvatar && (
                            <div className='rel'>
                                {avatar}
                                {showIcon && (
                                    <Icon
                                        data-testid='user-avatar-user-group'
                                        value={channel}
                                        classes={postChannelClasses}
                                        wrap={socialIconBgColor}
                                    />
                                )}
                            </div>
                        )}

                        {fullName}
                        {screenName}
                        {customProperty && property}

                        {getTimeStampLocationLockup(timestamp, locationInfo)}
                    </div>
                )
            );
        case 'd':
            const layoutD = (
                <>
                    {gradient}

                    {showAvatar && !showScreenName && avatar}

                    {showFullName && (
                        <UserName
                            data-testid='user-name-user-group'
                            name={full_name || ' '}
                            classes={classNames(`f-1-1-0`, {
                                'ta-r': showScreenName,
                                'ta-l': !showScreenName,
                                'pr-05': !showAvatar || !icon,
                            })}
                        />
                    )}

                    {showAvatar && showScreenName && avatar}
                    {showScreenName && (
                        <UserName
                            name={screen_name}
                            type={isRss ? 'rssSource' : 'screenName'}
                            classes={classNames('f-1-1-0', { 'pl-05': !showAvatar || !icon })}
                        />
                    )}
                    {customProperty && property}
                </>
            );
            return (
                !hideUserInfo && (
                    <div className={userContainerClasses}>
                        {retweetIndicator ? (
                            <FlexContainer retweetIndicator={retweetIndicator}>
                                {layoutD}
                            </FlexContainer>
                        ) : (
                            layoutD
                        )}
                    </div>
                )
            );

        default:
            return <div />;
    }
};

UserGroup.displayName = 'UserGroup';
export default observer(UserGroup);
