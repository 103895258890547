import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { UrlEntity } from '@sprinklr/stories/post/Post';
import { StyledPostProps } from './DefaultPost';
import { getNativeColor } from './Helpers';
import { StyledUrl } from './Parts/NativeText';
import { NativeColorMode } from 'src/widgets/PostsWidget/types';

export type NativeLinkPreviewProps = {
    urlEntity: UrlEntity;
    colorMode: NativeColorMode;
};

const colors = {
    darkGray: '#222',
    lightGray: '#777',
    lighterGray: '#DBDBDB',
};

const theme = {
    light: {
        title: colors.darkGray,
        description: colors.lightGray,
        border: colors.lighterGray,
    },
    dark: {
        title: colors.lighterGray,
        description: colors.lightGray,
        border: colors.lightGray,
    },
};

const StyledLinkPreview = styled.div`
    color: black;
    padding: 0.8rem 1.2rem;
    ${({ colorMode }: StyledPostProps) =>
        css`
            border: 0.5px ${getNativeColor(theme, colorMode, 'border')} solid;
        `}
    border-radius: 2px;
    display: flex;
    flex-direction: column;
`;

const StyledLinkTitle = styled.div`
    font-weight: bold;
    font-size: 0.95em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'title')};
        `}
`;

const StyledDescription = styled.div`
    font-size: 0.85em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'description')};
        `}
`;

const NativeLinkPreview: React.FC<NativeLinkPreviewProps> = ({ urlEntity, colorMode }) => {
    const { title, desc, display_url } = urlEntity;

    if (!title && !desc && !display_url) {
        return <div />;
    }

    const formattedUrl = display_url?.split('/')[0];

    return (
        <StyledLinkPreview colorMode={colorMode} className={'link_preview_container'}>
            {title && (
                <StyledLinkTitle
                    colorMode={colorMode}
                    className={'link_preview_title primary_font_family'}>
                    {title}
                </StyledLinkTitle>
            )}
            {desc && (
                <StyledDescription
                    colorMode={colorMode}
                    className={'link_preview_description secondary_font_family'}>
                    {desc}
                </StyledDescription>
            )}
            {formattedUrl && (
                <StyledUrl
                    className={'link_preview_url primary_font_family'}
                    style={{ fontSize: '0.9em !important' }}>
                    {formattedUrl}
                </StyledUrl>
            )}
        </StyledLinkPreview>
    );
};

NativeLinkPreview.displayName = 'NativeLinkPreview';
export default observer(NativeLinkPreview);
