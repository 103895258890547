import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { round } from 'd3';

import './AdsMetrics.scss';

interface AdsMetricsProps {
    classes?: string;
    projectionStats?: any;
}

class AdsMetrics extends React.Component<AdsMetricsProps, any> {
    public static defaultProps: AdsMetricsProps = {
        classes: '',
        projectionStats: [],
    };

    render() {
        const { classes, projectionStats } = this.props;

        const metricContainerClasses = `metric_metric_channel flex`;

        return (
            <div className={`ads_metrics_container flex no-grow ${classes}`}>
                {projectionStats && (
                    <div className={`${metricContainerClasses} flex vertical gap`}>
                        {projectionStats.map((stat, idx) => (
                            <div
                                key={idx}
                                className={classNames(
                                    `ads_metric ads_metric_${stat.label}  middle center flex no-grow`,
                                    {
                                        metric_metric_value_0: !stat.value,
                                    }
                                )}>
                                <div className='ads_metric_number flex'>
                                    {this.projectionLabel(stat.projection)}{' '}
                                    {this.projectionPrefix(stat.projection)}
                                    {round(
                                        !stat.value ? 0 : stat.value.value,
                                        this.projectionPrecision(stat.projection)
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    private projectionLabel(projection: any): string {
        if (projection.details && projection.details.alternateHeading) {
            return projection.details.alternateHeading.replace(/_+/g, ' ');
        }
        return projection.heading.replace(/_+/g, ' ');
    }

    private projectionPrefix(projection: any): string {
        if (projection.details && projection.details.dataType === 'CURRENCY') {
            return '$';
        }
        return '';
    }

    private projectionPrecision(projection: any): number {
        if (projection.details && projection.details.dataType === 'CURRENCY') {
            return 2;
        }
        return 0;
    }
}

export default observer(AdsMetrics);
