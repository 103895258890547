import * as React from 'react';
import generateFragments, { getFormattedFragment } from '../../utils/EntityUtils/EntityUtils';
import { PostEntities } from '@sprinklr/stories/post/Post';
import { parse } from 'twemoji-parser';
import { Emoji } from '@sprinklr/stories/widget/types';

interface EntityMapProps {
    classes?: string;
    clamp?: number;
    styles?: any;
    entityMap?: any;
    maxCharacters?: number;
    rawText?: string;
    rtl?: boolean;
    language?: string;
    blastDelimiter?: string;
    blastText?: boolean;
    showUrls?: boolean;
    clickableUrls?: boolean;
    channel?: string;
    emoji?: Emoji;
}

export default class EntityMap extends React.Component<EntityMapProps, any> {
    static noRegExpUnicode: boolean;

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            linesCheck: null,
        };

        if (EntityMap.noRegExpUnicode === undefined) {
            // Check to see if we're running in the BrightSign enviornment.  If so,
            // don't use the "u" option since their browser throws an exception using it.
            // https://sprinklr.atlassian.net/browse/DISPLAY-1385
            if ((window as any).IsBSLocalFilePresent) {
                EntityMap.noRegExpUnicode = true;
            } else {
                // IE11 and lower don't support it either.  Check for those
                let msie = parseInt(
                    (/msie (\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1],
                    10
                );
                if (isNaN(msie)) {
                    msie = parseInt(
                        (/trident\/.*; rv:(\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1],
                        10
                    );
                }

                if (!isNaN(msie)) {
                    EntityMap.noRegExpUnicode = true;
                } else {
                    try {
                        const myRegEx = new RegExp('(\\s*(\\S+)\\s*)', 'gu');
                        EntityMap.noRegExpUnicode = false;
                    } catch (e) {
                        EntityMap.noRegExpUnicode = true;
                    }
                }
            }
        }
    }

    render() {
        const {
            channel,
            maxCharacters,
            blastDelimiter,
            blastText,
            classes,
            styles,
            showUrls,
            clickableUrls,
            rawText,
            language,
            rtl,
            emoji,
        } = this.props;

        const entityMap = this.props.entityMap ?? [];

        const twitterEmojis = parse(rawText);
        const twitterEmojiEntities: PostEntities[] =
            twitterEmojis && twitterEmojis?.length
                ? twitterEmojis.map(item => {
                      return {
                          indices: item.indices,
                          [item.type]: item.url,
                          source: item.text,
                      };
                  })
                : [];
        const entitiesMerged: PostEntities[] =
            emoji && emoji?.platform === 'twitter'
                ? [...entityMap, ...twitterEmojiEntities]
                : entityMap;
        const fragments = generateFragments({
            text: rawText,
            textEntities: entitiesMerged,
            maxCharacters,
            channel,
        });

        return (
            <p
                className={classes}
                dir={rtl ? 'rtl' : undefined}
                lang={language}
                style={styles}
                ref={node => this.getNode(node)}>
                {fragments.map((fragment, index) => {
                    const blast =
                        !fragment.type && blastText
                            ? fragment.value !== undefined &&
                              fragment.value.match(this.getRegexType(this.props.blastDelimiter))
                            : null;
                    const stripLinksCheck = showUrls ? true : fragment.type !== 'url';
                    const spaceFill = ` `;

                    const entityFragment =
                        fragment.type !== 'emoji' ? (
                            getFormattedFragment(fragment, clickableUrls)
                        ) : (
                            <>
                                <span>{` `}</span>
                                <img
                                    style={{ height: '1em', width: '1em' }}
                                    className='emoji_image'
                                    src={fragment.url}
                                />
                                <span>{` `}</span>
                            </>
                        );

                    return [
                        fragment.type && stripLinksCheck && this.state.linesCheck === null && (
                            <span
                                key={fragment.value + index}
                                className={`text_entity text_entity_${fragment.type}
                                                  text_entity_${this.cssSafe(fragment.value)}`}>
                                {entityFragment}
                            </span>
                        ),

                        !fragment.type && !blast && this.state.linesCheck === null && (
                            <span key={fragment.value + index}> {fragment.value} </span>
                        ),

                        !fragment.type &&
                            blast &&
                            this.state.linesCheck === null &&
                            blast.map((blastFragment, idx) => {
                                const emptyFrag = blastFragment === ' ';
                                const emptyFill =
                                    emptyFrag || blastDelimiter === 'words' ? spaceFill : null;

                                return (
                                    <span
                                        key={blastFragment + idx}
                                        className={`blast ${
                                            emptyFrag ? '' : 'blast_index_' + (index + 1)
                                        }`}>
                                        {blastFragment}
                                        {emptyFill}
                                    </span>
                                );
                            }),
                    ];
                })}
            </p>
        );
    }

    private cssSafe(string) {
        const result: string = string
            .replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '')
            .toLowerCase();
        return result;
    }

    private getRegexType(blastDelimiter) {
        let regex;

        // Note: In IE11, if you use RegExp literal, like /.*/gu, then the
        // "u" option will cause the page to fail to load.  That's why
        // using the constructor version.
        switch (blastDelimiter) {
            case 'words':
            case 'lines':
                if (EntityMap.noRegExpUnicode) {
                    regex = new RegExp('(\\s*(\\S+)\\s*)', 'g');
                } else {
                    regex = new RegExp('(\\s*(\\S+)\\s*)', 'gu');
                }
                break;

            case 'letters':
                if (EntityMap.noRegExpUnicode) {
                    regex = new RegExp('(.)', 'g');
                } else {
                    regex = new RegExp('(.)', 'gu');
                }
                break;
        }

        return regex;
    }

    private getNode(node) {
        if (
            this.props.blastDelimiter === 'lines' &&
            this.state.linesCheck === null &&
            node !== null
        ) {
            const spans = node.children;
            let piece;
            let prev;
            let chunk = [];
            const line = [];

            // loop over each word wrapped span
            for (let index = 0, len = spans.length; index < len; index++) {
                // spans.length && spans.forEach.call(spans, function(el, index) {
                piece = spans[index];
                prev = [index > 0] ? spans[index - 1] : null;

                if (piece.offsetTop === (prev && prev.offsetTop)) {
                    // add the span html to the chunk
                    chunk.push([
                        piece.localName,
                        piece.classList.value,
                        piece.textContent.replace(/<!--(.*?)-->/g, ''),
                    ]);
                } else {
                    // push the chunk as a new index of the line array
                    line.push([chunk]);
                    chunk = [];
                }
            }

            this.setState({
                linesCheck: line,
            });
        }
    }
}
