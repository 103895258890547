import { observable } from 'mobx';
import { HeaderImageProps, ObjectFit, Dimensions } from './types';

export class HeaderImageImpl implements HeaderImageProps {
    @observable src = '';
    @observable backgroundColor = '';
    @observable objectFit: ObjectFit = 'contain';
    @observable dimensions: Dimensions = {
        height: 22,
        width: 22,
    };
    @observable radius = 50;
    @observable imageMask = true;
}
