import styled, { css } from 'components/_UI/designSystem';
import { Icon } from 'components/spaceKit';

const getSpacing = props => {
    const { size } = props;
    switch (size) {
        case 's':
            return '.5em';
        case 'm':
            return '1em';
        case 'l':
            return '1.25em';
    }
};

const getColor = props => {
    const { color } = props;
    if (color) {
        return `${color}`;
    } else {
        return 'initial';
    }
};

// NativeIcon
// extends the Icon component to use em based units for sizing
const NativeIcon = styled(Icon)`
    ${props =>
        css`
            height: ${getSpacing(props)};
            width: ${getSpacing(props)};
            min-width: ${getSpacing(props)};
            svg {
                fill: ${getColor(props)};
            }
        `}
`;

export default NativeIcon;
