import * as React from 'react';
import { observer } from 'mobx-react';
import getPrettyNumber, { getPrettyTimeAgo } from '../../utils/Number/NumberUtils';
import styled, { css } from 'components/_UI/designSystem';
import NativeProfileLockup, {
    StyledUserName,
    StyledSecondaryName,
} from './Parts/NativeProfileLockup';
import NativeText from './Parts/NativeText';
import NativeMedia, { StyledMedia } from './Parts/NativeMedia';
import {
    getNativeMediaOverrides,
    useNativePost,
    baseStyles,
    getEntityStyles,
    StyledMeta,
    getNativeColor,
} from './Helpers';
import { NativePostProps, StyledPostProps } from './DefaultPost';
import NativeIcon from './Bits/NativeIcon';
import NativeFlex from './Bits/NativeFlex';
import NativeLinkPreview from './NativeLinkPreview';

const colors = {
    lightGray: '#60675C',
    blue: '#385898',
    snow: '#7f7f7',
};

const theme = {
    light: {
        link: colors.blue,
        secondary: colors.lightGray,
    },
    dark: {
        link: colors.blue,
        secondary: colors.lightGray,
    },
};

const StyledPost = styled(NativeFlex)`
    ${(props: NativePostProps) => baseStyles(props.options)}
    ${({ ratio }: any) => getNativeMediaOverrides(ratio > 2.3 ? 40 : 60, 60)}

    ${({ colorMode }: StyledPostProps) => getEntityStyles(getNativeColor(theme, colorMode, 'link'))}

    ${StyledUserName} {
    }
    ${StyledSecondaryName} {
    }
    ${StyledMedia} {
        border-radius: 0;
    }
    ${({ colorMode }: StyledPostProps) =>
        css`
            .extra_post_data_value {
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
        `}
`;

const StyledLightText = styled.span`
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'secondary')};
        `}
    font-size: .85em;
`;

const FacebookPost: React.FC<NativePostProps> = ({
    post,
    playerInterval,
    options,
    extraPostDataTags,
}) => {
    const {
        lineClamp,
        maxCharacters,
        colorMode,
        removeUrls,
        emoji,
        hideZero,
        showLinkPreview,
        showExpandedEntityUrls,
        clickableUrls,
    } = options;

    const {
        postRef,
        name,
        screenName,
        profileImgUrl,
        images,
        videos,
        snCreatedTime,
        snStats,
        unique,
        snType,
        expandedTextEntities,
        urlEntities,
        verified,
        hasMedia,
        text,
        rtl,
        language,
        orientation,
        ratio,
        permalink,
    } = useNativePost(post, showExpandedEntityUrls);

    if (!post) {
        return <div key={unique} />;
    }

    const ProfileAndText = (
        <>
            {(profileImgUrl || name || screenName || snType) && (
                <NativeProfileLockup
                    colorMode={colorMode}
                    verified={verified}
                    primary={name}
                    secondary={getPrettyTimeAgo(snCreatedTime)}
                    snType={snType}
                    profileImgUrl={profileImgUrl}
                    clickableUrls={clickableUrls}
                    permalink={permalink}
                />
            )}
            {text && (
                <NativeText
                    removeUrls={removeUrls}
                    emoji={emoji}
                    colorMode={colorMode}
                    hasMedia={hasMedia}
                    lineClamp={lineClamp}
                    maxCharacters={maxCharacters}
                    // size={text && text.length > 70 ? 'l' : 's'}
                    channel={snType}
                    text={text}
                    rtl={rtl}
                    language={language}
                    textEntities={expandedTextEntities && expandedTextEntities.message}
                    clickableUrls={clickableUrls}
                />
            )}
        </>
    );

    const { value: commentValue, suffix: commentSuffix } = getPrettyNumber(
        snStats.numComments,
        2,
        false
    );

    // console.log('snStats', snStats);
    const comments = snStats.numComments && `${commentValue}${commentSuffix}`;

    const { value: shareValue, suffix: shareSuffix } = getPrettyNumber(snStats.numShares, 2, false);
    const shares = snStats.numShares && `${shareValue}${shareSuffix}`;

    const { value: likesValue, suffix: likesSuffix } = snStats.totalLikesAndReacts
        ? getPrettyNumber(snStats.totalLikesAndReacts, 2, false)
        : getPrettyNumber(snStats.numLikes, 2, false);
    const likes = snStats.numLikes && `${likesValue}${likesSuffix}`;

    const facebookEngagement = (comments || shares || likes || !hideZero) && (
        <NativeFlex gap='m' between bottom>
            <>
                {(likes || !hideZero) && (
                    <NativeFlex gap='m' noGrow middle>
                        <NativeIcon size='m' value='native_facebook_like' />
                        <StyledLightText colorMode={colorMode}>{likes}</StyledLightText>
                    </NativeFlex>
                )}
                {(comments || shares) && (
                    <NativeFlex gap='m' noGrow>
                        {comments && (
                            <StyledLightText colorMode={colorMode}>
                                <span>
                                    {comments} {comments === '1' ? 'Comment' : 'Comments'}
                                </span>
                            </StyledLightText>
                        )}
                        {shares && (
                            <StyledLightText colorMode={colorMode}>
                                <span>
                                    {shares} {shares === '1' ? 'Share' : 'Shares'}
                                </span>
                            </StyledLightText>
                        )}
                    </NativeFlex>
                )}
            </>
        </NativeFlex>
    );

    return (
        <StyledPost
            key={unique}
            ref={postRef}
            hasMedia={hasMedia}
            ratio={ratio}
            vertical={orientation === 'vertical'}
            orientation={orientation}
            options={options}>
            <>
                {orientation === 'vertical' && (
                    <>
                        <NativeFlex pad vertical gap='m' noGrow>
                            {ProfileAndText}
                        </NativeFlex>
                        <>
                            {hasMedia && (
                                <NativeMedia
                                    snType={snType}
                                    playerInterval={playerInterval}
                                    images={images}
                                    videos={videos}
                                />
                            )}

                            {showLinkPreview && (
                                <NativeLinkPreview
                                    urlEntity={urlEntities.message[0]}
                                    colorMode={colorMode}
                                />
                            )}
                        </>
                        <NativeFlex pad bottom>
                            {facebookEngagement}
                            {extraPostDataTags}
                        </NativeFlex>
                    </>
                )}

                {orientation === 'horizontal' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <StyledMeta vertical gap pad>
                            {ProfileAndText}
                            {showLinkPreview && (
                                <NativeLinkPreview
                                    urlEntity={urlEntities.message[0]}
                                    colorMode={colorMode}
                                />
                            )}
                            <NativeFlex bottom>
                                {facebookEngagement}
                                {extraPostDataTags}
                            </NativeFlex>
                        </StyledMeta>
                    </>
                )}
            </>
        </StyledPost>
    );
};

FacebookPost.displayName = 'FacebookPost';
export default observer(FacebookPost);
