import React, { ReactElement } from 'react';
import { PublicationEntity } from '@sprinklr/stories/post/Post';

// Publications
export interface PublicationsProps {
    publicationEntity: PublicationEntity[];
}
export function Publications({ publicationEntity }: PublicationsProps): ReactElement {
    const colors = ['rgb(22, 170, 210)', 'rgb(222, 69, 107)'];
    const showCount = 2;
    const diff = publicationEntity ? publicationEntity.length - showCount : 0;

    return (
        <div
            key='publicationEntitys'
            className='story_publications secondary_font_family flex no-grow middle'>
            <>
                {publicationEntity?.slice(0, showCount).map((publicationEntityItem, index) => (
                    <div
                        className='story_publication flex middle center no-grow bg-cover circle'
                        key={index}
                        style={
                            publicationEntityItem.thumbnail
                                ? { backgroundImage: `url("${publicationEntityItem.thumbnail}")` }
                                : { backgroundColor: `${colors[index]}` }
                        }>
                        {!publicationEntityItem.thumbnail &&
                            publicationEntityItem?.name.slice(0, 1)}
                    </div>
                ))}
                {diff > 0 && (
                    <span className='story_publication_description ml-1'>
                        {' '}
                        +{diff} are talking about this
                    </span>
                )}
            </>
        </div>
    );
}
