import classNames from 'classnames';
import * as React from 'react';
import { observer } from 'mobx-react';
import { BlastDelimiterTypes, Emoji, PostTextFormat } from '@sprinklr/stories/widget/types';
import EntityMap from './EntityMap';
import Textfit from 'react-textfit';
import $ from 'jquery';
import './PostText.scss';
import LinkPreview from './LinkPreview';
import QuotedTweet from './QuotedTweet';

(window as any).jQuery = $; // HACKTOWN:  Text Tailor plugin is expecting window.jQuery defined, but it's not because of jQuery CommonJS implementation - dboyd

interface PostTextProps {
    text?: string;
    rtl?: boolean;
    language?: string;
    textFormat?: PostTextFormat;
    clamp?: number;
    textEntities?: any;
    stripLinks?: boolean;
    maxCharacters?: number;
    classes?: string;
    blastText?: boolean;
    blastDelimiter?: BlastDelimiterTypes;
    showUrls?: boolean;
    clickableUrls?: boolean;
    showPostTextOverlay?: boolean;
    showPostTextOverlayAsGradient?: boolean;
    activePostsInWindow?: number;
    postOffset?: number;
    animationLength?: number;
    animationDelay?: number;
    textSize?: number;
    inGroup?: boolean;
    teleprompterSpeed?: number;
    showEngagementMetrics?: boolean;
    engagementMetricsSize?: number;
    channel?: string;
    textFitMin?: number;
    classOverrides?: string;
    lineClampVerticalAlign?: string;
    postVersion2?: boolean;
    isComment?: boolean;
    isReview?: boolean;
    emoji?: Emoji;
    showLinkPreview?: any;
    urlEntities?: any;
    linkPreviewSize?: any;
    showQuotedTweet?: boolean;
    quotedTweet?: any;
    quotedTweetSize?: number;
    postLayoutOrientation?: string;
}

class PostText extends React.Component<PostTextProps, any> {
    public static defaultProps: PostTextProps = {
        text: '',
        textFormat: 'fit',
        clamp: 2,
        stripLinks: false,
        classes: '',
        blastDelimiter: 'letters',
        blastText: true,
        maxCharacters: 180,
        isComment: false,
    };

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            offset: 0,
            duration: 0,
        };
    }

    componentDidUpdate(prevProps: PostTextProps) {
        if (
            this.props.teleprompterSpeed !== prevProps.teleprompterSpeed ||
            this.props.showEngagementMetrics !== prevProps.showEngagementMetrics ||
            this.props.engagementMetricsSize !== prevProps.engagementMetricsSize ||
            this.props.textSize !== prevProps.textSize
        ) {
            this.setState({
                offset: 0,
                duration: 0,
            });
        }
    }

    render() {
        const {
            channel,
            rtl,
            language,
            textEntities,
            textFormat,
            blastDelimiter,
            blastText,
            clamp,
            showUrls,
            clickableUrls,
            showPostTextOverlay,
            showPostTextOverlayAsGradient,
            activePostsInWindow,
            postOffset,
            animationDelay,
            animationLength,
            inGroup,
            textFitMin,
            classOverrides,
            classes,
            postVersion2,
            lineClampVerticalAlign,
            isComment,
            isReview,
            emoji,
            showLinkPreview,
            urlEntities,
            linkPreviewSize,
            showQuotedTweet,
            quotedTweet,
            quotedTweetSize,
            postLayoutOrientation,
        } = this.props;

        let text = this.props.text ? this.props.text : '';

        const maxCharacters = textFormat === 'teleprompter' ? -1 : this.props.maxCharacters;

        const textContainerClasses = classNames(
            `post_text_container rel flex vertical ${classOverrides} format_${textFormat} w-100`,
            {
                [lineClampVerticalAlign || 'middle']: textFormat === 'clamp',
                [classes]: !postVersion2,
            }
        );

        const textClasses = classNames(`post_text rel format_${textFormat}`, {
            [`clamp-${clamp}`]: textFormat === 'clamp' ? true : false,
            'flex rel middle': textFormat === 'fit' ? true : false,
            center: textFormat === 'fit' && !isComment ? true : false,
            left: textFormat === 'fit' && isComment ? true : false,
            'flex post_text-comment': isComment,
            post_text_review: isReview,
        });

        const wordClampStyles = {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
        };

        // limit length / add ellipsis
        if (
            textFormat !== 'teleprompter' &&
            maxCharacters !== -1 &&
            text &&
            text.length > maxCharacters
        ) {
            text = text.slice(0, maxCharacters) + '...';
        } else if (text.length > 3000) {
            // I don't care who you are, 3000 characters is too much
            text = text.slice(0, 3000) + '...';
        }

        const textOverlayClasses = classNames('post_text_overlay fullbleed', {});
        const textOverlay =
            showPostTextOverlay && !showPostTextOverlayAsGradient ? (
                <div className={textOverlayClasses}></div>
            ) : null;

        const linkPreview = !showLinkPreview ? null : (
            <LinkPreview urlEntity={urlEntities.message[0]} size={linkPreviewSize} />
        );

        const quotedTweetView = !showQuotedTweet ? null : (
            <QuotedTweet
                quotedTweet={quotedTweet}
                quotedTweetSize={quotedTweetSize}
                postLayoutOrientation={postLayoutOrientation}
            />
        );

        switch (textFormat) {
            case 'fit':
                return (
                    <div className={textContainerClasses}>
                        {textOverlay}

                        <Textfit className={textClasses} max={400} min={textFitMin}>
                            <EntityMap
                                rawText={text}
                                language={language}
                                rtl={rtl}
                                maxCharacters={maxCharacters}
                                emoji={emoji}
                                entityMap={textEntities && textEntities.message}
                                blastDelimiter={blastDelimiter}
                                showUrls={showUrls}
                                clickableUrls={clickableUrls}
                                blastText={blastText}
                                channel={channel}
                            />
                        </Textfit>

                        {linkPreview}
                        {quotedTweetView}
                    </div>
                );
            case 'clamp':
                return (
                    <div className={textContainerClasses}>
                        {textOverlay}

                        <EntityMap
                            rawText={text}
                            language={language}
                            rtl={rtl}
                            maxCharacters={maxCharacters}
                            emoji={emoji}
                            entityMap={textEntities && textEntities.message}
                            blastDelimiter={blastDelimiter}
                            blastText={blastText}
                            styles={wordClampStyles}
                            classes={textClasses}
                            showUrls={showUrls}
                            clickableUrls={clickableUrls}
                            clamp={clamp}
                            channel={channel}
                        />

                        {linkPreview}
                        {quotedTweetView}
                    </div>
                );
            case 'teleprompter':
                const case1 =
                    !inGroup &&
                    activePostsInWindow === 3 &&
                    (postOffset === 4 || postOffset === 3 || postOffset === 2 || postOffset === 1);
                const case2 =
                    !inGroup && activePostsInWindow === 1 && (postOffset === 2 || postOffset === 1);
                const case3 = inGroup && (activePostsInWindow === 1 || activePostsInWindow === 0);

                const teleprompterTextContainerClasses = textContainerClasses;
                let teleprompterStyles: any = {};

                if ((case1 || case2 || case3) && this.state.offset !== 0) {
                    teleprompterStyles = {
                        transform: 'translate3d(0,' + this.state.offset + 'px,0)',
                        transitionDuration: this.state.duration + 'ms',
                        transitionDelay: animationDelay + animationLength + 'ms',
                        transitionProperty: 'transform',
                    };
                }

                return (
                    <div className={teleprompterTextContainerClasses} ref={this.getNode}>
                        {textOverlay}
                        <EntityMap
                            rawText={text}
                            language={language}
                            rtl={rtl}
                            maxCharacters={5000}
                            entityMap={textEntities && textEntities.message}
                            blastDelimiter={blastDelimiter}
                            blastText={false}
                            styles={teleprompterStyles}
                            classes={textClasses}
                            showUrls={showUrls}
                            clickableUrls={clickableUrls}
                            clamp={clamp}
                        />
                        {linkPreview}
                        {quotedTweetView}
                    </div>
                );
            default:
                return (
                    <div className={textContainerClasses}>
                        {textOverlay}

                        <EntityMap
                            rawText={text}
                            language={language}
                            rtl={rtl}
                            maxCharacters={maxCharacters}
                            emoji={emoji}
                            entityMap={textEntities && textEntities.message}
                            classes={textClasses}
                            styles={undefined}
                            blastDelimiter={blastDelimiter}
                            showUrls={showUrls}
                            clickableUrls={clickableUrls}
                            blastText={blastText}
                            channel={channel}
                        />
                        {linkPreview}
                        {quotedTweetView}
                    </div>
                );
        }
    }

    private getNode = node => {
        if (node && this.state.offset === 0) {
            if (node.scrollHeight > node.offsetHeight) {
                const offset = node.scrollHeight - node.offsetHeight + this.props.textSize * 1.5;
                const duration = offset / (Math.max(10, this.props.teleprompterSpeed) * 0.004);
                this.setState({
                    offset: offset * -1,
                    duration,
                });
            }
        }
    };
}

export default observer(PostText);
