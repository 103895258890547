import * as React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { VideoPlayer, VideoPlayerOptions } from '../../services/VideoService/Video';
import VideoService from '../../services/VideoService/VideoService';
import SignalService from '../../services/SignalService/SignalService';
import RenderSettingsService from '../../services/RenderSettingsService/RenderSettingsService';
import { PlayerInterval } from '../../utils/PlayerInterval/PlayerInterval';

import './Video.scss';
import './VideoJs.scss';
import { GenerateSafeString } from '../../utils/StringUtils/StringUtils';
import { thumbnailUrl } from '../../utils/Thumbnailer/Thumbnailer';
import Icon from 'components/Icon/Icon';

interface VideoProps {
    signalService?: SignalService;
    renderSettingsService?: RenderSettingsService;
    playerInterval?: PlayerInterval;
    type: string;
    url: string;
    poster: string;
    width: number | string;
    height: number | string;
    play: boolean; // Lets caller start/stop video via prop
    autoplay?: boolean;
    loop?: boolean;
    hidePosterAfterPlay?: boolean;
    showControls?: boolean;
    hasOverlay?: boolean;
    imageBackgroundSize?: string;
    showSecondaryImageOverlay?: boolean;
}

const videoEvents = ['videoPause', 'videoPlay'];

class Video extends React.Component<VideoProps, any> {
    private static unique = 1;
    private target: any = null;
    private video: VideoPlayer = null;
    private id: string;
    private playing = false;
    private boundSaveReference = this.saveReference.bind(this);
    private boundVideoEvent = this.playerEvent.bind(this);

    constructor(props: VideoProps) {
        super(props);

        const type = this.getRealType(this.props.type, this.props.url);

        if (this.props.renderSettingsService.videoPlayerEnabled) {
            this.video = VideoService.get(type);
        }

        this.id = 'spr_video_' + Video.unique++;
    }

    UNSAFE_componentWillMount() {
        this.props.signalService.addAll(videoEvents, this.boundVideoEvent);
    }

    componentDidMount() {
        if (this.props.play) {
            this.videoStart();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: VideoProps) {
        if (!this.playing && nextProps.play) {
            this.videoStart();
        } else if (!nextProps.play && this.props.play != nextProps.play) {
            if (this.video) {
                this.video?.pause();
            }

            // Spread out destruction across 1/2 sec so that
            // teardowns don't happen at the same time and screw up animations
            setTimeout(() => {
                this.videoStop();
            }, 500 + Math.floor(Math.random() * 500));
        }
    }

    componentWillUnmount() {
        this.props.signalService.removeAll(videoEvents, this.boundVideoEvent);

        if (this.video) {
            this.videoStop();
            this.video = null;
        }
    }

    public render() {
        const hideMarker = false;

        const classExtra = '';
        const classOuter = classNames(
            `post_video_container fullbleed over-hidden video_${GenerateSafeString(
                this.props.imageBackgroundSize
            )}`,
            {
                [`meta_${this.props.type}`]: true,
                [`${classExtra}`]: true,
            }
        );

        const styleInner = {
            backgroundImage: `url('${thumbnailUrl(this.props.poster)}')`,
        };
        const useImageFallback = this.props.poster === 'image-fallback';

        const classMarkerExtra = '';
        const classInner = classNames({
            video_placeholder: true,
            // 'fullbleed': true,
            [`${classMarkerExtra}`]: true,
        });

        const secondaryOverlayClasses = classNames({
            fullbleed: true,
            post_image_secondary_overlay: this.props.showSecondaryImageOverlay,
        });

        return (
            <div
                id={this.id}
                className={classOuter}
                data-feedtype={this.props.type}
                ref={this.boundSaveReference}>
                {!useImageFallback && (
                    <div style={styleInner} className={classInner}>
                        {' '}
                    </div>
                )}

                {useImageFallback && (
                    <div className='image_fallback_container flex center middle h100 video_placeholder'>
                        <Icon value='image-fallback' classes='image_fallback' reset />
                    </div>
                )}

                {/* Overlay */
                this.props.hasOverlay && <div className='post_image_overlay'> </div>}
                {
                    /* Secondary Overlay */
                    <div className={secondaryOverlayClasses}> </div>
                }
            </div>
        );
    }

    private saveReference(element: any) {
        this.target = element;
    }

    private videoStart() {
        // note if you log this.video, Chrome devtools hold on to a ref and it's a mem leak.
        // console.log('video start', this.video);
        if (this.video) {
            this.playing = true;

            const {
                url,
                width,
                height,
                loop,
                hidePosterAfterPlay,
                showControls,
                autoplay,
                playerInterval,
            } = this.props;

            const options: VideoPlayerOptions = {
                url: url,
                width: width,
                height: height,
                loop: loop !== undefined ? loop : true,
                hideMarkerAfterPlay: hidePosterAfterPlay !== undefined ? hidePosterAfterPlay : true,
                controls: showControls !== undefined ? showControls : false,
                autoplay: autoplay !== undefined ? autoplay : true,
                preload: 'auto',
                loadingSpinner: false,
            };

            if (playerInterval && playerInterval.isWaitVideoEnd()) {
                playerInterval?.pause();
            }

            this.video.start(this.target, options, () => {
                if (!options.loop) {
                    //     videoService.broadcast(videoService.eventFinished, { id: $scope.videoId });
                }

                if (playerInterval && playerInterval.isWaitVideoEnd()) {
                    playerInterval.resumeNext();
                    return false;
                }

                return options.loop; // Return true to loop video
            });
        }
    }

    private videoStop() {
        if (this.video) {
            this.video.stop();
            this.playing = false;
        }
    }

    // Facebook can have other formats besides its own in there
    private getRealType(type: string, url: string): string {
        if (type === 'facebook' || type === 'linkedin' || type === 'rss') {
            if (
                /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.)?youtube\.com\/(?:watch(?:\.php)?\?.*v=|shorts\/))([a-zA-Z0-9\-_]+)/.test(
                    url
                )
            ) {
                type = 'youtube';
            } else if (
                /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/.test(
                    url
                )
            ) {
                type = 'vimeo';
            } else if (url.indexOf('.mp4') !== -1) {
                type = 'instagram';
            }
        }

        //If this is hosted via SAM, use the default player
        if (/(?:https?:\/\/)?(sprinklr.com)/.test(url)) {
            type = 'sprinklr'; //falls through to default videojs option
        }

        return type;
    }

    // Using start/stop instead of play/pause because snapshotService during publish causes
    // YouTube to start playing audio, even if video paused!!!
    protected playerEvent(type: string, ...args: Array<any>): void {
        switch (type) {
            case 'videoPause':
                this.videoStop();
                break;

            case 'videoPlay':
                this.videoStart();
                break;
        }
    }
}

export default inject('signalService', 'renderSettingsService')(observer(Video));
