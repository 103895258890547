export function getSocialPostType(
    url: string
): 'twitter' | 'instagram' | 'facebook' | 'linkedIn' | 'unknown' {
    if (url?.includes('twitter.com') && url?.includes('/status/')) {
        return 'twitter';
    } else if (url?.includes('instagr')) {
        return 'instagram';
    } else if (url?.includes('facebook.com')) {
        return 'facebook';
    } else if (url?.includes('linkedin.com')) {
        return 'linkedIn';
    } else {
        return 'unknown';
    }
}
