import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import './UserName.scss';

type UserNameProps = {
    type?: string;
    name: string;
    classes?: string;
    showAtSymbol?: boolean;
};

const UserName: React.FC<UserNameProps> = ({ name, classes, type, showAtSymbol = false }) => {
    const userNameClasses = classNames(`user_name ellipsis ${classes}`, {
        'user_full_name primary_font_family': type === 'fullName',
        'user_screen_name secondary_font_family': type !== 'fullName',
        show_at_symbol: showAtSymbol,
    });

    return <div className={userNameClasses}>{name}</div>;
};

UserName.defaultProps = {
    type: 'fullName',
    classes: '',
};
UserName.displayName = 'UserName';

export default observer(UserName);
