import classNames from 'classnames';
import * as React from 'react';
import { PostCompositionConfig } from '../Post';
import { PostCompositionTypeValues } from '@sprinklr/stories/widget/types';
import { PostComposition, Parts } from 'models/Widget/WidgetType';
import useDimensions from 'src/hooks/UseDimensions';

interface StoryCompProps {
    type: PostCompositionTypeValues;
    parts: Parts;
    config: PostCompositionConfig;
    unique: string;
    onPostClick: (event: React.MouseEvent | React.TouchEvent) => void;
}

const storyCardCompositions = ({
    compositionType: type,
    parts,
    config,
    unique,
    onPostClick,
}: PostComposition) => {
    return (
        <StoryComp
            unique={unique}
            type={type}
            parts={parts}
            config={config}
            onPostClick={onPostClick}
        />
    );
};

export default storyCardCompositions;

const StoryComp: React.FunctionComponent<StoryCompProps> = ({
    parts,
    config,
    type,
    unique,
    onPostClick,
}) => {
    const { postText, media, summaryComp, publicationEntitysComp, metricsComp, metaComp } = parts;
    const { isInterstitialMedia, postOuterClasses, noMedia, postInnerClasses } = config;

    const containerRef = React.useRef();
    const { height, width } = useDimensions({ ref: containerRef, liveMeasure: true });

    const isPortrait = React.useCallback(() => {
        return height > width;
    }, [height, width]);

    const portrait = (
        <>
            <div
                key='mediaContainer'
                className={classNames(`media_group flex rel h-40 w-100`, {})}
                style={{ height: height * 0.4 }}>
                {media}
                <div className={'fullbleed post_text_overlay_gradient'} />
                <div className='story_title_group fullbleed flex bottom'>
                    <div className='flex vertical'>{[postText, metaComp]}</div>
                </div>
            </div>
            <div
                key='textContainer'
                className={classNames(
                    `summary_group relative flex vertical between h-60 w-100`,
                    {}
                )}>
                {summaryComp}
                {publicationEntitysComp}
                {metricsComp}
            </div>
        </>
    );

    const landscape = (
        <>
            <div key='mediaContainer' className={classNames(`media_group flex rel w-50 h-100`, {})}>
                {media}
                <div className={'fullbleed post_text_overlay_gradient'} />
                <div className='story_title_group fullbleed flex bottom'>
                    <div className='flex vertical'>{[postText, metaComp]}</div>
                </div>
            </div>
            <div
                key='textContainer'
                className={classNames(
                    `summary_group relative flex vertical between h-100 w-50`,
                    {}
                )}>
                {summaryComp}
                {publicationEntitysComp}
                {metricsComp}
            </div>
        </>
    );

    return (
        <React.Fragment key={unique}>
            <div
                className={classNames(`${postOuterClasses}`, {})}
                ref={containerRef}
                onClick={onPostClick}
                onTouchEnd={onPostClick}>
                <div
                    className={classNames(
                        `post_inner flex noGrow rel w-100 h-100 ${postInnerClasses}`,
                        {
                            vertical:
                                (noMedia && !isInterstitialMedia) ||
                                (!noMedia && isPortrait() && !isInterstitialMedia),
                        }
                    )}>
                    {isPortrait() ? portrait : landscape}
                    {isInterstitialMedia && media}
                </div>
            </div>
        </React.Fragment>
    );
};
