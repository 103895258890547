import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import NativeFlex from '../Bits/NativeFlex';
import { NativeColorMode } from '../../../widgets/PostsWidget/types';
import { getNativeColor } from '../Helpers';
import { Emoji } from '@sprinklr/stories/widget/types';
import { getLineClamp, LineClamp } from './NativeText';

// NativeTitle
type FontSize = 's' | 'm' | 'l';

export type NativeTitleProps = {
    title: string;
    colorMode?: NativeColorMode;
    maxCharacters?: number;
    emoji?: Emoji;
    lineClamp?: LineClamp;
};

const colors = {
    darkGrey: '#333',
    lightGrey: '#bababd',
};

const theme = {
    light: {
        text: colors.darkGrey,
    },
    dark: {
        text: colors.lightGrey,
    },
};
type StyledProps = {
    colorMode?: NativeColorMode;
    size?: FontSize;
    emoji?: Emoji;
    maxCharacters?: number;
    lineClamp?: LineClamp;
};
export const StyledEmoji = styled.img`
    vertical-align: middle;
`;
const StyledNativeTitle = styled(NativeFlex)<StyledProps>`
    ${({ colorMode }) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'text')};
        `}
    ${StyledEmoji} {
        height: 1em;
        width: 1em;
    }

    font-size: 1.3em;
    font-weight: 650;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    ${props => getLineClamp((props as StyledProps).lineClamp)}
`;
StyledNativeTitle.displayName = 'StyledNativeTitle';

const NativeTitle: React.FC<NativeTitleProps> = ({
    title,
    lineClamp,
    maxCharacters = 200,
    emoji = {
        platform: 'default',
    },
    colorMode = 'light' as NativeColorMode,
}) => {
    // console.log('lineClamp', lineClamp);
    return (
        <StyledNativeTitle
            emoji={emoji}
            lineClamp={lineClamp}
            colorMode={colorMode}
            data-id='native-title'
            noGrow
            maxCharacters={maxCharacters}
            size={'l'}>
            {title}
        </StyledNativeTitle>
    );
};

NativeTitle.displayName = 'NativeTitle';
export default observer(NativeTitle);
export { StyledNativeTitle };
