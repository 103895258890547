import * as React from 'react';
import classNames from 'classnames';

import './Avatar.scss';

interface AvatarPropsInterface {
    url?: string | null;
    shape?: 'round' | 'rounded';
    classes?: string;
    size?: number; // in ems
    userInitial?: string;
    onClick?: () => void;
}

export default class Avatar extends React.Component<AvatarPropsInterface, any> {
    public static defaultProps: AvatarPropsInterface = {
        url: null,
        shape: 'round',
        classes: '',
        size: null,
    };

    render() {
        const { classes, size, url, shape, onClick, userInitial } = this.props;

        const styles: any = {};
        if (url) {
            styles.backgroundImage = `url(${url.replace('_normal', '')})`;
        }

        // add inline size if its passed as a prop
        if (size !== null && !isNaN(size)) {
            styles.height = `${size}em`;
            styles.width = `${size}em`;
        }

        const avatarClasses = classNames(classes, {
            avatar: true,
            'avatar--round': shape === 'round',
            'avatar--rounded': shape === 'rounded',
        });

        return (
            <div style={styles} className={avatarClasses} onClick={onClick}>
                {!url && (
                    <span className='avatar_initial flex center middle h100'>{userInitial}</span>
                )}
            </div>
        );
    }
}
