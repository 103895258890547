export const waterfallConfig: any = {
    a: {
        classOverrides: {
            postOuter: {
                default: 'm-1',
            },
            metaGroup: {
                default: 'p-reset p-1 pt-0',
            },
            userGroup: {
                default: 'p-0',
            },
            postText: {
                default: 'ta-l p-reset p-05 f-order-i',
            },
        },
    },
};
