import {
    FONT_FAMILIES_TO_SPLIT,
    FONT_FAMILIES_VS_GOOGLE_FONT,
    FONT_WEIGHT_MAP,
    NON_GOOGLE_FONTS,
} from 'components/_UI/FontLoader/constants';
import { isGoogleFont } from 'utils/Fonts/googleFonts';

export function getResolvedWeight(weight: string | number | number[]): number | number[] {
    return typeof weight === 'string' ? FONT_WEIGHT_MAP[weight.toLowerCase()] || 400 : weight;
}

type FontPartial = { family: string; weight: string | number | number[] };

export function shouldDeriveWeight(family: string) {
    Object.keys(FONT_FAMILIES_VS_GOOGLE_FONT)
        // remove exclusion list items
        .filter(family => !NON_GOOGLE_FONTS.includes(family))
        // check for font family
        .includes(family);
}

export function getResolvedFamilyWeight(font: FontPartial) {
    if (FONT_FAMILIES_TO_SPLIT.includes(font.family)) {
        const split = font.family
            // splits family name from modifier
            .split(/(Regular|Bold|Light)/gi);
        let family = split[0]
            // adds a space before each capital
            .replace(/([A-Z1-9])/g, ' $1')
            // removes consecutive white spaces
            .replace(/\s\s+/g, ' ')
            // removes white space from ends
            .trim();

        // special cases
        if (family.includes('Mplus')) {
            family = family.replace('Mplus', 'M PLUS');
            if (family === 'Rounded M PLUS 1c') {
                family = 'M PLUS Rounded 1c';
            }
        }
        if (family === 'Noto Sans J P') {
            family = 'Noto Sans JP';
        }

        // if a font already has weight use that, might be from rich text
        const weight = font.weight
            ? getResolvedWeight(font.weight)
            : FONT_WEIGHT_MAP[split[1]?.toLowerCase()];
        return { family, weight };
    }
    // some fonts like Secular One don't have a weight, so we use the default of 400
    if (!font.weight || font.weight === '' || font.weight === 'normal') {
        return { family: font.family, weight: 400 };
    }
    return font;
}

export function getFontWeightPairs(fonts: FontPartial[]) {
    const resolvedFonts = [];
    fonts.forEach(font => {
        const resolvedFont = getResolvedFamilyWeight(font);
        if (!!resolvedFont.weight) {
            const found = resolvedFonts.find(font => font.family === resolvedFont.family);
            if (found) {
                // remove duplicates
                const weightSet = new Set(
                    [found.weight, resolvedFont.weight].flat().sort((a, b) => a - b)
                );
                found.weight = Array.from(weightSet);
            } else {
                resolvedFonts.push(resolvedFont);
            }
        }
    });
    return resolvedFonts;
}

function replaceSpacesWithSymbol(str: string): string {
    return str.replace(/ /g, '+');
}

function shouldRenderWeight(weight: FontPartial['weight']): boolean {
    if (Array.isArray(weight)) {
        return weight.flat().every(weightItem => !!weightItem && !Number.isNaN(weightItem));
    }

    return !Number.isNaN(weight);
}

export function generateFontQueryString(fonts: FontPartial[]) {
    return fonts
        .filter(font => isGoogleFont({ family: font.family }))
        .map(font => {
            const resolvedWeight = Array.isArray(font.weight)
                ? font.weight
                      .flat()
                      .sort()
                      .join(';')
                : font.weight;
            const weightString = shouldRenderWeight(font.weight) ? `:wght@${resolvedWeight}` : '';
            return `&family=${replaceSpacesWithSymbol(font.family)}${weightString}`;
        })
        .join('');
}
