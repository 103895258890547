import * as React from 'react';
import Color from 'color';
import { observer } from 'mobx-react';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { getPrettyNumber, getPrettyTimeInterval } from 'utils/Number/NumberUtils';
import { Orientation } from '@sprinklr/stories/widget/types';

import './StackedBar.scss';

export interface StackedBarValue {
    name: string;
    label?: string;
    value: number;
    type?: FieldType;
    threshold: number;
    color?: string;
}

interface StackedBarProps {
    metricGoal: StackedBarValue;
    barWidth?: number;
    barLength?: number;
    orientation?: Orientation;
    showPercent?: boolean;
}

/**
 * StackedBar -- React component which displays an 'attained' value as it relates to another 'goal' value.
 * Can be used as a 'thermometer' or progress bar.
 * @param {StackedBarValue} metricGoal - The metric of attained value vs. goal/max.
 * @param {string} metricGoal.name - The label for the metric being measured.
 * @param {string=} metricGoal.label - The label for the threshold, as assigned by the user.
 * @param {number} metricGoal.value - The value that has been attained.
 * @param {type=} metricGoal.type - The field type of the value (i.e. a number, time interval, etc.).
 * @param {number} metricGoal.threshold - The value of the 'goal' or 'max'.
 * @param {string=} metricGoal.color - The color of the StackedBar (enables propogation of theme from above); optional.
 * @param {number=} barWidth - The width of the bar.
 * @param {number=} barLength - The length of the bar.
 * @param {number=} orientation - The orientation of the bar (either 'horizontal' or 'vertical').
 * @param {boolean=} showPercent - Whether to show the ratio as a percentage or a set of values.
 */

class StackedBar extends React.Component<StackedBarProps, any> {
    public static defaultProps: StackedBarProps = {
        metricGoal: {
            name: 'mentions',
            value: 5000,
            type: 'NUMBER',
            color: '#AB5678',
            threshold: 8000,
        },
        barWidth: 2,
        barLength: 10,
        orientation: 'horizontal',
        showPercent: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { barWidth, barLength, metricGoal } = this.props;
        const { color, type } = metricGoal;
        let metricValue;
        let thresholdValue;

        switch (type) {
            case 'NUMBER':
            default:
                metricValue = getPrettyNumber(this.props.metricGoal.value, 1, false);
                thresholdValue = getPrettyNumber(this.props.metricGoal.threshold, 1, false);
                break;
            case 'TIME_INTERVAL':
                metricValue = getPrettyTimeInterval(this.props.metricGoal.value, false);
                thresholdValue = getPrettyTimeInterval(this.props.metricGoal.threshold, false);
                break;
        }

        const percentage =
            metricGoal.threshold > 0 ? (metricGoal.value / metricGoal.threshold) * 100 : 0;
        const attainedValue = metricValue.value
            ? metricValue.value.toString() + metricValue.suffix
            : 0;
        const goalValue =
            metricGoal.threshold > 0
                ? thresholdValue.value.toString() + thresholdValue.suffix
                : '0' + thresholdValue.suffix;

        const color05: string = Color(color).fade(0.5) as any;

        return (
            <div className='widget_stacked_bar' style={{ width: barLength + 'rem' }}>
                {/* The div containing the threshold line and percentage complete */}
                <div className='stacked_threshold'>
                    <span
                        className='stacked_threshold_inner secondary_font_family absolute'
                        style={{
                            right:
                                percentage > 100
                                    ? '0%'
                                    : (percentage / 100) * barLength > 0.25
                                    ? (100 - percentage).toString() + '%'
                                    : (((barLength - 0.25) / barLength) * 100).toString() + '%',
                            borderRight: '0.25rem solid' + color,
                        }}>
                        {metricGoal.label}
                    </span>
                    <span
                        className='value_threshold primary_font_family absolute'
                        style={{ left: percentage <= 100 ? percentage.toString() + '%' : '100%' }}>
                        {percentage.toFixed(0).toString() + '%'}
                    </span>
                </div>
                {/* The 'bar' showing the progress toward, or over, a goal/max. */}
                <div
                    className='stacked_bar'
                    style={{ height: barWidth.toString() + 'rem', backgroundColor: color05 }}>
                    <span
                        className='stacked_bar_inner'
                        style={{
                            width: percentage.toString() + '%',
                            backgroundColor: color,
                            transition: 'width 0.5sec ease-in-out',
                        }}></span>
                </div>

                {/* The metrics showing the attained value and goal value, or percentage attained. */}
                <div className='stacked_info'>
                    <div className='info info_wrap info_attained' style={{ color }}>
                        <div className='value value_attained primary_font_family'>
                            {attainedValue}
                        </div>
                        <p className='label label_attained secondary_font_family'>
                            {metricGoal.name}
                        </p>
                    </div>
                    <div
                        className='info info_wrap info_goal'
                        style={{ opacity: percentage >= 100 ? 1 : 0.5, color }}>
                        <div className='value value_goal primary_font_family'>{goalValue}</div>
                        <p className='label label_goal secondary_font_family'>{metricGoal.name}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(StackedBar);
