import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { zeroIfNegative } from 'utils/Number/NumberUtils';

interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
}
type UseDimensionsHook = DimensionObject;
interface UseDimensionsArgs {
    ref: any;
    liveMeasure?: boolean;
    scaleSafe?: boolean;
    watchObject?: any;
}
function getDimensionObject(
    node: HTMLElement | SVGGraphicsElement,
    scaleSafe: boolean
): DimensionObject {
    if (!node) {
        return {
            width: 0,
            height: 0,
            top: 0,
            left: 0,
        };
    }

    if (node instanceof SVGGraphicsElement) {
        const elDims = (node as SVGGraphicsElement).getBBox();
        return {
            height: zeroIfNegative(elDims.height),
            width: zeroIfNegative(elDims.width),
            top: elDims.y,
            left: elDims.x,
        };
    } else {
        if (scaleSafe) {
            return {
                width: zeroIfNegative((node as HTMLElement).offsetWidth),
                height: zeroIfNegative((node as HTMLElement).offsetHeight),
                top: (node as HTMLElement).offsetTop,
                left: (node as HTMLElement).offsetLeft,
            };
        } else {
            const rect = (node as HTMLElement).getBoundingClientRect() as DOMRect;
            return {
                width: zeroIfNegative(rect.width),
                height: zeroIfNegative(rect.height),
                top: rect.x ? rect.x : rect.top,
                left: rect.y ? rect.y : rect.left,
            };
        }
    }
}

const defaultPosition: DimensionObject = { width: 0, height: 0, top: 0, left: 0 };
function useDimensions(
    { ref, liveMeasure = true, scaleSafe = true, watchObject }: UseDimensionsArgs = { ref: null }
): UseDimensionsHook {
    const disposeFrame = useRef(null);
    const [dimensions, setDimensions] = useState<DimensionObject>(defaultPosition);

    useEffect(() => {
        if (!!ref?.current) {
            const measure = () => {
                disposeFrame.current = window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(ref.current, scaleSafe))
                );
            };
            measure();
            if (liveMeasure) {
                if (dimensions.width && dimensions.height) {
                    return;
                }
                const element = ref.current;
                const resizeObserver = new ResizeObserver(entries => {
                    if (!Array.isArray(entries)) {
                        return;
                    }
                    if (!entries?.length) {
                        return;
                    }
                    const entry = entries[0];

                    setDimensions({
                        width: zeroIfNegative(entry.contentRect.width),
                        height: zeroIfNegative(entry.contentRect.height),
                        top: entry.contentRect.top,
                        left: entry.contentRect.left,
                    });
                });
                resizeObserver.observe(element);

                return () => resizeObserver.unobserve(element);
            }
        } else {
            disposeFrame.current = window.requestAnimationFrame(() =>
                setDimensions(defaultPosition)
            );
        }

        // cancel the animation frame when the component is unmounted
        return () => {
            window.cancelAnimationFrame(disposeFrame.current);
        };
    }, [ref, watchObject]);

    return dimensions;
}
export default useDimensions;
