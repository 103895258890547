import * as React from 'react';
import { observer } from 'mobx-react';
import getPrettyNumber, {
    DecimalFormat,
    getDecimalsByFormat,
} from '../../../utils/Number/NumberUtils';
import './SizeLegend.scss';

type ISizeLegendProps = {
    items: Array<{ value: number; radius: number }>;
    label: string;
    decimalFormat: DecimalFormat;
    isPercentage: boolean;
};

export interface ZAxis {
    enabled: boolean;
    numberFormat: DecimalFormat;
    radius: {
        min?: number;
        max?: number;
        size?: number;
    };
}

const SizeLegend: React.FC<ISizeLegendProps> = ({ items, label, decimalFormat, isPercentage }) => {
    if (!items) {
        return;
    }

    //removing overlapping circles with same radius AMR
    const circles = items
        .slice()
        .sort((a, b) => b.radius - a.radius)
        .reduce((acc, current) => {
            const x = acc.find(item => item.radius === current.radius);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

    const maxDiameter = circles[0].radius * 2;
    const marginRight = circles[0].radius + (maxDiameter - circles[0].radius) - maxDiameter * 0.3;

    return (
        <div className='size_legend flex vertical center no-grow' style={{ marginRight }}>
            <div
                className='size_legend_circles flex rel center bottom w-100 no-grow'
                style={{ minWidth: maxDiameter, minHeight: maxDiameter }}>
                {circles &&
                    circles.map((circle, index) => {
                        const diameter = circle.radius * 2;
                        const value = getPrettyNumber(
                            circle.value,
                            getDecimalsByFormat(decimalFormat),
                            isPercentage
                        );
                        return (
                            <div
                                className={`size_legend_circle abs circle flex bottom no-grow`}
                                style={{ width: diameter, height: diameter }}
                                key={circle.value + circle.radius}>
                                <div
                                    className={`size_legend_metric ${
                                        items.length > 1 ? '' : 'flex center middle h-100'
                                    }`}
                                    style={
                                        items.length > 1
                                            ? {
                                                  marginLeft:
                                                      diameter +
                                                      (maxDiameter - circle.radius) -
                                                      maxDiameter * 0.3,
                                                  marginBottom:
                                                      index === circles.length - 1
                                                          ? 0
                                                          : index === 0
                                                          ? maxDiameter * 0.75
                                                          : circle.radius,
                                              }
                                            : undefined
                                    }>
                                    {value.value + value.suffix}
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className='size_legend_label primary_font_family no-grow mt-05 top'>{label}</div>
        </div>
    );
};

export default observer(SizeLegend);
