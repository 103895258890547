import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { GlyphDot } from '@vx/glyph';
import { GenerateLabelClasses } from 'utils/StringUtils/StringUtils';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import Icon from 'components/Icon/Icon';
import classNames from 'classnames';

// Legend

export type LegendAlignment = 'left' | 'center' | 'middle';

export interface LegendOptions {
    enabled: boolean;
    size: number;
    alignment: LegendAlignment;
    points: {
        enabled: boolean;
        size: number;
        stroke: {
            enabled: boolean;
            size: number;
        };
    };
    maxItems?: {
        enabled: boolean;
        size: number;
    };
    maxItemWidth?: {
        enabled: boolean;
        size: number;
    };
}

type Item = {
    name: string | BulkItem;
    color?: string;
    snType?: string;
};

type LegendProps = {
    items: Item[];
    options: LegendOptions;
    innerRef?: any;
    onLabelClick?: (
        event: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent,
        index: number
    ) => void;
    showAccountIcon?: boolean;
};

function Legend({ items = [], options, innerRef, onLabelClick, showAccountIcon }: LegendProps) {
    const { alignment = 'left', points, enabled, maxItemWidth, maxItems } = options;

    const ellipsifyLabels = maxItemWidth?.enabled;

    const resolvedItems = useMemo(() => {
        return maxItems?.enabled && maxItems?.size ? items.slice(0, maxItems?.size) : items;
    }, [items, maxItems?.enabled, maxItems?.size]);

    const moreCount = items.length - resolvedItems.length;

    const labelStyles = useMemo(() => {
        if (ellipsifyLabels && maxItemWidth?.size) {
            return {
                width: `${maxItemWidth?.size}px`,
            };
        }

        return {};
    }, [ellipsifyLabels, maxItemWidth?.size]);

    if (!enabled) {
        return;
    }

    return (
        <div className={`legend flex ${alignment} wrap mb-1 mr-1`} ref={innerRef}>
            {resolvedItems?.map((legendItem, index) => {
                const handleClick = event => {
                    if (onLabelClick) {
                        onLabelClick(event, index);
                    }
                };
                const legendClassname = GenerateLabelClasses(legendItem.name + '', 'legend_item');
                return (
                    <div
                        key={legendClassname + legendItem.color + index}
                        className={`legend_item legend_item_index_${index +
                            1} middle ${legendClassname} flex no-grow mr-1`}
                        onClick={handleClick}>
                        {points?.enabled && (
                            <svg width={points.size} height={points.size}>
                                <GlyphDot
                                    cx={points.size * 0.5}
                                    cy={points.size * 0.5}
                                    r={points.size * 0.3}
                                    fill={legendItem.color}
                                    stroke={legendItem.color}
                                    strokeWidth={points.stroke.enabled ? points.stroke.size : 0}
                                />
                            </svg>
                        )}
                        <div
                            className={classNames('legend_item_label primary_font_family', {
                                ellipsis: ellipsifyLabels,
                            })}
                            style={labelStyles}>
                            {legendItem.name + ''}
                            {legendItem.snType && showAccountIcon && (
                                <Icon
                                    padding={
                                        options.size > 24
                                            ? options.size * 0.005
                                            : options.size * 0.0075
                                    }
                                    classes={classNames('ml-025')}
                                    reset
                                    value={legendItem.snType.toLowerCase()}
                                    wrap
                                />
                            )}
                        </div>
                    </div>
                );
            }) ?? null}
            {moreCount > 0 ? (
                <div className='legend_item legend_item_index_more middle w-100 ta-r'>{`+${moreCount} more`}</div>
            ) : null}
        </div>
    );
}

Legend.displayName = 'Legend';
export default observer(Legend);
