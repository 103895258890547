import { useState, useLayoutEffect } from 'react';

type Position = {
    height: number;
    width: number;
    top: number;
    left: number;
};

const defaultPosition = { height: 0, width: 0, top: 0, left: 0 };

const usePosition = (ref: any, loaded?: boolean, isSvg = false): Position => {
    const resolvedLoaded = loaded !== undefined ? loaded : true;
    const [position, setPosition] = useState(defaultPosition);
    useLayoutEffect(() => {
        if (ref && ref.current && resolvedLoaded) {
            if (isSvg) {
                const elDims = ref.current.getBBox();
                setPosition({
                    height: elDims.height,
                    width: elDims.width,
                    top: elDims.y,
                    left: elDims.x,
                });
            } else {
                setPosition({
                    height: ref.current.offsetHeight,
                    width: ref.current.offsetWidth,
                    top: ref.current.offsetTop,
                    left: ref.current.offsetLeft,
                });
            }
        } else {
            setPosition(defaultPosition);
        }
    }, [ref, resolvedLoaded]);
    return position;
};

export default usePosition;
