const defaultClassOverrides = {
    userGroup: {
        default: 'p-reset p-0 pb-1',
    },
    postText: {
        default: 'p-reset p-0 pt-1',
    },
    metaGroup: {
        default: 'p-reset p-0 pt-1',
    },
    lineClampVerticalAlign: {
        default: 'top',
    },
    extraPostData: {
        default: 'p-reset p-0 pt-1',
    },
    postInner: {
        default: 'p-reset p-2',
    },
};

const smallSizes = {
    ...defaultClassOverrides,
    userGroup: {
        default: 'p-reset p-0 pb-05',
    },
    postText: {
        default: 'p-reset p-0 pb-05',
    },
    metaGroup: {
        default: 'p-reset p-0 pb-05',
    },
    extraPostData: {
        default: 'p-reset p-0 pb-05',
    },
    postInner: {
        default: 'p-reset p-05',
    },
};
export const runOfShowConfig: any = {
    a: {
        smartSizeCompositions: {
            baseline: {
                postTextOnlySize: 18,
                postImageTextSize: 13,
                engagementMetricsSize: 15,
                userGroupIconSize: 10,
                userGroupAvatarSize: 18,
                userGroupNameSize: 13,
                userGroupScreenNameSize: 10,
                extraPostDataOptions: {
                    size: 35,
                    header: {
                        size: 50,
                    },
                },
                nativeStyling: {
                    fontScale: 35,
                },
                gridLayout: {
                    gap: 15,
                },
            },
            optionDefaults: {
                postLineClampValue: 7,
                imagePostLineClampValue: 3,
                extraPostDataOptions: {
                    header: {
                        enabled: true,
                    },
                },
                classOverrides: defaultClassOverrides,
            },
            breakpoints: {
                xs: {
                    options: {
                        postLineClampValue: 3,
                        imagePostLineClampValue: 2,
                        extraPostDataOptions: {
                            header: {
                                enabled: false,
                            },
                        },
                        classOverrides: smallSizes,
                    },
                },
                s: {
                    options: {
                        postLineClampValue: 4,
                        imagePostLineClampValue: 2,
                        extraPostDataOptions: {
                            header: {
                                enabled: false,
                            },
                        },
                        classOverrides: smallSizes,
                    },
                },
                m: {
                    options: {
                        postLineClampValue: 4,
                        imagePostLineClampValue: 3,
                        classOverrides: smallSizes,
                    },
                },
                ml: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postInner: {
                                default: 'p-reset p-1',
                            },
                        },
                    },
                },
                l: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postInner: {
                                default: 'p-reset p-1',
                            },
                        },
                    },
                },
            },
        },
        classOverrides: defaultClassOverrides,
    },
};
