import React from 'react';
import { IGlyph } from '@sprinklr/space-kit/lib/designSystem/glyphs';
import SVG from 'react-inlinesvg';

import accountgroup from 'src/svgs/space/accountgroup.svg';
import add from 'src/svgs/add.svg';
import add_widget from 'src/svgs/add-widget.svg';
import addtoprofilelist from 'src/svgs/space/addtoprofilelist.svg';
import advocacy from 'src/svgs/social/advocacy.svg';
import affiliate from 'src/svgs/social/affiliate.svg';
import aggregation from 'src/svgs/space/aggregation.svg';
import align_centre from 'src/svgs/space/align_centre.svg';
import align_justify from 'src/svgs/space/align_justify.svg';
import alignleft from 'src/svgs/space/alignleft.svg';
import alignright from 'src/svgs/space/alignright.svg';
import analytics from 'src/svgs/space/analytics.svg';
import animation from 'src/svgs/animation-icon.svg';
import annotation from 'src/svgs/space/annotation.svg';
import applyfunction from 'src/svgs/space/applyfunction.svg';
import archive from 'src/svgs/space/archive.svg';
import area from 'src/svgs/space/area.svg';
import areaspline from 'src/svgs/space/areaspline.svg';
import arrange from 'src/svgs/arrange.svg';
import arrange_back from 'src/svgs/arrange-back.svg';
import arrange_backward from 'src/svgs/arrange-backward.svg';
import arrange_forward from 'src/svgs/arrange-forward.svg';
import arrange_front from 'src/svgs/arrange-front.svg';
import arrow_down from 'src/svgs/arrow-down.svg';
import arrow_left from 'src/svgs/arrow-left.svg';
import arrow_right from 'src/svgs/arrow-right.svg';
import arrow_up from 'src/svgs/arrow-up.svg';
import article from 'src/svgs/space/article.svg';
import articles from 'src/svgs/social/articles.svg';
import assets_notification from 'src/svgs/social/assets_notification.svg';
import assign from 'src/svgs/space/assign.svg';
import assignprofile from 'src/svgs/space/assignprofile.svg';
import attachment from 'src/svgs/space/attachment.svg';
import attachmentasset from 'src/svgs/space/attachmentasset.svg';
import au from 'src/svgs/au.svg';
import auctions from 'src/svgs/social/auctions.svg';
import audio from 'src/svgs/space/audio.svg';
import avatar from 'src/svgs/avatar-icon.svg';
import awards from 'src/svgs/space/awards.svg';
import background_image from 'src/svgs/background-image.svg';
import bargraph from 'src/svgs/space/bargraph.svg';
import bars from 'src/svgs/bars.svg';
import bars_alt from 'src/svgs/bars-alt.svg';
import bazaarvoice from 'src/svgs/social/bazaarvoice.svg';
import bell from 'src/svgs/bell.svg';
import benchmarking from 'src/svgs/social/benchmarking.svg';
import bing from 'src/svgs/social/bing.svg';
import blog from 'src/svgs/social/blog.svg';
import blog_comments from 'src/svgs/social/blog_comments.svg';
import blogs from 'src/svgs/social/blogs.svg';
import blogswebsites from 'src/svgs/social/blogs.svg';
import brandgroups from 'src/svgs/space/brandgroups.svg';
import brands from 'src/svgs/space/brands.svg';
import budget from 'src/svgs/space/budget.svg';
import bulletedlist from 'src/svgs/space/bulletedlist.svg';
import burger_check from 'src/svgs/burger-check.svg';
import businesshour from 'src/svgs/space/businesshour.svg';
import businesslocation from 'src/svgs/space/businesslocation.svg';
import button from 'src/svgs/space/button.svg';
import ca from 'src/svgs/ca.svg';
import calendar from 'src/svgs/calendar.svg';
import calendar_schedule from 'src/svgs/space/calendar_schedule.svg';
import camera from 'src/svgs/camera.svg';
import card_basic from 'src/svgs/embed/card-basic.svg';
import card_stylish from 'src/svgs/embed/card-stylish.svg';
import card_vertical from 'src/svgs/embed/card-vertical.svg';
import carousel_horizontal from 'src/svgs/embed/carousel-horizontal.svg';
import carousel_vertical from 'src/svgs/embed/carousel-vertical.svg';
import cascade from 'src/svgs/embed/cascade.svg';
import case_management from 'src/svgs/social/case_management.svg';
import casetags_stroke from 'src/svgs/space/casetags_stroke.svg';
import ch from 'src/svgs/ch.svg';
import chart from 'src/svgs/data.svg';
import charts from 'src/svgs/space/charts.svg';
import check from 'src/svgs/check.svg';
import check_alt from 'src/svgs/check-alt.svg';
import chevron_double_down from 'src/svgs/chevron-double-down.svg';
import chevron_double_left from 'src/svgs/chevron-double-left.svg';
import chevron_double_left_large from 'src/svgs/chevron-double-left-large.svg';
import chevron_double_right from 'src/svgs/chevron-double-right.svg';
import chevron_double_right_large from 'src/svgs/chevron-double-right-large.svg';
import chevron_double_up from 'src/svgs/chevron-double-up.svg';
import chevron_down from 'src/svgs/chevron-down.svg';
import chevron_left from 'src/svgs/chevron-left.svg';
import chevron_right from 'src/svgs/chevron-right.svg';
import chevron_up from 'src/svgs/chevron-up.svg';
import chordchart from 'src/svgs/space/chordchart.svg';
import cinematic_a from 'src/svgs/cinematic_a.svg';
import cinematic_b from 'src/svgs/cinematic_b.svg';
import cinematic_c from 'src/svgs/cinematic_c.svg';
import clarabridge from 'src/svgs/social/clarabridge.svg';
import clavis from 'src/svgs/social/clavis.svg';
import clear_theme_value from 'src/svgs/clear-theme-value.svg';
import click from 'src/svgs/space/click.svg';
import client from 'src/svgs/space/client.svg';
import clientgroup from 'src/svgs/space/clientgroup.svg';
import clock from 'src/svgs/clock.svg';
import clone from 'src/svgs/clone.svg';
import x from 'src/svgs/space/close.svg';
import cn from 'src/svgs/cn.svg';
import code from 'src/svgs/code-icon.svg';
import codesnippet from 'src/svgs/space/codesnippet.svg';
import cog from 'src/svgs/cog.svg';
import collage_a from 'src/svgs/singlepost_b.svg';
import collage_b from 'src/svgs/singlepost_c.svg';
import color from 'src/svgs/space/color.svg';
import color_blend from 'src/svgs/color-blend.svg';
import color_group_preview from 'src/svgs/color-group-preview.svg';
import coloumnview from 'src/svgs/space/coloumnview.svg';
import column4 from 'src/svgs/space/column4.svg';
import columngraph from 'src/svgs/space/columngraph.svg';
import combination from 'src/svgs/space/combination.svg';
import comment from 'src/svgs/space/comment.svg';
import community from 'src/svgs/social/community.svg';
import compostion from 'src/svgs/compostion-icon.svg';
import conditionalformatting from 'src/svgs/space/conditionalformatting.svg';
import contain from 'src/svgs/contain.svg';
import content_fit_text from 'src/svgs/content/content-fit-text.svg';
import content_iframe from 'src/svgs/content/content-iframe.svg';
import content_image from 'src/svgs/content/content-image.svg';
import content_rich_text from 'src/svgs/content/content-rich-text.svg';
import content_social_post from 'src/svgs/content/content-social-post.svg';
import content_video from 'src/svgs/content/content-video.svg';
import conversation from 'src/svgs/space/conversation.svg';
import copy from 'src/svgs/space/copy.svg';
import counter from 'src/svgs/space/counter.svg';
import cover from 'src/svgs/cover.svg';
import cpgram from 'src/svgs/social/cpgram.svg';
import createvalue from 'src/svgs/space/createvalue.svg';
import crimson_hexagon from 'src/svgs/social/crimson_hexagon.svg';
import custom from 'src/svgs/display-custom.svg';
import customproperties from 'src/svgs/space/customproperties.svg';
import datasourceadd from 'src/svgs/space/datasourceadd.svg';
import datasourcesync from 'src/svgs/space/datasourcesync.svg';
import datasrc_content_marketing from 'src/svgs/datasrc/datasrc-content-marketing.svg';
import datasrc_paid from 'src/svgs/datasrc/datasrc-paid.svg';
import datasrc_reporting from 'src/svgs/datasrc/datasrc-reporting.svg';
import datasrc_sprinksights from 'src/svgs/datasrc/datasrc-sprinksights.svg';
import de from 'src/svgs/de.svg';
import decisionbox from 'src/svgs/space/decisionbox.svg';
import design from 'src/svgs/design-icon.svg';
import desktop from 'src/svgs/embed/desktop.svg';
import disable from 'src/svgs/space/disable.svg';
import dislike from 'src/svgs/space/dislike.svg';
import display from 'src/svgs/social/display.svg';
import display_chart from 'src/svgs/display-chart.svg';
import display_wall from 'src/svgs/display-wall.svg';
import displayallrows from 'src/svgs/space/displayallrows.svg';
import disqus from 'src/svgs/social/disqus.svg';
import distributed_display from 'src/svgs/display-single-distributed.svg';
import distributed_display_wall from 'src/svgs/display-wall-distributed.svg';
import distributedcontrolpanel from 'src/svgs/space/distributedcontrolpanel.svg';
import doubleaxis from 'src/svgs/space/doubleaxis.svg';
import doublechevron from 'src/svgs/space/doublechevron.svg';
import drilldowndata from 'src/svgs/space/drilldowndata.svg';
import dropdown from 'src/svgs/space/dropdown.svg';
import dropdownarrow from 'src/svgs/space/dropdownarrow.svg';
import duration from 'src/svgs/duration.svg';
import edit from 'src/svgs/edit.svg';
import editcoloumn from 'src/svgs/space/editcoloumn.svg';
import editorial from 'src/svgs/embed/editorial.svg';
import email from 'src/svgs/social/email.svg';
import email_draft from 'src/svgs/social/email_draft.svg';
import email_template_builder from 'src/svgs/space/email_template_builder.svg';
import embedPresentations from 'src/svgs/embed-presentations.svg';
import enable from 'src/svgs/space/enable.svg';
import engagement from 'src/svgs/engagement.svg';
import equalizer from 'src/svgs/equalizer.svg';
import es from 'src/svgs/es.svg';
import exacttarget from 'src/svgs/social/exacttarget.svg';
import eyedropper from 'src/svgs/eyedropper.svg';
import eyedropper0 from 'src/svgs/design.svg';
import facebook from 'src/svgs/social/facebook.svg';
import facebook_ads from 'src/svgs/social/facebook_ads.svg';
import facebook_business_manager from 'src/svgs/social/facebook_business_manager.svg';
import facebook_comments from 'src/svgs/social/facebook_comments.svg';
import facebook_domain from 'src/svgs/social/facebook_domain.svg';
import facebook_filled from 'src/svgs/social/facebook_filled.svg';
import facebook_group from 'src/svgs/social/facebook_group.svg';
import facebook_likes from 'src/svgs/social/facebook_likes.svg';
import facebook_page from 'src/svgs/social/facebook_page.svg';
import facebook_share from 'src/svgs/social/facebook_share.svg';
import facebook_unfilled from 'src/svgs/social/facebook_unfilled.svg';
import favorite from 'src/svgs/space/favorite.svg';
import feed from 'src/svgs/social/feed.svg';
import feeds from 'src/svgs/space/feeds.svg';
import file from 'src/svgs/space/file.svg';
import filmstrip_a from 'src/svgs/filmstrip_a.svg';
import filmstrip_b from 'src/svgs/filmstrip_b.svg';
import filmstrip_c from 'src/svgs/filmstrip_c.svg';
import filter from 'src/svgs/filter.svg';
import flag from 'src/svgs/space/flag.svg';
import flickr from 'src/svgs/social/flickr.svg';
import followunfollow from 'src/svgs/space/followunfollow.svg';
import font from 'src/svgs/space/font.svg';
import format_ordered_list from 'src/svgs/content/text-format-ordered-list.svg';
import format_unordered_list from 'src/svgs/content/text-format-unordered-list.svg';
import forums from 'src/svgs/social/forums.svg';
import fourk from 'src/svgs/4k.svg';
import foursquare from 'src/svgs/social/foursquare.svg';
import foursquare_page from 'src/svgs/social/foursquare_page.svg';
import foursquare_venue from 'src/svgs/social/foursquare_venue.svg';
import fpx from 'src/svgs/social/fpx.svg';
import fr from 'src/svgs/fr.svg';
import friends_feed from 'src/svgs/social/friends_feed.svg';
import frown from 'src/svgs/frown.svg';
import ftp from 'src/svgs/space/ftp.svg';
import fullscreen from 'src/svgs/fullscreen.svg';
import funnelgraph from 'src/svgs/space/funnelgraph.svg';
import gallery_carousel from 'src/svgs/embed/gallery-carousel.svg';
import gallery_thumbs from 'src/svgs/embed/gallery-thumbs.svg';
import gb from 'src/svgs/gb.svg';
import generic_sms from 'src/svgs/social/generic_sms.svg';
import get_satisfaction from 'src/svgs/social/get_satisfaction.svg';
import givegold from 'src/svgs/space/givegold.svg';
import globe from 'src/svgs/space/globe.svg';
import gmail from 'src/svgs/social/gmail.svg';
import google from 'src/svgs/social/google.svg';
import google_ads from 'src/svgs/social/google_ads.svg';
import google_ads_manager from 'src/svgs/social/google_ads_manager.svg';
import google_adwords from 'src/svgs/social/google_adwords.svg';
import google_apps_store from 'src/svgs/social/google_apps_store.svg';
import google_plus from 'src/svgs/social/google_plus.svg';
import google_plus_filled from 'src/svgs/social/google_plus_filled.svg';
import google_plus_unfilled from 'src/svgs/social/google_plus_unfilled.svg';
import googlecomment from 'src/svgs/space/googlecomment.svg';
import googlelike from 'src/svgs/space/googlelike.svg';
import gowalla from 'src/svgs/social/gowalla.svg';
import grid from 'src/svgs/grid.svg';
import grid_a from 'src/svgs/singlepost_b.svg';
import grid_b from 'src/svgs/singlepost_c.svg';
import grid_view from 'src/svgs/grid-view.svg';
import grid2 from 'src/svgs/grid2.svg';
import gridview from 'src/svgs/space/gridview.svg';
import groupedsummarytable from 'src/svgs/space/groupedsummarytable.svg';
import growth from 'src/svgs/growth.svg';
import hamburger from 'src/svgs/hamburger.svg';
import happy from 'src/svgs/happy.svg';
import hd from 'src/svgs/hd.svg';
import heart from 'src/svgs/heart.svg';
import hidden from 'src/svgs/hidden.svg';
import horizontal_align_center from 'src/svgs/content/horizontal-align-center.svg';
import horizontal_align_left from 'src/svgs/content/horizontal-align-left.svg';
import horizontal_align_right from 'src/svgs/content/horizontal-align-right.svg';
import icon_1column from 'src/svgs/space/1column.svg';
import icon_2column from 'src/svgs/space/2column.svg';
import icon_3column from 'src/svgs/space/3column.svg';
import icon_clone from 'src/svgs/space/icon-clone.svg';
import icon_cog from 'src/svgs/space/icon-cog.svg';
import icon_export from 'src/svgs/export.svg';
import icon_import from 'src/svgs/space/import.svg';
import icon_minus from 'src/svgs/space/icon-minus.svg';
import icon_photo from 'src/svgs/space/icon-photo.svg';
import icon_planner from 'src/svgs/icon-planner.svg';
import icon_plus from 'src/svgs/space/icon-plus.svg';
import icon_question_mark from 'src/svgs/space/icon-question-mark.svg';
import icon_reporting from 'src/svgs/space/icon-reporting.svg';
import icon_undo from 'src/svgs/space/icon-undo.svg';
import icon_upload from 'src/svgs/space/icon-upload.svg';
import image from 'src/svgs/image.svg';
import image_adjustment from 'src/svgs/image-adjustment.svg';
import in_product_comm from 'src/svgs/social/in_product_comm.svg';
import in_store_event from 'src/svgs/social/in_store_event.svg';
import indent from 'src/svgs/space/indent.svg';
import info from 'src/svgs/space/info.svg';
import inherit_theme from 'src/svgs/inherit-theme.svg';
import instagram from 'src/svgs/social/instagram.svg';
import instagram_comments from 'src/svgs/social/instagram_comments.svg';
import instagram_likes from 'src/svgs/social/instagram_likes.svg';
import instagramcomment from 'src/svgs/space/instagramcomment.svg';
import instagramlike from 'src/svgs/space/instagramlike.svg';
import intranet from 'src/svgs/social/intranet.svg';
import ipad from 'src/svgs/embed/ipad.svg';
import iphone from 'src/svgs/embed/iphone.svg';
import it from 'src/svgs/it.svg';
import jive from 'src/svgs/social/jive.svg';
import jive_space from 'src/svgs/social/jive_space.svg';
import jive_unfilled from 'src/svgs/social/jive_unfilled.svg';
import join_inner from 'src/svgs/space/join_inner.svg';
import join_outer from 'src/svgs/space/join_outer.svg';
import join_outerleft from 'src/svgs/space/join_outerleft.svg';
import join_outerright from 'src/svgs/space/join_outerright.svg';
import kakao from 'src/svgs/social/kakao.svg';
import kakao_story from 'src/svgs/social/kakao_story.svg';
import key from 'src/svgs/key.svg';
import keyboard_shortcuts from 'src/svgs/space/keyboard_shortcuts.svg';
import keywordlist from 'src/svgs/space/keywordlist.svg';
import knowledgebase_line_stroke from 'src/svgs/space/knowledgebase_line_stroke.svg';
import kr from 'src/svgs/kr.svg';
import laptop from 'src/svgs/embed/laptop.svg';
import launchpad from 'src/svgs/launchpad.svg';
import layers from 'src/svgs/layers.svg';
import layout from 'src/svgs/layout-icon.svg';
import layouts from 'src/svgs/layouts.svg';
import leadgeneration from 'src/svgs/space/leadgeneration.svg';
import leftsidebar from 'src/svgs/space/leftsidebar.svg';
import legend from 'src/svgs/legend-icon.svg';
import like from 'src/svgs/space/like.svg';
import line from 'src/svgs/social/line.svg';
import line_chart_cardinal from 'src/svgs/line-chart-cardinal.svg';
import line_chart_linear from 'src/svgs/line-chart-linear.svg';
import line_chart_step_after from 'src/svgs/line-chart-step-after.svg';
import lines from 'src/svgs/social/lines.svg';
import link from 'src/svgs/link.svg';
import linkchart from 'src/svgs/space/linkchart.svg';
import linkedin from 'src/svgs/social/linkedin.svg';
import linkedin_ads from 'src/svgs/social/linkedin_ads.svg';
import linkedin_comments from 'src/svgs/social/linkedin_comments.svg';
import linkedin_company from 'src/svgs/social/linkedin_company.svg';
import linkedin_group from 'src/svgs/social/linkedin_group.svg';
import linkedin_likes from 'src/svgs/social/linkedin_likes.svg';
import linkedin_shares from 'src/svgs/social/linkedin_shares.svg';
import list from 'src/svgs/list.svg';
import listening from 'src/svgs/social/listening.svg';
import listview from 'src/svgs/space/listview.svg';
import lithium from 'src/svgs/social/lithium.svg';
import live_event from 'src/svgs/social/live_event.svg';
import livechat from 'src/svgs/social/livechat.svg';
import locationanalysis from 'src/svgs/space/locationanalysis.svg';
import lock from 'src/svgs/lock.svg';
import logo from 'src/svgs/space/logo.svg';
import logout from 'src/svgs/logout.svg';
import mail from 'src/svgs/social/mail.svg';
import marketo from 'src/svgs/social/marketo.svg';
import marketplace from 'src/svgs/space/marketplace.svg';
import mc_content from 'src/svgs/mc-content.svg';
import mc_growth from 'src/svgs/mc-growth.svg';
import mc_reaction from 'src/svgs/mc-reaction.svg';
import merge from 'src/svgs/space/merge.svg';
import messagetags from 'src/svgs/space/messagetags.svg';
import microsoft_dynamics_crm from 'src/svgs/social/microsoft_dynamics_crm.svg';
import milestone from 'src/svgs/space/milestone.svg';
import minus from 'src/svgs/minus.svg';
import minus_alt from 'src/svgs/minus-alt.svg';
import mobile from 'src/svgs/social/mobile.svg';
import mobile_advertising from 'src/svgs/social/mobile_advertising.svg';
import mobile_app from 'src/svgs/social/mobile_app.svg';
import monochrome from 'src/svgs/monochrome.svg';
import more from 'src/svgs/space/more.svg';
import move from 'src/svgs/space/move.svg';
import movie from 'src/svgs/movie.svg';
import mozilla from 'src/svgs/social/mozilla.svg';
import multiselect from 'src/svgs/space/multiselect.svg';
import multitext from 'src/svgs/space/multitext.svg';
import native_facebook_like from 'src/svgs/native/native_facebook_like.svg';
import native_twitter_comment from 'src/svgs/native/native_twitter_comment.svg';
import native_twitter_like from 'src/svgs/native/native_twitter_like.svg';
import native_twitter_retweet from 'src/svgs/native/native_twitter_retweet.svg';
import native_twitter_verified from 'src/svgs/native/native_twitter_verified.svg';
import neutralFace from 'src/svgs/neutral-face.svg';
import news from 'src/svgs/social/news.svg';
import nike from 'src/svgs/social/nike.svg';
import nl from 'src/svgs/nl.svg';
import none from 'src/svgs/none.svg';
import note from 'src/svgs/space/note.svg';
import notification from 'src/svgs/notification.svg';
import number from 'src/svgs/space/number.svg';
import numberedlist from 'src/svgs/space/numberedlist.svg';
import order from 'src/svgs/order.svg';
import out_of_home from 'src/svgs/social/out_of_home.svg';
import outbound from 'src/svgs/social/outbound.svg';
import outboundmessage from 'src/svgs/space/outboundmessage.svg';
import outdent from 'src/svgs/space/outdent.svg';
import outlook from 'src/svgs/social/outlook.svg';
import paginatetable from 'src/svgs/space/paginatetable.svg';
import paid from 'src/svgs/social/paid.svg';
import paintcan from 'src/svgs/paintcan.svg';
import partnership from 'src/svgs/social/partnership.svg';
import pdf from 'src/svgs/pdf.svg';
import pdf_file from 'src/svgs/pdf-file.svg';
import pen from 'src/svgs/pen.svg';
import phone from 'src/svgs/social/phone.svg';
import picklist from 'src/svgs/space/picklist.svg';
import piechart from 'src/svgs/space/piechart.svg';
import pin from 'src/svgs/pin.svg';
import pinterest from 'src/svgs/social/pinterest.svg';
import pinterest_ads from 'src/svgs/social/pinterest_ads.svg';
import pinterest_filled from 'src/svgs/social/pinterest_filled.svg';
import pivottable from 'src/svgs/space/pivottable.svg';
import pl from 'src/svgs/pl.svg';
import placement_bottom_left from 'src/svgs/placement-bottom-left.svg';
import placement_bottom_right from 'src/svgs/placement-bottom-right.svg';
import placement_top_left from 'src/svgs/placement-top-left.svg';
import placement_top_right from 'src/svgs/placement-top-right.svg';
import pluck from 'src/svgs/social/pluck.svg';
import plus from 'src/svgs/plus.svg';
import plus_alt from 'src/svgs/plus-alt.svg';
import post_comp from 'src/svgs/post-comp.svg';
import postcard from 'src/svgs/space/postcard.svg';
import pptx_file from 'src/svgs/pptx-file.svg';
import press_release from 'src/svgs/social/press_release.svg';
import preview from 'src/svgs/preview.svg';
import print from 'src/svgs/social/print.svg';
import priority from 'src/svgs/space/priority.svg';
import product from 'src/svgs/space/product.svg';
import profile from 'src/svgs/social/profile.svg';
import profilecard from 'src/svgs/space/profilecard.svg';
import profiletags from 'src/svgs/space/profiletags.svg';
import publishingqueue from 'src/svgs/space/publishingqueue.svg';
import question_header from 'src/svgs/space/question_header.svg';
import question_mark from 'src/svgs/space/icon-question-mark.svg';
import queue from 'src/svgs/space/queue.svg';
import quote from 'src/svgs/space/quote.svg';
import radio from 'src/svgs/social/radio.svg';
import ranked from 'src/svgs/ranked.svg';
import reach from 'src/svgs/space/reach.svg';
import recall from 'src/svgs/space/recall.svg';
import reddit from 'src/svgs/social/reddit.svg';
import redo from 'src/svgs/space/redo.svg';
import refresh from 'src/svgs/space/refresh.svg';
import reminders from 'src/svgs/space/reminders.svg';
import removecolumn from 'src/svgs/space/removecolumn.svg';
import removefromprofilelist from 'src/svgs/space/removefromprofilelist.svg';
import renren from 'src/svgs/social/renren.svg';
import reorder from 'src/svgs/space/reorder.svg';
import reply from 'src/svgs/space/reply.svg';
import report from 'src/svgs/space/report.svg';
import resize from 'src/svgs/resize.svg';
import retweet from 'src/svgs/social/retweet.svg';
import revert_clear from 'src/svgs/revert-clear.svg';
import revert_history from 'src/svgs/revert-history.svg';
import revert_theme_overwrite from 'src/svgs/revert-theme-overwrite.svg';
import reviews from 'src/svgs/social/reviews.svg';
import rightarrow from 'src/svgs/space/rightarrow.svg';
import rightsidebar from 'src/svgs/space/rightsidebar.svg';
import roles from 'src/svgs/space/roles.svg';
import rss_feeds from 'src/svgs/social/rss_feeds.svg';
import rssfeed from 'src/svgs/space/rssfeed.svg';
import ru from 'src/svgs/ru.svg';
import sad from 'src/svgs/sad.svg';
import salesforce from 'src/svgs/social/salesforce.svg';
import sam from 'src/svgs/space/sam.svg';
import sap_hybris from 'src/svgs/social/sap_hybris.svg';
import save from 'src/svgs/space/save.svg';
import scattergraph from 'src/svgs/space/scattergraph.svg';
import scene_duration from 'src/svgs/scene-duration.svg';
import search from 'src/svgs/social/search.svg';
import search2 from 'src/svgs/search2.svg';
import search3 from 'src/svgs/search3.svg';
import sectiondivider from 'src/svgs/space/sectiondivider.svg';
import securitysettings from 'src/svgs/space/securitysettings.svg';
import send from 'src/svgs/space/send.svg';
import sentiment from 'src/svgs/space/sentiment.svg';
import settings from 'src/svgs/settings.svg';
import shape_ellipse from 'src/svgs/shape-ellipse.svg';
import shape_line from 'src/svgs/shape-line.svg';
import shape_polygon from 'src/svgs/shape-polygon.svg';
import shape_rectangle from 'src/svgs/shape-rectangle.svg';
import shape_triangle from 'src/svgs/shape-triangle.svg';
import share from 'src/svgs/share.svg';
import signle_sign_on_identity_provider from 'src/svgs/space/signle_sign_on_identity_provider.svg';
import simple from 'src/svgs/embed/simple.svg';
import sina_weibo from 'src/svgs/social/sina_weibo.svg';
import single_display from 'src/svgs/display-single.svg';
import single_signon from 'src/svgs/space/single_signon.svg';
import singlepost_a from 'src/svgs/singlepost_a.svg';
import singlepost_b from 'src/svgs/singlepost_b.svg';
import singlepost_c from 'src/svgs/singlepost_c.svg';
import site from 'src/svgs/social/site.svg';
import size_bottom_half from 'src/svgs/size-bottom-half.svg';
import size_bottom_left_eighth from 'src/svgs/size-bottom-left-eighth.svg';
import size_bottom_left_quarter from 'src/svgs/size-bottom-left-quarter.svg';
import size_bottom_right_eighth from 'src/svgs/size-bottom-right-eighth.svg';
import size_bottom_right_quarter from 'src/svgs/size-bottom-right-quarter.svg';
import size_centered_eighth from 'src/svgs/size-centered-eighth.svg';
import size_centered_quarter from 'src/svgs/size-centered-quarter.svg';
import size_full_page from 'src/svgs/size-full-page.svg';
import size_left_half from 'src/svgs/size-left-half.svg';
import size_right_half from 'src/svgs/size-right-half.svg';
import size_top_half from 'src/svgs/size-top-half.svg';
import size_top_left_eighth from 'src/svgs/size-top-left-eighth.svg';
import size_top_left_quarter from 'src/svgs/size-top-left-quarter.svg';
import size_top_right_eighth from 'src/svgs/size-top-right-eighth.svg';
import size_top_right_quarter from 'src/svgs/size-top-right-quarter.svg';
import slide from 'src/svgs/space/slide.svg';
import slider_horizontal from 'src/svgs/embed/slider-horizontal.svg';
import slideshare from 'src/svgs/social/slideshare.svg';
import smile from 'src/svgs/smile.svg';
import sms from 'src/svgs/social/sms.svg';
import snapchat from 'src/svgs/social/snapchat.svg';
import snapchat_ads from 'src/svgs/social/snapchat_ads.svg';
import social from 'src/svgs/space/social.svg';
import social_asset from 'src/svgs/social/social_asset.svg';
import social_sign_on from 'src/svgs/space/social_sign_on.svg';
import solid from 'src/svgs/solid.svg';
import sort from 'src/svgs/space/sort.svg';
import sortascending from 'src/svgs/space/sortascending.svg';
import sortdescending from 'src/svgs/space/sortdescending.svg';
import spacer from 'src/svgs/space/spacer.svg';
import spam from 'src/svgs/space/spam.svg';
import splash from 'src/svgs/splash.svg';
import spline from 'src/svgs/space/spline.svg';
import split from 'src/svgs/space/split.svg';
import spr_task from 'src/svgs/social/spr_task.svg';
import sprinklr_alert from 'src/svgs/social/sprinklr_alert.svg';
import sprinklr_commerce from 'src/svgs/social/sprinklr_commerce.svg';
import sprinklr_logo from 'src/svgs/sprinklr-logo.svg';
import sprinklr_logo_powered from 'src/svgs/sprinklr-logo-powered-by.svg';
import stacked_bar_chart_series from 'src/svgs/stacked-bar-chart-series.svg';
import stacked_bar_chart_stacked from 'src/svgs/stacked-bar-chart-stacked.svg';
import stacked_bar_chart_stacked_100 from 'src/svgs/stacked-bar-chart-stacked-100.svg';
import stacked_column_chart_series from 'src/svgs/stacked-column-chart-series.svg';
import stacked_column_chart_stacked from 'src/svgs/stacked-column-chart-stacked.svg';
import stacked_column_chart_stacked_100 from 'src/svgs/stacked-column-chart-stacked-100.svg';
import stackedbargraph from 'src/svgs/space/stackedbargraph.svg';
import stackedcolumngraph from 'src/svgs/space/stackedcolumngraph.svg';
import star from 'src/svgs/star.svg';
import stop from 'src/svgs/space/stop.svg';
import strikethrough from 'src/svgs/space/strikethrough.svg';
import subscribe from 'src/svgs/space/subscribe.svg';
import suggestion from 'src/svgs/social/suggestion.svg';
import survey_monkey from 'src/svgs/social/survey_monkey.svg';
import switch_client from 'src/svgs/switch_client.svg';
import table from 'src/svgs/space/table.svg';
import tag from 'src/svgs/tag.svg';
import task from 'src/svgs/task.svg';
import tencent_weibo from 'src/svgs/social/tencent_weibo.svg';
import termsandconditions from 'src/svgs/space/termsandconditions.svg';
import testrule from 'src/svgs/space/testrule.svg';
import text from 'src/svgs/space/text.svg';
import text_align_center from 'src/svgs/content/text-align-center.svg';
import text_align_left from 'src/svgs/content/text-align-left.svg';
import text_align_right from 'src/svgs/content/text-align-right.svg';
import text_clamp from 'src/svgs/text_clamp.svg';
import text_entities from 'src/svgs/text-entities.svg';
import text_fit from 'src/svgs/text_fit.svg';
import text_style_bold from 'src/svgs/content/text-style-bold.svg';
import text_style_italic from 'src/svgs/content/text-style-italic.svg';
import text_style_quote from 'src/svgs/content/text-style-quote.svg';
import text_teleprompter from 'src/svgs/text_teleprompter.svg';
import textarea from 'src/svgs/space/textarea.svg';
import textbox from 'src/svgs/space/textbox.svg';
import textcolor from 'src/svgs/space/textcolor.svg';
import textfield from 'src/svgs/space/textfield.svg';
import textform from 'src/svgs/space/textform.svg';
import themes from 'src/svgs/themes-icon.svg';
import thresholds from 'src/svgs/thresholds.svg';
import thumb_view from 'src/svgs/thumb-view.svg';
import thumbs_up from 'src/svgs/thumbs-up.svg';
import tick from 'src/svgs/space/tick.svg';
import tile from 'src/svgs/embed/tile.svg';
import time from 'src/svgs/time.svg';
import timefilter from 'src/svgs/space/timefilter.svg';
import timezone from 'src/svgs/space/timezone.svg';
import todotips from 'src/svgs/space/todotips.svg';
import topiccluster from 'src/svgs/space/topiccluster.svg';
import touchscreenArrowLeft from 'src/svgs/touchscreen/arrow-left.svg';
import touchscreenArrowRight from 'src/svgs/touchscreen/arrow-right.svg';
import touchscreenbarchart from 'src/svgs/touchscreen/barchart.svg';
import touchscreenbubble from 'src/svgs/touchscreen/bubble.svg';
import touchscreenCheck from 'src/svgs/touchscreen/check.svg';
import touchscreenClose from 'src/svgs/touchscreen/close.svg';
import touchscreencolumn from 'src/svgs/touchscreen/column.svg';
import touchscreenhome from 'src/svgs/touchscreen/home.svg';
import touchscreenlinechart from 'src/svgs/touchscreen/linechart.svg';
import touchscreenpiechart from 'src/svgs/touchscreen/piechart.svg';
import touchscreentreemap from 'src/svgs/touchscreen/treemap.svg';
import touchscreenwordcloud from 'src/svgs/touchscreen/wordcloud.svg';
import touchscreencombochart from 'src/svgs/touchscreen/combo.svg';
import touchscreenrankedtable from 'src/svgs/touchscreen/rankedTable.svg';
import trash from 'src/svgs/trash.svg';
import treemap from 'src/svgs/space/treemap.svg';
import trigger from 'src/svgs/space/trigger.svg';
import tumblr from 'src/svgs/social/tumblr.svg';
import tumblr_unfilled from 'src/svgs/social/tumblr_unfilled.svg';
import tunables from 'src/svgs/tunables.svg';
import tv from 'src/svgs/social/tv.svg';
import twilio from 'src/svgs/social/twilio.svg';
import twitter from 'src/svgs/social/twitter.svg';
import twitter_ads from 'src/svgs/social/twitter_ads.svg';
import twitter_mentions from 'src/svgs/social/twitter.svg';
import twitter_replies from 'src/svgs/social/twitter_replies.svg';
import twitter_retweet from 'src/svgs/social/twitter_retweet.svg';
import twitterreply from 'src/svgs/space/twitterreply.svg';
import twittershare from 'src/svgs/space/twittershare.svg';
import typography from 'src/svgs/typography.svg';
import ugc from 'src/svgs/social/ugc.svg';
import under_armour from 'src/svgs/social/under_armour.svg';
import underline from 'src/svgs/space/underline.svg';
import undo from 'src/svgs/space/undo.svg';
import unlink from 'src/svgs/space/unlink.svg';
import unlock from 'src/svgs/unlock.svg';
import up from 'src/svgs/thumbs-up.svg';
import updatetags from 'src/svgs/space/updatetags.svg';
import us from 'src/svgs/us.svg';
import user from 'src/svgs/user.svg';
import user_feedback from 'src/svgs/social/user_feedback.svg';
import user_type_a from 'src/svgs/user_type_a.svg';
import user_type_c from 'src/svgs/user_type_c.svg';
import user_type_d from 'src/svgs/user_type_d.svg';
import usergroup from 'src/svgs/space/usergroup.svg';
import verified from 'src/svgs/space/verified.svg';
import versions from 'src/svgs/versions.svg';
import versions_alt from 'src/svgs/versions-alt.svg';
import vert_align_bottom from 'src/svgs/content/text-format-vert-align-bottom.svg';
import vert_align_middle from 'src/svgs/content/text-format-vert-align-center.svg';
import vert_align_top from 'src/svgs/content/text-format-vert-align-top.svg';
import vertical_align_bottom from 'src/svgs/content/vertical-align-bottom.svg';
import vertical_align_middle from 'src/svgs/content/vertical-align-middle.svg';
import vertical_align_top from 'src/svgs/content/vertical-align-top.svg';
import video from 'src/svgs/space/video.svg';
import videos from 'src/svgs/social/videos.svg';
import videos_comment from 'src/svgs/social/videos_comment.svg';
import view2 from 'src/svgs/space/view2.svg';
import visualize from 'src/svgs/space/visualize.svg';
import vk from 'src/svgs/social/vk.svg';
import web from 'src/svgs/social/web.svg';
import webinar from 'src/svgs/social/webinar.svg';
import website from 'src/svgs/social/website.svg';
import wechat from 'src/svgs/social/wechat.svg';
import whitepaper from 'src/svgs/social/whitepaper.svg';
import widget_asset from 'src/svgs/widget-asset.svg';
import widget_chart from 'src/svgs/widget-chart.svg';
import widget_composition from 'src/svgs/widget-composition.svg';
import widget_error from 'src/svgs/widget-error.svg';
import widget_link from 'src/svgs/widget-link.svg';
import widget_link_button from 'src/svgs/widget-link-button.svg';
import widget_no_data from 'src/svgs/widget-no-data.svg';
import widget_shapes from 'src/svgs/widget-shapes.svg';
import widget_speech_bubble from 'src/svgs/widget-speech-bubble.svg';
import widget_text from 'src/svgs/widget-text.svg';
import widget_ugc from 'src/svgs/widget-ugc.svg';
import wondering from 'src/svgs/wondering.svg';
import wordcloud from 'src/svgs/space/wordcloud.svg';
import wordpress from 'src/svgs/social/wordpress.svg';
import workflow from 'src/svgs/social/workflow.svg';
import close_x from 'src/svgs/x.svg';
import x_alt from 'src/svgs/x-alt.svg';
import xing from 'src/svgs/social/xing.svg';
import yahoo from 'src/svgs/social/yahoo.svg';
import yahoo_ads from 'src/svgs/social/yahoo_ads.svg';
import youtube from 'src/svgs/social/youtube.svg';
import youtube_comments from 'src/svgs/social/youtube_comments.svg';
import youtube_likes from 'src/svgs/social/youtube_likes.svg';
import zendesk from 'src/svgs/social/zendesk.svg';
import zimbra from 'src/svgs/social/zimbra.svg';
import zimbra_community from 'src/svgs/social/zimbra_community.svg';
import zimbra_forum from 'src/svgs/social/zimbra_forum.svg';
import zip from 'src/svgs/space/zip.svg';

export default {
    '1column': () => <SVG src={icon_1column} />,
    '2column': () => <SVG src={icon_2column} />,
    '3column': () => <SVG src={icon_3column} />,
    '4k': () => <SVG src={fourk} />,
    'add-widget': () => <SVG src={add_widget} />,
    'arrange-back': () => <SVG src={arrange_back} />,
    'arrange-backward': () => <SVG src={arrange_backward} />,
    'arrange-forward': () => <SVG src={arrange_forward} />,
    'arrange-front': () => <SVG src={arrange_front} />,
    'arrow-down': () => <SVG src={arrow_down} />,
    'arrow-left': () => <SVG src={arrow_left} />,
    'arrow-right': () => <SVG src={arrow_right} />,
    'arrow-up': () => <SVG src={arrow_up} />,
    'background-image': () => <SVG src={background_image} />,
    'bars-alt': () => <SVG src={bars_alt} />,
    'burger-check': () => <SVG src={burger_check} />,
    'card-basic': () => <SVG src={card_basic} />,
    'card-stylish': () => <SVG src={card_stylish} />,
    'card-vertical': () => <SVG src={card_vertical} />,
    'carousel-horizontal': () => <SVG src={carousel_horizontal} />,
    'carousel-vertical': () => <SVG src={carousel_vertical} />,
    'check-alt': () => <SVG src={check_alt} />,
    'chevron-double-down': () => <SVG src={chevron_double_down} />,
    'chevron-double-left': () => <SVG src={chevron_double_left} />,
    'chevron-double-left-large': () => <SVG src={chevron_double_left_large} />,
    'chevron-double-right': () => <SVG src={chevron_double_right} />,
    'chevron-double-right-large': () => <SVG src={chevron_double_right_large} />,
    'chevron-double-up': () => <SVG src={chevron_double_up} />,
    'chevron-down': () => <SVG src={chevron_down} />,
    'chevron-left': () => <SVG src={chevron_left} />,
    'chevron-right': () => <SVG src={chevron_right} />,
    'chevron-up': () => <SVG src={chevron_up} />,
    'clear-theme-value': () => <SVG src={clear_theme_value} />,
    'color-blend': () => <SVG src={color_blend} />,
    'color-group-preview': () => <SVG src={color_group_preview} />,
    'content-fit-text': () => <SVG src={content_fit_text} />,
    'content-iframe': () => <SVG src={content_iframe} />,
    'content-image': () => <SVG src={content_image} />,
    'content-rich-text': () => <SVG src={content_rich_text} />,
    'content-social-post': () => <SVG src={content_social_post} />,
    'content-video': () => <SVG src={content_video} />,
    'datasrc-content-marketing': () => <SVG src={datasrc_content_marketing} />,
    'datasrc-paid': () => <SVG src={datasrc_paid} />,
    'datasrc-reporting': () => <SVG src={datasrc_reporting} />,
    'datasrc-sprinksights': () => <SVG src={datasrc_sprinksights} />,
    'display-chart': () => <SVG src={display_chart} />,
    'display-wall': () => <SVG src={display_wall} />,
    'distributed-display-wall': () => <SVG src={distributed_display_wall} />,
    'distributed-display': () => <SVG src={distributed_display} />,
    'embed-presentations': () => <SVG src={embedPresentations} />,
    'format-ordered-list': () => <SVG src={format_ordered_list} />,
    'format-unordered-list': () => <SVG src={format_unordered_list} />,
    'gallery-carousel': () => <SVG src={gallery_carousel} />,
    'gallery-thumbs': () => <SVG src={gallery_thumbs} />,
    'grid-view': () => <SVG src={grid_view} />,
    'horizontal-align-center': () => <SVG src={horizontal_align_center} />,
    'horizontal-align-left': () => <SVG src={horizontal_align_left} />,
    'horizontal-align-right': () => <SVG src={horizontal_align_right} />,
    'icon-clone': () => <SVG src={icon_clone} />,
    'icon-cog': () => <SVG src={icon_cog} />,
    'icon-minus': () => <SVG src={icon_minus} />,
    'icon-photo': () => <SVG src={icon_photo} />,
    'icon-planner': () => <SVG src={icon_planner} />,
    'icon-plus': () => <SVG src={icon_plus} />,
    'icon-question-mark': () => <SVG src={icon_question_mark} />,
    'icon-reporting': () => <SVG src={icon_reporting} />,
    'icon-undo': () => <SVG src={icon_undo} />,
    'icon-upload': () => <SVG src={icon_upload} />,
    'image-adjustment': () => <SVG src={image_adjustment} />,
    'inherit-theme': () => <SVG src={inherit_theme} />,
    'line-chart-cardinal': () => <SVG src={line_chart_cardinal} />,
    'line-chart-linear': () => <SVG src={line_chart_linear} />,
    'line-chart-step-after': () => <SVG src={line_chart_step_after} />,
    'mc-content': () => <SVG src={mc_content} />,
    'mc-growth': () => <SVG src={mc_growth} />,
    'mc-reaction': () => <SVG src={mc_reaction} />,
    'minus-alt': () => <SVG src={minus_alt} />,
    'neutral-face': () => <SVG src={neutralFace} />,
    'pdf-file': () => <SVG src={pdf_file} />,
    'placement-bottom-left': () => <SVG src={placement_bottom_left} />,
    'placement-bottom-right': () => <SVG src={placement_bottom_right} />,
    'placement-top-left': () => <SVG src={placement_top_left} />,
    'placement-top-right': () => <SVG src={placement_top_right} />,
    'plus-alt': () => <SVG src={plus_alt} />,
    'post-comp': () => <SVG src={post_comp} />,
    'pptx-file': () => <SVG src={pptx_file} />,
    'revert-clear': () => <SVG src={revert_clear} />,
    'revert-history': () => <SVG src={revert_history} />,
    'revert-theme-overwrite': () => <SVG src={revert_theme_overwrite} />,
    'scene-duration': () => <SVG src={scene_duration} />,
    'shape-ellipse': () => <SVG src={shape_ellipse} />,
    'shape-line': () => <SVG src={shape_line} />,
    'shape-polygon': () => <SVG src={shape_polygon} />,
    'shape-rectangle': () => <SVG src={shape_rectangle} />,
    'shape-triangle': () => <SVG src={shape_triangle} />,
    'single-display': () => <SVG src={single_display} />,
    'size-bottom-half': () => <SVG src={size_bottom_half} />,
    'size-bottom-left-eighth': () => <SVG src={size_bottom_left_eighth} />,
    'size-bottom-left-quarter': () => <SVG src={size_bottom_left_quarter} />,
    'size-bottom-right-eighth': () => <SVG src={size_bottom_right_eighth} />,
    'size-bottom-right-quarter': () => <SVG src={size_bottom_right_quarter} />,
    'size-centered-eighth': () => <SVG src={size_centered_eighth} />,
    'size-centered-quarter': () => <SVG src={size_centered_quarter} />,
    'size-full-page': () => <SVG src={size_full_page} />,
    'size-left-half': () => <SVG src={size_left_half} />,
    'size-right-half': () => <SVG src={size_right_half} />,
    'size-top-half': () => <SVG src={size_top_half} />,
    'size-top-left-eighth': () => <SVG src={size_top_left_eighth} />,
    'size-top-left-quarter': () => <SVG src={size_top_left_quarter} />,
    'size-top-right-eighth': () => <SVG src={size_top_right_eighth} />,
    'size-top-right-quarter': () => <SVG src={size_top_right_quarter} />,
    'slider-horizontal': () => <SVG src={slider_horizontal} />,
    'sprinklr-logo-powered': () => <SVG src={sprinklr_logo_powered} />,
    'sprinklr-logo': () => <SVG src={sprinklr_logo} />,
    'stacked-bar-chart-series': () => <SVG src={stacked_bar_chart_series} />,
    'stacked-bar-chart-stacked-100': () => <SVG src={stacked_bar_chart_stacked_100} />,
    'stacked-bar-chart-stacked': () => <SVG src={stacked_bar_chart_stacked} />,
    'stacked-column-chart-series': () => <SVG src={stacked_column_chart_series} />,
    'stacked-column-chart-stacked-100': () => <SVG src={stacked_column_chart_stacked_100} />,
    'stacked-column-chart-stacked': () => <SVG src={stacked_column_chart_stacked} />,
    'text-align-center': () => <SVG src={text_align_center} />,
    'text-align-left': () => <SVG src={text_align_left} />,
    'text-align-right': () => <SVG src={text_align_right} />,
    'text-entities': () => <SVG src={text_entities} />,
    'text-style-bold': () => <SVG src={text_style_bold} />,
    'text-style-italic': () => <SVG src={text_style_italic} />,
    'text-style-quote': () => <SVG src={text_style_quote} />,
    'thumb-view': () => <SVG src={thumb_view} />,
    'touchscreen-arrow-left': () => <SVG src={touchscreenArrowLeft} />,
    'touchscreen-arrow-right': () => <SVG src={touchscreenArrowRight} />,
    'touchscreen-check': () => <SVG src={touchscreenCheck} />,
    'touchscreen-close': () => <SVG src={touchscreenClose} />,
    'versions-alt': () => <SVG src={versions_alt} />,
    'vert-align-bottom': () => <SVG src={vert_align_bottom} />,
    'vert-align-middle': () => <SVG src={vert_align_middle} />,
    'vert-align-top': () => <SVG src={vert_align_top} />,
    'vertical-align-bottom': () => <SVG src={vertical_align_bottom} />,
    'vertical-align-middle': () => <SVG src={vertical_align_middle} />,
    'vertical-align-top': () => <SVG src={vertical_align_top} />,
    'widget-asset': () => <SVG src={widget_asset} />,
    'widget-chart': () => <SVG src={widget_chart} />,
    'widget-composition': () => <SVG src={widget_composition} />,
    'widget-error': () => <SVG src={widget_error} />,
    'widget-link': () => <SVG src={widget_link} />,
    'widget-link-button': () => <SVG src={widget_link_button} />,
    'widget-no-data': () => <SVG src={widget_no_data} />,
    'widget-shapes': () => <SVG src={widget_shapes} />,
    'widget-speech-bubble': () => <SVG src={widget_speech_bubble} />,
    'widget-text': () => <SVG src={widget_text} />,
    'widget-ugc': () => <SVG src={widget_ugc} />,
    'x-alt': () => <SVG src={x_alt} />,
    accountgroup: () => <SVG src={accountgroup} />,
    add: () => <SVG src={add} />,
    addtoprofilelist: () => <SVG src={addtoprofilelist} />,
    advocacy: () => <SVG src={advocacy} />,
    affiliate: () => <SVG src={affiliate} />,
    aggregation: () => <SVG src={aggregation} />,
    align_centre: () => <SVG src={align_centre} />,
    align_justify: () => <SVG src={align_justify} />,
    alignleft: () => <SVG src={alignleft} />,
    alignright: () => <SVG src={alignright} />,
    analytics: () => <SVG src={analytics} />,
    animation: () => <SVG src={animation} />,
    annotation: () => <SVG src={annotation} />,
    applyfunction: () => <SVG src={applyfunction} />,
    archive: () => <SVG src={archive} />,
    area: () => <SVG src={area} />,
    areaspline: () => <SVG src={areaspline} />,
    arrange: () => <SVG src={arrange} />,
    article: () => <SVG src={article} />,
    articles: () => <SVG src={articles} />,
    assets_notification: () => <SVG src={assets_notification} />,
    assign: () => <SVG src={assign} />,
    assignprofile: () => <SVG src={assignprofile} />,
    attachment: () => <SVG src={attachment} />,
    attachmentasset: () => <SVG src={attachmentasset} />,
    au: () => <SVG src={au} />,
    auctions: () => <SVG src={auctions} />,
    audio: () => <SVG src={audio} />,
    avatar: () => <SVG src={avatar} />,
    awards: () => <SVG src={awards} />,
    bargraph: () => <SVG src={bargraph} />,
    bars: () => <SVG src={bars} />,
    bazaarvoice: () => <SVG src={bazaarvoice} />,
    bell: () => <SVG src={bell} />,
    benchmarking: () => <SVG src={benchmarking} />,
    bing: () => <SVG src={bing} />,
    blog_comments: () => <SVG src={blog_comments} />,
    blog: () => <SVG src={blog} />,
    blogs: () => <SVG src={blogs} />,
    blogswebsites: () => <SVG src={blogswebsites} />,
    brandgroups: () => <SVG src={brandgroups} />,
    brands: () => <SVG src={brands} />,
    budget: () => <SVG src={budget} />,
    bulletedlist: () => <SVG src={bulletedlist} />,
    businesshour: () => <SVG src={businesshour} />,
    businesslocation: () => <SVG src={businesslocation} />,
    button: () => <SVG src={button} />,
    ca: () => <SVG src={ca} />,
    calendar_schedule: () => <SVG src={calendar_schedule} />,
    calendar: () => <SVG src={calendar} />,
    camera: () => <SVG src={camera} />,
    cascade: () => <SVG src={cascade} />,
    case_management: () => <SVG src={case_management} />,
    casetags_stroke: () => <SVG src={casetags_stroke} />,
    ch: () => <SVG src={ch} />,
    chart: () => <SVG src={chart} />,
    charts: () => <SVG src={charts} />,
    check: () => <SVG src={check} />,
    chordchart: () => <SVG src={chordchart} />,
    cinematic_a: () => <SVG src={cinematic_a} />,
    cinematic_b: () => <SVG src={cinematic_b} />,
    cinematic_c: () => <SVG src={cinematic_c} />,
    clarabridge: () => <SVG src={clarabridge} />,
    clavis: () => <SVG src={clavis} />,
    click: () => <SVG src={click} />,
    client: () => <SVG src={client} />,
    clientgroup: () => <SVG src={clientgroup} />,
    clock: () => <SVG src={clock} />,
    clone: () => <SVG src={clone} />,
    cn: () => <SVG src={cn} />,
    code: () => <SVG src={code} />,
    codesnippet: () => <SVG src={codesnippet} />,
    cog: () => <SVG src={cog} />,
    collage_a: () => <SVG src={collage_a} />,
    collage_b: () => <SVG src={collage_b} />,
    color: () => <SVG src={color} />,
    coloumnview: () => <SVG src={coloumnview} />,
    column4: () => <SVG src={column4} />,
    columngraph: () => <SVG src={columngraph} />,
    combination: () => <SVG src={combination} />,
    comment: () => <SVG src={comment} />,
    community: () => <SVG src={community} />,
    compostion: () => <SVG src={compostion} />,
    conditionalformatting: () => <SVG src={conditionalformatting} />,
    contain: () => <SVG src={contain} />,
    conversation: () => <SVG src={conversation} />,
    copy: () => <SVG src={copy} />,
    counter: () => <SVG src={counter} />,
    cover: () => <SVG src={cover} />,
    cpgram: () => <SVG src={cpgram} />,
    createvalue: () => <SVG src={createvalue} />,
    crimson_hexagon: () => <SVG src={crimson_hexagon} />,
    custom: () => <SVG src={custom} />,
    customproperties: () => <SVG src={customproperties} />,
    datasourceadd: () => <SVG src={datasourceadd} />,
    datasourcesync: () => <SVG src={datasourcesync} />,
    de: () => <SVG src={de} />,
    decisionbox: () => <SVG src={decisionbox} />,
    design: () => <SVG src={design} />,
    desktop: () => <SVG src={desktop} />,
    disable: () => <SVG src={disable} />,
    dislike: () => <SVG src={dislike} />,
    display: () => <SVG src={display} />,
    displayallrows: () => <SVG src={displayallrows} />,
    disqus: () => <SVG src={disqus} />,
    distributedcontrolpanel: () => <SVG src={distributedcontrolpanel} />,
    doubleaxis: () => <SVG src={doubleaxis} />,
    doublechevron: () => <SVG src={doublechevron} />,
    drilldowndata: () => <SVG src={drilldowndata} />,
    dropdown: () => <SVG src={dropdown} />,
    dropdownarrow: () => <SVG src={dropdownarrow} />,
    duration: () => <SVG src={duration} />,
    edit: () => <SVG src={edit} />,
    editcoloumn: () => <SVG src={editcoloumn} />,
    editorial: () => <SVG src={editorial} />,
    email_draft: () => <SVG src={email_draft} />,
    email_template_builder: () => <SVG src={email_template_builder} />,
    email: () => <SVG src={email} />,
    enable: () => <SVG src={enable} />,
    engagement: () => <SVG src={engagement} />,
    equalizer: () => <SVG src={equalizer} />,
    es: () => <SVG src={es} />,
    exacttarget: () => <SVG src={exacttarget} />,
    export: () => <SVG src={icon_export} />,
    eyedropper: () => <SVG src={eyedropper} />,
    eyedropper0: () => <SVG src={eyedropper0} />,
    facebook_ads: () => <SVG src={facebook_ads} />,
    facebook_business_manager: () => <SVG src={facebook_business_manager} />,
    facebook_comments: () => <SVG src={facebook_comments} />,
    facebook_domain: () => <SVG src={facebook_domain} />,
    facebook_filled: () => <SVG src={facebook_filled} />,
    facebook_group: () => <SVG src={facebook_group} />,
    facebook_likes: () => <SVG src={facebook_likes} />,
    facebook_page: () => <SVG src={facebook_page} />,
    facebook_share: () => <SVG src={facebook_share} />,
    facebook_unfilled: () => <SVG src={facebook_unfilled} />,
    facebook: () => <SVG src={facebook} />,
    favorite: () => <SVG src={favorite} />,
    feed: () => <SVG src={feed} />,
    feeds: () => <SVG src={feeds} />,
    file: () => <SVG src={file} />,
    filmstrip_a: () => <SVG src={filmstrip_a} />,
    filmstrip_b: () => <SVG src={filmstrip_b} />,
    filmstrip_c: () => <SVG src={filmstrip_c} />,
    filter: () => <SVG src={filter} />,
    flag: () => <SVG src={flag} />,
    flickr: () => <SVG src={flickr} />,
    followunfollow: () => <SVG src={followunfollow} />,
    font: () => <SVG src={font} />,
    forums: () => <SVG src={forums} />,
    foursquare_page: () => <SVG src={foursquare_page} />,
    foursquare_venue: () => <SVG src={foursquare_venue} />,
    foursquare: () => <SVG src={foursquare} />,
    fpx: () => <SVG src={fpx} />,
    fr: () => <SVG src={fr} />,
    friends_feed: () => <SVG src={friends_feed} />,
    frown: () => <SVG src={frown} />,
    ftp: () => <SVG src={ftp} />,
    fullscreen: () => <SVG src={fullscreen} />,
    funnelgraph: () => <SVG src={funnelgraph} />,
    gb: () => <SVG src={gb} />,
    generic_sms: () => <SVG src={generic_sms} />,
    get_satisfaction: () => <SVG src={get_satisfaction} />,
    givegold: () => <SVG src={givegold} />,
    globe: () => <SVG src={globe} />,
    gmail: () => <SVG src={gmail} />,
    google_ads_manager: () => <SVG src={google_ads_manager} />,
    google_ads: () => <SVG src={google_ads} />,
    google_adwords: () => <SVG src={google_adwords} />,
    google_apps_store: () => <SVG src={google_apps_store} />,
    google_plus_filled: () => <SVG src={google_plus_filled} />,
    google_plus_unfilled: () => <SVG src={google_plus_unfilled} />,
    google_plus: () => <SVG src={google_plus} />,
    google: () => <SVG src={google} />,
    googlecomment: () => <SVG src={googlecomment} />,
    googlelike: () => <SVG src={googlelike} />,
    gowalla: () => <SVG src={gowalla} />,
    grid_a: () => <SVG src={grid_a} />,
    grid_b: () => <SVG src={grid_b} />,
    grid: () => <SVG src={grid} />,
    grid2: () => <SVG src={grid2} />,
    gridview: () => <SVG src={gridview} />,
    groupedsummarytable: () => <SVG src={groupedsummarytable} />,
    growth: () => <SVG src={growth} />,
    hamburger: () => <SVG src={hamburger} />,
    happy: () => <SVG src={happy} />,
    hd: () => <SVG src={hd} />,
    heart: () => <SVG src={heart} />,
    hidden: () => <SVG src={hidden} />,
    image: () => <SVG src={image} />,
    import: () => <SVG src={icon_import} />,
    in_product_comm: () => <SVG src={in_product_comm} />,
    in_store_event: () => <SVG src={in_store_event} />,
    indent: () => <SVG src={indent} />,
    info: () => <SVG src={info} />,
    instagram_comments: () => <SVG src={instagram_comments} />,
    instagram_likes: () => <SVG src={instagram_likes} />,
    instagram: () => <SVG src={instagram} />,
    instagramcomment: () => <SVG src={instagramcomment} />,
    instagramlike: () => <SVG src={instagramlike} />,
    intranet: () => <SVG src={intranet} />,
    ipad: () => <SVG src={ipad} />,
    iphone: () => <SVG src={iphone} />,
    it: () => <SVG src={it} />,
    jive_space: () => <SVG src={jive_space} />,
    jive_unfilled: () => <SVG src={jive_unfilled} />,
    jive: () => <SVG src={jive} />,
    join_inner: () => <SVG src={join_inner} />,
    join_outer: () => <SVG src={join_outer} />,
    join_outerleft: () => <SVG src={join_outerleft} />,
    join_outerright: () => <SVG src={join_outerright} />,
    kakao_story: () => <SVG src={kakao_story} />,
    kakao: () => <SVG src={kakao} />,
    key: () => <SVG src={key} />,
    keyboard_shortcuts: () => <SVG src={keyboard_shortcuts} />,
    keywordlist: () => <SVG src={keywordlist} />,
    knowledgebase_line_stroke: () => <SVG src={knowledgebase_line_stroke} />,
    kr: () => <SVG src={kr} />,
    laptop: () => <SVG src={laptop} />,
    launchpad: () => <SVG src={launchpad} />,
    layers: () => <SVG src={layers} />,
    layout: () => <SVG src={layout} />,
    layouts: () => <SVG src={layouts} />,
    leadgeneration: () => <SVG src={leadgeneration} />,
    leftsidebar: () => <SVG src={leftsidebar} />,
    legend: () => <SVG src={legend} />,
    like: () => <SVG src={like} />,
    line: () => <SVG src={line} />,
    lines: () => <SVG src={lines} />,
    link: () => <SVG src={link} />,
    linkchart: () => <SVG src={linkchart} />,
    linkedin_ads: () => <SVG src={linkedin_ads} />,
    linkedin_comments: () => <SVG src={linkedin_comments} />,
    linkedin_company: () => <SVG src={linkedin_company} />,
    linkedin_group: () => <SVG src={linkedin_group} />,
    linkedin_likes: () => <SVG src={linkedin_likes} />,
    linkedin_shares: () => <SVG src={linkedin_shares} />,
    linkedin: () => <SVG src={linkedin} />,
    list: () => <SVG src={list} />,
    listening: () => <SVG src={listening} />,
    listview: () => <SVG src={listview} />,
    lithium: () => <SVG src={lithium} />,
    live_event: () => <SVG src={live_event} />,
    livechat: () => <SVG src={livechat} />,
    locationanalysis: () => <SVG src={locationanalysis} />,
    lock: () => <SVG src={lock} />,
    logo: () => <SVG src={logo} />,
    logout: () => <SVG src={logout} />,
    mail: () => <SVG src={mail} />,
    marketo: () => <SVG src={marketo} />,
    marketplace: () => <SVG src={marketplace} />,
    merge: () => <SVG src={merge} />,
    messagetags: () => <SVG src={messagetags} />,
    microsoft_dynamics_crm: () => <SVG src={microsoft_dynamics_crm} />,
    milestone: () => <SVG src={milestone} />,
    minus: () => <SVG src={minus} />,
    mobile_advertising: () => <SVG src={mobile_advertising} />,
    mobile_app: () => <SVG src={mobile_app} />,
    mobile: () => <SVG src={mobile} />,
    monochrome: () => <SVG src={monochrome} />,
    more: () => <SVG src={more} />,
    move: () => <SVG src={move} />,
    movie: () => <SVG src={movie} />,
    mozilla: () => <SVG src={mozilla} />,
    multiselect: () => <SVG src={multiselect} />,
    multitext: () => <SVG src={multitext} />,
    native_facebook_like: () => <SVG src={native_facebook_like} />,
    native_twitter_comment: () => <SVG src={native_twitter_comment} />,
    native_twitter_like: () => <SVG src={native_twitter_like} />,
    native_twitter_retweet: () => <SVG src={native_twitter_retweet} />,
    native_twitter_verified: () => <SVG src={native_twitter_verified} />,
    news: () => <SVG src={news} />,
    nike: () => <SVG src={nike} />,
    nl: () => <SVG src={nl} />,
    none: () => <SVG src={none} />,
    note: () => <SVG src={note} />,
    notification: () => <SVG src={notification} />,
    number: () => <SVG src={number} />,
    numberedlist: () => <SVG src={numberedlist} />,
    order: () => <SVG src={order} />,
    out_of_home: () => <SVG src={out_of_home} />,
    outbound: () => <SVG src={outbound} />,
    outboundmessage: () => <SVG src={outboundmessage} />,
    outdent: () => <SVG src={outdent} />,
    outlook: () => <SVG src={outlook} />,
    paginatetable: () => <SVG src={paginatetable} />,
    paid: () => <SVG src={paid} />,
    paintcan: () => <SVG src={paintcan} />,
    partnership: () => <SVG src={partnership} />,
    pdf: () => <SVG src={pdf} />,
    pen: () => <SVG src={pen} />,
    phone: () => <SVG src={phone} />,
    picklist: () => <SVG src={picklist} />,
    piechart: () => <SVG src={piechart} />,
    pin: () => <SVG src={pin} />,
    pinterest_ads: () => <SVG src={pinterest_ads} />,
    pinterest_filled: () => <SVG src={pinterest_filled} />,
    pinterest: () => <SVG src={pinterest} />,
    pivottable: () => <SVG src={pivottable} />,
    pl: () => <SVG src={pl} />,
    pluck: () => <SVG src={pluck} />,
    plus: () => <SVG src={plus} />,
    postcard: () => <SVG src={postcard} />,
    press_release: () => <SVG src={press_release} />,
    preview: () => <SVG src={preview} />,
    print: () => <SVG src={print} />,
    priority: () => <SVG src={priority} />,
    product: () => <SVG src={product} />,
    profile: () => <SVG src={profile} />,
    profilecard: () => <SVG src={profilecard} />,
    profiletags: () => <SVG src={profiletags} />,
    publishingqueue: () => <SVG src={publishingqueue} />,
    question_header: () => <SVG src={question_header} />,
    question_mark: () => <SVG src={question_mark} />,
    queue: () => <SVG src={queue} />,
    quote: () => <SVG src={quote} />,
    radio: () => <SVG src={radio} />,
    ranked: () => <SVG src={ranked} />,
    reach: () => <SVG src={reach} />,
    recall: () => <SVG src={recall} />,
    reddit: () => <SVG src={reddit} />,
    redo: () => <SVG src={redo} />,
    refresh: () => <SVG src={refresh} />,
    reminders: () => <SVG src={reminders} />,
    removecolumn: () => <SVG src={removecolumn} />,
    removefromprofilelist: () => <SVG src={removefromprofilelist} />,
    renren: () => <SVG src={renren} />,
    reorder: () => <SVG src={reorder} />,
    reply: () => <SVG src={reply} />,
    report: () => <SVG src={report} />,
    resize: () => <SVG src={resize} />,
    retweet: () => <SVG src={retweet} />,
    reviews: () => <SVG src={reviews} />,
    rightarrow: () => <SVG src={rightarrow} />,
    rightsidebar: () => <SVG src={rightsidebar} />,
    roles: () => <SVG src={roles} />,
    rss_feeds: () => <SVG src={rss_feeds} />,
    rssfeed: () => <SVG src={rssfeed} />,
    ru: () => <SVG src={ru} />,
    sad: () => <SVG src={sad} />,
    salesforce: () => <SVG src={salesforce} />,
    sam: () => <SVG src={sam} />,
    sap_hybris: () => <SVG src={sap_hybris} />,
    save: () => <SVG src={save} />,
    scattergraph: () => <SVG src={scattergraph} />,
    search: () => <SVG src={search} />,
    search2: () => <SVG src={search2} />,
    search3: () => <SVG src={search3} />,
    sectiondivider: () => <SVG src={sectiondivider} />,
    securitysettings: () => <SVG src={securitysettings} />,
    send: () => <SVG src={send} />,
    sentiment: () => <SVG src={sentiment} />,
    settings: () => <SVG src={settings} />,
    share: () => <SVG src={share} />,
    signle_sign_on_identity_provider: () => <SVG src={signle_sign_on_identity_provider} />,
    simple: () => <SVG src={simple} />,
    sina_weibo: () => <SVG src={sina_weibo} />,
    single_signon: () => <SVG src={single_signon} />,
    singlepost_a: () => <SVG src={singlepost_a} />,
    singlepost_b: () => <SVG src={singlepost_b} />,
    singlepost_c: () => <SVG src={singlepost_c} />,
    site: () => <SVG src={site} />,
    slide: () => <SVG src={slide} />,
    slideshare: () => <SVG src={slideshare} />,
    smile: () => <SVG src={smile} />,
    sms: () => <SVG src={sms} />,
    snapchat_ads: () => <SVG src={snapchat_ads} />,
    snapchat: () => <SVG src={snapchat} />,
    social_asset: () => <SVG src={social_asset} />,
    social_sign_on: () => <SVG src={social_sign_on} />,
    social: () => <SVG src={social} />,
    solid: () => <SVG src={solid} />,
    sort: () => <SVG src={sort} />,
    sortascending: () => <SVG src={sortascending} />,
    sortdescending: () => <SVG src={sortdescending} />,
    spacer: () => <SVG src={spacer} />,
    spam: () => <SVG src={spam} />,
    splash: () => <SVG src={splash} />,
    spline: () => <SVG src={spline} />,
    split: () => <SVG src={split} />,
    spr_task: () => <SVG src={spr_task} />,
    sprinklr_alert: () => <SVG src={sprinklr_alert} />,
    sprinklr_commerce: () => <SVG src={sprinklr_commerce} />,
    stackedbargraph: () => <SVG src={stackedbargraph} />,
    stackedcolumngraph: () => <SVG src={stackedcolumngraph} />,
    star: () => <SVG src={star} />,
    stop: () => <SVG src={stop} />,
    strikethrough: () => <SVG src={strikethrough} />,
    subscribe: () => <SVG src={subscribe} />,
    suggestion: () => <SVG src={suggestion} />,
    survey_monkey: () => <SVG src={survey_monkey} />,
    switch_client: () => <SVG src={switch_client} />,
    table: () => <SVG src={table} />,
    tag: () => <SVG src={tag} />,
    task: () => <SVG src={task} />,
    tencent_weibo: () => <SVG src={tencent_weibo} />,
    termsandconditions: () => <SVG src={termsandconditions} />,
    testrule: () => <SVG src={testrule} />,
    text_clamp: () => <SVG src={text_clamp} />,
    text_fit: () => <SVG src={text_fit} />,
    text_teleprompter: () => <SVG src={text_teleprompter} />,
    text: () => <SVG src={text} />,
    textarea: () => <SVG src={textarea} />,
    textbox: () => <SVG src={textbox} />,
    textcolor: () => <SVG src={textcolor} />,
    textfield: () => <SVG src={textfield} />,
    textform: () => <SVG src={textform} />,
    themes: () => <SVG src={themes} />,
    thresholds: () => <SVG src={thresholds} />,
    tick: () => <SVG src={tick} />,
    tile: () => <SVG src={tile} />,
    time: () => <SVG src={time} />,
    timefilter: () => <SVG src={timefilter} />,
    timezone: () => <SVG src={timezone} />,
    todotips: () => <SVG src={todotips} />,
    topiccluster: () => <SVG src={topiccluster} />,
    touchscreenbarchart: () => <SVG src={touchscreenbarchart} />,
    touchscreenbubble: () => <SVG src={touchscreenbubble} />,
    touchscreencolumn: () => <SVG src={touchscreencolumn} />,
    touchscreenhome: () => <SVG src={touchscreenhome} />,
    touchscreenlinechart: () => <SVG src={touchscreenlinechart} />,
    touchscreenpiechart: () => <SVG src={touchscreenpiechart} />,
    touchscreentreemap: () => <SVG src={touchscreentreemap} />,
    touchscreenwordcloud: () => <SVG src={touchscreenwordcloud} />,
    touchscreencombochart: () => <SVG src={touchscreencombochart} />,
    touchscreenrankedtable: () => <SVG src={touchscreenrankedtable} />,
    trash: () => <SVG src={trash} />,
    treemap: () => <SVG src={treemap} />,
    trigger: () => <SVG src={trigger} />,
    tumblr_unfilled: () => <SVG src={tumblr_unfilled} />,
    tumblr: () => <SVG src={tumblr} />,
    tunables: () => <SVG src={tunables} />,
    tv: () => <SVG src={tv} />,
    twilio: () => <SVG src={twilio} />,
    twitter_ads: () => <SVG src={twitter_ads} />,
    twitter_mentions: () => <SVG src={twitter_mentions} />,
    twitter_replies: () => <SVG src={twitter_replies} />,
    twitter_retweet: () => <SVG src={twitter_retweet} />,
    twitter: () => <SVG src={twitter} />,
    twitterreply: () => <SVG src={twitterreply} />,
    twittershare: () => <SVG src={twittershare} />,
    typography: () => <SVG src={typography} />,
    ugc: () => <SVG src={ugc} />,
    under_armour: () => <SVG src={under_armour} />,
    underline: () => <SVG src={underline} />,
    undo: () => <SVG src={undo} />,
    unlink: () => <SVG src={unlink} />,
    unlock: () => <SVG src={unlock} />,
    up: () => <SVG src={thumbs_up} />,
    updatetags: () => <SVG src={updatetags} />,
    us: () => <SVG src={us} />,
    user_feedback: () => <SVG src={user_feedback} />,
    user_type_a: () => <SVG src={user_type_a} />,
    user_type_c: () => <SVG src={user_type_c} />,
    user_type_d: () => <SVG src={user_type_d} />,
    user: () => <SVG src={user} />,
    usergroup: () => <SVG src={usergroup} />,
    verified: () => <SVG src={verified} />,
    versions: () => <SVG src={versions} />,
    video: () => <SVG src={video} />,
    videos_comment: () => <SVG src={videos_comment} />,
    videos: () => <SVG src={videos} />,
    view2: () => <SVG src={view2} />,
    visualize: () => <SVG src={visualize} />,
    vk: () => <SVG src={vk} />,
    web: () => <SVG src={web} />,
    webinar: () => <SVG src={webinar} />,
    website: () => <SVG src={website} />,
    wechat: () => <SVG src={wechat} />,
    whitepaper: () => <SVG src={whitepaper} />,
    wondering: () => <SVG src={wondering} />,
    wordcloud: () => <SVG src={wordcloud} />,
    wordpress: () => <SVG src={wordpress} />,
    workflow: () => <SVG src={workflow} />,
    x: () => <SVG src={x} />,
    close_x: () => <SVG src={close_x} />,
    xing: () => <SVG src={xing} />,
    yahoo_ads: () => <SVG src={yahoo_ads} />,
    yahoo: () => <SVG src={yahoo} />,
    youtube_comments: () => <SVG src={youtube_comments} />,
    youtube_likes: () => <SVG src={youtube_likes} />,
    youtube: () => <SVG src={youtube} />,
    zendesk: () => <SVG src={zendesk} />,
    zimbra_community: () => <SVG src={zimbra_community} />,
    zimbra_forum: () => <SVG src={zimbra_forum} />,
    zimbra: () => <SVG src={zimbra} />,
    zip: () => <SVG src={zip} />,
} as IGlyph;
