import * as React from 'react';
import { observer } from 'mobx-react';
import { getPrettyTimestamp, getPrettyTimeAgo, getEndianTime } from 'utils/Number/NumberUtils';
import { TimeStampOptions } from '@sprinklr/stories/widget/types';

import './TimeStamp.scss';

interface TimeStampProps {
    time?: number;
    timeStampFormat?: TimeStampOptions;
    endianTime?: string;
    classes?: string;
}

class TimeStamp extends React.Component<TimeStampProps, any> {
    public static defaultProps: TimeStampProps = {
        classes: '',
    };

    render() {
        const { time, endianTime, timeStampFormat, classes } = this.props;
        const formattedTime = time ? getPrettyTimestamp(time) : null;
        const timeAgo = time ? getPrettyTimeAgo(time) : null;
        const timeNoAgo = time ? getPrettyTimeAgo(time, false) : null;
        const endian = time && endianTime ? getEndianTime(time, endianTime) : null;

        return (
            <span className={`post_time_stamp ${classes}`}>
                {timeStampFormat === 'timeAgo' && timeAgo}
                {timeStampFormat === 'timeNoAgo' && timeNoAgo}
                {endianTime && timeStampFormat === 'timePosted' && endian}
                {!endianTime && !timeStampFormat && formattedTime}
            </span>
        );
    }
}

export default observer(TimeStamp);
