import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { InstagramEmbed, LinkedInEmbed, FacebookEmbed } from 'react-social-media-embed';
import { useMeasure } from 'react-use';

// types
import { SocialMediaEmbedProps } from './types';
import { resolveLinkedInUrl } from '../helpers/resolveLinkedInUrl';

function SocialMediaEmbed({
    contentUrl,
    width,
    onAfterRender,
    socialNetwork,
}: SocialMediaEmbedProps) {
    const [ref, { height = 0 }] = useMeasure();

    const resolvedLinkedInUrl = useMemo(() => {
        return resolveLinkedInUrl(contentUrl);
    }, [contentUrl]);

    useEffect(() => {
        if (height > 0) {
            onAfterRender?.();
        }
    }, [height, onAfterRender]);

    return (
        <div ref={ref}>
            {socialNetwork === 'instagram' ? (
                <InstagramEmbed url={contentUrl} width={width} />
            ) : null}
            {socialNetwork === 'linkedIn' ? (
                <LinkedInEmbed url={resolvedLinkedInUrl} width={width} />
            ) : null}
            {socialNetwork === 'facebook' ? <FacebookEmbed url={contentUrl} width={width} /> : null}
        </div>
    );
}

const SocialMediaEmbedHOC = observer(SocialMediaEmbed);
export { SocialMediaEmbedHOC as SocialMediaEmbed };
