import { Box } from '@sprinklr/spaceweb/box';
import styled from 'components/_UI/designSystem';

export const StyledStatusTagDot = styled(Box)<{ size: number; color: string }>`
    height: ${props => props.size * 0.25}em;
    width: ${props => props.size * 0.25}em;
    border-radius: 50%;
    background-color: ${props => props.color};
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props.size * 0.1}em;
`;

export const StyledPulsingDot = styled(Box)<{ color: string; duration: number; size: number }>`
    animation: pulseDot ${props => props.duration}ms cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    box-sizing: border-box;
    border-radius: 50%;
    height: ${props => props.size * 0.25}em;
    width: ${props => props.size * 0.25}em;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props.size * 0.1}em;
    background-color: ${props => props.color};

    &::before {
        animation: pulseRing ${props => props.duration}ms cubic-bezier(0.215, 0.61, 0.355, 1)
            infinite;
        background-color: ${props => props.color};
        border-radius: 50%;
        content: '';
        margin-right: ${props => props.size * 0.1}em;
        display: flex;
        height: ${props => props.size * 0.5}em;
        width: ${props => props.size * 0.5}em;
        left: -50%;
        position: relative;
        align-items: center;
        justify-content: center;
        top: -50%;
    }
    @keyframes pulseDot {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
    }

    @keyframes pulseRing {
        0% {
            transform: scale(0.5);
        }
        80%,
        100% {
            opacity: 0;
        }
    }
`;
