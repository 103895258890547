import { observer } from 'mobx-react';
import * as React from 'react';
import { computed } from 'mobx';
import TweetEmbed from './TweetEmbed';
import { SocialMediaEmbed } from './socialMediaEmbed';
import { getSocialPostType } from './helpers/getSocialPostType';

require('./SocialPost.scss');

export interface SocialPostProps {
    contentUrl?: string;
    onEmbedLoaded?: (arg1?: any, arg2?: any) => void;
    width?: number;
}

class SocialPost extends React.Component<SocialPostProps, any> {
    static faceboookAppId = '326815137355753';
    static isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    private innerRef;

    constructor(props) {
        super(props);
        this.innerRef = React.createRef<HTMLDivElement>();
    }

    @computed get socialNetwork() {
        return getSocialPostType(this.props.contentUrl);
    }

    @computed get socialPostId() {
        const url = this.props.contentUrl;
        if (url?.indexOf('twitter.com') !== -1) {
            return url.split('/status/')[1];
        } else if (url?.indexOf('instagr.am') !== -1) {
            return url;
        }
    }

    getNode = node => {
        if (!node || !SocialPost.isSafari) {
            return;
        }
        const embedNode = node.firstChild.firstChild.firstChild;
        const embedNodeParent = node.firstChild.firstChild;
        if (!embedNode || !embedNodeParent) {
            return;
        }

        // if width is 0 apply its child's width else remove the style so it doesn't fix the size
        if (embedNodeParent.clientWidth === 0) {
            embedNodeParent.style.width = embedNode.clientWidth + 'px';
            setTimeout(() => {
                embedNodeParent.removeAttribute('style');
                this.forceUpdate();
            }, 0);
        }
    };

    render() {
        const { contentUrl, onEmbedLoaded, width } = this.props;
        const socialNetwork = this.socialNetwork;
        const resolvedWidth = width ? Math.min(520, Math.max(320, width)) : 320;

        return (
            <div
                className={`${socialNetwork}_embed social_embed us-n flex no-grow center middle rel w100 h100`}
                ref={this.getNode}>
                <div className='social_embed_inner' ref={this.innerRef}>
                    {(socialNetwork === 'instagram' ||
                        socialNetwork === 'linkedIn' ||
                        socialNetwork === 'facebook') && (
                        <SocialMediaEmbed
                            socialNetwork={socialNetwork}
                            key={`${contentUrl}`}
                            contentUrl={contentUrl}
                            width={resolvedWidth}
                            onAfterRender={() => onEmbedLoaded(this.innerRef.current)}
                        />
                    )}
                    {socialNetwork === 'twitter' && (
                        <TweetEmbed
                            key={this.socialPostId}
                            maxWidth={resolvedWidth}
                            hideThread={true}
                            onTweetLoadSuccess={onEmbedLoaded}
                            id={this.socialPostId}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default observer(SocialPost);
