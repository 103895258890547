import React, { useCallback } from 'react';
import { Group } from '@vx/group';
import { AxisBottom } from '@vx/axis';
import { Line } from '@vx/shape';
import { Text } from '@vx/text';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { getFieldTypeValue } from '@sprinklr/stories/analytics/Field';
import { FieldType } from '@sprinklr/stories/reporting/types';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import { GenerateLabelClasses } from 'utils/StringUtils/StringUtils';
import useDimensions from '../../../hooks/UseDimensions';
import { XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { observer } from 'mobx-react';
import { FontCase } from 'models/Theme/Theme';

export type ITicks = {
    enabled: boolean;
    label: {
        enabled: boolean;
        size: number;
        numberFormat: DecimalFormat;
        numberCase?: FontCase;
        timeFormat: XAxisDateFormats;
        angle?: number;
    };
    line: {
        enabled: boolean;
    };
    numTicks?: number;
};

export type IAxisLabel = {
    enabled: boolean;
    size: number;
    padding: number;
};

export type IAxis = {
    enabled: boolean;
    label: IAxisLabel;
    ticks: ITicks;
    padding?: {
        start?: number;
        end?: number;
    };
};

export type AxisProps = {
    enabled: boolean;
    label: {
        enabled: boolean;
        size: number;
        padding: number;
    };
    email?: {
        enabled: boolean;
        size: number;
    };
    image?: {
        enabled: boolean;
        opacity?: number;
        size?: number;
    };
    ticks: ITicks;
};

export type XAxisProps = {
    bandwidth: number;
    dateFormat?: XAxisDateFormats;
    hideAxisLine?: boolean;
    label: string;
    labelType?: FieldType;
    left?: number;
    numTicksForWidth?: number;
    top?: number;
    options: AxisProps;
    xScale: any;
    xValues?: string[] | number[];
    language?: string;
};

const LABEL_PADDING_RATIO = 0.6;
const LABEL_PADDING_OFFSET = 10;

const XAxis = React.forwardRef(
    (
        {
            bandwidth,
            dateFormat,
            hideAxisLine,
            label,
            labelType,
            left,
            numTicksForWidth,
            top,
            options,
            xScale,
            xValues,
            language,
        }: XAxisProps,
        ref
    ) => {
        const {
            ticks,
            label: xAxisLabel,
            email = {
                enabled: false,
                size: 50,
            },
            image = {
                enabled: false,
                size: 50,
                opacity: 50,
            },
        } = options;

        const tickRef = React.useRef();
        const { height } = useDimensions({
            ref: tickRef,
            watchObject: JSON.stringify([options]),
        });

        const decimalFormat: DecimalFormat = ticks.label.numberFormat;
        const valueCase: FontCase = ticks.label.numberCase;

        const { enabled: imageEnabled, size: imageSize, opacity } = image;

        const { size: emailSize, enabled: emailEnabled } = email;

        const getImageStyles = useCallback(
            url => {
                return {
                    width: imageSize,
                    height: imageSize,
                    backgroundImage: url ? `url('${url}')` : 'none',
                    // backgroundColor: item.color,
                    backgroundRepeat: 'none',
                    backgroundSize: 'cover',
                    opacity: !!url ? opacity * 0.01 : 1,
                };
            },
            [imageSize, opacity]
        );

        const getTickFormat = useCallback(
            (value, index) => {
                const newValue = ['TIMESTAMP', 'TIME_INTERVAL', 'DATE'].includes(labelType)
                    ? new Date(value as string)
                    : value;

                return xValues?.length > 0
                    ? xValues[newValue as string | number]
                    : getFieldTypeValue(newValue, labelType, {
                          dateFormat,
                          decimalFormat,
                          valueCase,
                          language,
                      });
            },
            [dateFormat, decimalFormat, labelType, language, valueCase, xValues]
        );

        if (!ticks) {
            return;
        }

        const { line } = ticks;
        const tickValueSize = ticks.enabled && ticks.label.size * 0.4;
        const bulkItemHeight = (emailEnabled ? emailSize : 0) + (imageEnabled ? imageSize : 0);

        const labelY = height + xAxisLabel.padding * LABEL_PADDING_RATIO + LABEL_PADDING_OFFSET;
        const showTickLines = ticks?.label.enabled && line.enabled && !hideAxisLine;

        return (
            <Group innerRef={ref as React.Ref<SVGGElement>}>
                <AxisBottom
                    hideAxisLine={hideAxisLine}
                    key='bottom'
                    label={label}
                    left={left || 0}
                    numTicks={numTicksForWidth}
                    scale={xScale}
                    top={top || 0}
                    tickFormat={getTickFormat}>
                    {axis => {
                        const tickRotate = ticks.label.angle;
                        const isAngled = tickRotate !== 0;
                        const axisCenter = (axis.axisToPoint.x - axis.axisFromPoint.x) / 2;

                        return (
                            <>
                                <Group innerRef={tickRef}>
                                    {ticks.enabled &&
                                        axis.ticks.map((tick, i) => {
                                            const tickX = tick.to.x;
                                            const yBase =
                                                tick.to.y + tickValueSize + axis.tickLength;
                                            const tickY =
                                                yBase +
                                                (imageEnabled ? imageSize + tickValueSize * 2 : 0);
                                            const emailY = tickY + emailSize;
                                            const imageY = yBase;
                                            const resolvedLabel = getTickFormat(tick.value, i);

                                            return (
                                                <Group
                                                    key={`vx-tick-${tick.value}-${i}`}
                                                    className={`vx-axis-tick ${GenerateLabelClasses(
                                                        resolvedLabel,
                                                        'axis'
                                                    )}`}>
                                                    {imageEnabled &&
                                                        tick.value instanceof BulkItem &&
                                                        tick.value.toImageUrl() && (
                                                            <foreignObject
                                                                x={tickX - imageSize / 2}
                                                                y={imageY}
                                                                height={imageSize}
                                                                width={imageSize}>
                                                                <div
                                                                    className='circle'
                                                                    style={getImageStyles(
                                                                        tick.value.toImageUrl()
                                                                    )}
                                                                />
                                                            </foreignObject>
                                                        )}

                                                    <Line
                                                        style={
                                                            !showTickLines
                                                                ? { visibility: 'hidden' }
                                                                : {}
                                                        }
                                                        from={tick.from}
                                                        to={tick.to}
                                                    />

                                                    {ticks.label && ticks.label.enabled && (
                                                        <Text
                                                            verticalAnchor='middle'
                                                            className='primary_font_family'
                                                            fontSize={ticks.label.size * 0.5}
                                                            textAnchor={isAngled ? 'end' : 'middle'}
                                                            x={tickX}
                                                            y={tickY}
                                                            angle={tickRotate}
                                                            width={bandwidth}>
                                                            {resolvedLabel}
                                                        </Text>
                                                    )}
                                                    {email &&
                                                        email.enabled &&
                                                        tick.value instanceof BulkItem &&
                                                        tick.value.toEmail() && (
                                                            <Text
                                                                verticalAnchor='middle'
                                                                className='primary_font_family'
                                                                fontSize={emailSize}
                                                                textAnchor='middle'
                                                                x={tickX}
                                                                y={tickY}
                                                                angle={tickRotate}
                                                                width={bandwidth}>
                                                                {tick.value.toEmail()}
                                                            </Text>
                                                        )}
                                                </Group>
                                            );
                                        })}
                                </Group>
                                {!hideAxisLine && (
                                    <Line
                                        className='vx-axis-line'
                                        x1={axis.axisFromPoint.x}
                                        x2={axis.axisToPoint.x}
                                        y1={0}
                                        y2={0.5}
                                    />
                                )}

                                {xAxisLabel.enabled && (
                                    <Text
                                        verticalAnchor='start'
                                        className='primary_font_family bottom-axis-label'
                                        fontSize={xAxisLabel.size * 0.75}
                                        textAnchor='middle'
                                        x={axisCenter}
                                        y={labelY + bulkItemHeight}>
                                        {axis.label}
                                    </Text>
                                )}
                            </>
                        );
                    }}
                </AxisBottom>
            </Group>
        );
    }
);

export default observer(XAxis);
