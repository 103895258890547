import * as React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import './CornerTear.scss';

interface CornerTearProps {
    iconClasses?: string;
    channel?: string;
    xPosition?: string;
    yPosition?: string;
    socialIconBgColor?: boolean;
    className?: string;
}

export default class CornerTear extends React.Component<CornerTearProps, any> {
    public static defaultProps: CornerTearProps = {};

    render() {
        const {
            channel,
            iconClasses,
            xPosition,
            yPosition,
            socialIconBgColor,
            className,
        } = this.props;
        return (
            <div
                className={classNames(
                    `corner_tear position_${yPosition}_${xPosition} abs channel_${channel} ${xPosition} ${yPosition}`,
                    { className }
                )}>
                {socialIconBgColor && <div className='corner_tear_triangle abs'></div>}
                <Icon value={channel} classes={iconClasses} />
            </div>
        );
    }
}
