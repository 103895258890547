import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ApolloProvider } from '@apollo/client';
import AuthService, { FEATURE_FLAG } from 'services/Auth/AuthService';
import i18n from '../../i18n';
import { EnvironmentConfig } from '../../config';
import { apolloClient } from 'utils/apollo';
import loadable from '@loadable/component';
import { FALLBACK_SPINNER, FALLBACK_EMPTY } from '../../utils/constants';
import PrivateRoute from '@sprinklr/display-builder/containers/App/PrivateRoute';

const queryString = require('query-string');

const RootRedirect = loadable(
    () =>
        import(/* webpackChunkName: "RootRedirect" */ './RootRedirect').then(
            module => module.RootRedirectContainer
        ),
    FALLBACK_SPINNER
);
const DisplaysManagerPage = loadable(
    () => import(/* webpackChunkName: "DisplaysManagerPage" */ '../../pages/DisplaysManagerPage'),
    FALLBACK_EMPTY
);

// this route is no longer used
// const UpdateLocation = loadable(
//     () =>
//         import(
//             /* webpackChunkName: "UpdateLocation" */ '../../components/Location/UpdateLocation/UpdateLocation'
//         ),
//     FALLBACK_EMPTY
// );

const StoryboardsManagerPage = loadable(
    () =>
        import(
            /* webpackChunkName: "StoryboardsManagerPage" */ '../../pages/StoryboardsManagerPage'
        ),
    FALLBACK_EMPTY
);
const StoryboardEditorPage = loadable(
    () => import(/* webpackChunkName: "StoryboardEditorPage" */ '../../pages/StoryboardEditorPage'),
    FALLBACK_EMPTY
);
const PanelEditorPage = loadable(
    () => import(/* webpackChunkName: "PanelEditorPage" */ '../../pages/PanelEditorPage'),
    FALLBACK_EMPTY
);
const ExternalPage = loadable(
    () => import(/* webpackChunkName: "ExternalPage" */ '../../pages/ExternalPage'),
    FALLBACK_EMPTY
);
const EmbedEditorPage = loadable(
    () => import(/* webpackChunkName: "EmbedEditorPage" */ '../../pages/EmbedEditorPage'),
    FALLBACK_EMPTY
);
const EmbedsManagerPage = loadable(
    () => import(/* webpackChunkName: "EmbedsManagerPage" */ '../../pages/EmbedsManagerPage'),
    FALLBACK_EMPTY
);

const GalleryEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "GalleryEditor" */ '@sprinklr/gallery-builder/src/components/galleryEditor/GalleryEditor'
        ),
    FALLBACK_EMPTY
);
const GalleriesManager = loadable(
    () =>
        import(
            /* webpackChunkName: "GalleriesManager" */ '@sprinklr/gallery-builder/src/components/galleriesManager/GalleriesManager'
        ),
    FALLBACK_EMPTY
);
const GalleryV2RootPage = loadable(
    () => import(/* webpackChunkName: "GalleryV2RootPage" */ '../../pages/GalleryV2RootPage'),
    FALLBACK_EMPTY
);

const PanelRendererPage = loadable(
    () => import(/* webpackChunkName: "PanelRendererPage" */ '../../pages/PanelRendererPage'),
    FALLBACK_EMPTY
);
const StoryboardPlayerPage = loadable(
    () => import(/* webpackChunkName: "StoryboardPlayerPage" */ '../../pages/StoryboardPlayerPage'),
    FALLBACK_EMPTY
);
const RemotePage = loadable(
    () => import(/* webpackChunkName: "RemotePage" */ '../../pages/RemotePage'),
    FALLBACK_EMPTY
);
const RemoteLocationsListPage = loadable(
    () =>
        import(
            /* webpackChunkName: "RemoteLocationsListPage" */ '../../pages/RemoteLocationsListPage'
        ),
    FALLBACK_SPINNER
);
const RemotePanelLensPage = loadable(
    () => import(/* webpackChunkName: "RemotePanelLensPage" */ '../../pages/RemotePanelLensPage'),
    FALLBACK_SPINNER
);
const MobileLocationsGridPage = loadable(
    () =>
        import(
            /* webpackChunkName: "MobileLocationsGridPage" */ '../../pages/MobileLocationsGridPage'
        ),
    FALLBACK_SPINNER
);
const MobilePlayerPage = loadable(
    () => import(/* webpackChunkName: "MobilePlayer" */ '../../pages/MobilePlayerPage'),
    FALLBACK_SPINNER
);
const RemoteLocationPage = loadable(
    () => import(/* webpackChunkName: "RemoteLocationPage" */ '../../pages/RemoteLocationPage'),
    FALLBACK_SPINNER
);
const RemoteIndexPage = loadable(
    () => import(/* webpackChunkName: "RemoteIndexPage" */ '../../pages/RemoteIndexPage'),
    FALLBACK_EMPTY
);
const PanelPreviewLinkPage = loadable(
    () => import(/* webpackChunkName: "PanelPreviewLinkPage" */ '../../pages/PanelPreviewLinkPage'),
    FALLBACK_EMPTY
);
const PublicURLRunnerPage = loadable(
    () => import(/* webpackChunkName: "PublicURLRunnerPage" */ '../../pages/PublicURLRunnerPage'),
    FALLBACK_EMPTY
);
const FaceDetector = loadable(
    () => import(/* webpackChunkName: "FaceDetector" */ '../../components/intuition/FaceDetector'),
    FALLBACK_EMPTY
);
const ConnectPage = loadable(
    () => import(/* webpackChunkName: "ConnectPage" */ '../../pages/ConnectPage'),
    FALLBACK_EMPTY
);
const ClearUrlPlayerPage = loadable(
    () => import(/* webpackChunkName: "ClearUrlPlayerPage" */ '../../pages/ClearUrlPlayerPage'),
    FALLBACK_EMPTY
);
const AppVersionPage = loadable(
    () => import(/* webpackChunkName: "AppVersionPage" */ '../../pages/AppVersionPage'),
    FALLBACK_EMPTY
);

export type AppProps = RouteComponentProps<any> & {
    authService?: AuthService;
    config?: EnvironmentConfig;
    location?: any;
    match?: any;
    history?: any;
};

class App extends React.Component<AppProps, {}> {
    @computed get isPresentations(): boolean {
        return this.props.config.applicationMode === 'PRESENTATIONS';
    }

    @computed get storyboardPath(): string {
        return this.isPresentations ? '/presentations' : '/storyboards';
    }

    @computed get developmentMode(): boolean {
        return !!this.props.config.developmentMode;
    }

    @computed get galleryV2Enabled(): boolean {
        const flagEnabled = this.props.authService?.isFeatureEnabled(
            FEATURE_FLAG.GALLERY_V2_ENABLED
        );
        const appModeIsEmbed = 'EMBED' === this.props.config.applicationMode;
        return flagEnabled && appModeIsEmbed;
    }

    @computed get locale() {
        return this.props.authService?.userInfo?.locale;
    }

    private updateComponentTree = () => {
        this.forceUpdate();
    };

    componentDidUpdate() {
        const currentI18nLang = i18n.language;
        const parsedParams = queryString.parse(this.props.location.search.replace('?', ''));

        // set language based on query param `lang=ja-JP` with fallback of user language
        if (!!parsedParams?.lang && currentI18nLang !== parsedParams.lang) {
            i18n.changeLanguage(parsedParams.lang, this.updateComponentTree);
        } else if (
            !parsedParams?.lang &&
            this.locale &&
            currentI18nLang !== this.locale.replace('_', '-')
        ) {
            // set language; i18n expects - instead of _
            i18n.changeLanguage(toJS(this.locale).replace('_', '-'), this.updateComponentTree);
        }
    }

    render() {
        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <Switch>
                        <Route path='/connect' component={ConnectPage} />

                        <PrivateRoute
                            exact
                            path={`/clients/:clientId${this.storyboardPath}`}
                            component={StoryboardsManagerPage}
                        />
                        <PrivateRoute
                            exact
                            path={`/clients/:clientId${this.storyboardPath}/:storyboardId`}
                            component={StoryboardEditorPage}
                        />
                        <PrivateRoute
                            exact
                            path={`/clients/:clientId${this.storyboardPath}/:storyboardId/scenes/:sceneId`}
                            component={StoryboardEditorPage}
                        />
                        <PrivateRoute
                            exact
                            path={`/clients/:clientId${this.storyboardPath}/:storyboardId/scenes/:sceneId/panels/:panelId`}
                            component={PanelEditorPage}
                        />

                        <PrivateRoute
                            exact
                            path='/clients/:clientId/embeds'
                            component={EmbedsManagerPage}
                        />
                        <PrivateRoute
                            exact
                            path='/clients/:clientId/embeds/:embedId'
                            component={EmbedEditorPage}
                        />
                        {this.galleryV2Enabled && <GalleryV2RootPage />}
                        {/* {this.galleryV2Enabled && (
                        <AuthProvider>
                            <ApolloProvider client={client}>
                                <RecoilRoot>
                                    <PrivateRoute
                                        exact
                                        path='/clients/:clientId/galleries'
                                        component={GalleriesManager}
                                    />
                                    <PrivateRoute
                                        exact
                                        path='/clients/:clientId/gallery/:embedId'
                                        component={GalleryEditor}
                                    />
                                    <GlobalStyles />
                                </RecoilRoot>
                            </ApolloProvider>
                        </AuthProvider>
                    )} */}

                        <PrivateRoute
                            exact
                            path='/clients/:clientId/locations'
                            component={DisplaysManagerPage}
                        />
                        <PrivateRoute
                            exact
                            path='/clients/:clientId/locations/:locationId'
                            component={DisplaysManagerPage}
                        />
                        {/* This doesn't work anymore */}
                        {/* <PrivateRoute
                        exact
                        path='/clients/:clientId/locations/:locationId/rename'
                        component={UpdateLocation}
                    /> */}

                        <PrivateRoute
                            exact
                            path='/clients/:clientId/remote'
                            component={RemoteIndexPage}
                        />
                        <PrivateRoute
                            exact
                            path='/clients/:clientId/remote/:locationId'
                            component={RemotePage}
                        />

                        {/* remote v2 */}
                        <PrivateRoute
                            exact
                            path='/:clientId/remote'
                            component={RemoteLocationsListPage}
                            suppressNavigation
                        />
                        <PrivateRoute
                            exact
                            path='/:clientId/remote/:locationId'
                            component={RemoteLocationPage}
                            suppressNavigation
                        />
                        <PrivateRoute
                            exact
                            path='/:clientId/remote/:locationId/:screenId'
                            component={RemotePanelLensPage}
                            suppressNavigation
                        />

                        {/* mobile */}
                        <PrivateRoute
                            exact
                            path='/mobile/:clientId/displays'
                            component={MobileLocationsGridPage}
                            suppressNavigation
                        />
                        <Route
                            exact
                            path='/mobile/:clientId/:locationId/:screenId'
                            component={MobilePlayerPage}
                        />
                        <PrivateRoute
                            exact
                            path='/mobile/:clientId/remote'
                            component={RemoteLocationsListPage}
                            suppressNavigation
                        />

                        <PrivateRoute
                            exact
                            path='/:clientId/:locationId/remote'
                            suppressNavigation={true}
                            component={RemotePage}
                        />

                        {this.developmentMode && (
                            <PrivateRoute
                                exact
                                path={`/clients/:clientId/preview${this.storyboardPath}/:storyboardId/scenes/:sceneId/panels/:panelId`}
                                component={PanelPreviewLinkPage}
                            />
                        )}

                        <Route
                            exact
                            path={`/clients/:clientId${this.storyboardPath}/:storyboardId/player`}
                            component={StoryboardPlayerPage}
                        />

                        {/* Uncomment for dev if you want to explore the API */}
                        {/*{ dev ? <Route exact path="/graphql" component={ require("spr-display-runtime/GraphiQL") }/> : null }*/}
                        <Route exact path='/render/panel' component={PanelRendererPage} />
                        <Route exact path='/clear' component={ClearUrlPlayerPage} />
                        <Route
                            exact
                            path='/external/:clientId/:locationId/:screenId'
                            component={ExternalPage}
                        />
                        <Route
                            exact
                            path='/pub/:clientId/:locationId/:screenId'
                            component={PublicURLRunnerPage}
                        />
                        <Route
                            exact
                            path='/:clientId/:locationId/:screenId'
                            component={ExternalPage}
                        />
                        <Route
                            exact
                            path='/multi/:clientId/:locationId/:screenId'
                            component={ExternalPage}
                        />
                        <Route path='/version' component={AppVersionPage} />

                        <PrivateRoute path='/' component={RootRedirect} />
                    </Switch>
                </ApolloProvider>
            </>
        );
    }
}

export default withRouter(inject('authService', 'config')(observer(App)));
