import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'components/_UI/designSystem';
import getPrettyNumber from '../../../utils/Number/NumberUtils';
import { PostStats, PostNetworkType } from '@sprinklr/stories/post/Post';
import { getNumberString } from '../Helpers';
import NativeIcon from '../Bits/NativeIcon';
import NativeFlex from '../Bits/NativeFlex';

// NativeEngagement

export type NumberFormat = {
    type: 'pretty' | 'default';
    decimals: number;
};

export type NativeEngagementProps = {
    snStats?: PostStats;
    snType?: PostNetworkType;
    numberFormat?: NumberFormat;
    hideZero?: boolean;
};

export const StyledNativeEngagement = styled(NativeFlex)`
    color: #777;
    font-size: 0.85em;
    ${NativeIcon} {
        svg {
            fill: #777;
        }
    }
`;

const engagementOrder = ['numComments', 'numRetweets', 'numLikes', 'viewCount'];

const NativeEngagement: React.FC<NativeEngagementProps> = ({
    snStats,
    snType,
    hideZero = true,
    numberFormat = { type: 'pretty', decimals: 1 },
}) => {
    const engagementItems = snStats
        ? Object.keys(snStats)
              .map(stat => {
                  const icon = getStatIcon(stat as PostStats, snType);
                  if (!icon) {
                      return;
                  }
                  return {
                      icon,
                      value: snStats[stat],
                      stat,
                  };
              })
              .filter(item => item)
              .sort((a, b) => {
                  return engagementOrder.indexOf(a.stat) - engagementOrder.indexOf(b.stat);
              })
        : [];

    if (!engagementItems.length) {
        return <div />;
    }
    return (
        <StyledNativeEngagement gap='xl' left middle noGrow style={{ flexWrap: 'wrap' }}>
            {engagementItems.map((item, index) => {
                const { value, suffix } = getPrettyNumber(item.value, numberFormat.decimals, false);
                return (
                    <React.Fragment key={index}>
                        {((hideZero && value) || !hideZero) && (
                            <NativeFlex data-id-stat={item.stat} key={index} middle gap='m' noGrow>
                                <NativeIcon value={item.icon} size='m' />
                                {value && (
                                    <>
                                        {numberFormat.type === 'pretty' && (
                                            <span data-id-number-format='pretty'>
                                                {value}
                                                {suffix}
                                            </span>
                                        )}
                                        {numberFormat.type === 'default' && (
                                            <span data-id-number-format='default'>
                                                {getNumberString(item.value)}
                                            </span>
                                        )}
                                    </>
                                )}
                            </NativeFlex>
                        )}
                    </React.Fragment>
                );
            })}
        </StyledNativeEngagement>
    );
};

NativeEngagement.displayName = 'NativeEngagement';
export default observer(NativeEngagement);

const getStatIcon = (stat: PostStats, snType: PostNetworkType): string => {
    switch (snType) {
        case 'twitter':
        case 'x':
            switch (stat) {
                case 'numRetweets':
                    return 'native_twitter_retweet';
                case 'numComments':
                    return 'native_twitter_comment';
                case 'numLikes':
                    return 'native_twitter_like';
            }
            break;
        case 'facebook':
            switch (stat) {
                case 'numLikes':
                    return 'native_facebook_like';
            }
            break;
        case 'instagram':
            switch (stat) {
                case 'numComments':
                    return 'native_twitter_comment';
                case 'numLikes':
                    return 'native_twitter_like';
            }
            break;
        case 'youtube':
            switch (stat) {
                case 'viewCount':
                    return 'preview';
                case 'numComments':
                    return 'native_twitter_comment';
                case 'numLikes':
                    return 'native_twitter_like';
            }
            break;
        default:
            switch (stat) {
                case 'viewCount':
                    return 'preview';
                case 'numRetweets':
                    return 'native_twitter_retweet';
                case 'numComments':
                    return 'native_twitter_comment';
                case 'numLikes':
                    return 'native_twitter_like';
            }
            break;
    }
};

// linkedin: [
//     {icon: 'linkedin_likes', metric: 'likes', value: likes},
//     {icon: 'linkedin_shares', metric: 'shares', value: shares},
//     {icon: 'linkedin_comments', metric: 'comments', value: comments},
// ],
