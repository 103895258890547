import { observable } from 'mobx';

export const defaultClassOverrides = {
    postText: {
        default: 'story_text_container p-reset p-0',
    },
    postInner: {
        default: 'p-reset p-0',
    },
};

export const storyCardConfig: any = {
    a: {
        smartSizeCompositions: {
            baseline: {
                space: 50,
                gridLayout: {
                    gap: 10,
                },
                storyOptions: {
                    space: 50,
                    title: {
                        size: 65,
                    },
                    meta: {
                        size: 45,
                    },
                    summary: {
                        size: 45,
                    },
                    publications: {
                        size: 45,
                    },
                    divider: {
                        size: 10,
                    },
                    metrics: {
                        size: 45,
                    },
                },
            },
            optionDefaults: {
                imagePostLineClampValue: 3,
                classOverrides: defaultClassOverrides,
            },
        },
    },
};
