import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const collageCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition) => {
    const { userGroup, postText, media, metaGroup, postTitle } = parts;
    const {
        isInterstitialMedia,
        postOuterClasses,
        postInnerClasses,
        showIconInUserGroup,
        hasMedia,
        noMedia,
        isPanelPortrait,
    } = config;

    return (
        <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
            {!isInterstitialMedia && (
                <div
                    className={classNames(`post_inner flex rel w-100 h-100 vertical`, {
                        [postInnerClasses]: true,
                    })}>
                    {media}
                    {type === 'b' && metaGroup}
                    {type === 'a' && userGroup}
                    {noMedia && postTitle}
                    {postText}
                    {type === 'b' && userGroup}
                    {type === 'a' && metaGroup}
                </div>
            )}
            {isInterstitialMedia && <div className='post_inner rel w-100 h-100'>{media}</div>}
        </div>
    );
};

export default collageCompositions;
