import { Theme } from 'models/Theme/Theme';
import { ComputedStyle, mergeThemes, styler } from 'utils/GenerateStyles/GenerateStyles';
import { toJS } from 'mobx';
import {
    generateMonochromeColors,
    generateRankedColors,
} from 'utils/GenerateColors/GenerateColors';
import { CustomTableChartWidgetOptionsImpl } from 'components/_charts/CustomTableChartWidget/options';

export function calcScale(total) {
    return 1 / total;
}

export const getThemeColors = (theme: Theme): { header: string; body: string; border: string } => {
    const output = {
        header: '',
        body: '',
        border: theme.background && theme.background.color,
    };

    if (theme && theme.colorPalette) {
        switch (theme.colorPalette.type) {
            case 'colorBlend':
                output.header = theme.colorPalette.colorBlend.startColor;
                output.body = theme.colorPalette.colorBlend.endColor;
                break;
            case 'monochrome':
                output.header = theme.colorPalette.monochrome.startColor;
                output.body = generateMonochromeColors(
                    theme.colorPalette.monochrome.startColor,
                    theme.colorPalette.monochrome.endOffset,
                    2,
                    false
                )[1];
                break;
            case 'solid':
                output.header = theme.colorPalette?.ranked?.colors
                    ? theme.colorPalette.ranked.colors[0]
                    : theme.colorPalette.solid;
                output.body = theme?.colorPalette?.ranked?.colors
                    ? generateRankedColors(
                          theme.colorPalette.ranked.colors,
                          theme.colorPalette.ranked.contrast,
                          2
                      )[1]
                    : theme.colorPalette.solid;
                break;
            case 'ranked':
                output.header = theme?.colorPalette?.ranked?.colors?.[0];
                output.body =
                    theme.colorPalette.ranked &&
                    generateRankedColors(
                        theme.colorPalette.ranked.colors,
                        theme.colorPalette.ranked.contrast,
                        2
                    )[1];
                break;
            default:
                break;
        }
    }

    return output;
};

export const customTableChartOptionStyles = (
    options: CustomTableChartWidgetOptionsImpl,
    theme: Theme,
    mergedTheme: Theme
) => {
    const { fills, contentPadding, borderSize } = options;
    const { header, body, border } = getThemeColors(mergeThemes(toJS(theme), toJS(mergedTheme)));

    const computedStyles: ComputedStyle[] = [
        {
            selector: 'thead .widget',
            styles: {
                backgroundColor: fills.header || header,
            },
        },
        {
            selector: 'tbody .widget',
            styles: {
                backgroundColor: fills.body || body,
            },
        },
        {
            selector: 'td',
            styles: {
                border: `${styler(borderSize, 0.1, 'px', '')} solid ${fills.border || border}`,
            },
        },
        {
            selector: 'th',
            styles: {
                border: `${styler(borderSize, 0.1, 'px', '')} solid ${fills.border || border}`,
            },
        },
        {
            selector: '.custom_content_component',
            styles: {
                padding: `${contentPadding * 0.1}em`,
            },
        },
        {
            selector: '.resize_handle > div',
            styles: {
                background: fills.border || border,
            },
        },
    ];

    return computedStyles;
};
