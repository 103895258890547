import { useMemo } from 'react';
import queryString from 'query-string';

const useIsStandalone = () => {
    return useMemo(() => {
        const parsed = queryString.parse(location.search);
        if (parsed?.standalone) {
            localStorage.setItem('standalone', parsed.standalone);
        }
        return JSON.parse(localStorage.getItem('standalone'));
    }, [location.search]);
};

export default useIsStandalone;
