import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable, reaction } from 'mobx';

declare const window: any;
const callbacks = [];

export interface TweetEmbedProps {
    id: string;
    onTweetLoadSuccess?: () => void;
    onTweetLoadError?: (ref) => void;
    protocol?: 'https:' | 'https';
    className?: string;
    maxWidth?: number;
    hideThread?: boolean;
}

class TweetEmbed extends React.Component<TweetEmbedProps, any> {
    static defaultProps = {
        protocol: 'https:',
        className: null,
        hideThread: true,
    };

    private node = React.createRef<HTMLDivElement>();

    static addScript(src, cb) {
        if (callbacks.length === 0) {
            callbacks.push(cb);
            const s = document.createElement('script');
            s.setAttribute('src', src);
            s.onload = () => {
                return callbacks.forEach(cb => {
                    return cb();
                });
            };
            document.body.appendChild(s);
        } else {
            callbacks.push(cb);
        }
    }

    @observable div: any;
    @observable twitterReady = false;
    twitterRendered = false;
    unwatchWidth: any;
    unwatchProps: any;

    // @computed get oembedUrl() {
    //     const { id, maxWidth, hideThread } = this.props;
    //     const isLocal = window.location.protocol.indexOf('file') >= 0;
    //     const protocol = isLocal ? this.props.protocol : '';
    //
    //     return `${protocol}//publish.twitter.com/oembed?url=${encodeURIComponent(id)}${maxWidth ? `&maxwidth=${maxWidth}` : ''}${hideThread ? '&hide_thread=true' : ''}&omit_script=true&dnt=true`;
    // }

    renderTweet = () => {
        if (!this.node.current || !this.twitterReady || this.twitterRendered) {
            return;
        }

        const widgets = window.twttr.widgets;
        const { maxWidth, id, onTweetLoadSuccess, onTweetLoadError, hideThread } = this.props;

        widgets
            .createTweetEmbed(id, this.node.current, {
                maxwidth: maxWidth,
                width: maxWidth,
                id,
                hide_thread: hideThread ? 'true' : 'false',
                omit_script: 'true',
                dnt: 'true',
            })
            .then(onTweetLoadSuccess)
            .catch(onTweetLoadError);

        this.twitterRendered = true;
    };

    componentDidMount() {
        this.checkTwitterScript();

        this.unwatchProps = reaction(
            () => {
                return `${!!this.node.current}|${this.twitterReady}|${this.props.hideThread}|${
                    this.props.protocol
                }`;
            },
            this.renderTweet,
            {
                fireImmediately: false,
            }
        );

        this.unwatchWidth = reaction(
            () => {
                return this.node.current && this.twitterReady && this.props.maxWidth;
            },
            () => {
                if (!this.node.current || !this.props.maxWidth) {
                    return;
                }

                const widget = this.node.current.querySelector('.twitter-tweet');
                if (widget) {
                    (widget as HTMLElement).style.width = this.props.maxWidth + 'px';
                    if (this.props.onTweetLoadSuccess) {
                        setTimeout(this.props.onTweetLoadSuccess);
                    }
                }
            },
            {
                fireImmediately: false,
            }
        );
    }

    componentWillUnmount() {
        this.unwatchWidth();
        this.unwatchProps();
    }

    @action
    checkTwitterScript() {
        if (window.twttr && window.twttr.ready) {
            this.twitterReady = true;
        } else {
            const isLocal = window.location.protocol.indexOf('file') >= 0;
            const protocol = isLocal ? this.props.protocol : '';

            TweetEmbed.addScript(protocol + '//platform.twitter.com/widgets.js', () => {
                window.twttr.ready().then(
                    action(() => {
                        this.twitterReady = true;
                    })
                );
            });
        }
    }

    render() {
        if (this.twitterReady) {
            this.renderTweet();
        }
        return <div className={this.props.className} ref={this.node} />;
    }
}

export default observer(TweetEmbed);
