import * as React from 'react';
import { observer } from 'mobx-react';
import { Textfit } from 'react-textfit';
import { TextFormat } from '@sprinklr/stories/widget/types';
import './PostTitle.scss';

interface PostTitleProps {
    children: React.ReactNode;
    classes?: string;
    format: TextFormat;
}

const wordClampStyles: any = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
};

const PostTitle: React.FC<PostTitleProps> = ({ children, classes, format }): React.ReactElement => {
    return (
        <div
            className={`post_title_container no-grow format_${format.type} flex ${
                classes ? classes : ''
            }`}>
            {format.type === 'clamp' && (
                <div style={wordClampStyles} className={`post_title clamp-${format.clamp.lines}`}>
                    {children}
                </div>
            )}
            {format.type === 'fit' && (
                <Textfit className='post_title flex' min={format.fit.min} max={format.fit.max}>
                    {children}
                </Textfit>
            )}
        </div>
    );
};

PostTitle.displayName = 'PostTitle';
export default observer(PostTitle);
