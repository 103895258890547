import { observable } from 'mobx';
import { FontImpl } from 'models/Theme/Theme';
import { HeaderDateFormats, HeaderDateProps } from './types';

export class HeaderDateImpl implements HeaderDateProps {
    @observable enabled = true;
    @observable color = '';
    @observable size = 16;
    @observable format: HeaderDateFormats = 'Pretty Time';
}
