import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { PostLocation } from '@sprinklr/stories/post/Post';
import { PostOptionLocation } from 'src/widgets/PostsWindowWidget/types';
import { PostsLocation, getTimeStampLocationLockup } from 'utils/PostUtils/PostUtils';

type MetaGroupProps = {
    postChannelClasses?: string;
    channel?: string;
    socialIconBgColor?: boolean;
    showTimeStamp?: boolean;
    showIcon?: boolean;
    snCreatedTime?: number;
    timeFormat?: string;
    endianTime?: string;
    outerClass?: string;
    showEngagement?: boolean;
    showAdsMetrics?: boolean;
    numLikes?: number;
    numComments?: number;
    numShares?: number;
    numRetweets?: number;
    engagementClasses?: string;
    engagement?: JSX.Element;
    ads?: JSX.Element;
    timestamp?: JSX.Element;
    icon?: JSX.Element;
    extraPostDataTags: JSX.Element;
    location?: PostLocation;
    locationOptions?: PostOptionLocation;
};

const MetaGroup: React.FC<MetaGroupProps> = ({
    showTimeStamp,
    showEngagement,
    showAdsMetrics,
    engagementClasses,
    engagement,
    ads,
    timestamp,
    icon,
    showIcon,
    location,
    locationOptions,
    extraPostDataTags,
}) => {
    if (!showAdsMetrics && !showEngagement && !showIcon && !showTimeStamp) {
        return <div />;
    }

    const locationInfo = locationOptions?.enabled && (
        <PostsLocation key='post location' location={location} />
    );
    const timestampLocation = getTimeStampLocationLockup(showTimeStamp && timestamp, locationInfo);

    return (
        <div
            className={classNames(`meta_group flex ${engagementClasses} no-grow middle z-1 `, {
                'has_engagement between w100': showEngagement,
                'no_engagement right': !showEngagement,
                has_social_icon: showIcon,
                no_social_icon: !showIcon,
            })}>
            {engagement}
            {ads}
            {extraPostDataTags}

            {showTimeStamp && showIcon && (
                <div className='post_meta_container flex middle no-grow'>
                    {[timestampLocation, icon]}
                </div>
            )}

            {showTimeStamp && !showIcon && timestampLocation}

            {!showTimeStamp && showIcon && [timestampLocation, icon]}
        </div>
    );
};
export default observer(MetaGroup);
