const defaultClassOverrides = {
    postInner: {
        default: 'between',
        review: '',
    },
    postText: {
        default: 'p-reset pl-15 pr-15 pt-15 pb-05 ta-c',
        review: 'p-reset pl-2 pr-2 pt-05 pb-05 h50',
        showTitle: 'p-reset pl-15 pr-15 pt-05 pb-05 ta-c no-grow h50',
    },
    userGroup: {
        default: 'p-reset p-15',
        review: 'p-reset pl-2 w-a',
    },
    metaGroup: {
        default: 'p-reset pt-15 pl-15 pr-15 pb-0',
    },
    reviewDomain: {
        default: 'mr-2',
    },
    reviewRating: {
        default: 'pl-2 pb-2 pt-2',
    },
    postTitle: {
        default: 'p-reset pl-15 pr-15 pt-05 pb-2 ta-c no-grow middle h40',
    },
};

const gridSmallStuff = {
    options: {
        imagePostLineClampValue: 4,
        postLineClampValue: 4,
        classOverrides: {
            ...defaultClassOverrides,
            postText: {
                default: 'p-reset pl-05 pr-05 pt-05 pb-05 ta-c',
                review: 'p-reset pl-05 pr-05 pt-05 pb-05 h50',
            },
            userGroup: {
                default: 'p-reset p-05',
                review: 'p-reset pl-05 w-a',
            },
            metaGroup: {
                default: 'p-reset pt-05 pl-05 pr-05 pb-0',
                showTitle: 'p-reset pt-05 pl-05 pr-05 pb-0 h10',
            },
            reviewDomain: {
                default: 'mr-05',
            },
            reviewRating: {
                default: 'pl-05 pb-05 pt-05',
            },
            engagementMetric: {
                default: 'm-reset ml-05',
            },
            postTitle: {
                default: 'p-reset pl-05 pr-05 pt-05 pb-05 ta-c h40',
            },
        },
    },
};
const gridSmartSize = {
    baseline: {
        postTextOnlySize: 17,
        postImageTextSize: 17,
        engagementMetricsSize: 20,
        userGroupIconSize: 14,
        userGroupAvatarSize: 20,
        userGroupNameSize: 14,
        userGroupScreenNameSize: 11,
        nativeStyling: {
            fontScale: 40,
        },
        retweetIndicator: {
            size: 11,
        },
    },
    optionDefaults: {
        optionDefaults: {
            imagePostLineClampValue: 5,
            postLineClampValue: 5,
        },
        classOverrides: defaultClassOverrides,
    },
    breakpoints: {
        m: {
            options: {
                classOverrides: {
                    ...defaultClassOverrides,
                    postText: {
                        default: 'p-reset pl-1 pr-1 pt-1 pb-05 ta-c',
                        review: 'p-reset pl-1 pr-1 pt-05 pb-05 h50',
                        showTitle: 'p-reset pl-1 pr-1 pt-05 pb-05 h50',
                    },
                    userGroup: {
                        default: 'p-reset p-1',
                        review: 'p-reset pl-1 w-a',
                    },
                    metaGroup: {
                        default: 'p-reset pt-1 pl-1 pr-1 pb-0',
                    },
                    reviewDomain: {
                        default: 'mr-1',
                    },
                    reviewRating: {
                        default: 'pl-1 pb-1 pt-1',
                    },
                    engagementMetric: {
                        default: 'm-reset ml-1',
                    },
                    postTitle: {
                        default: 'p-reset pl-1 pr-1 pt-1 pb-05 ta-c h30',
                    },
                },
            },
        },
        s: gridSmallStuff,
        xs: gridSmallStuff,
    },
};

export const gridConfig = {
    a: {
        userGroupLayoutType: 'd',
        userGroupPosition: 'bottom',
        showIconInUserGroup: false,

        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 5,
        postTextOnlySize: 17,

        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: null,

        imagePostHideText: true,
        imagePostLineClamp: true,
        imagePostLineClampValue: 5,
        postImageTextFormat: 'clamp',
        postImageTextSize: 17,

        engagementMetricsSize: 20,

        userGroupIconSize: 14,
        userGroupAvatarSize: 20,
        userGroupNameSize: 14,
        userGroupScreenNameSize: 11,
        retweetIndicator: {
            size: 11,
        },

        classOverrides: defaultClassOverrides,
        smartSizeCompositions: gridSmartSize,
    },
    b: {
        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 5,
        postTextOnlySize: 17,

        userGroupIconSize: 14,
        userGroupAvatarSize: 20,

        imagePostHideText: true,
        imagePostLineClamp: true,
        imagePostLineClampValue: 5,
        postImageTextFormat: 'clamp',
        postImageTextSize: 17,

        engagementMetricsSize: 20,

        userGroupLayoutType: 'a',
        userGroupPosition: 'top',
        userGroupNameSize: 15,
        userGroupScreenNameSize: 11,
        retweetIndicator: {
            size: 11,
        },
        showIconInUserGroup: true,

        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: null,

        classOverrides: {
            postText: {
                default: 'p-reset pl-15 pr-15 pt-05 pb-05 f-order-i',
                review: 'p-reset pl-2 pr-2 pt-05 pb-05 h50',
            },
            postInner: {
                default: 'between',
            },
            userGroup: {
                default: 'p-reset p-15',
                review: 'p-reset pl-2 w-a',
            },
            metaGroup: {
                default: 'p-reset p-15',
            },
            reviewDomain: {
                default: 'mr-2',
            },
            reviewRating: {
                default: 'ml-2 mt-2',
            },
            postTitle: {
                default: 'p-reset pl-15 pr-15 pt-05 pb-05 h30',
            },
        },
        smartSizeCompositions: gridSmartSize,
    },
};
