import { defaultSmartSize } from './SharedConfig';

const defaultClassOverrides = {
    postText: {
        default: 'p-reset pr-2 pl-2 pt-0',
    },
    postInner: {
        default: 'between',
    },
    metaGroup: {
        default: 'p-reset pb-2 pt-1 pl-2 pr-2',
    },
    userGroup: {
        default: 'no-grow p-reset pb-2 pr-2 pl-2 pt-1',
    },
    postTitle: {
        default: 'p-rest pr-2 pl-2 pb-1',
    },
};

export const collageConfig = {
    a: {
        userGroupLayoutType: 'a',
        showUserScreenName: true,
        showIconInUserGroup: true,
        imagePostHideText: true,
        userGroupPosition: 'top',

        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: null,

        engagementMetricsSize: 20,

        userGroupIconSize: 10,
        userGroupAvatarSize: 12,
        userGroupNameSize: 17,
        userGroupScreenNameSize: 12,
        retweetIndicator: {
            size: 12,
        },
        userGroupGradientDirection: 'topToBottom',
        classOverrides: defaultClassOverrides,
        smartSizeCompositions: {
            baseline: {
                postTextOnlySize: 20,
                userGroupIconSize: 10,
                userGroupNameSize: 17,
                userGroupScreenNameSize: 12,
                userGroupAvatarSize: 12,
                engagementMetricsSize: 20,
                retweetIndicator: {
                    size: 12,
                },
            },
            optionDefaults: {
                classOverrides: defaultClassOverrides,
            },
            breakpoints: {
                m: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postText: {
                                default: 'p-reset pr-1 pl-1 pt-0',
                            },
                            metaGroup: {
                                default: 'p-reset pb-1 pt-05 pl-1 pr-1',
                            },
                            userGroup: {
                                default: 'no-grow p-reset pb-1 pr-1 pl-1 pt-05',
                            },
                            postTitle: {
                                default: 'p-reset pr-1 pl-1 pb-1',
                            },
                        },
                    },
                },
                s: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postText: {
                                default: 'p-reset pr-05 pl-05 pt-0',
                            },
                            metaGroup: {
                                default: 'p-reset pb-05 pt-05 pl-05 pr-05',
                            },
                            userGroup: {
                                default: 'no-grow p-reset pb-05 pr-05 pl-05 pt-05',
                            },
                            postTitle: {
                                default: 'p-reset pr-05 pl-05 pb-1',
                            },
                        },
                    },
                },
                xs: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postText: {
                                default: 'p-reset pr-05 pl-05 pt-0',
                            },
                            metaGroup: {
                                default: 'p-reset pb-05 pt-05 pl-05 pr-05',
                            },
                            userGroup: {
                                default: 'no-grow p-reset pb-05 pr-05 pl-05 pt-05',
                            },
                            postTitle: {
                                default: 'p-reset pr-05 pl-05 pb-1',
                            },
                        },
                    },
                },
            },
        },
    },
    b: {
        userGroupLayoutType: 'c',
        showUserScreenName: false,
        showIconInUserGroup: false,
        imagePostHideText: true,
        userGroupPosition: 'bottom',

        postLineClampValue: 5,
        postTextOnlySize: 22,

        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: null,

        engagementMetricsSize: 20,

        userGroupIconSize: 10,
        userGroupAvatarSize: 12,
        userGroupNameSize: 17,
        userGroupScreenNameSize: 12,
        retweetIndicator: {
            size: 12,
        },
        userGroupGradientDirection: 'bottomToTop',
        classOverrides: {
            postText: {
                default: 'p-reset pr-2 pl-2 pt-0',
            },
            postInner: {
                default: 'between',
            },
            metaGroup: {
                default: 'p-reset pt-2 pb-1 pl-2 pr-2',
            },
            userGroup: {
                default: 'no-grow p-reset pb-1 pr-2 pl-2 pt-2',
            },
            postTitle: {
                default: 'p-reset pr-2 pl-2 pb-1',
            },
        },
        smartSizeCompositions: {
            ...defaultSmartSize,
        },
    },
};
