import { useMemo } from 'react';

export function useNumTicks({
    height,
    yAxisNumTicks,
}: {
    height: number;
    yAxisNumTicks: number | null;
}): { numTicksForHeight: number } {
    const numTicksForHeight = useMemo((): number => {
        // possibly a better check for this?
        if (yAxisNumTicks) {
            return yAxisNumTicks;
        }
        if (height <= 300) {
            return 4;
        }
        if (300 < height && height <= 600) {
            return 5;
        }
        return 10;
    }, [height, yAxisNumTicks]);

    return { numTicksForHeight };
}
