import { observable } from 'mobx';
import { HeaderTitleProps } from './types';

export class HeaderTitleImpl implements HeaderTitleProps {
    @observable enabled = true;
    @observable content = 'Title';
    @observable inheritContent = true;
    @observable inheritFrom = 'sceneName';
    @observable sourceName = '';
    @observable size = 24;
    @observable color = '';
}

export class HeaderSubTitleImpl implements HeaderTitleProps {
    @observable enabled = false;
    @observable content = 'Subtitle';
    @observable inheritContent = false;
    @observable inheritFrom = null;
    @observable sourceName = '';
    @observable size = 16;
    @observable color = '';
}
