import React from 'react';
import moment from 'moment';
import isValid from 'date-fns/isValid';

// types
import { AdditionalDetailProps } from './types';
import { FieldDataType } from '@sprinklr/stories/reporting/types';

// components
import Metric from 'components/_charts/Metric/Metric';

// utils
import { getCurrencyString } from 'utils/Currency/Currency';

function AdditionalDetail({ field, dateFormat, format }: AdditionalDetailProps) {
    const isNumber = typeof field.value === 'number';
    const isHHMMTime =
        !isNumber && field.value?.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/) !== null;
    const parsedDate = moment(new Date(parseInt(field.value, 10)));
    const isDate =
        !isNumber &&
        isValid(parseInt(field.value, 10)) &&
        parseInt(field.value, 10) + '' === field.value + '' &&
        parsedDate.year() > 1999 &&
        parsedDate.year() < 2099;
    const formattedDate = isDate && parsedDate.format(dateFormat);

    return (
        <>
            {!isDate && isNumber && (
                <Metric
                    isPercentage={
                        field.dataType === 'PERCENTAGE' || field.dataType === 'PERCENTAGE_CHANGE'
                    }
                    countUp={false}
                    metricClasses='extra_post_data_value'
                    layoutType='valueOnly'
                    alignment='left'
                    value={Number(field.value)}
                    isPrettyNumber={format !== '1,234'}
                    valueFormat={format}
                    valuePrefix={getCurrencyString(field.dataType as FieldDataType)}
                />
            )}
            {isDate && !isHHMMTime && (
                <div className='extra_post_data_value ta-r w-100'>{formattedDate}</div>
            )}
            {(isHHMMTime || (!isDate && !isNumber)) && (
                <div className='extra_post_data_value ta-r w-100'>
                    {field.value instanceof Array ? field.value.join(', ') : field.value}
                </div>
            )}
        </>
    );
}

export { AdditionalDetail };
