export class BreakableRefCallback {
    constructor(private callback: any) {}

    public invoke = (arg: any) => {
        if (this.callback && typeof this.callback === 'function') {
            this.callback(arg);
            // } else {
            //     console.log("cannot invoke, ref broken. this is not an error.");
        }
    };

    public breakRef(): void {
        this.callback = null;
    }
}
