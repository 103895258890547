import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const singlePostCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition): JSX.Element => {
    const {
        userGroup,
        postText,
        media,
        metaGroup,
        icon,
        wrappedIcon,
        engagementMetrics,
        postComments,
        inReplyToPost,
        postTitle,
    } = parts;
    const {
        isInterstitialMedia,
        postOuterClasses,
        hasMedia,
        noMedia,
        isPanelPortrait,
        postLayoutOrientation,
        showComments,
        showReplyLabels,
    } = config;

    switch (type) {
        case 'a':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    {!!inReplyToPost && (
                        <>
                            {showReplyLabels && (
                                <div className='post_reply_header primary_font_family mt-1 mb-05'>
                                    Original
                                </div>
                            )}
                            <div
                                className={`post_reply_wrap flex h-50 w-100 rel vertical`}
                                key='postInReplyToWrap'>
                                {inReplyToPost}
                            </div>
                        </>
                    )}
                    <div
                        className={classNames(`post_inner flex rel ${postLayoutOrientation}`, {
                            'vertical p2': noMedia && !isInterstitialMedia,
                            'vertical h-100': isPanelPortrait && !inReplyToPost,
                            'vertical w-50 h-100': showComments && !isPanelPortrait,
                            'vertical h-50': showComments && isPanelPortrait,
                            'h-50 w-100 mt-1': !!inReplyToPost,
                            vertical: !!inReplyToPost && isPanelPortrait,
                        })}>
                        {!!inReplyToPost && showReplyLabels && (
                            <div className='post_reply_header primary_font_family mb-05'>Reply</div>
                        )}
                        {hasMedia &&
                            !isInterstitialMedia && [
                                <div
                                    key='mediaContainer'
                                    className={classNames(`media_group flex rel`, {
                                        'w-50': !isPanelPortrait && !showComments,
                                        'h-50': isPanelPortrait || showComments,
                                    })}>
                                    {media}
                                </div>,
                                <div
                                    key='textContainer'
                                    className={classNames(`text_group flex vertical`, {
                                        'w-50 h-100': !isPanelPortrait && !showComments,
                                        'h-50': isPanelPortrait || showComments,
                                    })}>
                                    {userGroup}

                                    <div
                                        className={`post_text_engagement_group flex vertical bottom`}>
                                        {[metaGroup, postTitle, postText]}
                                    </div>
                                </div>,
                            ]}

                        {noMedia &&
                            !isInterstitialMedia && [userGroup, postTitle, postText, metaGroup]}

                        {isInterstitialMedia && media}
                    </div>

                    {showComments && (
                        <div
                            className={`flex ${isPanelPortrait ? 'h-50' : 'w-50'}`}
                            key='postCommentWrap'>
                            {postComments}
                        </div>
                    )}
                </div>
            );
        case 'b':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    {!!inReplyToPost && (
                        <>
                            <div className='post_reply_header primary_font_family mt-1 mb-05'>
                                Original
                            </div>
                            <div
                                className={`post_reply_wrap flex h-50 w-100 rel vertical`}
                                key='postInReplyToWrap'>
                                {inReplyToPost}
                            </div>
                        </>
                    )}
                    <div
                        className={classNames(`post_inner flex rel ${postLayoutOrientation}`, {
                            'vertical p2': noMedia && !isInterstitialMedia,
                            'vertical h-100': isPanelPortrait && !inReplyToPost,
                            'vertical w-50': showComments && !isPanelPortrait,
                            'vertical h-50': showComments && isPanelPortrait,
                            'h-50 w-100': !!inReplyToPost,
                            vertical: !!inReplyToPost && isPanelPortrait,
                        })}>
                        {!!inReplyToPost && showReplyLabels && (
                            <div className='post_reply_header primary_font_family mb-05'>Reply</div>
                        )}
                        {showComments && (
                            <div
                                className={`flex ${isPanelPortrait ? 'h-50' : 'w-50'}`}
                                key='postCommentWrap'>
                                {postComments}
                            </div>
                        )}
                        {hasMedia &&
                            !isInterstitialMedia && [
                                <div
                                    className={classNames(`media_group flex rel top`, {
                                        'w-50': !isPanelPortrait && !showComments,
                                        'h-50': isPanelPortrait || showComments,
                                    })}
                                    key='mediaContainer'>
                                    {media}
                                    {metaGroup}
                                </div>,
                                <div
                                    className={classNames(`text_group flex vertical`, {
                                        'w-50': !isPanelPortrait && !showComments,
                                        'h-50': isPanelPortrait || showComments,
                                    })}
                                    key='textContainer'>
                                    {isPanelPortrait
                                        ? [userGroup, postTitle, postText]
                                        : [postTitle, postText, userGroup]}
                                </div>,
                            ]}

                        {noMedia &&
                            !isInterstitialMedia && [metaGroup, postTitle, postText, userGroup]}

                        {isInterstitialMedia && media}
                    </div>

                    {showComments && (
                        <div
                            className={`flex ${isPanelPortrait ? 'h-50' : 'w-50'}`}
                            key='postCommentWrap'>
                            {postComments}
                        </div>
                    )}
                </div>
            );
        case 'c':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    {!!inReplyToPost && (
                        <>
                            <div className='post_reply_header primary_font_family mt-1 mb-05'>
                                Original
                            </div>
                            <div
                                className={`post_reply_wrap flex h-50 w-100 rel vertical`}
                                key='postInReplyToWrap'>
                                {inReplyToPost}
                            </div>
                        </>
                    )}
                    <div
                        className={classNames(`post_inner flex rel ${postLayoutOrientation}`, {
                            'vertical p2': noMedia && !isInterstitialMedia,
                            'vertical h-100': isPanelPortrait && !inReplyToPost,
                            'vertical w-50': showComments && !isPanelPortrait,
                            'vertical h-50': showComments && isPanelPortrait,
                            'h-50 w-100': !!inReplyToPost,
                            vertical: !!inReplyToPost && isPanelPortrait,
                        })}>
                        {!!inReplyToPost && showReplyLabels && (
                            <div className='post_reply_header primary_font_family mb-05'>Reply</div>
                        )}
                        {hasMedia &&
                            !isInterstitialMedia && [
                                <div
                                    className={classNames(`text_group flex vertical`, {
                                        'w-40': !isPanelPortrait && !showComments,
                                        'h-40 f-order-1': isPanelPortrait || showComments,
                                    })}
                                    key='textContainer'>
                                    {!isPanelPortrait && userGroup}
                                    {postTitle}
                                    {postText}
                                    {!isPanelPortrait && engagementMetrics}
                                    {isPanelPortrait && metaGroup}
                                </div>,
                                <div
                                    className={classNames(`media_group flex rel`, {
                                        'w-60': !isPanelPortrait && !showComments,
                                        'h-60 f-order-0 top': isPanelPortrait || showComments,
                                    })}
                                    key='mediaContainer'>
                                    {media}
                                    {isPanelPortrait && userGroup}
                                    {!isPanelPortrait && wrappedIcon}
                                </div>,
                            ]}

                        {noMedia &&
                            !isInterstitialMedia && [userGroup, postTitle, postText, metaGroup]}

                        {isInterstitialMedia && media}
                    </div>
                    {showComments && (
                        <div
                            className={`flex ${isPanelPortrait ? 'h-50' : 'w-50'}`}
                            key='postCommentWrap'>
                            {postComments}
                        </div>
                    )}
                </div>
            );
        default:
            return <div></div>;
    }
};

export default singlePostCompositions;
