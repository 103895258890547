import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import NativeIcon from '../Bits/NativeIcon';
import NativeFlex from '../Bits/NativeFlex';
import { Avatar } from 'components/spaceKit';
import { NativeColorMode } from '../../../widgets/PostsWidget/types';
import { getNativeColor } from '../Helpers';
import { getLocationString, hasValidLocation } from 'utils/PostUtils/PostUtils';
import { PostLocation } from '@sprinklr/stories/post/Post';
import { getChannelColor } from 'components/_UI/designSystem/helpers';

// NativeProfileLockup

const colors = {
    darkGray: '#222',
    lightGray: '#777',
    lighterGrey: '#bababd',
};

const theme = {
    light: {
        userName: colors.darkGray,
        secondary: colors.lightGray,
    },
    dark: {
        userName: colors.lighterGrey,
        secondary: colors.lightGray,
    },
};

export type NativeProfileLockupProps = {
    colorMode?: NativeColorMode;
    profileImgUrl?: string;
    primary?: string;
    secondary?: any;
    tertiary?: string;
    snType?: string;
    snCreatedTime?: number;
    verified?: boolean;
    pad?: any;
    showRetweet?: boolean;
    clickableUrls?: boolean;
    permalink?: string;
};
export const StyledNativeProfileLockup = styled(NativeFlex)``;

type StyledPostProps = {
    colorMode: NativeColorMode;
};
export const StyledTimeStamp = styled.div`
    font-size: 0.7em;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'secondary')};
        `}
`;

export const StyledLocation = ({
    colorMode,
    location,
}: {
    colorMode: any;
    location: PostLocation;
}) =>
    hasValidLocation(location) && (
        <React.Fragment key='locationInfo'>
            <StyledTimeStamp colorMode={colorMode}>•</StyledTimeStamp>
            <StyledTimeStamp colorMode={colorMode}>{getLocationString(location)}</StyledTimeStamp>
        </React.Fragment>
    );

export const StyledUserName = styled.div`
    font-weight: bold;
    font-size: 0.9em;
    line-height: 1.2;
    word-break: break-word;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'userName')};
        `}
`;
export const StyledSecondaryName = styled.div`
    font-size: 0.7em;
    line-height: 1.2;
    word-break: break-word;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'secondary')};
        `}
`;
const StyledAvatar = styled(Avatar)`
    height: 2.5em;
    width: 2.5em;
`;

const NativeProfileLockup: React.FC<NativeProfileLockupProps> = ({
    profileImgUrl,
    primary,
    secondary,
    tertiary,
    showRetweet,
    snType,
    snCreatedTime,
    verified,
    pad,
    colorMode,
    clickableUrls,
    permalink,
}) => {
    const showProfileInfo = primary || secondary || (showRetweet && tertiary);
    const profile = (
        <StyledNativeProfileLockup
            between
            middle
            noGrow
            gap='m'
            pad={pad}
            right={!profileImgUrl && !primary && !secondary}>
            {profileImgUrl && <StyledAvatar user={{ url: profileImgUrl }} />}
            {showProfileInfo && (
                <NativeFlex between middle vertical>
                    {primary && (
                        <NativeFlex middle noGrow gap='s'>
                            <StyledUserName colorMode={colorMode}>{primary}</StyledUserName>
                            {verified && <NativeIcon size='m' value='native_twitter_verified' />}
                        </NativeFlex>
                    )}
                    {secondary && (
                        <StyledSecondaryName colorMode={colorMode}>{secondary}</StyledSecondaryName>
                    )}
                    {showRetweet && tertiary && (
                        <NativeFlex middle noGrow gap='s'>
                            <NativeIcon
                                size='s'
                                value={'twitter_retweet'}
                                color={getNativeColor(theme, colorMode, 'secondary')}
                            />
                            <StyledSecondaryName
                                colorMode={
                                    colorMode
                                }>{`Repost of ${tertiary}`}</StyledSecondaryName>
                        </NativeFlex>
                    )}
                </NativeFlex>
            )}
            {snType && (
                <NativeIcon
                    size='l'
                    value={snType === 'facebook' ? 'facebook_filled' : snType}
                    color={getChannelColor({ channel: snType })}
                />
            )}
        </StyledNativeProfileLockup>
    );

    if (clickableUrls && permalink) {
        return <a href={permalink}>{profile}</a>;
    }
    return profile;
};

NativeProfileLockup.displayName = 'NativeProfileLockup';
export default observer(NativeProfileLockup);
