const philippinesCitiesByProvince = {
    '1401': {
        cities: [
            'Bangued',
            'Boliney',
            'Bucay',
            'Bucloc',
            'Daguioman',
            'Danglas',
            'Dolores',
            'La Paz',
            'Lacub',
            'Lagangilang',
            'Lagayan',
            'Langiden',
            'Licuan-Baay (Licuan)',
            'Luba',
            'Malibcong',
            'Manabo',
            'Peñarrubia',
            'Pidigan',
            'Pilar',
            'Sallapadan',
            'San Isidro',
            'San Juan',
            'San Quintin',
            'Tayum',
            'Tineg',
            'Tubo',
            'Villaviciosa',
        ],
        code: '1401',
        name: 'Abra',
    },
    '1602': {
        cities: [
            'Agusan',
            'Butuan',
            'Cabadbaran',
            'Carmen',
            'Jabonga',
            'Kitcharao',
            'Las Nieves',
            'Magallanes',
            'Nasipit',
            'Remedios T. Romualdez',
            'Santiago',
            'Tubay',
        ],
        code: '1602',
        name: 'Agusan del Norte',
    },
    '1603': {
        cities: [
            'Bayugan',
            'Bunawan',
            'Esperanza',
            'La Paz',
            'Loreto',
            'Prosperidad',
            'Rosario',
            'San Francisco',
            'San Luis',
            'Santa Josefa',
            'Sibagat',
            'Talacogon',
            'Trento',
            'Veruela',
        ],
        code: '1603',
        name: 'Agusan del Sur',
    },
    '0604': {
        cities: [
            'Lupo',
            'Altavas',
            'Balete',
            'Banga',
            'Batan',
            'Buruanga',
            'Ibajay',
            'Kalibo',
            'Lezo',
            'Libacao',
            'Madalag',
            'Makato',
            'Malay',
            'Malinao',
            'Nabas',
            'New Washington',
            'Numancia',
            'Tangalan',
        ],
        code: '0604',
        name: 'Aklan',
    },
    '0505': {
        cities: [
            'Legaspi',
            'Legazpi City',
            'Bacacay',
            'Camalig',
            'Daraga (Locsin)',
            'Guinobatan',
            'Jovellar',
            'Legazpi',
            'Libon',
            'Ligao',
            'Malilipot',
            'Malinao',
            'Manito',
            'Oas',
            'Pio Duran',
            'Polangui',
            'Rapu-Rapu',
            'Santo Domingo',
            'Tabaco',
            'Tiwi',
        ],
        code: '0505',
        name: 'Albay',
    },
    '0606': {
        cities: [
            'Nato',
            'Anini-y',
            'Barbaza',
            'Belison',
            'Bugasong',
            'Caluya',
            'Culasi',
            'Hamtic',
            'Laua-an',
            'Libertad',
            'Pandan',
            'Patria',
            'Patnongon',
            'San Jose de Buenavista',
            'San Remigio',
            'Sebaste',
            'Sibalom',
            'Tibiao',
            'Tobias Fornier (Dao)',
            'Valderrama',
        ],
        code: '0606',
        name: 'Antique',
    },
    '1481': {
        cities: ['Calanasan', 'Conner', 'Flora', 'Kabugao', 'Luna', 'Pudtol', 'Santa Marcela'],
        code: '1481',
        name: 'Apayao',
    },
    '0377': {
        cities: [
            'Simbahan',
            'Baler',
            'Casiguran',
            'Dilasag',
            'Dinalungan',
            'Dingalan',
            'Dipaculao',
            'Maria Aurora',
            'San Luis',
        ],
        code: '0377',
        name: 'Aurora',
    },
    '1507': {
        cities: [
            'Akbar',
            'Al-Barka',
            'Hadji Mohammad Ajul',
            'Hadji Muhtamad',
            'Isabela City',
            'Lamitan',
            'Lantawan',
            'Maluso',
            'Sumisip',
            'Tabuan-Lasa',
            'Tipo-Tipo',
            'Tuburan',
            'Ungkaya Pukan',
        ],
        code: '1507',
        name: 'Basilan',
    },
    '0308': {
        cities: [
            'Abucay',
            'Bagac',
            'Balanga',
            'Dinalupihan',
            'Hermosa',
            'Limay',
            'Mariveles',
            'Morong',
            'Orani',
            'Orion',
            'Pilar',
            'Samal',
        ],
        code: '0308',
        name: 'Bataan',
    },
    '0209': {
        cities: ['Basco', 'Itbayat', 'Ivana', 'Mahatao', 'Sabtang', 'Uyugan'],
        code: '0209',
        name: 'Batanes',
    },
    '0410': {
        cities: [
            'Aya',
            'Agoncillo',
            'Alitagtag',
            'Balayan',
            'Balete',
            'Batangas',
            'Batangas City',
            'Bauan',
            'Calaca',
            'Calatagan',
            'Cuenca',
            'Ibaan',
            'Laurel',
            'Lemery',
            'Lian',
            'Lipa',
            'Lipa City',
            'Lobo',
            'Mabini',
            'Malvar',
            'Mataasnakahoy',
            'Nasugbu',
            'Padre Garcia',
            'Rosario',
            'San Jose',
            'San Juan',
            'San Luis',
            'San Nicolas',
            'San Pascual',
            'Santa Teresita',
            'Santo Tomas',
            'Solo',
            'Taal',
            'Talisay',
            'Tanauan',
            'Taysan',
            'Tingloy',
            'Tuy',
        ],
        code: '0410',
        name: 'Batangas',
    },
    '1411': {
        cities: [
            'Atok',
            'Baguio',
            'Bakun',
            'Bokod',
            'Buguias',
            'Itogon',
            'Kabayan',
            'Kapangan',
            'Kibungan',
            'La Trinidad',
            'Mankayan',
            'Sablan',
            'Tuba',
            'Tublay',
        ],
        code: '1411',
        name: 'Benguet',
    },
    '0878': {
        cities: [
            'Almeria',
            'Biliran',
            'Cabucgayan',
            'Caibiran',
            'Culaba',
            'Kawayan',
            'Maripipi',
            'Naval',
        ],
        code: '0878',
        name: 'Biliran',
    },
    '0712': {
        cities: [
            'Tagbilaran City',
            'Alburquerque',
            'Alicia',
            'Anda',
            'Antequera',
            'Baclayon',
            'Balilihan',
            'Batuan',
            'Bien Unido',
            'Bilar',
            'Buenavista',
            'Calape',
            'Candijay',
            'Carmen',
            'Catigbian',
            'Clarin',
            'Corella',
            'Cortes',
            'Dagohoy',
            'Danao',
            'Dauis',
            'Dimiao',
            'Duero',
            'Garcia Hernandez',
            'Getafe',
            'Guindulman',
            'Inabanga',
            'Jagna',
            'Lila',
            'Loay',
            'Loboc',
            'Loon',
            'Mabini',
            'Maribojoc',
            'Panglao',
            'Pilar',
            'President Carlos P. Garcia (Pitogo)',
            'Sagbayan (Borja)',
            'San Isidro',
            'San Miguel',
            'Sevilla',
            'Sierra Bullones',
            'Sikatuna',
            'Tagbilaran',
            'Talibon',
            'Trinidad',
            'Tubigon',
            'Ubay',
        ],
        code: '0712',
        name: 'Bohol',
    },
    '1013': {
        cities: [
            'Bangahan',
            'Baungon',
            'Cabanglasan',
            'Damulog',
            'Dangcagan',
            'Don Carlos',
            'Impasugong',
            'Kadingilan',
            'Kalilangan',
            'Kibawe',
            'Kitaotao',
            'Lantapan',
            'Libona',
            'Malaybalay',
            'Malitbog',
            'Manolo Fortich',
            'Maramag',
            'Pangantucan',
            'Quezon',
            'San Fernando',
            'Sumilao',
            'Talakag',
            'Valencia',
        ],
        code: '1013',
        name: 'Bukidnon',
    },
    '0314': {
        cities: [
            'Diliman Primero',
            'Balagtas',
            'San Jose Del Monte',
            'City Of San Jose Del Monte',
            'Bahay Pare',
            'Angat',
            'Balagtas (Bigaa)',
            'Baliuag',
            'Bocaue',
            'Bulakan',
            'Bulacan',
            'Bustos',
            'Calumpit',
            'Doña Remedios Trinidad',
            'Guiguinto',
            'Hagonoy',
            'Malolos',
            'Marilao',
            'Meycauayan',
            'Norzagaray',
            'Obando',
            'Pandi',
            'Paombong',
            'Plaridel',
            'Pulilan',
            'San Ildefonso',
            'San Jose del Monte',
            'San Miguel',
            'San Rafael',
            'Santa Maria',
        ],
        code: '0314',
        name: 'Bulacan',
    },
    '0215': {
        cities: [
            'Cagayan',
            'Abulug',
            'Alcala',
            'Allacapan',
            'Amulung',
            'Aparri',
            'Baggao',
            'Ballesteros',
            'Buguey',
            'Calayan',
            'Camalaniugan',
            'Claveria',
            'Enrile',
            'Gattaran',
            'Gonzaga',
            'Iguig',
            'Lal-lo',
            'Lasam',
            'Pamplona',
            'Peñablanca',
            'Piat',
            'Rizal',
            'Sanchez-Mira',
            'Santa Ana',
            'Santa Praxedes',
            'Santa Teresita',
            'Santo Niño (Faire)',
            'Solana',
            'Tuao',
            'Tuguegarao',
        ],
        code: '0215',
        name: 'Cagayan',
    },
    '0516': {
        cities: [
            'Bahay',
            'Basud',
            'Capalonga',
            'Daet',
            'Jose Panganiban',
            'Labo',
            'Mercedes',
            'Paracale',
            'San Lorenzo Ruiz (Imelda)',
            'San Vicente',
            'Santa Elena',
            'Talisay',
            'Vinzons',
        ],
        code: '0516',
        name: 'Camarines Norte',
    },
    '0517': {
        cities: [
            'Baao',
            'Balatan',
            'Bato',
            'Bombon',
            'Buhi',
            'Bula',
            'Cabusao',
            'Calabanga',
            'Camaligan',
            'Canaman',
            'Caramoan',
            'Del Gallego',
            'Gainza',
            'Garchitorena',
            'Goa',
            'Iriga',
            'Lagonoy',
            'Libmanan',
            'Lupi',
            'Magarao',
            'Milaor',
            'Minalabac',
            'Nabua',
            'Naga',
            'Ocampo',
            'Pamplona',
            'Pasacao',
            'Pili',
            'Presentación (Parubcan)',
            'Ragay',
            'Sagñay',
            'San Fernando',
            'San Jose',
            'Sipocot',
            'Siruma',
            'Tigaon',
            'Tinambac',
        ],
        code: '0517',
        name: 'Camarines Sur',
    },
    '1018': {
        cities: ['Catarman', 'Guinsiliban', 'Mahinog', 'Mambajao', 'Sagay'],
        code: '1018',
        name: 'Camiguin',
    },
    '0619': {
        cities: [
            'Cuartero',
            'Dao',
            'Dumalag',
            'Dumarao',
            'Ivisan',
            'Jamindan',
            'Ma-ayon',
            'Mambusao',
            'Panay',
            'Panitan',
            'Pilar',
            'Pontevedra',
            'President Roxas',
            'Roxas City',
            'Sapian',
            'Sigma',
            'Tapaz',
        ],
        code: '0619',
        name: 'Capiz',
    },
    '0520': {
        cities: [
            'Bagamanoc',
            'Baras',
            'Bato',
            'Caramoran',
            'Gigmoto',
            'Pandan',
            'Panganiban (Payo)',
            'San Andres (Calolbon)',
            'San Miguel',
            'Viga',
            'Virac',
        ],
        code: '0520',
        name: 'Catanduanes',
    },
    '0421': {
        cities: [
            'Dasmarinas',
            'Bayan Ng Heneral Emilio Aguinaldo',
            'Alfonso',
            'Amadeo',
            'Bacoor',
            'Carmona',
            'Cavite',
            'Cavite City',
            'Dasmariñas',
            'General Emilio Aguinaldo',
            'General Mariano Alvarez',
            'General Trias',
            'Imus',
            'Indang',
            'Kawit',
            'Magallanes',
            'Maragondon',
            'Mendez (Mendez-Nuñez)',
            'Naic',
            'Noveleta',
            'Pantijan No 2',
            'Rosario',
            'Silang',
            'Tagaytay',
            'Tanza',
            'Ternate',
            'Trece Martires',
        ],
        code: '0421',
        name: 'Cavite',
    },
    '0722': {
        cities: [
            'Mandaue City',
            'Estaca',
            'Bateria',
            'Maya',
            'Alcantara',
            'Alcoy',
            'Alegria',
            'Aloguinsan',
            'Argao',
            'Asturias',
            'Badian',
            'Balamban',
            'Bantayan',
            'Barili',
            'Bogo',
            'Boljoon',
            'Borbon',
            'Carcar',
            'Carmen',
            'Catmon',
            'Cebu City',
            'Compostela',
            'Consolacion',
            'Cordova',
            'Daanbantayan',
            'Dalaguete',
            'Danao',
            'Dumanjug',
            'Ginatilan',
            'Lapu-Lapu (Opon)',
            'Liloan',
            'Madridejos',
            'Malabuyoc',
            'Mandaue',
            'Medellin',
            'Minglanilla',
            'Moalboal',
            'Naga',
            'Oslob',
            'Pilar',
            'Pinamungajan',
            'Poro',
            'Ronda',
            'Samboan',
            'San Fernando',
            'San Francisco',
            'San Remigio',
            'Santa Fe',
            'Santander',
            'Sibonga',
            'Sogod',
            'Tabogon',
            'Tabuelan',
            'Talisay',
            'Toledo',
            'Tuburan',
            'Tudela',
        ],
        code: '0722',
        name: 'Cebu',
    },
    '1247': {
        cities: [
            'Bau',
            'New Cebu',
            'Alamada',
            'Aleosan',
            'Antipas',
            'Arakan',
            'Banisilan',
            'Carmen',
            'Kabacan',
            'Kidapawan',
            'Libungan',
            'M’lang',
            'Magpet',
            'Makilala',
            'Matalam',
            'Midsayap',
            'Pigcawayan',
            'Pikit',
            'President Roxas',
            'Tulunan',
        ],
        code: '1247',
        name: 'Cotobato',
    },
    '1182': {
        cities: [
            'Compostela',
            'Laak (San Vicente)',
            'Mabini (Doña Alicia)',
            'Maco',
            'Maragusan (San Mariano)',
            'Mawab',
            'Monkayo',
            'Montevista',
            'Nabunturan',
            'New Bataan',
            'Pantukan',
        ],
        code: '1182',
        name: 'Davao de Oro',
    },
    '1123': {
        cities: [
            'City Of Panabo',
            'Asuncion (Saug)',
            'Braulio E. Dujali',
            'Carmen',
            'Kapalong',
            'New Corella',
            'Panabo',
            'City of Panabo',
            'Samal',
            'San Isidro',
            'Santo Tomas',
            'Tagum',
            'Talaingod',
        ],
        code: '1123',
        name: 'Davao del Norte',
    },
    '1124': {
        cities: [
            'New Leyte',
            'Matti',
            'Bansalan',
            'Davao City',
            'Davao',
            'Digos',
            'Hagonoy',
            'Kiblawan',
            'Magsaysay',
            'Malalag',
            'Matanao',
            'Padada',
            'Santa Cruz',
            'Sulop',
        ],
        code: '1124',
        name: 'Davao del Sur',
    },
    '1186': {
        cities: [
            'Don Marcelino',
            'Jose Abad Santos (Trinidad)',
            'Malita',
            'Santa Maria',
            'Sarangani',
        ],
        code: '1186',
        name: 'Davao Occidental',
    },
    '1125': {
        cities: [
            'Ilangay',
            'Baganga',
            'Banaybanay',
            'Boston',
            'Caraga',
            'Cateel',
            'Governor Generoso',
            'Lupon',
            'Manay',
            'Mati',
            'San Isidro',
            'Tarragona',
        ],
        code: '1125',
        name: 'Davao Oriental',
    },
    '1685': {
        cities: [
            'Basilisa (Rizal)',
            'Cagdianao',
            'Dinagat',
            'Libjo (Albor)',
            'Loreto',
            'San Jose',
            'Tubajon',
        ],
        code: '1685',
        name: 'Dinagat Islands',
    },
    '0826': {
        cities: [
            'Arteche',
            'Balangiga',
            'Balangkayan',
            'Borongan',
            'Can-avid',
            'Dolores',
            'General MacArthur',
            'Giporlos',
            'Guiuan',
            'Hernani',
            'Jipapad',
            'Lawaan',
            'Llorente',
            'Maslog',
            'Maydolong',
            'Mercedes',
            'Oras',
            'Quinapondan',
            'Salcedo',
            'San Julian',
            'San Policarpo',
            'Sulat',
            'Taft',
        ],
        code: '0826',
        name: 'Eastern Samar',
    },
    '0679': {
        cities: [
            'Buenavista',
            'Jordan',
            'Nueva Valencia',
            'East Valencia',
            'San Lorenzo',
            'Sibunag',
        ],
        code: '0679',
        name: 'Guimaras',
    },
    '1427': {
        cities: [
            'Aguinaldo',
            'Alfonso Lista (Potia)',
            'Asipulo',
            'Banaue',
            'Hingyon',
            'Hungduan',
            'Kiangan',
            'Lagawe',
            'Lamut',
            'Mayoyao',
            'Tinoc',
        ],
        code: '1427',
        name: 'Ifugao',
    },
    '0128': {
        cities: [
            'Adams',
            'Bacarra',
            'Badoc',
            'Bangui',
            'Banna (Espiritu)',
            'Batac',
            'Burgos',
            'Carasi',
            'Currimao',
            'Dingras',
            'Dumalneg',
            'Laoag',
            'Marcos',
            'Nueva Era',
            'Pagudpud',
            'Paoay',
            'Pasuquin',
            'Piddig',
            'Pinili',
            'San Nicolas',
            'Sarrat',
            'Solsona',
            'Vintar',
        ],
        code: '0128',
        name: 'Ilocos Norte',
    },
    '0129': {
        cities: [
            'Alilem',
            'Banayoyo',
            'Bantay',
            'Burgos',
            'Cabugao',
            'Candon',
            'City Of Candon',
            'Caoayan',
            'Cervantes',
            'Galimuyod',
            'Gregorio del Pilar (Concepcion)',
            'Lidlidda',
            'Magsingal',
            'Nagbukel',
            'Narvacan',
            'Quirino (Angkaki)',
            'Salcedo (Baugen)',
            'San Emilio',
            'San Esteban',
            'San Ildefonso',
            'San Juan (Lapog)',
            'San Vicente',
            'Santa',
            'Santa Catalina',
            'Santa Cruz',
            'Santa Lucia',
            'Santa Maria',
            'Santiago',
            'Santo Domingo',
            'Sigay',
            'Sinait',
            'Sugpon',
            'Suyo',
            'Tagudin',
            'Vigan',
        ],
        code: '0129',
        name: 'Ilocos Sur',
    },
    '0630': {
        cities: [
            'Gabi',
            'Badlan',
            'Bito-on',
            'Bolilao',
            'Ajuy',
            'Alimodian',
            'Anilao',
            'Badiangan',
            'Balasan',
            'Banate',
            'Barotac Nuevo',
            'Barotac Viejo',
            'Batad',
            'Bingawan',
            'Cabatuan',
            'Calinog',
            'Carles',
            'Concepcion',
            'Dingle',
            'Dueñas',
            'Dumangas',
            'Estancia',
            'Guimbal',
            'Igbaras',
            'Iloilo City',
            'Iloilo',
            'Janiuay',
            'Lambunao',
            'Leganes',
            'Lemery',
            'Leon',
            'Maasin',
            'Miagao',
            'Mina',
            'New Lucena',
            'Oton',
            'Passi',
            'Pavia',
            'Pototan',
            'San Dionisio',
            'San Enrique',
            'San Joaquin',
            'San Miguel',
            'San Rafael',
            'Santa Barbara',
            'Sara',
            'Tigbauan',
            'Tubungan',
            'Zarraga',
        ],
        code: '0630',
        name: 'Iloilo',
    },
    '0231': {
        cities: [
            'Cabannungan Second',
            'Siempre Viva',
            'Alicia',
            'Angadanan',
            'Aurora',
            'Benito Soliven',
            'Burgos',
            'Cabagan',
            'Cabatuan',
            'Cauayan',
            'Cordon',
            'Delfin Albano (Magsaysay)',
            'Dinapigue',
            'Divilacan',
            'Muñoz East',
            'Echague',
            'Gamu',
            'Ilagan',
            'Jones',
            'Luna',
            'Maconacon',
            'Mallig',
            'Naguilian',
            'Palanan',
            'Quezon',
            'Quirino',
            'Ramon',
            'Reina Mercedes',
            'Roxas',
            'San Agustin',
            'San Guillermo',
            'San Isidro',
            'San Manuel (Callang)',
            'San Mariano',
            'San Mateo',
            'San Pablo',
            'Santa Maria',
            'Santiago',
            'Santo Tomas',
            'Tumauini',
        ],
        code: '0231',
        name: 'Isabela',
    },
    '1432': {
        cities: [
            'Balbalan',
            'Lubuagan',
            'Pasil',
            'Pinukpuk',
            'Rizal (Liwan)',
            'Tabuk',
            'Tanudan',
            'Tinglayan',
        ],
        code: '1432',
        name: 'Kalinga',
    },
    '0133': {
        cities: [
            'Agoo',
            'Aringay',
            'Bacnotan',
            'Bagulin',
            'Balaoan',
            'Bangar',
            'Bauang',
            'Burgos',
            'Caba',
            'Luna',
            'Naguilian',
            'Pugo',
            'Rosario',
            'San Fernando',
            'San Gabriel',
            'San Juan',
            'Santo Tomas',
            'Santol',
            'Sudipen',
            'Tubao',
        ],
        code: '0133',
        name: 'La Union',
    },
    '0434': {
        cities: [
            'San Pedro One',
            'San Gregorio',
            'Municipality Of Liliw',
            'Los Banos',
            'City Of Biñan',
            'Alaminos',
            'Bay',
            'Biñan',
            'Cabuyao',
            'Calamba',
            'Calauan',
            'Cavinti',
            'Famy',
            'Kalayaan',
            'Liliw',
            'Los Baños',
            'Luisiana',
            'Lumban',
            'Mabitac',
            'Magdalena',
            'Majayjay',
            'Nagcarlan',
            'Paete',
            'Pagsanjan',
            'Pakil',
            'Pangil',
            'Pila',
            'Rizal',
            'San Pablo',
            'San Pedro',
            'Santa Cruz',
            'Santa Maria',
            'City Of Santa Rosa',
            'Siniloan',
            'Victoria',
        ],
        code: '0434',
        name: 'Laguna',
    },
    '1035': {
        cities: [
            'Iligan City',
            'Bacolod',
            'Baloi',
            'Baroy',
            'Iligan',
            'Kapatagan',
            'Kauswagan',
            'Kolambugan',
            'Lala',
            'Linamon',
            'Magsaysay',
            'Maigo',
            'Matungao',
            'Munai',
            'Nunungan',
            'Pantao Ragat',
            'Pantar',
            'Poona Piagapo',
            'Salvador',
            'Sapad',
            'Sultan Naga Dimaporo (Karomatan)',
            'Tagoloan',
            'Tangcal',
            'Tubod',
        ],
        code: '1035',
        name: 'Lanao del Norte',
    },
    '1536': {
        cities: [
            'Amai Manabilang (Bumbaran)',
            'Bacolod-Kalawi (Bacolod-Grande)',
            'Balabagan',
            'Balindong (Watu)',
            'Bayang',
            'Binidayan',
            'Buadiposo-Buntong',
            'Bubong',
            'Butig',
            'Calanogas',
            'Ditsaan-Ramain',
            'Ganassi',
            'Kapai',
            'Kapatagan',
            'Lumba-Bayabao (Maguing)',
            'Lumbaca-Unayan',
            'Lumbatan',
            'Lumbayanague',
            'Madalum',
            'Madamba',
            'Maguing',
            'Malabang',
            'Marantao',
            'Marawi',
            'Marawi City',
            'Marogong',
            'Masiu',
            'Mulondo',
            'Pagayawan (Tatarikan)',
            'Piagapo',
            'Picong (Sultan Gumander)',
            'Poona Bayabao (Gata)',
            'Pualas',
            'Saguiaran',
            'Sultan Dumalondong',
            'Tagoloan II',
            'Tamparan',
            'Taraka',
            'Tubaran',
            'Tugaya',
            'Wao',
        ],
        code: '1536',
        name: 'Lanao del Sur',
    },
    '0837': {
        cities: [
            'Macarthur',
            'Abuyog',
            'Alangalang',
            'Albuera',
            'Babatngon',
            'Barugo',
            'Bato',
            'Baybay',
            'Burauen',
            'Calubian',
            'Capoocan',
            'Carigara',
            'Dagami',
            'Dulag',
            'Hilongos',
            'Hindang',
            'Inopacan',
            'Isabel',
            'Jaro',
            'Javier (Bugho)',
            'Julita',
            'Kananga',
            'La Paz',
            'Leyte',
            'MacArthur',
            'Mahaplag',
            'Matag-ob',
            'Matalom',
            'Mayorga',
            'Merida',
            'Ormoc',
            'Palo',
            'Palompon',
            'Pastrana',
            'San Isidro',
            'San Miguel',
            'Santa Fe',
            'Tabango',
            'Tabontabon',
            'Tacloban',
            'Tanauan',
            'Tolosa',
            'Tunga',
            'Villaba',
        ],
        code: '0837',
        name: 'Leyte',
    },
    '1538': {
        cities: [
            'Cotabato',
            'Satan',
            'Baka',
            'Ampatuan',
            'Barira',
            'Buldon',
            'Buluan',
            'Cotabato City',
            'Datu Abdullah Sangki',
            'Datu Anggal Midtimbang',
            'Datu Blah T. Sinsuat',
            'Datu Hoffer Ampatuan',
            'Datu Montawal (Pagagawan)',
            'Datu Odin Sinsuat (Dinaig)',
            'Datu Paglas',
            'Datu Piang (Dulawan)',
            'Datu Salibo',
            'Datu Saudi-Ampatuan',
            'Datu Unsay',
            'General Salipada K. Pendatun',
            'Guindulungan',
            'Kabuntalan (Tumbao)',
            'Limbo',
            'Mamasapano',
            'Mangudadatu',
            'Matanog',
            'Northern Kabuntalan',
            'Pagalungan',
            'Paglat',
            'Pandag',
            'Parang',
            'Rajah Buayan',
            'Shariff Aguak (Maganoy)',
            'Shariff Saydona Mustapha',
            'South Upi',
            'Sultan Kudarat (Nuling)',
            'Sultan Mastura',
            'Sultan sa Barongis (Lambayong)',
            'Sultan Sumagka (Talitay)',
            'Talayan',
            'Upi',
        ],
        code: '1538',
        name: 'Maguindanao',
    },
    '1740': {
        cities: ['Boac', 'Buenavista', 'Gasan', 'Mogpog', 'Santa Cruz', 'Torrijos'],
        code: '1740',
        name: 'Marinduque',
    },
    '0541': {
        cities: [
            'Puro',
            'Aroroy',
            'Baleno',
            'Balud',
            'Batuan',
            'Cataingan',
            'Cawayan',
            'Claveria',
            'Dimasalang',
            'Esperanza',
            'Mandaon',
            'Masbate City',
            'Milagros',
            'Mobo',
            'Monreal',
            'Palanas',
            'Pio V. Corpuz (Limbuhan)',
            'Placer',
            'San Fernando',
            'San Jacinto',
            'San Pascual',
            'Uson',
        ],
        code: '0541',
        name: 'Masbate',
    },
    '1042': {
        cities: [
            'Aloran',
            'Pines',
            'Baliangao',
            'Bonifacio',
            'Calamba',
            'Clarin',
            'Concepcion',
            'Don Victoriano Chiongbian (Don Mariano Marcos)',
            'Jimenez',
            'Lopez Jaena',
            'Oroquieta',
            'Ozamiz',
            'Panaon',
            'Plaridel',
            'Sapang Dalaga',
            'Sinacaban',
            'Tangub',
            'Tudela',
        ],
        code: '1042',
        name: 'Misamis Occidental',
    },
    '1043': {
        cities: [
            'Alubijid',
            'Balingasag',
            'Balingoan',
            'Binuangan',
            'Cagayan de Oro',
            'Cagayan De Oro',
            'Claveria',
            'El Salvador',
            'Gingoog',
            'Gitagum',
            'Initao',
            'Jasaan',
            'Kinoguitan',
            'Lagonglong',
            'Laguindingan',
            'Libertad',
            'Lugait',
            'Magsaysay (Linugos)',
            'Manticao',
            'Medina',
            'Naawan',
            'Opol',
            'Salay',
            'Sugbongcogon',
            'Tagoloan',
            'Talisayan',
            'Villanueva',
        ],
        code: '1043',
        name: 'Misamis Oriental',
    },
    '1444': {
        cities: [
            'Barlig',
            'Bauko',
            'Besao',
            'Bontoc',
            'Natonin',
            'Paracelis',
            'Sabangan',
            'Sadanga',
            'Sagada',
            'Tadian',
        ],
        code: '1444',
        name: 'Mountain Province',
    },
    '1339': {
        cities: [
            'Caloocan City',
            'Mandaluyong City',
            'Marikina',
            'Pasig',
            'Pasig City',
            'Quezon City',
            'San Juan',
            'Caloocan',
            'Malabon',
            'Navotas',
            'Valenzuela',
            'Las Piñas',
            'Makati City',
            'Muntinlupa',
            'Parañaque',
            'Pasay',
            'Pateros',
            'Taguia',
            'Manila',
            'Luzon',
            'Port Area',
            'Arenas',
            'Taguig',
            'Estrella',
            'Little Baguio',
            'Suz-on',
            'Las Pinas',
            'Espana',
            'Municipality Of Pateros',
            'Muntinglupa',
            'Paranaque City',
            'Pina',
        ],
        code: '1339',
        name: 'Metro Manilla',
    },
    '0645': {
        cities: [
            'Alicante',
            'Bacolod City',
            'Bago',
            'Binalbagan',
            'Cadiz',
            'Calatrava',
            'Candoni',
            'Cauayan',
            'Enrique B. Magalona (Saravia)',
            'Escalante',
            'Himamaylan',
            'Hinigaran',
            'Hinoba-an (Asia)',
            'Ilog',
            'Isabela',
            'Kabankalan',
            'La Carlota',
            'La Castellana',
            'Manapla',
            'Moises Padilla (Magallon)',
            'Murcia',
            'Pontevedra',
            'Pulupandan',
            'Sagay',
            'Salvador Benedicto',
            'San Carlos',
            'San Enrique',
            'Silay',
            'Sipalay',
            'Talisay',
            'Toboso',
            'Valladolid',
            'Victorias',
        ],
        code: '0645',
        name: 'Negros Occidental',
    },
    '0746': {
        cities: [
            'Dumaguete City',
            'Amlan (Ayuquitan)',
            'Ayungon',
            'Bacong',
            'Bais',
            'Basay',
            'Bayawan (Tulong)',
            'Bindoy (Payabon)',
            'Canlaon',
            'Dauin',
            'Dumaguete',
            'Guihulngan',
            'Jimalalud',
            'La Libertad',
            'Mabinay',
            'Manjuyod',
            'Pamplona',
            'San Jose',
            'Santa Catalina',
            'Siaton',
            'Sibulan',
            'Tanjay',
            'City Of Tanjay',
            'Tayasan',
            'Valencia (Luzurriaga)',
            'Vallehermoso',
            'Zamboanguita',
        ],
        code: '0746',
        name: 'Negros Oriental',
    },
    '0848': {
        cities: [
            'Allen',
            'Biri',
            'Bobon',
            'Capul',
            'Catarman',
            'Catubig',
            'Gamay',
            'Laoang',
            'Lapinig',
            'Las Navas',
            'Lavezares',
            'Lope de Vega',
            'Mapanas',
            'Mondragon',
            'Palapag',
            'Pambujan',
            'Rosario',
            'San Antonio',
            'San Isidro',
            'San Jose',
            'San Roque',
            'San Vicente',
            'Silvino Lobos',
            'Victoria',
        ],
        code: '0848',
        name: 'Northern Samar',
    },
    '0349': {
        cities: [
            'Salvacion I',
            'Munoz',
            'Science City Of Muñoz',
            'Aliaga',
            'Bongabon',
            'Cabanatuan City',
            'Cabanatuan',
            'Cabiao',
            'Carranglan',
            'Cuyapo',
            'Gabaldon (Bitulok & Sabani)',
            'Gapan',
            'General Mamerto Natividad',
            'General Tinio (Papaya)',
            'Guimba',
            'Jaen',
            'Laur',
            'Licab',
            'Llanera',
            'Lupao',
            'Muñoz',
            'Nampicuan',
            'Palayan',
            'Pantabangan',
            'Peñaranda',
            'Quezon',
            'Rizal',
            'San Antonio',
            'San Isidro',
            'San Jose',
            'San Leonardo',
            'Santo Domingo',
            'Talavera',
            'Talugtug',
            'Zaragoza',
        ],
        code: '0349',
        name: 'Nueva Ecija',
    },
    '0250': {
        cities: [
            'Alfonso Castañeda',
            'Ambaguio',
            'Aritao',
            'Bagabag',
            'Bambang',
            'Bayombong',
            'Diadi',
            'Dupax del Norte',
            'Dupax del Sur',
            'Kasibu',
            'Kayapa',
            'Quezon',
            'Santa Fe (Imugan)',
            'Solano',
            'Villaverde (Ibung)',
            'Baggabag B',
        ],
        code: '0250',
        name: 'Nueva Vizcaya',
    },
    '1751': {
        cities: [
            'Abra de Ilog',
            'Calintaan',
            'Looc',
            'Lubang',
            'Magsaysay',
            'Mamburao',
            'Paluan',
            'Rizal',
            'Sablayan',
            'San Jose',
            'Santa Cruz',
        ],
        code: '1751',
        name: 'Occidental Mindoro',
    },
    '1752': {
        cities: ['Canubing No 2', 'Evangelista', 'Gloria', 'Pinamalayan'],
        code: '1752',
        name: 'Oriental Mindoro',
    },
    '1753': {
        cities: [
            'El Nido',
            'Aborlan',
            'Agutaya',
            'Araceli',
            'Balabac',
            'Bataraza',
            'Batarasa',
            'Brooke’s Point',
            'Busuanga',
            'Cagayancillo',
            'Coron',
            'Culion',
            'Cuyo',
            'Dumaran',
            'El Nido (Bacuit)',
            'Kalayaan',
            'Linapacan',
            'Magsaysay',
            'Narra',
            'Puerto Princesa',
            'Quezon',
            'Rizal (Marcos)',
            'Roxas',
            'San Vicente',
            'Sofronio Española',
            'Taytay',
        ],
        code: '1753',
        name: 'Palawan',
    },
    '0354': {
        cities: [
            'Manibaug Pasig',
            'Angeles',
            'Angeles City',
            'Apalit',
            'Arayat',
            'Bacolor',
            'Candaba',
            'Floridablanca',
            'Guagua',
            'Lubao',
            'Mabalacat',
            'Macabebe',
            'Magalang',
            'Masantol',
            'Mexico',
            'Minalin',
            'Porac',
            'San Fernando',
            'San Luis',
            'San Simon',
            'Santa Ana',
            'Santa Rita',
            'Santo Tomas',
            'Sasmuan',
        ],
        code: '0354',
        name: 'Pampanga',
    },
    '0155': {
        cities: [
            'Pasibi West',
            'Gayaman',
            'Nama',
            'Polo',
            'Nilombot',
            'Doyong',
            'Agno',
            'Aguilar',
            'Alaminos',
            'Alcala',
            'Anda',
            'Asingan',
            'Balungao',
            'Bani',
            'Basista',
            'Bautista',
            'Bayambang',
            'Binalonan',
            'Binmaley',
            'Bolinao',
            'Bugallon',
            'Burgos',
            'Calasiao',
            'Dagupan',
            'Dasol',
            'Hacienda',
            'Infanta',
            'Labrador',
            'Laoac',
            'Lingayen',
            'Mabini',
            'Malasiqui',
            'Manaoag',
            'Mangaldan',
            'Mangatarem',
            'Mapandan',
            'Natividad',
            'Pozorrubio',
            'Rosales',
            'San Carlos',
            'San Fabian',
            'San Jacinto',
            'San Manuel',
            'San Nicolas',
            'San Quintin',
            'Santa Barbara',
            'Santa Maria',
            'Santo Tomas',
            'Sison',
            'Sual',
            'Tayug',
            'Umingan',
            'Urbiztondo',
            'Urdaneta',
            'Villasis',
        ],
        code: '0155',
        name: 'Pangasinan',
    },
    '0456': {
        cities: [
            'Lucena City',
            'Agdangan',
            'Alabat',
            'Atimonan',
            'Buenavista',
            'Burdeos',
            'Calauag',
            'Camp Flora',
            'Candelaria',
            'Catanauan',
            'Dolores',
            'General Luna',
            'General Nakar',
            'Guinayangan',
            'Gumaca',
            'Infanta',
            'Jomalig',
            'Lopez',
            'Lucban',
            'Lucena',
            'Macalelon',
            'Mauban',
            'Mulanay',
            'Padre Burgos',
            'Pagbilao',
            'Panukulan',
            'Patnanungan',
            'Perez',
            'Pitogo',
            'Plaridel',
            'Polillo',
            'Quezon',
            'Real',
            'Sampaloc',
            'San Andres',
            'San Antonio',
            'San Francisco (Aurora)',
            'San Narciso',
            'Sariaya',
            'Tagkawayan',
            'Tayabas',
            'Tiaong',
            'Unisan',
        ],
        code: '0456',
        name: 'Quezon',
    },
    '0257': {
        cities: ['Aglipay', 'Cabarroguis', 'Diffun', 'Maddela', 'Nagtipunan', 'Saguday'],
        code: '0257',
        name: 'Quirino',
    },
    '0458': {
        cities: [
            'Rodriguez',
            'Angono',
            'Antipolo',
            'Baras',
            'Binangonan',
            'Cainta',
            'Cardona',
            'Jalajala',
            'Morong',
            'Pililla',
            'Rodriguez (Montalban)',
            'San Mateo',
            'Tanay',
            'Taytay',
            'Teresa',
        ],
        code: '0458',
        name: 'Rizal',
    },
    '1759': {
        cities: [
            'Alcantara',
            'Banton (Jones)',
            'Cajidiocan',
            'Calatrava',
            'Concepcion',
            'Corcuera',
            'Ferrol',
            'Looc',
            'Magdiwang',
            'Odiongan',
            'Pato-o',
            'Romblon',
            'San Agustin',
            'San Andres',
            'San Fernando',
            'San Jose',
            'Santa Fe',
            'Santa Maria (Imelda)',
        ],
        code: '1759',
        name: 'Romblon',
    },
    '0860': {
        cities: [
            'Almagro',
            'Basey',
            'Calbayog',
            'Calbiga',
            'Catbalogan',
            'Daram',
            'Gandara',
            'Hinabangan',
            'Jiabong',
            'Marabut',
            'Matuguinao',
            'Motiong',
            'Pagsanghan',
            'Paranas (Wright)',
            'Pinabacdao',
            'San Jorge',
            'San Jose de Buan',
            'San Sebastian',
            'Santa Margarita',
            'Santa Rita',
            'Santo Niño',
            'Tagapul-an',
            'Talalora',
            'Tarangnan',
            'Villareal',
            'Zumarraga',
        ],
        code: '0860',
        name: 'Samar',
    },
    '1280': {
        cities: ['Tango', 'Alabel', 'Glan', 'Kiamba', 'Maasim', 'Maitum', 'Malapatan', 'Malungon'],
        code: '1280',
        name: 'Sarangani',
    },
    '0761': {
        cities: ['Enrique Villanueva', 'Larena', 'Lazi', 'Maria', 'San Juan', 'Siquijor'],
        code: '0761',
        name: 'Siquijor',
    },
    '0562': {
        cities: ['Bulan', 'Bacon', 'Castilla', 'Ogod'],
        code: '0562',
        name: 'Sorsogon',
    },
    '1263': {
        cities: [
            'Cebuano',
            'City Of Koronadal',
            'Silway 7',
            'Banga',
            'General Santos',
            'Gen. Santos City',
            'Koronadal',
            'Lake Sebu',
            'Norala',
            'Polomolok',
            'Santo Niño',
            'Surallah',
            'T’Boli',
            'Tampakan',
            'Tantangan',
            'Tupi',
        ],
        code: '1263',
        name: 'South Cotabato',
    },
    '0864': {
        cities: [
            'Anahawan',
            'Bontoc',
            'Hinunangan',
            'Hinundayan',
            'Libagon',
            'Liloan',
            'Limasawa',
            'Maasin',
            'Macrohon',
            'Malitbog',
            'Padre Burgos',
            'Pintuyan',
            'Saint Bernard',
            'San Francisco',
            'San Juan (Cabalian)',
            'San Ricardo',
            'Silago',
            'Sogod',
            'Tomas Oppus',
        ],
        code: '0864',
        name: 'Southern Leyte',
    },
    '1265': {
        cities: [
            'Bagumbayan',
            'Columbio',
            'Esperanza',
            'Isulan',
            'Kalamansig',
            'Lambayong (Mariano Marcos)',
            'Lebak',
            'Lutayan',
            'Palimbang',
            'President Quirino',
            'Senator Ninoy Aquino',
            'Tacurong',
        ],
        code: '1265',
        name: 'Sultan Kudarat',
    },
    '1566': {
        cities: [
            'Banguingui (Tongkil)',
            'Hadji Panglima Tahil (Marunggas)',
            'Indanan',
            'Jolo',
            'Kalingalan Caluang',
            'Lugus',
            'Luuk',
            'Maimbung',
            'Old Panamao',
            'Omar',
            'Pandami',
            'Panglima Estino (New Panamao)',
            'Pangutaran',
            'Parang',
            'Pata',
            'Patikul',
            'Siasi',
            'Talipao',
            'Tapul',
        ],
        code: '1566',
        name: 'Sulu',
    },
    '1667': {
        cities: [
            'Alegria',
            'Bacuag',
            'Burgos',
            'Claver',
            'Dapa',
            'Del Carmen',
            'General Luna',
            'Gigaquit',
            'Mainit',
            'Malimono',
            'Pilar',
            'Placer',
            'San Benito',
            'San Francisco (Anao-Aon)',
            'San Isidro',
            'Santa Monica (Sapao)',
            'Sison',
            'Socorro',
            'Surigao City',
            'Tagana-an',
            'Tubod',
        ],
        code: '1667',
        name: 'Surigao del Norte',
    },
    '1668': {
        cities: [
            'Barobo',
            'Bayabas',
            'Bislig',
            'Cagwait',
            'Cantilan',
            'Carmen',
            'Carrascal',
            'Cortes',
            'Hinatuan',
            'Lanuza',
            'Lianga',
            'Lingig',
            'Madrid',
            'Marihatag',
            'San Agustin',
            'San Miguel',
            'Tagbina',
            'Tago',
            'Tandag',
        ],
        code: '1668',
        name: 'Surigao del Sur',
    },
    '0369': {
        cities: [
            'Estacion',
            'Anao',
            'Bamban',
            'Camiling',
            'Capas',
            'Concepcion',
            'Gerona',
            'La Paz',
            'Mayantoc',
            'Moncada',
            'Paniqui',
            'Pura',
            'Ramos',
            'San Clemente',
            'San Jose',
            'San Manuel',
            'Santa Ignacia',
            'Tarlac City',
            'Victoria',
        ],
        code: '0369',
        name: 'Tarlac',
    },
    '1570': {
        cities: [
            'Bongao',
            'Languyan',
            'Mapun (Cagayan de Tawi-Tawi)',
            'Panglima Sugala (Balimbing)',
            'Sapa-Sapa',
            'Sibutu',
            'Simunul',
            'Sitangkai',
            'South Ubian',
            'Tandubas',
            'Turtle Islands (Taganak)',
        ],
        code: '1570',
        name: 'Tawi-Tawi',
    },
    '0371': {
        cities: [
            'Botolan',
            'Cabangan',
            'Candelaria',
            'Castillejos',
            'Iba',
            'Masinloc',
            'Olongapo',
            'Palauig',
            'San Antonio',
            'San Felipe',
            'San Marcelino',
            'San Narciso',
            'Santa Cruz',
            'Subic',
        ],
        code: '0371',
        name: 'Zambales',
    },
    '0972': {
        cities: [
            'Muricay',
            'Muti',
            'Baliguian',
            'Dapitan',
            'Dipolog',
            'Godod',
            'Gutalac',
            'Jose Dalman (Ponot)',
            'Kalawit',
            'Katipunan',
            'Municipality Of Katipunan',
            'La Libertad',
            'Labason',
            'Leon B. Postigo (Bacungan)',
            'Liloy',
            'Manukan',
            'Mutia',
            'Piñan (New Piñan)',
            'Polanco',
            'President Manuel A. Roxas',
            'Rizal',
            'Salug',
            'Seres',
            'Sergio Osmeña Sr.',
            'Siayan',
            'Sibuco',
            'Sibutad',
            'Sindangan',
            'Siocon',
            'Sirawai',
            'Tampilisan',
        ],
        code: '0972',
        name: 'Zamboanga del Norte',
    },
    '0973': {
        cities: [
            'Zamboanga',
            'Aurora',
            'Bayog',
            'Dimataling',
            'Dinas',
            'Dumalinao',
            'Dumingag',
            'Guipos',
            'Josefina',
            'Kumalarang',
            'Labangan',
            'Lakewood',
            'Lapuyan',
            'Mahayag',
            'Margosatubig',
            'Midsalip',
            'Molave',
            'Pagadian',
            'Pitogo',
            'Ramon Magsaysay (Liargo)',
            'San Miguel',
            'San Pablo',
            'Sominot (Don Mariano Marcos)',
            'Tabina',
            'Tambulig',
            'Tigbao',
            'Tukuran',
            'Vincenzo A. Sagun',
            'Zamboanga City',
        ],
        code: '0973',
        name: 'Zamboanga del Sur',
    },
    '0983': {
        cities: [
            'Alicia',
            'Buug',
            'Diplahan',
            'Imelda',
            'Ipil',
            'Kabasalan',
            'Mabuhay',
            'Malangas',
            'Naga',
            'Olutanga',
            'Payao',
            'Roseller Lim',
            'Siay',
            'Talusan',
            'Titay',
            'Tunga',
        ],
        code: '0983',
        name: 'Zamboanga Sibugay',
    },
};

export default philippinesCitiesByProvince;
