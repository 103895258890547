export const tickerConfig: any = {
    a: {
        classOverrides: {
            postOuter: {
                default: 'm-1',
            },
            metaGroup: {
                default: 'p-reset w-100',
            },
            userGroup: {
                default: 'p-0 pb-1',
            },
            postText: {
                default: 'ta-l p-reset p-0',
            },
            postTitle: {
                default: 'ta-l p-reset p-0',
            },
            lineClampVerticalAlign: {
                default: 'top',
            },
        },
    },
};
