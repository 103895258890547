const defaultClassOverrides = {
    userGroup: {
        default: 'p-reset p-0 pb-1',
    },
    postText: {
        default: 'p-reset p-0 pt-1',
    },
    metaGroup: {
        default: 'p-reset p-0 pt-1',
    },
    lineClampVerticalAlign: {
        default: 'top',
    },
    extraPostData: {
        default: 'p-reset p-0 pt-1',
    },
    postInner: {
        default: 'p-reset p-2',
    },
    postTitle: {
        default: 'p-reset p-0 pb-1',
    },
};

const smallSizes = {
    ...defaultClassOverrides,
    userGroup: {
        default: 'p-reset p-0 pb-05',
    },
    postText: {
        default: 'p-reset p-0 pb-05',
    },
    metaGroup: {
        default: 'p-reset p-0 pb-05',
    },
    extraPostData: {
        default: 'p-reset p-0 pb-05',
    },
    postInner: {
        default: 'p-reset p-05',
    },
    postTitle: {
        default: 'p-reset p-0',
    },
};
export const postCardConfig: any = {
    a: {
        smartSizeCompositions: {
            baseline: {
                postTextOnlySize: 18,
                postImageTextSize: 13,
                engagementMetricsSize: 15,
                userGroupIconSize: 10,
                userGroupAvatarSize: 18,
                userGroupNameSize: 13,
                userGroupScreenNameSize: 10,
                retweetIndicator: {
                    size: 10,
                },
                extraPostDataOptions: {
                    size: 20,
                    header: {
                        size: 30,
                    },
                },
                nativeStyling: {
                    fontScale: 25,
                },
                gridLayout: {
                    gap: 15,
                },
            },
            optionDefaults: {
                postLineClampValue: 7,
                imagePostLineClampValue: 3,
                extraPostDataOptions: {
                    header: {
                        enabled: true,
                    },
                },
                classOverrides: defaultClassOverrides,
            },
            breakpoints: {
                xs: {
                    options: {
                        postLineClampValue: 3,
                        imagePostLineClampValue: 2,
                        extraPostDataOptions: {
                            header: {
                                enabled: false,
                            },
                        },
                        classOverrides: smallSizes,
                    },
                },
                s: {
                    options: {
                        postLineClampValue: 4,
                        imagePostLineClampValue: 2,
                        extraPostDataOptions: {
                            header: {
                                enabled: false,
                            },
                            nativeStyling: {
                                lineClamp: {
                                    enabled: true,
                                    lines: 4,
                                    hasMedia: {
                                        enabled: true,
                                        lines: 1,
                                    },
                                },
                            },
                        },
                        classOverrides: smallSizes,
                    },
                },
                m: {
                    options: {
                        postLineClampValue: 4,
                        imagePostLineClampValue: 3,
                        extraPostDataOptions: {
                            header: {
                                enabled: false,
                            },
                        },
                        nativeStyling: {
                            lineClamp: {
                                enabled: true,
                                lines: 5,
                                hasMedia: {
                                    enabled: true,
                                    lines: 2,
                                },
                            },
                        },
                        classOverrides: smallSizes,
                    },
                },
                ml: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postInner: {
                                default: 'p-reset p-1',
                            },
                        },
                        nativeStyling: {
                            lineClamp: {
                                enabled: true,
                                lines: 7,
                                hasMedia: {
                                    enabled: true,
                                    lines: 4,
                                },
                            },
                        },
                    },
                },
                l: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postInner: {
                                default: 'p-reset p-1',
                            },
                        },
                        nativeStyling: {
                            lineClamp: {
                                enabled: true,
                                lines: 10,
                                hasMedia: {
                                    enabled: true,
                                    lines: 5,
                                },
                            },
                        },
                    },
                },
                xl: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            postInner: {
                                default: 'p-reset p-1',
                            },
                        },
                        nativeStyling: {
                            lineClamp: {
                                enabled: true,
                                lines: 10,
                                hasMedia: {
                                    enabled: true,
                                    lines: 5,
                                },
                            },
                        },
                    },
                },
            },
        },
        classOverrides: defaultClassOverrides,
    },
};
