import React from 'react';
import { observer } from 'mobx-react';
import Icon from 'components/Icon/Icon';

type RetweetIndicatorProps = {
    name: string;
};

const RetweetIndicator: React.FC<RetweetIndicatorProps> = ({ name = '' }) => {
    return (
        <div className='retweet_indicator flex no-grow middle w-100 mb-05'>
            <Icon value='twitter_retweet' reset />
            <span className='ml-05'>{`Repost of ${name}`}</span>
        </div>
    );
};

RetweetIndicator.displayName = 'RetweetIndicator';
export default observer(RetweetIndicator);
