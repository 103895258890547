import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { UrlEntity } from '@sprinklr/stories/post/Post';
import { styler } from 'utils/GenerateStyles/GenerateStyles';

export type LinkPreviewProps = {
    urlEntity: UrlEntity;
    size: number;
};

const StyledLinkPreview = styled.div`
    padding: 0.8rem 1.2rem;
    border: 0.5px solid;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin: 1.5em 0;
    width: 100%;
    text-align: left;
`;

const StyledLinkTitle = styled.div<{ size: number }>`
    font-weight: bold;
    ${({ size }) =>
        css`
            font-size: ${styler(size, 0.095, 'em', '')};
        `}
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const StyledDescription = styled.div<{ size: number }>`
    ${({ size }) => {
        console.log(size);
        return css`
            font-size: ${styler(size, 0.085, 'em', '')};
        `;
    }}
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const StyledUrl = styled.div<{ size: number }>`
    ${({ size }) =>
        css`
            font-size: ${styler(size, 0.085, 'em', '')};
        `}
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const LinkPreview: React.FC<LinkPreviewProps> = ({ urlEntity, size }) => {
    const { title, desc, display_url } = urlEntity;

    if (!title && !desc && !display_url) {
        return <div />;
    }

    const formattedUrl = display_url?.split('/')[0];

    return (
        <StyledLinkPreview className={'link_preview_container'}>
            {title && (
                <StyledLinkTitle size={size} className={'link_preview_title primary_font_family'}>
                    {title}
                </StyledLinkTitle>
            )}
            {desc && (
                <StyledDescription
                    size={size}
                    className={'link_preview_description secondary_font_family'}>
                    {desc}
                </StyledDescription>
            )}
            {formattedUrl && (
                <StyledUrl size={size} className={'link_preview_url primary_font_family'}>
                    {formattedUrl}
                </StyledUrl>
            )}
        </StyledLinkPreview>
    );
};

LinkPreview.displayName = 'LinkPreview';
export default observer(LinkPreview);
