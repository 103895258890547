import { nativeStylingTunables } from './../../../../Sidebar/RenderTunables/SharedTunables';
import { portraitReviewOpts } from './SharedConfig';

export const smartSize = {
    baseline: {
        postImageTextSize: 30,
        postTextOnlySize: 30,
        userGroupNameSize: 17,
        postLineClampValue: 8,
        userGroupScreenNameSize: 11,
        userGroupAvatarSize: 25,
        userGroupIconSize: 14,
        engagementMetricsSize: 27,
        imagePostLineClampValue: 8,
        nativeStyling: {
            fontScale: 45,
        },
        retweetIndicator: {
            size: 11,
        },
    },
    optionDefaults: {
        userGroupLayoutType: 'c',
        imagePostLineClampValue: 8,
        postLineClampValue: 8,
    },
    breakpoints: {
        xs: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 3,
                postLineClampValue: 3,
                nativeStyling: {
                    lineClamp: {
                        enabled: true,
                        lines: 4,
                        hasMedia: {
                            enabled: true,
                            lines: 2,
                        },
                    },
                },
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-05 pl-0 pr-0 pb-0',
                    },
                    postInner: {
                        default: 'p-05',
                    },
                },
            },
        },
        s: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 3,
                postLineClampValue: 3,
                nativeStyling: {
                    lineClamp: {
                        enabled: true,
                        lines: 5,
                        hasMedia: {
                            enabled: true,
                            lines: 4,
                        },
                    },
                },
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-05 pl-1 pr-1 pb-0',
                    },
                    postInner: {
                        default: 'p-05',
                    },
                },
            },
        },
        m: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 4,
                postLineClampValue: 4,
                nativeStyling: {
                    lineClamp: {
                        enabled: true,
                        lines: 7,
                        hasMedia: {
                            enabled: true,
                            lines: 5,
                        },
                    },
                },
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-1 pl-15 pr-15 pb-0',
                    },
                    postInner: {
                        default: 'p-1',
                    },
                },
            },
        },
        l: {
            options: {
                userGroupLayoutType: 'a',
                imagePostLineClampValue: 5,
                postLineClampValue: 5,
                classOverrides: {
                    userGroup: {
                        default: 'p-reset pt-1 pl-15 pr-15 pb-0',
                    },
                    postInner: {
                        default: 'p-15',
                    },
                },
            },
        },
    },
};

const retweetOptions = {
    enabled: true,
    opacity: 50,
    size: 11,
};

export const filmStripConfig: any = {
    a: {
        postLayoutType: 'a',
        userGroupLayoutType: 'c',
        userGroupPosition: 'bottom',
        showSecondaryImageOverlay: false,
        imageBlendMode: null,
        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 8,
        postTextOnlySize: 30,

        imagePostLineClamp: true,
        imagePostLineClampValue: 8,
        postImageTextFormat: 'clamp',
        postImageTextSize: 30,
        imagePostHideText: true,

        showIconInUserGroup: false,

        userGroupNameSize: 17,
        userGroupScreenNameSize: 11,
        userGroupAvatarSize: 25,
        userGroupIconSize: 12,
        retweetIndicator: retweetOptions,
        engagementMetricsSize: 20,
        classOverrides: {
            postText: {
                default: 'h100 p-reset p-0',
                showTitle: 'h70 p-reset p-0',
            },
            lineClampVerticalAlign: {
                default: 'top',
            },
            metaGroup: {
                default: 'p-reset pb-1',
            },
            postInner: {
                default: 'p-2',
            },
            userGroup: {
                default: 'p-reset pt-15 pl-2 pr-2 pb-0',
            },
            reviewTitle: {
                default: 'p-0 p-reset pb-1 mb-1',
            },
            reviewRating: {
                default: 'p-0 p-reset mt-1',
            },
            reviewProductTitle: {
                default: 'p-0 p-reset pb-1 pt-1',
            },
            extraPostData: {
                default: 'f-order-6 p-reset p-0',
            },
            postTitle: {
                default: 'pb-3 h30 no-grow',
            },
        },
        portrait: {
            imagePostLineClampValue: 4,
            postLineClampValue: 4,
            postImageTextSize: 23,
            postTextOnlySize: 23,
            classOverrides: {
                postText: {
                    default: 'h100 p-reset p-0',
                    review: 'h100 p-reset p-0 pl-2',
                    showTitle: 'h70 p-reset p-0',
                },
                metaGroup: {
                    default: 'p-reset pb-1',
                },
                postInner: {
                    default: 'p-2',
                    showTitle: 'p-4',
                },
                userGroup: {
                    default: 'p-reset pt-0 pl-2 pr-2 pb-0',
                },
                reviewDomain: {
                    default: 'flex no-grow right',
                },
                postTitle: {
                    default: 'pb-3 h30 no-grow',
                },
            },
            reviews: portraitReviewOpts,
        },
        smartSizeCompositions: {
            ...smartSize,
        },
    },
    b: {
        postLayoutType: 'b',
        userGroupLayoutType: 'd',
        userGroupPosition: 'top',
        showSecondaryImageOverlay: false,
        imageBlendMode: null,
        showIconInUserGroup: false,

        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 10,
        postTextOnlySize: 25,

        imagePostLineClamp: true,
        imagePostLineClampValue: 5,
        postImageTextFormat: 'clamp',
        postImageTextSize: 25,
        imagePostHideText: false,

        userGroupNameSize: 17,
        userGroupScreenNameSize: 11,
        userGroupAvatarSize: 25,
        userGroupIconSize: 12,
        retweetIndicator: retweetOptions,

        engagementMetricsSize: 27,
        classOverrides: {
            postInner: {
                default: 'pl-2 pr-2 pt-1 pb-2',
            },
            metaGroup: {
                default: 'p-2',
            },
            lineClampVerticalAlign: {
                default: 'top',
            },
            postTitle: {
                default: 'p-reset pl-2 pr-2 pt-1 pb-1 h30 no-grow',
            },
            userGroup: {
                default: 'no-grow p-reset pt-1 pl-2 pr-2 pb-1',
            },
            reviewTitle: {
                default: 'p-0 p-reset',
            },
            postText: {
                default: 'p-reset p-2',
                showTitle: 'p-reset p-2 h70',
            },
            reviewProductTitle: {
                default: 'p-0 p-reset pb-1 pt-1',
            },
            extraPostData: {
                default: 'f-order-6 p-reset p-0',
            },
        },
        portrait: {
            imagePostLineClampValue: 5,
            postLineClampValue: 4,
            postImageTextSize: 23,
            classOverrides: {
                postText: {
                    default: 'p-reset pt-0 pl-2 pr-2 pb-0',
                    showTitle: 'p-reset pt-0 pl-2 pr-2 pb-0 h70',
                },
                postInner: {
                    default: 'p-2',
                    review: 'ta-c p-2',
                },
                metaGroup: {
                    default: 'pt-2 pl-2 pr-2 pb-1',
                },
                userGroup: {
                    default: 'no-grow p-reset pt-1 pl-2 pr-2 pb-2',
                    review: 'no-grow p-reset p-0',
                },
                reviewRating: {
                    default: 'center mt-1 mb-1',
                },
                reviewDomain: {
                    default: 'flex no-grow right',
                },
                postTitle: {
                    default: 'p-reset pl-2 pr-2 pt-1 pb-1 h30 no-grow',
                },
            },
            reviews: {
                enabled: false,
                title: {
                    enabled: true,
                    color: null,
                    size: 21,
                    format: {
                        type: 'clamp',
                        clamp: {
                            enabled: true,
                            lines: 2,
                        },
                        fit: {
                            min: 20,
                            max: 400,
                        },
                    },
                },
                rating: {
                    enabled: true,
                    filledColor: null,
                    emptyColor: null,
                    size: 17,
                },
                product: {
                    enabled: true,
                    color: null,
                    size: 20,
                },
                message: {
                    enabled: true,
                    size: 14,
                    format: {
                        type: 'clamp',
                        clamp: {
                            enabled: true,
                            lines: 3,
                        },
                        fit: {
                            min: 20,
                            max: 400,
                        },
                    },
                },
                domain: {
                    enabled: true,
                    color: null,
                    size: 20,
                },
            },
        },
        smartSizeCompositions: {
            ...smartSize,
        },
    },
    c: {
        postLayoutType: 'c',
        userGroupLayoutType: 'a',
        userGroupPosition: 'top',
        showSecondaryImageOverlay: false,
        showIconInUserGroup: true,
        imageBlendMode: null,

        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 11,
        postTextOnlySize: 25,

        imagePostLineClamp: true,
        imagePostLineClampValue: 6,
        postImageTextFormat: 'clamp',
        postImageTextSize: 27,
        imagePostHideText: false,

        userGroupNameSize: 17,
        userGroupScreenNameSize: 11,
        userGroupAvatarSize: 25,
        userGroupIconSize: 12,
        retweetIndicator: retweetOptions,

        engagementMetricsSize: 20,
        classOverrides: {
            lineClampVerticalAlign: {
                default: 'top',
            },
            userGroup: {
                default: 'no-grow p-reset pt-15 pl-2 pr-2 pb-15',
            },
            metaGroup: {
                default: 'p-reset p-1 pl-2 pr-2',
            },
            reviewRating: {
                default: 'pr-2 pl-2',
            },
            reviewDomain: {
                default: 'p-1',
            },
            extraPostData: {
                default: 'f-order-6 p-reset p-0',
            },
            postTitle: {
                default: 'p-reset pl-2 pr-2 pt-1 pb-1 h30 no-grow',
            },
            postText: {
                showTitle: 'h70',
            },
        },
        portrait: {
            imagePostLineClampValue: 5,
            postLineClampValue: 4,
            postImageTextSize: 23,
            classOverrides: {
                postText: {
                    default: 'p-reset pt-0 pl-2 pr-2 pb-0',
                    showTitle: 'p-reset pt-0 pl-2 pr-2 pb-0 h70',
                },
                postInner: {
                    default: 'p-2',
                },
                metaGroup: {
                    default: 'pt-1 pl-2 pr-2 pb-2',
                },
                userGroup: {
                    default: 'no-grow p-reset pt-2 pl-2 pr-2 pb-1',
                    review: 'p-reset p-0 w-a pl-2',
                },
                reviewRating: {
                    default: 'right',
                },
                reviewDomain: {
                    default: 'flex no-grow right',
                },
                postTitle: {
                    default: 'p-reset pl-2 pr-2 pt-1 pb-1 h30 no-grow',
                },
            },
            reviews: portraitReviewOpts,
        },
        smartSizeCompositions: {
            ...smartSize,
        },
    },
};
