import * as React from 'react';
import classNames from 'classnames';

interface PartsBuilderProps {
    parts: any;
    classes?: string;
}

function getPartKey(part) {
    const { classes, content } = part;
    return content
        ? content.length > 0
            ? content[0].key + content[0].type + classes
            : content.key + content.type + classes
        : `${classes}`;
}
export default class PartsBuilder extends React.Component<PartsBuilderProps, any> {
    public static defaultProps: PartsBuilderProps = {
        parts: [],
        classes: '',
    };

    render() {
        const { parts, classes } = this.props;

        return (
            <div className={classes}>
                {parts &&
                    parts.length &&
                    parts.map((part, index) => (
                        <div key={getPartKey(part)} className={classNames(`${part.classes}`, {})}>
                            {!part.parts && part.content}

                            {part.parts &&
                                part.parts.map((part, index) => (
                                    <div
                                        key={getPartKey(part)}
                                        className={classNames(`${part.classes}`, {})}>
                                        {!part.parts && part.content}

                                        {part.parts &&
                                            part.parts.map((part, index) => (
                                                <div
                                                    key={getPartKey(part)}
                                                    className={classNames(`${part.classes}`, {})}>
                                                    {!part.parts && part.content}

                                                    {part.parts &&
                                                        part.parts.map((part, index) => (
                                                            <div
                                                                key={getPartKey(part)}
                                                                className={classNames(
                                                                    `${part.classes}`,
                                                                    {}
                                                                )}>
                                                                {part.content}
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>
        );
    }
}
