import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule, ThemeConsumer } from 'styled-components';
import { ColorScheme } from '@sprinklr/space-kit/lib/designSystem/colorSchemes';
import colors from '@sprinklr/space-kit/lib/designSystem/colors';

const {
    default: styled,
    css,
    keyframes,
    ThemeProvider,
    withTheme,
    useTheme,
} = styledComponents as ThemedStyledComponentsModule<ColorScheme>;
type ColorMode = 'light' | 'dark';

export default styled;
export {
    css,
    keyframes,
    ThemeProvider,
    ThemeConsumer,
    withTheme,
    ColorScheme,
    colors,
    ColorMode,
    useTheme,
};
