import * as React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import SocialPosts from '../CustomContent/SocialPosts';
import { Post as PostModel } from '@sprinklr/stories/post/Post';
import Post from '../../Post/Post';
import { SourcePost as SourcePostModel } from '../../../widgets/AutoAlertsWidget/types';
import { postCompositionConfigFilmStrip } from '../../Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { WidgetType } from '../../../models/Widget/WidgetType';
import merge from 'lodash/merge';
import { getSocialPostType } from '../CustomContent/helpers/getSocialPostType';
import NativePostWrapper from 'components/NativePost/NativePostWrapper';
import { NativeStylingOptionsImpl } from '@sprinklr/display-builder/widgets/PostsWidget/options';

export interface SourcePostProps {
    actualTweet: PostModel;
    permalink: string;
    sourcePost: SourcePostModel;
    postWidgetType?: WidgetType;
    onPostClick?: (stuff: any) => void;
    onEmbedLoaded?: (ref: Element) => void;
}

const SourcePost: React.FC<SourcePostProps> = ({
    permalink,
    actualTweet,
    sourcePost,
    postWidgetType,
    onEmbedLoaded,
    onPostClick,
}) => {
    const handleEmbedLoad = React.useCallback(
        (ref: Element) => {
            if (!!onEmbedLoaded) {
                onEmbedLoaded(ref);
            }
        },
        [onEmbedLoaded]
    );

    const mergedPostOptions = merge(
        {},
        defaultPostOptions,
        toJS(sourcePost.options, { recurseEverything: true })
    );
    permalink = permalink ? permalink : actualTweet.permalink;
    const renderEmbedded = getSocialPostType(permalink) !== 'unknown';
    const nativeStyling = new NativeStylingOptionsImpl();

    return (
        <>
            {sourcePost.useNativeEmbed && (
                <>
                    {renderEmbedded ? (
                        <SocialPosts
                            key='SocialPosts'
                            contentSocialPostUrls={[permalink]}
                            onPostClick={onPostClick}
                            onEmbedLoaded={handleEmbedLoad}
                        />
                    ) : (
                        <NativePostWrapper
                            options={nativeStyling}
                            key='nativePost'
                            postOuterClasses='flex w-100 h-100'
                            postInnerClasses=''
                            post={actualTweet}
                            playerInterval={null}
                        />
                    )}
                </>
            )}
            {!sourcePost.useNativeEmbed && (
                <Post
                    data={actualTweet}
                    offset={0}
                    widgetType={postWidgetType}
                    onPostClick={onPostClick}
                    {...mergedPostOptions}
                />
            )}
        </>
    );
};

export const defaultPostOptions = Object.assign(postCompositionConfigFilmStrip.c, {
    textOnlyTextFormat: 'clamp',
    postCompositionType: 'c',
    postLineClamp: true,
    postLineClampValue: 8,
    postTextOnlySize: 25,
    imagePostLineClamp: true,
    imagePostLineClampValue: 4,
    postImageTextSize: 20,
    postImageTextFormat: 'clamp',
    showEngagementMetrics: true,
    engagementMetricsPosition: 'bottom_right',
    engagementMetricsSize: 20,
    userGroupAvatarSize: 20,
    userGroupNameSize: 25,
    userGroupScreenNameSize: 18,
    textOnlyBackgroundColor: '',
    postVersion: 2,
});

SourcePost.displayName = 'SourcePost';
export default observer(SourcePost);
