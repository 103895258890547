import classNames from 'classnames';
import * as React from 'react';
import glyphs from './Glyphs';
import { observer } from 'mobx-react';

import './Icon.scss';

interface Props {
    value?: string;
    wrap?: boolean;
    channelColor?: boolean;
    iconColor?: string;
    backgroundColor?: string;
    backgroundShape?: 'round' | 'rounded' | 'square';
    size?: number;
    classes?: string;
    onClick?: () => void;
    reset?: boolean;
    useRems?: boolean;
    disabled?: boolean;
    title?: string;
    opacity?: number;
    padding?: number;
    marginRight?: number;
    center?: boolean;
}

class Icon extends React.Component<Props, any> {
    public static defaultProps: Props = {
        value: '',
        wrap: false,
        iconColor: '',
        channelColor: false,
        backgroundColor: '',
        backgroundShape: 'round',
        classes: '',
        reset: false,
        useRems: false,
        disabled: false,
        opacity: null,
    };

    render() {
        const {
            iconColor,
            backgroundShape,
            backgroundColor,
            wrap,
            value,
            channelColor,
            classes,
            reset,
            size,
            useRems,
            onClick,
            disabled,
            title,
            opacity,
            padding,
            marginRight,
            center,
        } = this.props;

        const valueLower = value?.toLowerCase() || '';
        const glyph = glyphs[valueLower];

        const iconContainerClasses = classNames(
            [`icon__container no-grow channel--${valueLower}`],
            {
                [`icon__container--${backgroundShape}`]: backgroundShape.length > 0,
                'bg-color': wrap,
                no_wrap: !wrap,
                [`${classes}`]: classes.length > 0,
                'icon__container--reset': reset,
                no_glyph_found: !glyphs[valueLower],
                'icon__container--disabled': this.props.disabled,
            }
        );

        const iconContainerStyles: any = {};
        if (wrap) {
            iconContainerStyles.background = backgroundColor;
        }
        // add inline size if you get it as a prop
        if (size) {
            if (useRems) {
                iconContainerStyles.width = [`${size * 2}rem`];
                iconContainerStyles.height = [`${size * 2}rem`];
            } else {
                iconContainerStyles.width = [`${size}em`];
                iconContainerStyles.height = [`${size}em`];
            }
        }

        if (opacity !== null) {
            iconContainerStyles.opacity = opacity;
        }

        if (padding) {
            iconContainerStyles.padding = `${padding}em`;
        }

        if (marginRight) {
            iconContainerStyles.marginRight = `${marginRight}em`;
        }

        if (center) {
            iconContainerStyles.alignSelf = 'center';
        }

        let iconStyles = {};
        if (iconColor !== '') {
            iconStyles = {
                fill: iconColor,
            };
        }

        return (
            <div
                className={iconContainerClasses}
                style={iconContainerStyles}
                onClick={
                    disabled
                        ? () => {
                              console.log('disabled');
                          }
                        : onClick
                }
                title={title}>
                <svg
                    dangerouslySetInnerHTML={{ __html: glyph }}
                    className={classNames(`icon`, {
                        [`icon fill-color channel--${valueLower}`]: channelColor,
                    })}
                    style={iconStyles}></svg>
            </div>
        );
    }
}

export const BigIcon: React.FC<Props> = props => (
    <Icon size={3.5} reset classes={`circle bg-7 highlight highlight-alt`} {...props} />
);

export default observer(Icon);
