import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const gridCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition) => {
    const { userGroup, postText, media, metaGroup, postExtraPostData, postTitle } = parts;
    const { isInterstitialMedia, postOuterClasses, postInnerClasses, hasMedia } = config;

    return (
        <div
            className={`${postOuterClasses} post_composition_grid`}
            onClick={onPostClick}
            onTouchEnd={onPostClick}>
            {!isInterstitialMedia && (
                <div
                    className={classNames(`post_inner flex rel w-100 h-100 vertical`, {
                        [postInnerClasses]: true,
                    })}>
                    {type === 'a' &&
                        hasMedia &&
                        !isInterstitialMedia && [media, metaGroup, postText, userGroup]}

                    {type === 'a' && !hasMedia && [metaGroup, postTitle, postText, userGroup]}

                    {type === 'b' &&
                        hasMedia &&
                        !isInterstitialMedia && [media, userGroup, postText, metaGroup]}

                    {type === 'b' && !hasMedia && [userGroup, postTitle, postText, metaGroup]}
                </div>
            )}
            {postExtraPostData}
            {isInterstitialMedia && <div className='post_inner rel w-100 h-100'>{media}</div>}
        </div>
    );
};

export default gridCompositions;
