import * as React from 'react';
import AuthService from 'services/Auth/AuthService';
import Icon from 'components/Icon/Icon';
import { Button, Text } from 'components/spaceKit';
import i18n from '@sprinklr/display-builder/i18n';
import config from '@sprinklr/display-builder/config';
import { StyledBuilder } from '@sprinklr/display-builder/containers/App/PrivateRoute';

const ModuleNotEnabled: React.FC<{ authService: AuthService }> = ({ authService }) => (
    <StyledBuilder className='builder' middle center vertical gap='l'>
        <Icon value='splash' />
        <Text.TextSemiBold13>
            {i18n.t(
                `Your partner (${
                    authService.userInfo.partnerId
                }) does not have the ${config.applicationMode.toLowerCase()} product enabled.`
            )}
        </Text.TextSemiBold13>

        <Button onClick={() => authService.logout()}>{i18n.t('Logout')}</Button>
    </StyledBuilder>
);

export default ModuleNotEnabled;
