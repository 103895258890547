import { AnomalyLineChart } from '@sprinklr/display-builder/widgets/AutoAlertsWidget/types';
import { XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';

export class LineChartExpected {
    @observable enabled = true;
    @observable line: AnomalyLineChart['expected']['line'] = {
        style: 'solid',
        size: 25,
        color: 'rgba(255, 96, 96, 1)',
    };
    @observable label = {
        enabled: true,
    };
    @observable metric = {
        enabled: true,
    };
}

export class AnomalyLineChartImpl implements AnomalyLineChart {
    @observable enabled = true;
    @observable cumulative = false;
    @observable line = {
        color: '',
        size: 50,
    };
    @observable points = {
        enabled: true,
        size: 34,
        color: '',
    };
    @observable segments = {
        enabled: true,
        size: 50,
    };
    @observable fill = {
        enabled: true,
        color: '',
    };
    @observable spike = {
        enabled: true,
        metric: {
            enabled: true,
            size: 60,
        },
        metricName: {
            enabled: true,
            size: 50,
        },
    };
    @observable expected = new LineChartExpected();
    @observable xAxisDateFormat: XAxisDateFormats = 'auto';
}
