import * as React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { observer } from 'mobx-react';
import Icon from '../../Icon/Icon';
import SocialPost from './SocialPost';
import { action, computed, observable } from 'mobx';
import measured from '../../../utils/Measure/measured';
import { ContentRect } from 'react-measure';
import classNames from 'classnames';
require('./SocialPost.scss');

export interface SocialPostsProps {
    measureRef?: (ref: Element | null) => void;
    contentRect?: ContentRect;
    contentSocialPostUrls?: string[];
    onEmbedLoaded?: (ref: Element) => void;
    mode?: 'grid' | 'list';
    showActions?: boolean;
    arrangeUrls?: (oldIndex?: number, newIndex?: number) => void;
    removePostUrl?: (postUrl?: string) => void;
    portrait?: boolean;
    onPostClick?: (event: React.MouseEvent | React.TouchEvent, url?: string) => void;
}

class SocialPosts extends React.Component<SocialPostsProps, any> {
    public static defaultProps: SocialPostsProps = {
        showActions: false,
        mode: 'grid',
    };

    @observable node;
    @observable embedsLoaded = 0;

    handlePostClick = (event: React.MouseEvent | React.TouchEvent) => {
        if (this.props.onPostClick) {
            this.props.onPostClick(event, this.props.contentSocialPostUrls?.[0]);
        }
    };

    render() {
        const {
            contentSocialPostUrls,
            removePostUrl,
            showActions,
            portrait,
            arrangeUrls,
            mode,
        } = this.props;
        const List = SortableContainer ? SortableItemList : ItemList;

        const hasDimensions =
            !!this.props.contentRect &&
            !!this.props.contentRect.client.height &&
            !!this.props.contentRect.client.width;
        const hasSize: boolean = hasDimensions && this.embedsLoaded > 0;
        const classes = classNames('social_embed_container rel fullbleed middle center no-grow', {
            'o-0': !hasSize,
            flex: mode === 'grid',
        });

        if (!hasDimensions) {
            return <div className={classes} ref={this.getNode} />;
        }

        return (
            <div className={classes} ref={this.getNode} onClick={this.handlePostClick}>
                <List
                    width={
                        contentSocialPostUrls.length === 1
                            ? this.props.contentRect.bounds.width
                            : undefined
                    }
                    scale={this.embedScale}
                    onSortEnd={this.onSortEnd}
                    helperClass='dragging'
                    axis={mode === 'grid' ? 'xy' : 'x'}
                    useDragHandle={true}
                    lockToContainerEdges
                    contentSocialPostUrls={contentSocialPostUrls}
                    onEmbedLoaded={this.onEmbedLoaded}
                    removePostUrl={removePostUrl}
                    useWindowAsScrollContainer
                    sortable={!!arrangeUrls}
                    mode={mode}
                    showActions={showActions}
                    portrait={portrait}
                />
            </div>
        );
    }

    @computed get embedScale() {
        const node = this.node;
        if (this.embedsLoaded === 0 || !this.node || !node.hasChildNodes()) {
            return 1;
        }

        const embed = node.querySelector('.social_embed-wrapper');
        if (!embed) {
            return 1;
        }
        const embedHeight = embed.clientHeight;
        const embedWidth = embed.clientWidth;
        const nodeHeight = this.props.contentRect
            ? this.props.contentRect.client.height
            : embedHeight;
        const nodeWidth = this.props.contentRect ? this.props.contentRect.client.width : embedWidth;

        // console.log('nodeHeight', nodeHeight, 'nodeWidth', nodeWidth, 'embedHeight', embedHeight, 'embedWidth', embedWidth);

        return Math.min(nodeHeight / embedHeight, nodeWidth / embedWidth);
    }

    @action
    private onEmbedLoaded = ref => {
        this.embedsLoaded++;
        this.props.onEmbedLoaded && this.props.onEmbedLoaded(ref);
    };

    @action
    private getNode = node => {
        this.node = node;
        this.props.measureRef(node);
    };

    private onSortEnd = indecies => {
        const { oldIndex, newIndex } = indecies;
        this.props.arrangeUrls(oldIndex, newIndex);
    };
}

const getGrid = (array: string[], portrait?: boolean) => {
    const length = array && array.length;
    const grid = { columns: null, rows: null };

    switch (length) {
        case 1:
        default:
            grid.columns = 1;
            grid.rows = 1;
            break;
        case 2:
            grid.columns = 2;
            grid.rows = 1;
            break;
        case 3:
            grid.columns = 3;
            grid.rows = 1;
            break;
        case 4:
            grid.columns = 2;
            grid.rows = 2;
            break;
        case 5:
        case 6:
            grid.columns = 3;
            grid.rows = 2;
            break;
    }

    if (portrait) {
        grid.columns = 1;
        grid.rows = length;
    }

    return grid;
};

const DragHandle = SortableHandle(() => (
    <div className='flex no-grow rel cursor-grab'>
        <Icon value='arrange' classes='social_embed_drag-handle' reset />
    </div>
));

type ListItemProps = {
    contentSocialPostUrl: string;
    onEmbedLoaded: () => void;
    removePostUrl: () => void;
    showActions: boolean;
    sortable: boolean;
    mode: 'grid' | 'list';
    width: number;
    style?: React.CSSProperties;
};
const ListItem: React.FC<ListItemProps> = observer(
    ({
        contentSocialPostUrl,
        onEmbedLoaded,
        removePostUrl,
        showActions,
        sortable,
        mode,
        width,
        style,
    }) => {
        const classes = classNames('social_embed-item flex no-grow rel center middle', {
            'm-1': mode === 'list',
            'w100 h100': mode === 'grid',
        });

        return (
            <div style={style} className={classes}>
                <SocialPost
                    width={width || (mode === 'list' ? 320 : undefined)}
                    contentUrl={contentSocialPostUrl}
                    onEmbedLoaded={onEmbedLoaded}
                />
                {showActions && (
                    <div className='social_embed-actions fullbleed top left flex between no-grow p-1'>
                        <Icon
                            value='trash'
                            reset
                            classes='flex no-grow pointer'
                            onClick={removePostUrl.bind(this, contentSocialPostUrl)}
                        />
                        {sortable && <DragHandle />}
                    </div>
                )}
            </div>
        );
    }
);

const SortableItem = SortableElement(ListItem);

const ItemList = observer(
    ({
        contentSocialPostUrls,
        onEmbedLoaded,
        removePostUrl,
        showActions,
        portrait,
        scale,
        sortable,
        mode,
        width,
    }) => {
        let styles;
        if (mode === 'grid' && contentSocialPostUrls.length > 1) {
            const grid = getGrid(contentSocialPostUrls, portrait);
            styles = {
                gridTemplateColumns: `repeat(${grid.columns}, 1fr)`,
                gridTemplateRows: !portrait ? `repeat(${grid.rows}, 1fr)` : 'auto',
                display: 'grid',
                gridGap: '3em',
                transform: `scale(${scale || 1})`,
            };
        } else {
            styles = {
                transform: contentSocialPostUrls.length > 1 ? undefined : `scale(${scale || 1})`,
            };
        }

        const Item = sortable ? SortableItem : ListItem;
        const items =
            contentSocialPostUrls &&
            contentSocialPostUrls.map((contentSocialPostUrl: string, index) => {
                return (
                    <Item
                        width={width}
                        key={`item-${contentSocialPostUrl}`}
                        index={index}
                        sortable={sortable}
                        collection='social-posts'
                        onEmbedLoaded={onEmbedLoaded}
                        contentSocialPostUrl={contentSocialPostUrl}
                        removePostUrl={removePostUrl}
                        mode={mode}
                        showActions={showActions}
                    />
                );
            });

        return (
            <div
                style={styles}
                className={classNames('social_embed-wrapper', {
                    'flex inline horizontal over-x-auto fullbleed': mode === 'list',
                })}>
                {items}
            </div>
        );
    }
);

const SortableItemList = SortableContainer(ItemList);

export default measured(observer(SocialPosts));
