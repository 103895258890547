import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const cinematicCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition) => {
    const { userGroup, postText, media, metaGroup, postTitle } = parts;
    const {
        isInterstitialMedia,
        postOuterClasses,
        postInnerClasses,
        hasMedia,
        noMedia,
        textFormat,
        showPostTextOverlayAsGradient,
    } = config;

    const textOverlayClasses = classNames(
        'fullbleed post_text_overlay post_text_overlay_gradient',
        {}
    );
    return (
        <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
            {!isInterstitialMedia && (
                <div
                    className={classNames(`post_inner flex rel w-100 h-100 vertical`, {
                        [postInnerClasses]: true,
                    })}>
                    {media}

                    {(type === 'b' || type === 'c') && [metaGroup, postTitle, postText]}

                    {userGroup}

                    {type === 'a' && hasMedia && (
                        <div
                            className={classNames('post_text_engagement_group flex vertical rel', {
                                'no-grow': textFormat === 'clamp',
                            })}>
                            {showPostTextOverlayAsGradient && (
                                <div className={textOverlayClasses}></div>
                            )}

                            <div className='rel'>
                                {metaGroup}
                                {postTitle}
                                {postText}
                            </div>
                        </div>
                    )}
                    {type === 'a' && noMedia && [postTitle, postText, metaGroup]}
                </div>
            )}
            {isInterstitialMedia && <div className='post_inner rel w-100 h-100'>{media}</div>}
        </div>
    );
};

export default cinematicCompositions;
