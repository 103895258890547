import { defaultSmartSize } from './SharedConfig';

const defaultClassOverrides = {
    metaGroup: {
        hasMedia: 'p-reset pl-3 pr-3',
        noMedia: 'p-reset pl-2 pr-2',
    },
    userGroup: {
        noMedia: 'p-reset pt-2 pl-2 pr-2 pb-0',
        hasMedia: 'p-reset pt-2 pr-3 pl-3 pb-2',
    },
    postText: {
        noMedia: 'ta-l',
        hasMedia: 'no-grow p-reset pl-3 pr-3 pt-2 pb-2',
    },
    lineClampVerticalAlign: {
        noMedia: 'middle',
        hasMedia: 'bottom',
    },
    reviewRating: {
        default: 'p-0 p-reset pr-2 pl-2 mt-4',
    },
    reviewTitle: {
        default: 'f-ng-reset',
    },
    reviewDomain: {
        default: 'flex left no-grow',
    },
    postTitle: {
        default: 'p-reset pl-2 pr-2 ta-l middle',
    },
};

const retweetOptions = {
    enabled: true,
    opacity: 50,
    size: 13,
};

export const singlePostConfig: any = {
    a: {
        textOnlyTextFormat: 'clamp',
        postLineClamp: true,
        postLineClampValue: 4,
        postTextOnlySize: 40,
        imageBlendMode: null,
        imagePostHideText: false,
        imagePostLineClamp: true,
        postImageTextFormat: 'clamp',
        postImageTextSize: 35,
        imagePostLineClampValue: 6,

        userGroupIconSize: 15,
        socialIconBgColor: false,

        userGroupNameSize: 22,
        userGroupScreenNameSize: 13,
        retweetIndicator: retweetOptions,

        userGroupLayoutType: 'a',
        userGroupPosition: 'top',

        showIconInUserGroup: true,

        imageBlendColor: 'rgba(0,0,0,.0)',

        classOverrides: {
            ...defaultClassOverrides,
        },
        smartSizeCompositions: {
            baseline: {
                postTextOnlySize: 40,
                postImageTextSize: 35,
                userGroupIconSize: 15,
                userGroupNameSize: 22,
                userGroupScreenNameSize: 13,
                userGroupAvatarSize: 27,
                engagementMetricsSize: 30,
                nativeStyling: {
                    fontScale: 50,
                },
                retweetIndicator: {
                    size: 13,
                },
            },
            optionDefaults: {
                showUserGroup: true,
                showEngagementMetrics: true,
                imagePostHideText: false,
                classOverrides: {
                    ...defaultClassOverrides,
                },
            },
            breakpoints: {
                m: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            metaGroup: {
                                hasMedia: 'p-reset pl-2 pr-2',
                                noMedia: 'p-reset pl-1 pr-1',
                            },
                            userGroup: {
                                noMedia: 'p-reset pt-1 pl-1 pr-1 pb-0',
                                hasMedia: 'p-reset pt-1 pr-2 pl-2 pb-1',
                            },
                            postText: {
                                noMedia: 'ta-l',
                                hasMedia: 'no-grow p-reset pl-2 pr-2 pt-1 pb-1',
                            },
                            reviewRating: {
                                default: 'p-0 p-reset pr-1 pl-1 mt-3',
                            },
                        },
                    },
                },
                s: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            metaGroup: {
                                hasMedia: 'p-reset pl-1 pr-1',
                                noMedia: 'p-reset pl-05 pr-05',
                            },
                            userGroup: {
                                noMedia: 'p-reset pt-05 pl-05 pr-05 pb-0',
                                hasMedia: 'p-reset pt-05 pr-1 pl-1 pb-05',
                            },
                            postText: {
                                noMedia: 'ta-l',
                                hasMedia: 'no-grow p-reset pl-1 pr-1 pt-05 pb-05',
                            },
                            reviewRating: {
                                default: 'p-0 p-reset pr-05 pl-05 mt-2',
                            },
                        },
                    },
                },
                xs: {
                    options: {
                        classOverrides: {
                            ...defaultClassOverrides,
                            metaGroup: {
                                hasMedia: 'p-reset pl-05 pr-05',
                                noMedia: 'p-reset pl-05 pr-05',
                            },
                            userGroup: {
                                noMedia: 'p-reset pt-05 pl-05 pr-05 pb-0',
                                hasMedia: 'p-reset pt-05 pr-05 pl-05 pb-05',
                            },
                            postText: {
                                noMedia: 'ta-l',
                                hasMedia: 'no-grow p-reset pl-05 pr-05 pt-05 pb-05',
                            },
                            reviewRating: {
                                default: 'p-0 p-reset pr-05 pl-05 mt-1',
                            },
                        },
                    },
                },
            },
        },
    },
    b: {
        imagePostLineClamp: true,
        imagePostLineClampValue: 6,
        imagePostHideText: false,
        postImageTextFormat: 'clamp',
        postTextOnlySize: 35,

        postImageTextSize: 35,
        userGroupIconSize: 15,
        socialIconBgColor: false,

        userGroupLayoutType: 'c',
        userGroupPosition: 'bottom',

        showIconInUserGroup: false,

        userGroupNameSize: 22,
        userGroupScreenNameSize: 13,
        retweetIndicator: retweetOptions,

        imageBlendColor: 'rgba(0,0,0,.0)',

        classOverrides: {
            postText: {
                hasMedia: 'ta-c',
                noMedia: 'ta-c',
            },
            metaGroup: {
                default: 'p-reset p-2',
            },
            lineClampVerticalAlign: {
                default: 'middle',
            },
            userGroup: {
                review: 'p-0 p-reset',
                hasMedia: 'p-reset pt-1 pl-2 pr-2 pb-25',
                noMedia: 'p-reset pt-2 pb-0',
            },
            domain: {
                default: 'p-reset pt-1 pl-1',
            },
            reviewTitle: {
                default: 'h100 center ta-c mt-1 mb-1 f-ng-reset middle',
            },
            reviewRating: {
                default: 'flex center no-grow mb-2',
            },
            reviewDomain: {
                default: 'flex left no-grow',
            },
            postTitle: {
                default: 'ta-c',
            },
        },
        smartSizeCompositions: {
            ...defaultSmartSize,
        },
    },
    c: {
        userGroupLayoutType: 'c',
        showIconInUserGroup: false,
        userGroupIconSize: 15,
        socialIconBgColor: false,
        userGroupPosition: 'top',

        textOnlyTextFormat: 'fit',

        postImageTextFormat: 'fit',
        imagePostLineClamp: false,

        userGroupNameSize: 22,
        userGroupScreenNameSize: 13,
        retweetIndicator: retweetOptions,

        imageBlendColor: 'rgba(0,0,0,.0)',
        imageBlendMode: null,
        classOverrides: {
            userGroup: {
                default: 'p-reset pt-2 pl-2 pr-2 pb-1',
                noMedia: 'p-reset pt-2 pb-0',
            },
            engagementGroup: {
                default: 'p-reset pb-2 pl-2 pr-2 pt-0',
            },
            postText: {
                hasMedia: 'p-reset pl-2 pr-2 pt-0 pb-2',
                noMedia: '',
            },
            socialIconContainer: {
                hasMedia: 'abs a-right a-bottom m-2',
            },
            reviewRating: {
                default: 'p-reset',
            },
            reviewTitle: {
                default: 'mt-4 f-ng-reset h-33',
            },
            domain: {
                default: 'p-reset mt-1 ml-1',
            },
            postTitle: {
                default: 'p-reset pl-2 pr-2',
            },
        },
        portrait: {
            classOverrides: {
                userGroup: {
                    default: 'p-reset pt-2 pl-2 pr-2 pb-1',
                    noMedia: 'p-reset pt-2 pb-0',
                },
                metaGroup: {
                    default: 'p-reset pb-2 pl-2 pr-2 pt-0',
                },
                postText: {
                    hasMedia: 'p-reset p-2',
                    noMedia: '',
                },
            },
        },
        smartSizeCompositions: {
            ...defaultSmartSize,
        },
    },
};
