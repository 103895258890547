import { Post } from '@sprinklr/stories/post/Post';

export function getRatingInfo(post: Post): Post['ratingInfo'] | null {
    const { maxRating, itemRating, maxNormalized, normalized, rating, ratingInfo, ratingRange } =
        post || {};

    // if any of these values are present we try and coerce the ratingInfo object.
    const hasAlternateRatingInfo =
        maxRating ||
        maxNormalized ||
        ratingRange ||
        itemRating ||
        normalized ||
        rating !== undefined;
    const resolvedRatingInfo =
        ratingInfo ||
        (hasAlternateRatingInfo
            ? {
                  min: 0,
                  max: parseInt(maxRating || maxNormalized || ratingRange || '0', 10),
                  normalized: parseInt(itemRating || normalized, 10),
                  actual: parseInt(itemRating || '0', 10) || rating,
                  note: '',
              }
            : null);

    return resolvedRatingInfo;
}
