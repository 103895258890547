import React from 'react';
import { observer } from 'mobx-react';
import { Grid as VxGrid } from '@vx/grid';

// Grid
type Margin = {
    top: number;
    right: number;
    left: number;
    bottom: number;
};

type GridProps = {
    xScale: any;
    yScale: any;
    width: number;
    height: number;
    xOffset?: number;
    margin: Margin;
    numTicksRows: number;
    numTicksColumns?: number;
};

const Grid = ({
    width,
    height,
    yScale,
    xScale,
    margin,
    numTicksRows,
    numTicksColumns = 0,
    xOffset = 0,
}: GridProps) => (
    <VxGrid
        key='grid'
        xOffset={xOffset}
        top={margin.top}
        left={margin.left}
        xScale={xScale}
        yScale={yScale}
        width={width}
        height={height}
        numTicksRows={numTicksRows}
        numTicksColumns={numTicksColumns}
    />
);

export default observer(Grid);
