import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useStores from '@sprinklr/display-builder/hooks/useStores';

type AuthProps = RouteComponentProps<any> & {
    clientId?: string | number;
};

const Auth: React.FC<AuthProps> = ({ clientId, children, location, history }) => {
    const { authService } = useStores();
    const { isLoggedIn } = authService;

    const parsed = queryString.parse(location?.search);
    let authToken: string;
    if (parsed?.auth) {
        authToken = parsed.auth;
    }

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        // clear auth token from history so that refresh doesn't spiral
        if (authToken) {
            console.debug('Clearing auth token from url');
            history.replace(history.location.pathname);
            return;
        }

        // watch for expired session and redirect
        const onBadSession = () => authService.goToLoginPage();
        authService.onBadSession(onBadSession);
        return () => {
            authService.offBadSession(onBadSession);
        };
    }, [history, authService, authToken, isLoggedIn]);

    if (isLoggedIn === null) {
        console.debug('Session status is unknown, rendering nothing for now...');

        // load user details or initialize user session
        authService.getSessionContext({
            clientId: clientId ? +clientId : undefined,
            authToken,
        });
        return null;
    }

    if (isLoggedIn === false) {
        console.debug('Session expired or unable to load, redirecting to login page...');
        authService.goToLoginPage();
        return null;
    }

    return <>{children}</>;
};

export default withRouter(observer(Auth));
