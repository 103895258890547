import { HeaderDateImpl } from 'components/storyboard/StoryboardHeaderFooter/Header/HeaderDate/options';
import { HeaderDateProps } from 'components/storyboard/StoryboardHeaderFooter/Header/HeaderDate/types';
import { observable, action } from 'mobx';
import { PageNumber, PageNumberAlignment, Footnote } from './types';

export const PageNumberSnippet = `
pageNumber {
        enabled
        alignment
        color
        size
        showTotal
        pageNumberEnabled
        footnote {
            enabled
            color
            size
            text
        }
        date {
            enabled
            size
            color
            format
        }
        isEmpty
     }
`;

export class FootnoteImpl implements Footnote {
    @observable enabled?: boolean = false;
    @observable color?: string = '';
    @observable size?: number = 10;
    @observable text?: string = 'Footnotes';
}

export class PageNumberImpl implements PageNumber {
    @observable enabled?: boolean = false;
    @observable alignment?: PageNumberAlignment = 'right';
    @observable color?: string = '';
    @observable size?: number = 10;
    @observable total?: number;
    @observable showTotal?: boolean = false;

    @observable pageNumberEnabled?: boolean = true;
    @observable footnote?: Footnote = new FootnoteImpl();

    @observable date?: HeaderDateProps = new HeaderDateImpl();

    @observable isEmpty?: boolean = false;

    @action
    setValues(opts?: any): this {
        if (opts) {
            Object.assign(this, opts);
        }
        return this;
    }
}

export class ScenePageNumberImpl implements PageNumber {
    @observable enabled?: boolean = true;
    @observable alignment?: PageNumberAlignment = 'right';
    @observable color?: string = '';
    @observable size?: number = 10;
    @observable total?: number;
    @observable showTotal?: boolean = false;

    @observable pageNumberEnabled?: boolean = true;
    @observable footnote?: Footnote = new FootnoteImpl();

    @observable date?: HeaderDateProps = new HeaderDateImpl();

    @observable isEmpty?: boolean = true;

    @action
    setValues(opts?: any): this {
        if (opts) {
            Object.assign(this, opts);
        }
        return this;
    }
}

export const getEmptyPageNumber = () => {
    return new ScenePageNumberImpl();
};
