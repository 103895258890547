import React, { ReactElement, ReactNode } from 'react';
// Summary
export interface SummaryProps {
    summary: string[];
}
export function Summary({ summary }: SummaryProps): ReactElement {
    return (
        <ul key='summary' className='story_summary flex vertical secondary_font_family '>
            {summary?.map((summaryItem, index) => (
                <li className={'story_summary_item ml-1'} key={index}>
                    {summaryItem}
                </li>
            ))}
        </ul>
    );
}
