import styled, { css } from 'components/_UI/designSystem';
import { Flexbox, SpacingSizes } from 'components/spaceKit';

// NativeFlex

const getSpacing = size => {
    if (size && typeof size === 'boolean') {
        return '1em';
    }
    switch (size) {
        case 'xs':
            return '.25em';
        case 's':
            return '.5em';
        case 'm':
            return '.75em';
        case 'l':
            return '1em';
        case 'xl':
            return '1.1em';
        case 'xxl':
            return '1.5em';
    }
};

const NativeFlex = styled(Flexbox)<{ gap?: SpacingSizes; vertical?: boolean; pad?: SpacingSizes }>`
    min-height: 0;
    ${({ gap, vertical }) =>
        gap &&
        vertical &&
        css`
            > * + * {
                margin-top: ${getSpacing(gap)};
            }
        `}
    ${({ gap, vertical }) =>
        gap &&
        !vertical &&
        css`
            > * + * {
                margin-left: ${getSpacing(gap)};
                margin-inline-start: ${getSpacing(gap)};
            }
        `}
    ${({ pad }) =>
        css`
            padding: ${getSpacing(pad)};
        `}
`;

export default NativeFlex;
