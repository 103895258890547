import * as React from 'react';
import classNames from 'classnames';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import PostText from '../PostText/PostText';
import EngagementMetrics from '../EngagementMetrics/EngagementMetrics';
import { Post, PostEntities } from '@sprinklr/stories/post/Post';
import PostImage from '../PostImage/PostImage';
import UserGroup from '../UserGroup/UserGroup';
import Video from '../Video/Video';
import { PlayerInterval } from 'utils/PlayerInterval/PlayerInterval';
import { PostTextFormat, TimeStampOptions } from '@sprinklr/stories/widget/types';

import './PostComment.scss';

export interface CommentsOptions {
    enabled: boolean;
    background: {
        color: string;
    };
    animation: {
        enabled: boolean;
        duration: number;
    };
    comment: {
        background: {
            color: string;
            brandEnabled: boolean;
        };
        text: {
            color: string;
            size: number;
            format: PostTextFormat;
            scale: {
                // scales the comment text and original message text/engagement
                enabled: boolean;
                value: number;
            };
            clamp: {
                enabled: boolean;
                lines: number;
            };
            teleprompter: {
                speed: number;
            };
        };
        userGroup: {
            timeStamp: {
                enabled: boolean;
                size: number;
                format: TimeStampOptions;
                endianTime: string;
            };
        };
    };
}

type PostCommentProps = {
    playerInterval?: PlayerInterval;
    comments: Post[];
    animationDuration: number;
    channel: string;
    isActive: boolean;
    originalScreenName: string;
    showEngagement: boolean;
    showZeroEngagementValues: boolean;
    options?: CommentsOptions;
};

class PostComment extends React.Component<PostCommentProps, any> {
    static defaultProps: PostCommentProps = {
        comments: [],
        animationDuration: 20,
        channel: '',
        isActive: false,
        originalScreenName: '',
        showEngagement: false,
        showZeroEngagementValues: false,
    };

    private commentInterval: number;
    private commentTimeout: any;
    private items: { [key: string]: number } = {};
    private scrollerHeight = 0;

    @observable
    private currentCommentOffset = 0;

    get currentY(): number {
        return Object.keys(this.items)
            .slice(0, this.currentCommentOffset)
            .reduce((prev, key) => prev + this.items[key], 0);
    }

    componentDidMount() {
        if (this.props.isActive) {
            this.startAnimation();
        }
    }

    UNSAFE_componentWillUpdate(nextProps: PostCommentProps, nextState) {
        if (!this.props.isActive) {
            if (nextProps.isActive) {
                this.startAnimation();
            }
        } else {
            if (!nextProps.isActive) {
                this.forceStopAnimation();
            }
        }
    }

    componentWillUnmount() {
        this.clearInterval();
    }

    render() {
        const { comments } = this.props;

        return (
            <div className='post_comments-container flex vertical w-50 h-100 rel'>
                {comments.length > 0 ? this.renderComments(comments) : <div />}
            </div>
        );
    }

    // recursively render comments
    renderComments = (comments: Post[]): React.ReactNode => {
        const renderCommentList = comments.map((comment: Post, index) => {
            let nestedComments;
            if (comment.comments && comment.comments.length > 0) {
                nestedComments = this.renderComments(comment.comments);
            }

            const textEntities: { [key: string]: PostEntities[] } = comment.textEntities;
            const commentStyles: any = {};
            const { snStats, images, videos, snType } = comment;
            const {
                showEngagement,
                originalScreenName,
                channel,
                showZeroEngagementValues,
                options,
            } = this.props;

            const numLikes = snStats && snStats.numLikes;
            const numRetweets = snStats && snStats.numRetweets;
            const numComments = snStats && snStats.numComments;
            const numShares = snStats && snStats.numShares;

            if (index < this.currentCommentOffset) {
                commentStyles.opacity = 1;
                commentStyles.transform = 'translateX(0)';
            }

            return (
                <div
                    key={comment.snMsgId}
                    ref={node => this.onEnter(node, comment.snMsgId)}
                    style={commentStyles}
                    className={classNames(`post_comment post_comment_index_${index + 1}  flex`, {
                        post_comment_brand: comment.senderProfile.screenName === originalScreenName,
                    })}>
                    <div className='post_comment_inner flex rel w-100 h-100 vertical'>
                        <UserGroup
                            key='userGroup'
                            classes='no-grow'
                            icon={comment.senderProfile && comment.senderProfile.profileImgUrl}
                            full_name={comment.senderProfile && comment.senderProfile.name}
                            screen_name={comment.senderProfile && comment.senderProfile.screenName}
                            time={comment.snCreatedTime}
                            channel={comment.snType}
                            layoutType={'a'}
                            postLayoutType={'b'}
                            showAvatar={true}
                            showIcon={false}
                            showTimeStampInUserGroup={true}
                            timeStampFormat={
                                (options && options.comment.userGroup.timeStamp.format) || null
                            }
                            showScreenName={true}
                            showFullName={true}
                            socialIconBgColor={false}
                            userGroupPosition={'top'}
                            showPostTextOverlay={false}
                            showTimeStamp={options && options.comment.userGroup.timeStamp.enabled}
                            endianTime={'D MMM YYYY, h:mm a'}
                            userGroupGradientEnabled={false}
                        />

                        <PostText
                            classes='h-50'
                            isComment
                            blastText={false}
                            textSize={6}
                            textFormat={options && options.comment.text.format}
                            clamp={options && options.comment.text.clamp.lines}
                            textEntities={textEntities}
                            text={comment.message}
                            showPostTextOverlay={false}
                            stripLinks={false}
                            channel={channel}
                        />

                        {images && images.length > 0 && (
                            <div className='rel flex h-50' style={{ minHeight: '200px' }}>
                                <PostImage
                                    src={images.length > 0 && images[0].url}
                                    imageBackgroundSize='contain'
                                    showSecondaryImageOverlay={false}
                                    hasOverlay={false}
                                />
                            </div>
                        )}

                        {videos && videos.length > 0 && (
                            <div className='rel flex h-50' style={{ minHeight: '200px' }}>
                                <Video
                                    type={snType}
                                    url={videos[0].url}
                                    poster={
                                        videos[0].poster || (images.length && images[0].url) || null
                                    }
                                    width={400}
                                    height={400}
                                    play={true}
                                    hasOverlay={false}
                                    hidePosterAfterPlay={true}
                                    showControls={false}
                                    autoplay={true}
                                    showSecondaryImageOverlay={false}
                                    imageBackgroundSize='contain'
                                />
                            </div>
                        )}

                        {showEngagement && (
                            <EngagementMetrics
                                channel={channel}
                                likes={numLikes}
                                retweets={numRetweets}
                                comments={numComments}
                                shares={numShares}
                                showZeroEngagementValues={showZeroEngagementValues}
                            />
                        )}
                        {nestedComments}
                    </div>
                </div>
            );
        });

        // offset the scroll by the bottom padding of 2em
        const scrollCalc = `calc(${this.scrollerHeight - this.currentY + 'px'} - ${2 *
            this.currentCommentOffset}em)`;

        // these styles will scroll the post_comments-scroller
        const wrapStyles = {
            transform: `translateY(${this.scrollerHeight > 0 ? scrollCalc : '100%'})`,
        };

        return (
            <div
                className='post_comments-scroller abs bottom h100 w-100 pl-2'
                style={wrapStyles}
                ref={this.scrollerNode}>
                {renderCommentList}
            </div>
        );
    };

    private clearInterval() {
        if (this.commentInterval) {
            clearInterval(this.commentInterval);
            this.commentInterval = 0;
        }

        if (this.commentTimeout) {
            clearTimeout(this.commentTimeout);
            this.commentTimeout = 0;
        }
    }

    private startAnimation() {
        const { playerInterval } = this.props;

        if (playerInterval) {
            playerInterval.pause();
        }

        this.clearInterval();
        this.commentInterval = window.setInterval(
            () => this.tick(),
            this.props.animationDuration * 1000
        );
    }

    @action
    private stopAnimation(lastFrame: boolean) {
        const { playerInterval } = this.props;

        this.clearInterval();

        // Give the last comment time to appear before moving on
        if (lastFrame && playerInterval && playerInterval.isPaused()) {
            this.commentTimeout = setTimeout(() => {
                playerInterval.resume();
            }, this.props.animationDuration * 1000);
        }
    }

    @action
    private forceStopAnimation() {
        this.currentCommentOffset = 0;
        this.stopAnimation(false);
    }

    private scrollerNode = node => {
        if (!node) {
            return;
        }
        this.scrollerHeight = node.offsetHeight;
    };

    // set the height of each of the comments so we can animate in
    private onEnter = (node: HTMLElement, id: string) => {
        if (!node) {
            return;
        }
        this.items[id] = node.offsetHeight;
    };

    // create the interval to cycle through the comments
    @action
    private tick = () => {
        this.currentCommentOffset++;
        if (this.currentCommentOffset === this.props.comments.length) {
            this.stopAnimation(true);
        }
    };
}

export default observer(PostComment);
